import { createIcon } from '@chakra-ui/react';

export const IconSettingsCog: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconSettingsCog',
  path: (
    <path
      clipRule="evenodd"
      d="M4.41588 15.1737C3.83938 15.4869 3.10627 15.2781 2.77919 14.712L2.16094 13.6402C1.83382 13.073 2.02058 12.3353 2.58317 11.9896L2.97291 11.7518C3.2165 11.6022 3.41486 11.2484 3.41486 10.9607L3.41371 9.27295C3.41371 8.98528 3.21535 8.63267 2.9706 8.48185L2.58201 8.24406C2.02176 7.90073 1.83385 7.16067 2.16094 6.59344L2.77919 5.52279C3.1063 4.95556 3.83822 4.74793 4.41819 5.06344L4.81955 5.28035C5.07126 5.41723 5.47723 5.41259 5.72548 5.26875L7.18819 4.42548C7.43642 4.28164 7.64407 3.93483 7.65101 3.64715L7.66377 3.19127C7.68117 2.53359 8.2275 2.00116 8.88174 2H10.1183C10.7736 1.99884 11.3188 2.52893 11.3362 3.18664L11.349 3.64599C11.3559 3.9325 11.5636 4.28048 11.8118 4.42432L13.2745 5.26878C13.5227 5.41262 13.9276 5.41726 14.1804 5.28038L14.5841 5.06115C15.1606 4.74796 15.8937 4.95676 16.2208 5.52282L16.8391 6.59462C17.1662 7.16186 16.9794 7.89956 16.4168 8.24524L16.0271 8.48303C15.7835 8.63267 15.5851 8.98645 15.5851 9.27413L15.5863 10.9619C15.5863 11.2495 15.7846 11.6022 16.0294 11.753L16.418 11.9908C16.9782 12.3341 17.1662 13.0742 16.8391 13.6414L16.2208 14.712C15.8937 15.2793 15.1618 15.4869 14.5818 15.1714L14.1805 14.9545C13.9287 14.8176 13.5228 14.8222 13.2745 14.9661L11.8118 15.8093C11.5636 15.9532 11.3559 16.3 11.349 16.5877L11.3362 17.0436C11.3188 17.7012 10.7725 18.2337 10.1183 18.2348H8.88174C8.22637 18.236 7.68118 17.7059 7.66377 17.0482L7.65101 16.5888C7.64405 16.3023 7.43642 15.9543 7.18819 15.8105L5.72548 14.966C5.47725 14.8222 5.07243 14.8176 4.81955 14.9544L4.41588 15.1737ZM7.71802 13.2032C9.422 14.188 11.6015 13.6034 12.5853 11.8994C13.5701 10.1954 12.9855 8.01591 11.2815 7.03211C9.57754 6.04731 7.39803 6.63192 6.41424 8.3359C5.42944 10.0399 6.01404 12.2194 7.71802 13.2032Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
  viewBox: '0 0 20 20',
});
