import { createIcon } from '@chakra-ui/react';

export const IconExchange: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconExchange',
  path: (
    <>
      <g clipPath="url(#clip0_1639_28067)">
        <path
          clipRule="evenodd"
          d="M22.0078 1.96875H17.3579C17.1701 1.96874 16.9841 2.00572 16.8107 2.0776C16.6372 2.14947 16.4796 2.25482 16.3468 2.38763L5.4472 13.287H5.4473L3.84066 14.8937C3.57252 15.1618 3.42188 15.5255 3.42188 15.9047V20.5547C3.42188 21.3442 4.06191 21.9843 4.85147 21.9844H9.50156C9.88078 21.9844 10.2445 21.8337 10.5126 21.5655L12.1193 19.9589V19.959L23.0188 9.05944C23.2869 8.79127 23.4375 8.4276 23.4375 8.04839V3.39844C23.4375 2.60892 22.7975 1.96884 22.0079 1.96875H22.0078Z"
          fill="#28A8EA"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M4.85156 1.96875H9.50156C9.88074 1.96875 10.2444 2.11937 10.5125 2.38748L13.4297 5.3047V9.42455C13.4297 10.834 12.2871 11.9766 10.8777 11.9766H6.75783L3.84061 9.05939C3.5725 8.79126 3.42187 8.42762 3.42188 8.04844V3.39844C3.42188 2.60883 4.06195 1.96875 4.85156 1.96875Z"
          fill="#50D9FF"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M22.0078 21.9844H17.3578C17.1701 21.9844 16.9841 21.9474 16.8107 21.8756C16.6372 21.8037 16.4796 21.6984 16.3469 21.5656L13.4297 18.6484V14.5286C13.4297 13.1191 14.5723 11.9766 15.9817 11.9766H20.1015L23.0188 14.8937C23.1515 15.0265 23.2568 15.1841 23.3287 15.3576C23.4005 15.531 23.4375 15.7169 23.4375 15.9047V20.5547C23.4375 21.3443 22.7974 21.9844 22.0078 21.9844Z"
          fill="#0078D4"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M12.4766 5.54297H3.42188V19.125H12.4766C13.0023 19.1235 13.4282 18.6976 13.4297 18.1718V6.49608C13.4281 5.97028 13.0023 5.54452 12.4765 5.54297H12.4766Z"
          fill="black"
          fillOpacity="0.1"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11.7617 6.25781H3.42188V19.8398H11.7617C12.2875 19.8383 12.7133 19.4124 12.7149 18.8867V7.21092C12.7133 6.68512 12.2875 6.25936 11.7617 6.25781H11.7617Z"
          fill="black"
          fillOpacity="0.2"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11.7617 6.25781H3.42188V18.4102H11.7617C12.2875 18.4086 12.7133 17.9827 12.7149 17.457V7.21092C12.7133 6.68512 12.2875 6.25936 11.7617 6.25781H11.7617Z"
          fill="black"
          fillOpacity="0.2"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M11.0469 6.25781H3.42188V18.4102H11.0469C11.5726 18.4086 11.9985 17.9827 12 17.457V7.21092C11.9985 6.68512 11.5726 6.25936 11.0468 6.25781H11.0469Z"
          fill="black"
          fillOpacity="0.2"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M1.51561 6.25781H11.0469C11.5733 6.25781 12 6.68456 12 7.21092V16.7422C12 17.2686 11.5733 17.6953 11.0469 17.6953H1.51561C0.989203 17.6953 0.5625 17.2686 0.5625 16.7422V7.21092C0.5625 6.68452 0.989203 6.25781 1.51561 6.25781Z"
          fill="#0078D4"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M8.44255 9.56395H5.35677V11.3684H8.25318V12.525H5.35677V14.3992H8.60699V15.5508H3.95605V8.40234H8.44255V9.564V9.56395Z"
          fill="white"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_1639_28067">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </>
  ),
  viewBox: '0 0 24 24',
});
