import { createIcon } from '@chakra-ui/react';

export const IconCheckAnalytics: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconCheckAnalytics',
  path: (
    <path
      d="M8.33361 12.6433L15.9936 4.98242L17.1728 6.16076L8.33361 14.9999L3.03027 9.69659L4.20861 8.51826L8.33361 12.6433Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 20 20',
});
