interface RefreshTokenProps {
  refreshTokenName?: string;
  returnToken?: boolean;
  sessionName?: string;
  token?: string;
}

interface RefreshTokenResponse {
  token?: string;
}

export const refreshToken = async (props: RefreshTokenProps): Promise<RefreshTokenResponse> => {
  const { refreshTokenName, returnToken, sessionName, token } = props;
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL ?? process.env.HIHELLO_API_URL}/refresh`,
      {
        body: JSON.stringify({ refreshTokenName, returnToken, sessionName, token }),
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      },
    );

    if (!returnToken || !response.ok) {
      return { token: undefined };
    }

    const data = (await response.json()) as RefreshTokenResponse;

    return data;
  } catch (error) {
    return { token: undefined };
  }
};
