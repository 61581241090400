import { Tr as ChakraTr } from '@chakra-ui/react';
import type { TableRowProps } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

type TrProps = TableRowProps & {
  hasHoverEffect?: boolean;
};

export const Tr: FunctionComponent<TrProps> = ({ hasHoverEffect = true, ...tableRowProps }) => (
  <ChakraTr _hover={hasHoverEffect ? { bg: 'gray.50' } : undefined} {...tableRowProps} />
);
