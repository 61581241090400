import { ClientError } from 'graphql-request';

export enum GraphQLErrorCode {
  Forbidden = 'FORBIDDEN',
  ResourceNotFound = 'RESOURCE_NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
}

export function hasGraphQLErrorCode<T extends object>(
  error: T | ClientError,
  codes: GraphQLErrorCode[],
) {
  if ('response' in error) {
    return error.response?.errors?.some((e) => codes.includes(e.extensions.code));
  }

  return false;
}
