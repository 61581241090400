import { createIcon } from '@chakra-ui/react';

export const IconSpotify: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconSpotify',
  path: (
    <path
      d="M12 3C7.02927 3 3 7.02927 3 12C3 16.9707 7.02927 21 12 21C16.9707 21 21 16.9707 21 12C21 7.02988 16.9707 3.00061 12 3ZM16.1275 15.9801C15.966 16.2455 15.6203 16.3283 15.3562 16.1668C13.243 14.8753 10.5826 14.5836 7.44995 15.2991C7.1478 15.3685 6.84688 15.1793 6.7781 14.8772C6.7087 14.575 6.89724 14.2741 7.2 14.2053C10.6281 13.4223 13.5691 13.7594 15.9414 15.2088C16.2055 15.3716 16.2897 15.716 16.1275 15.9801ZM17.2287 13.5304C17.0254 13.8608 16.593 13.964 16.2632 13.7613C13.8448 12.2745 10.1564 11.844 7.29519 12.7124C6.92426 12.8248 6.53244 12.6154 6.41945 12.245C6.30767 11.8741 6.51709 11.4829 6.88741 11.3699C10.1558 10.3781 14.2195 10.8584 16.9971 12.5656C17.3275 12.7689 17.432 13.2006 17.2287 13.5304ZM17.3232 10.9781C14.4221 9.25545 9.63683 9.09705 6.86714 9.93714C6.42252 10.0723 5.9521 9.8211 5.81761 9.37644C5.68311 8.93184 5.93367 8.46141 6.37891 8.32631C9.55824 7.36151 14.8428 7.54759 18.1824 9.53001C18.5822 9.76766 18.7136 10.2841 18.4766 10.6833C18.2402 11.0837 17.7224 11.2158 17.3232 10.9781Z"
      fill="currentColor"
    />
  ),
});
