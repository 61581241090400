import { createIcon } from '@chakra-ui/react';

export const IconPatreon: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconPatreon',
  path: (
    <>
      <path
        clipRule="evenodd"
        d="M15.9426 0.249939C11.0968 0.249939 7.1543 4.20878 7.1543 9.07366C7.1543 13.9222 11.0968 17.8688 15.9426 17.8688C20.7721 17.8688 24.7024 13.9222 24.7024 9.07366C24.7024 4.20878 20.7721 0.249939 15.9426 0.249939Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M0.298828 23.7499H4.5872V0.249939H0.298828V23.7499Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </>
  ),
  viewBox: '0 0 25 24',
});
