import { createIcon } from '@chakra-ui/react';

export const IconText: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconText',
  path: (
    <path
      d="M6.33329 1.75H8.66663C9.9043 1.75 11.0913 2.24167 11.9665 3.11683C12.8416 3.992 13.3333 5.17899 13.3333 6.41667C13.3333 7.65434 12.8416 8.84133 11.9665 9.7165C11.0913 10.5917 9.9043 11.0833 8.66663 11.0833V13.125C5.74996 11.9583 1.66663 10.2083 1.66663 6.41667C1.66663 5.17899 2.15829 3.992 3.03346 3.11683C3.90863 2.24167 5.09562 1.75 6.33329 1.75ZM7.49996 9.91667H8.66663C9.12625 9.91667 9.58138 9.82614 10.006 9.65025C10.4307 9.47435 10.8165 9.21655 11.1415 8.89154C11.4665 8.56653 11.7243 8.1807 11.9002 7.75606C12.0761 7.33142 12.1666 6.87629 12.1666 6.41667C12.1666 5.95704 12.0761 5.50191 11.9002 5.07727C11.7243 4.65263 11.4665 4.2668 11.1415 3.94179C10.8165 3.61679 10.4307 3.35898 10.006 3.18309C9.58138 3.0072 9.12625 2.91667 8.66663 2.91667H6.33329C5.40503 2.91667 4.5148 3.28542 3.85842 3.94179C3.20204 4.59817 2.83329 5.48841 2.83329 6.41667C2.83329 8.5225 4.26946 9.89683 7.49996 11.3633V9.91667Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 14 14',
});
