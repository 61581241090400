import { Box, BoxProps, Skeleton, useStyleConfig } from '@chakra-ui/react';
import { FunctionComponent, useMemo } from 'react';
import Linkify, { Props as LinkifyProps } from 'react-linkify';
import { useStore } from 'zustand';
import { useCardStore } from '../../store';

export type HeadlineProps = BoxProps;

const LinkDecorator: LinkifyProps['componentDecorator'] = (decoratedHref, decoratedText, key) => (
  <a key={key} href={decoratedHref} rel="noopener noreferrer" target="_blank">
    {decoratedText}
  </a>
);

export const Headline: FunctionComponent<HeadlineProps> = (props) => {
  const cardStore = useCardStore();
  const { details, palette, variant } = useStore(cardStore, (state) => ({
    details: state.details,
    palette: state.themePalette,
    variant: state.variant,
  }));
  const styles = useStyleConfig('CardHeadline', { palette });

  const parts = useMemo(() => {
    if (!details?.headline) {
      return undefined;
    }

    const headlineWithMarkdownLinks: Array<string | JSX.Element> = details.headline
      // Replace twitter hashtags and usernames
      .replace(/(?:[^a-zA-Z0-9-_.])(@|#)([A-Za-z]+[A-Za-z0-9-_]+)/gm, (match, prefix, text) => {
        if (prefix === '#') {
          return `[${match}](https://twitter.com/search?q=%23${text})`;
        }

        if (prefix === '@') {
          return `[${match}](https://twitter.com/${text})`;
        }

        return match;
      })

      // Split out all the markdown formatted
      .split(/\[([^[\]]+)\]\(([^)]+)\)/gim);

    for (let part = 1; part <= headlineWithMarkdownLinks.length; part += 3) {
      const text = headlineWithMarkdownLinks[part];
      const link = headlineWithMarkdownLinks[part + 1];

      if (link) {
        headlineWithMarkdownLinks[part] = (
          <a key={`link-${part}`} href={link as string} rel="noopener noreferrer" target="_blank">
            {text}
          </a>
        );
        headlineWithMarkdownLinks[part + 1] = '';
      }
    }

    return headlineWithMarkdownLinks;
  }, [details?.headline]);

  if (
    variant !== 'mini' &&
    (details?.headline === null || details?.headline?.trim().length === 0)
  ) {
    return null;
  }

  const renderContent = () => {
    if (variant === 'mini') {
      return (
        <>
          <Box bg="gray.200" borderRadius="lg" h="4" mb="1.5" w="93.1034%" />
          <Box bg="gray.200" borderRadius="lg" h="4" mb="1.5" w="74.1379%" />
          <Box bg="gray.200" borderRadius="lg" h="4" w="81.0344%" />
        </>
      );
    }

    return parts ? (
      <Linkify componentDecorator={LinkDecorator}>{parts}</Linkify>
    ) : (
      <>
        <Skeleton h="5" mb="1" w="75%" />
        <Skeleton h="5" mb="3" w="60%" />
      </>
    );
  };

  return (
    <Box className="CardHeadline" {...props}>
      <Box __css={styles}>{renderContent()}</Box>
    </Box>
  );
};
