import { getLoginProviders, GetLoginProvidersResponse } from '@src/lib/api';
import { useQuery } from 'react-query';

export function useEnabledLoginProviders({ email }: { email?: string }) {
  const { data, isLoading } = useQuery<GetLoginProvidersResponse>(
    ['getLoginProviders', email],
    () => getLoginProviders(email!),
    {
      enabled: !!email,
    },
  );

  return {
    enabledProviders: data,
    isLoading,
  };
}
