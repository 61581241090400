import { createIcon } from '@chakra-ui/react';

export const IconProfileSwitch: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconProfileSwitch',
  path: (
    <path
      d="M11.8594 5.23438C11.8594 3.09375 10.125 1.35938 7.98441 1.35938C5.84378 1.35938 4.10941 3.09375 4.10941 5.23438C4.10941 6.52812 4.74378 7.675 5.71878 8.37813C5.71253 8.38125 5.70784 8.38281 5.70472 8.38437C5.00628 8.67969 4.37972 9.10313 3.84066 9.64375C3.30159 10.1828 2.87972 10.8109 2.58441 11.5109C2.29534 12.1953 2.14066 12.9219 2.12503 13.6687C2.12347 13.7391 2.17972 13.7969 2.25003 13.7969H3.18597C3.25316 13.7969 3.30941 13.7422 3.31097 13.675C3.34222 12.4688 3.82503 11.3391 4.67972 10.4828C5.56253 9.59688 6.73441 9.10938 7.98441 9.10938C10.125 9.10938 11.8594 7.375 11.8594 5.23438ZM7.98441 7.92188C6.50003 7.92188 5.29691 6.71875 5.29691 5.23438C5.29691 3.75 6.50003 2.54688 7.98441 2.54688C9.46878 2.54688 10.6719 3.75 10.6719 5.23438C10.6719 6.71875 9.46878 7.92188 7.98441 7.92188ZM9.62503 11.375H13.75C13.8188 11.375 13.875 11.3188 13.875 11.25V10.375C13.875 10.3062 13.8188 10.25 13.75 10.25H10.9922L11.7297 9.31094C11.7469 9.28906 11.7563 9.26094 11.7563 9.23438C11.7563 9.16563 11.7 9.10938 11.6313 9.10938H10.4969C10.4203 9.10938 10.3485 9.14531 10.3 9.20469L9.22972 10.5656C9.16097 10.6531 9.12347 10.7625 9.12347 10.875C9.12503 11.1516 9.34847 11.375 9.62503 11.375ZM13.375 12.375H9.25003C9.18128 12.375 9.12503 12.4312 9.12503 12.5V13.375C9.12503 13.4438 9.18128 13.5 9.25003 13.5H12.0078L11.2703 14.4391C11.2532 14.4609 11.2438 14.4891 11.2438 14.5156C11.2438 14.5844 11.3 14.6406 11.3688 14.6406H12.5032C12.5797 14.6406 12.6516 14.6047 12.7 14.5453L13.7703 13.1844C13.8391 13.0969 13.8766 12.9875 13.8766 12.875C13.875 12.5984 13.6516 12.375 13.375 12.375Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 16 16',
});
