import { createIcon } from '@chakra-ui/react';

export const IconWindowsColored: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconWindowsColored',
  path: (
    <path
      d="M68 0L31.3733 5.67683V34.4425L68 34.1343V0ZM0.0202267 37.5638L0.0218667 62.0422L27.7877 66.0732L27.7658 37.7543L0.0202267 37.5638ZM31.0819 37.9564L31.1333 66.5108L67.9656 72L67.9743 38.0204L31.0819 37.9564ZM0 10.1945L0.0256933 34.6607L27.7915 34.4939L27.7789 6.19868L0 10.1945Z"
      fill="#63B3ED"
    />
  ),
  viewBox: '0 0 68 72',
});
