import { FieldType } from './generated';

export const UrlFieldTypes = [
  FieldType.Applemusic,
  FieldType.Behance,
  FieldType.Brightcove,
  FieldType.Calendly,
  FieldType.Cashapp,
  FieldType.Discord,
  FieldType.Dribbble,
  FieldType.Facebook,
  FieldType.File,
  FieldType.Github,
  FieldType.Instagram,
  FieldType.Line,
  FieldType.Link,
  FieldType.Linkedin,
  FieldType.Patreon,
  FieldType.Paypal,
  FieldType.Pinterest,
  FieldType.Snapchat,
  FieldType.Soundcloud,
  FieldType.Spotify,
  FieldType.Telegram,
  FieldType.Tiktok,
  FieldType.Twitch,
  FieldType.Twitter,
  FieldType.Venmo,
  FieldType.Vimeo,
  FieldType.Website,
  FieldType.Whatsapp,
  FieldType.Xing,
  FieldType.Yelp,
  FieldType.Youtube,
];
