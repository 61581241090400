import { useState } from 'react';

export const useGroupToggle = (
  items: (string | number | symbol)[] = [],
  initialItem: string | number | symbol | null = null,
) => {
  const [state, setState] = useState(
    items.reduce((acc, item) => ({ ...acc, [item]: item === initialItem }), {}),
  );

  const toggle = (item: string | number | symbol) => {
    setState({
      ...items.reduce((acc, curr) => ({ ...acc, [curr]: false }), {}),
      [item]: true,
    });
  };

  return { state, toggle };
};
