import { createIcon } from '@chakra-ui/react';

export const IconUser: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconUser',
  path: (
    <>
      <path
        d="M2 14C2 10.9875 4.442 8.54547 7.45455 8.54547H8.54545C11.558 8.54547 14 10.9875 14 14"
        stroke="currentColor"
        strokeLinecap="square"
        strokeMiterlimit="10"
      />
      <path
        d="M8.00124 8.54545C9.80872 8.54545 11.274 7.0802 11.274 5.27273C11.274 3.46525 9.80872 2 8.00124 2C6.19377 2 4.72852 3.46525 4.72852 5.27273C4.72852 7.0802 6.19377 8.54545 8.00124 8.54545Z"
        stroke="currentColor"
        strokeLinecap="square"
        strokeMiterlimit="10"
      />
    </>
  ),
  viewBox: '0 0 16 16',
});
