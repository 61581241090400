import { authWithEmailAndPassword } from '@src/lib/api/authWithEmailAndPassword';
import { authWithOAuth } from '@src/lib/api/authWithOAuth';
import { authWithToken } from '@src/lib/api/authWithToken';
import { getValidNextUrl, getClientId } from '@src/lib/utils';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { AuthMethod, OAuthProviderId } from './helpers';

function useAuthWithEmailAndPassword(isSignUp?: boolean) {
  const router = useRouter();
  const nextUrl = getValidNextUrl(router.query);
  const clientId = getClientId(router.query.clientId?.toString() || '');
  return useCallback(
    async (email: string, password: string, persistence?: boolean) => {
      await authWithEmailAndPassword({
        clientId,
        email,
        isSignUp,
        nextUrl,
        password,
        persist: Boolean(persistence),
      });
    },
    [isSignUp, nextUrl, clientId],
  );
}

function useAuthWithOAuth() {
  const router = useRouter();
  const nextUrl = getValidNextUrl(router.query);
  const clientId = getClientId(router.query.clientId?.toString() || '');

  return useCallback(
    async (id: OAuthProviderId) => {
      if (id !== OAuthProviderId.WorkOS) {
        await authWithOAuth({ clientId, issuer: id, nextUrl });
      }
    },
    [nextUrl, clientId],
  );
}

function useAuthWithToken() {
  const router = useRouter();
  const nextUrl = getValidNextUrl(router.query);
  const clientId = getClientId(router.query.clientId?.toString() || '');

  return useCallback(
    async (token: string) => {
      await authWithToken({ clientId, nextUrl, token });
    },
    [nextUrl, clientId],
  );
}

export function useAuthMethod<T extends AuthMethod>(method: T, isSignUp?: boolean) {
  const handler = {
    [AuthMethod.EmailAndPassword]: useAuthWithEmailAndPassword(isSignUp),
    [AuthMethod.OAuth]: useAuthWithOAuth(),
    [AuthMethod.Token]: useAuthWithToken(),
  }[method];

  return handler;
}
