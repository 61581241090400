/* eslint-disable no-console */
export const logger = {
  error(...data: any[]) {
    console.error(...data);
  },
  info(...data: any[]) {
    console.info(...data);
  },
  warn(...data: any[]) {
    console.warn(...data);
  },
};
