import { createIcon } from '@chakra-ui/react';

export const IconOptional: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconOptional',
  path: (
    <path
      d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM5.53098 12C5.53098 15.5727 8.42726 18.469 12 18.469C15.5727 18.469 18.469 15.5727 18.469 12C18.469 8.42726 15.5727 5.53098 12 5.53098C8.42726 5.53098 5.53098 8.42726 5.53098 12Z"
      fill="currentColor"
    />
  ),
});
