import { HStack, StackProps, IconButton, IconButtonProps, Text, TextProps } from '@chakra-ui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import { IconCheck, IconCopy } from '../Icons';
import { useCopyToClipboard } from './useCopyToClipboard';

type TextCopyProps = StackProps & {
  iconButtonProps?: IconButtonProps;
  onAfterCopy?: () => void;
  showCopiedIndicator?: boolean;
  text: string;
  textProps?: TextProps;
  textToCopy?: string;
};

export const TextCopy: FunctionComponent<TextCopyProps> = ({
  iconButtonProps,
  onAfterCopy,
  showCopiedIndicator = true,
  text,
  textProps,
  textToCopy,
  ...stackProps
}) => {
  const [copyIconOpacity, setCopyIconOpacity] = useState(0);

  const showCopyIcon = () => setCopyIconOpacity(1);
  const hideCopyIcon = () => setCopyIconOpacity(0);

  const { copyToClipboard, isCopied } = useCopyToClipboard(textToCopy ?? text);

  useEffect(() => {
    if (isCopied && onAfterCopy) {
      onAfterCopy();
    }
  }, [isCopied, onAfterCopy]);

  return (
    <HStack
      color="gray.500"
      onMouseEnter={showCopyIcon}
      onMouseLeave={hideCopyIcon}
      {...stackProps}
    >
      <Text fontSize="xs" lineHeight="100%" {...textProps}>
        {text}
      </Text>
      <IconButton
        aria-label="copy"
        icon={isCopied && showCopiedIndicator ? <IconCheck /> : <IconCopy />}
        onClick={copyToClipboard}
        opacity={copyIconOpacity}
        size="xs"
        transition="opacity 400ms"
        variant="ghost"
        {...iconButtonProps}
      />
    </HStack>
  );
};
