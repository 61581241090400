import { createIcon } from '@chakra-ui/react';

export const IconBuilding: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconBuilding',
  path: (
    <>
      <path
        d="M10.3125 7.69928H14.6563C14.7813 7.69928 14.9063 7.57517 14.9063 7.45107C14.9063 7.32696 14.7813 7.20286 14.6563 7.20286H10.3125C10.1875 7.20286 10.0625 7.32696 10.0625 7.45107C10.0625 7.57517 10.1875 7.69928 10.3125 7.69928Z"
        fill="currentColor"
      />
      <path
        d="M10.3125 9.31265H14.6563C14.7813 9.31265 14.9063 9.18854 14.9063 9.06444C14.9063 8.94033 14.7813 8.81623 14.6563 8.81623H10.3125C10.1875 8.81623 10.0625 8.94033 10.0625 9.06444C10.0625 9.21957 10.1875 9.31265 10.3125 9.31265Z"
        fill="currentColor"
      />
      <path
        d="M14.6563 12.5704H10.3125C10.1875 12.5704 10.0625 12.4463 10.0625 12.3222C10.0625 12.1981 10.1875 12.074 10.3125 12.074H14.6563C14.7813 12.074 14.9063 12.1981 14.9063 12.3222C14.9063 12.4463 14.7813 12.5704 14.6563 12.5704Z"
        fill="currentColor"
      />
      <path
        d="M10.3125 10.926H14.6563C14.7813 10.926 14.9063 10.8019 14.9063 10.6778C14.9063 10.5537 14.7813 10.4296 14.6563 10.4296H10.3125C10.1875 10.4296 10.0625 10.5537 10.0625 10.6778C10.0625 10.8019 10.1875 10.926 10.3125 10.926Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M20.125 17.0072H21.4688C21.75 17.0072 21.9688 17.2243 22 17.5036C22 17.7828 21.7812 18 21.5 18H3.5C3.21875 18 3 17.7828 3 17.5036C3 17.2243 3.21875 17.0072 3.5 17.0072H4.875V8.56802C4.875 8.47494 4.90625 8.38186 4.96875 8.31981C5.03125 8.25776 5.125 8.22673 5.21875 8.22673H8.1875V5.34129C8.1875 5.24821 8.21875 5.15513 8.28125 5.09308C8.34375 5.03103 8.4375 5 8.53125 5H16.4688C16.5625 5 16.6562 5.03103 16.75 5.12411C16.8125 5.18616 16.8438 5.27924 16.8438 5.37232V8.28878H19.7812C19.875 8.28878 19.9688 8.31981 20.0313 8.38186C20.0938 8.44391 20.125 8.53699 20.125 8.63007V17.0072ZM5.59375 17.0072H6.5V13.4391H5.59375V17.0072ZM5.59375 12.6945H6.5V8.97136H5.59375V12.6945ZM7.25 17.0072H8.15625V13.4391H7.25V17.0072ZM7.25 12.6945H8.15625V8.97136H7.25V12.6945ZM11.9062 17.0072H13.0312V14.494H11.9062V17.0072ZM16.0938 5.7136H8.875V17.0072H11.4375V14.2458L11.5 14.0597L11.6875 13.9976H13.2813L13.4688 14.0597L13.5313 14.2458V17.0072H16.0938V5.7136ZM17.7188 9.00239H16.8125V12.7255H17.7188V9.00239ZM16.8438 17.0072H17.75V13.4391H16.8438V17.0072ZM18.4688 17.0072H19.375V13.4391H18.4688V17.0072ZM18.4688 12.6945H19.375V8.97136H18.4688V12.6945Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </>
  ),
});
