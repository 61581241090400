import { createIcon } from '@chakra-ui/react';

export const IconAddPhoto: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconAddPhoto',
  path: (
    <>
      <path
        d="M9.5 3V9H5.5V10H9.5C10.0515 10 10.5 9.5515 10.5 9V3C10.5 2.4485 10.0515 2 9.5 2L3 2C2.4485 2 2 2.4485 2 3V6.5H3L3 3L9.5 3Z"
        fill="currentColor"
      />
      <path
        d="M5.22727 5.83333L4 7.5H8.5L6.86364 5L5.63636 6.66667L5.22727 5.83333Z"
        fill="currentColor"
      />
      <path
        d="M3.375 7.5H2.625V8.625H1.5V9.375H2.625V10.5H3.375V9.375H4.5V8.625H3.375V7.5Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: '0 0 12 12',
});
