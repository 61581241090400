import { getLoginConfiguration } from '@src/lib/api';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';

export function useLoginConfiguration() {
  const router = useRouter();
  const configId = router.query.configurationId || router.query.configuration_id;

  const { data, isLoading } = useQuery(['getLoginConfig', configId], () =>
    getLoginConfiguration(configId?.toString() ?? ''),
  );

  return { isLoading, loginMethods: data };
}
