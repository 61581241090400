import { createIcon } from '@chakra-ui/react';

export const IconGoogleG: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconGoogleG',
  path: (
    <>
      <path
        d="M9.54487 10.1155C10.9044 8.84661 11.5086 6.73184 11.146 4.7077H5.73828V6.94332H8.8198C8.69896 7.66838 8.27601 8.2726 7.67179 8.66534L9.54487 10.1155Z"
        fill="currentColor"
      />
      <path
        d="M0.603516 8.33306C1.00094 9.11594 1.5709 9.7984 2.27044 10.329C2.96997 10.8595 3.78082 11.2244 4.64186 11.396C5.50291 11.5676 6.39168 11.5416 7.24118 11.3198C8.09068 11.0979 8.87875 10.6862 9.54597 10.1155L7.67289 8.66538C6.07171 9.72277 3.41314 9.33002 2.50681 6.85272L0.603516 8.33306Z"
        fill="currentColor"
      />
      <path
        d="M2.50633 6.8527C2.26464 6.09743 2.26464 5.40258 2.50633 4.6473L0.60304 3.16696C-0.0918133 4.55667 -0.30329 6.52038 0.60304 8.33304L2.50633 6.8527Z"
        fill="currentColor"
      />
      <path
        d="M2.50681 4.64732C3.17145 2.56276 6.01128 1.35432 7.91458 3.13677L9.57618 1.50538C7.21972 -0.76045 2.62765 -0.669817 0.603516 3.16698L2.50681 4.64732Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: '0 0 12 12',
});
