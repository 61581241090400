import { createIcon } from '@chakra-ui/react';

export const IconRestore: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconRestore',
  path: (
    <path
      clipRule="evenodd"
      d="M5.27586 7.94828C6.01243 7.94828 6.73245 7.72986 7.34488 7.32065C7.95731 6.91143 8.43464 6.3298 8.71652 5.6493C8.99839 4.96881 9.07214 4.22001 8.92844 3.4976C8.78474 2.77518 8.43005 2.11161 7.90922 1.59078C7.38839 1.06995 6.72482 0.715257 6.0024 0.57156C5.27999 0.427863 4.53119 0.501614 3.8507 0.783485C3.1702 1.06536 2.58857 1.54269 2.17935 2.15512C1.77014 2.76755 1.55172 3.48757 1.55172 4.22414V6.14828L0.434483 5.03104L0 5.46552L1.86207 7.32759L3.72414 5.46552L3.28965 5.03104L2.17241 6.14828V4.22414C2.17241 3.61034 2.35443 3.01032 2.69544 2.49996C3.03645 1.9896 3.52114 1.59182 4.08822 1.35693C4.6553 1.12203 5.2793 1.06058 5.88131 1.18032C6.48332 1.30007 7.03631 1.59565 7.47033 2.02967C7.90436 2.4637 8.19993 3.01668 8.31968 3.61869C8.43942 4.2207 8.37797 4.8447 8.14307 5.41178C7.90818 5.97886 7.5104 6.46355 7.00004 6.80456C6.48969 7.14557 5.88967 7.32759 5.27586 7.32759V7.94828Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
  viewBox: '0 0 9 8',
});
