import { createIcon } from '@chakra-ui/react';

export const IconDynamics: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconDynamics',
  path: (
    <>
      <g clipPath="url(#clip0)">
        <mask
          height="96"
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          width="96"
          x="0"
          y="0"
        >
          <path
            d="M95.6804 53.5695C95.8915 51.7423 96 49.8838 96 48C96 21.4903 74.5097 0 48 0C46.1162 0 44.2577 0.108519 42.4305 0.319647C41.0015 0.929827 40 2.34796 40 4V12.0468C17.7327 13.0907 0 31.4745 0 54C0 77.196 18.804 96 42 96C64.5255 96 82.9093 78.2673 83.9532 56H92C93.652 56 95.0702 54.9985 95.6804 53.5695Z"
            fill="url(#paint0_linear)"
          />
        </mask>
        <g mask="url(#mask0)">
          <circle cx="42" cy="54" fill="url(#paint1_linear)" r="42" />
          <g filter="url(#filter0_f)">
            <path
              d="M95.6804 55.5695C95.8915 53.7423 96 51.8838 96 50C96 23.4903 74.5097 2 48 2C46.1162 2 44.2577 2.10852 42.4305 2.31965C41.0015 2.92983 40 4.34796 40 6V58H92C93.652 58 95.0702 56.9985 95.6804 55.5695Z"
              fill="black"
              fillOpacity="0.32"
            />
          </g>
          <g filter="url(#filter1_f)">
            <path
              d="M95.6804 53.9495C95.8915 52.1223 96 50.2638 96 48.38C96 21.8703 74.5097 0.380005 48 0.380005C46.1162 0.380005 44.2577 0.488524 42.4305 0.699652C41.0015 1.30983 40 2.72796 40 4.38001V56.38H92C93.652 56.38 95.0702 55.3785 95.6804 53.9495Z"
              fill="black"
              fillOpacity="0.24"
            />
          </g>
          <path
            clipRule="evenodd"
            d="M95.6804 53.5695C95.8915 51.7423 96 49.8838 96 48C96 21.4903 74.5097 0 48 0C46.1162 0 44.2577 0.108519 42.4305 0.319647C41.0015 0.929827 40 2.34796 40 4V56H92C93.652 56 95.0702 54.9985 95.6804 53.5695Z"
            fill="url(#paint2_linear)"
            fillRule="evenodd"
          />
          <mask
            height="56"
            id="mask1"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            width="56"
            x="40"
            y="0"
          >
            <path
              clipRule="evenodd"
              d="M95.6804 53.5695C95.8915 51.7423 96 49.8838 96 48C96 21.4903 74.5097 0 48 0C46.1162 0 44.2577 0.108519 42.4305 0.319647C41.0015 0.929827 40 2.34796 40 4V56H92C93.652 56 95.0702 54.9985 95.6804 53.5695Z"
              fill="url(#paint3_linear)"
              fillRule="evenodd"
            />
          </mask>
          <g mask="url(#mask1)">
            <g filter="url(#filter2_f)">
              <path
                d="M40 32H62C64.2091 32 66 33.7909 66 36V58H40V32Z"
                fill="black"
                fillOpacity="0.32"
              />
            </g>
            <g filter="url(#filter3_f)">
              <path
                d="M40 30.38H62C64.2091 30.38 66 32.1709 66 34.38V56.38H40V30.38Z"
                fill="black"
                fillOpacity="0.24"
              />
            </g>
            <path d="M40 30H62C64.2091 30 66 31.7909 66 34V56H40V30Z" fill="url(#paint4_linear)" />
          </g>
        </g>
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="72"
          id="filter0_f"
          width="72"
          x="32"
          y="-6"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur" stdDeviation="4" />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="57.52"
          id="filter1_f"
          width="57.52"
          x="39.24"
          y="-0.379995"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur" stdDeviation="0.38" />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="42"
          id="filter2_f"
          width="42"
          x="32"
          y="24"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur" stdDeviation="4" />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="27.52"
          id="filter3_f"
          width="27.52"
          x="39.24"
          y="29.62"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur" stdDeviation="0.38" />
        </filter>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear"
          x1="41.8995"
          x2="52.9186"
          y1="30.211"
          y2="58.0298"
        >
          <stop stopColor="#A7F2FF" />
          <stop offset="1" stopColor="#7CECFF" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear"
          x1="30.8348"
          x2="58.9012"
          y1="40.3526"
          y2="92.7582"
        >
          <stop stopColor="#0078D4" />
          <stop offset="1" stopColor="#2064AE" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear"
          x1="56"
          x2="109.184"
          y1="9.5"
          y2="57.733"
        >
          <stop stopColor="#40C4F5" />
          <stop offset="1" stopColor="#0095E6" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint3_linear"
          x1="56"
          x2="109.184"
          y1="9.5"
          y2="57.733"
        >
          <stop stopColor="#40C4F5" />
          <stop offset="1" stopColor="#0095E6" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint4_linear"
          x1="41.8995"
          x2="52.9186"
          y1="30.211"
          y2="58.0298"
        >
          <stop stopColor="#A7F2FF" />
          <stop offset="1" stopColor="#7CECFF" />
        </linearGradient>
        <clipPath id="clip0">
          <rect fill="white" height="96" width="96" />
        </clipPath>
      </defs>
    </>
  ),
  viewBox: '0 0 96 96',
});
