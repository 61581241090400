import '@fontsource/inter';
import {
  getIdTokenFromQuery,
  redirectToLogin,
  redirectToUrl,
  setCookie,
  getUserData,
  UserSession,
} from '@hihello/core';
import { ThemeProvider, theme as defaultTheme } from '@hihello/ui';
import { AuthProvider } from '@src/contexts/auth';
import { authSignOut } from '@src/lib/api';
import {
  updateLastAuthenticatedEmail,
  serverRedirectToInternalUrl,
  isPathnameToRedirectIfAuthenticated,
  getNextAndParamsFromQuery,
  cleanUpLastAuthenticatedEmailForWorkOSProvider,
} from '@src/lib/utils';
import NextApp, { AppContext, AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { DefaultSeo } from 'next-seo';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { parse } from 'url';
import nextI18NextConfig from '../../next-i18next.config';

const client = new QueryClient();

const theme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    company: defaultTheme.colors.violet,
  },
};

interface ApplicationProps extends AppProps {
  cookies: string;
  user: UserSession | null;
}

const Application = ({ Component, cookies, user, pageProps }: ApplicationProps) => (
  <QueryClientProvider client={client}>
    <DefaultSeo
      additionalLinkTags={[
        {
          href: `${process.env.NEXT_PUBLIC_CDN_URL}/common/logo-80x80.v2.png`,
          rel: 'icon',
          sizes: '80x80',
        },
        {
          href: `${process.env.NEXT_PUBLIC_CDN_URL}/common/logo-240x240.v2.png`,
          rel: 'icon',
          sizes: '240x240',
        },
        {
          href: `${process.env.NEXT_PUBLIC_CDN_URL}/common/logo-512x512.v2.png`,
          rel: 'icon',
          sizes: '512x512',
        },
      ]}
      dangerouslySetAllPagesToNoFollow
      dangerouslySetAllPagesToNoIndex
      defaultTitle="HiHello - Digital Business Cards"
      titleTemplate="HiHello - Digital Business Cards | %s"
    />
    {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
    <ThemeProvider cookies={cookies} theme={theme}>
      <AuthProvider initialUser={user}>
        <Component {...pageProps} />
      </AuthProvider>
    </ThemeProvider>
  </QueryClientProvider>
);

Application.getInitialProps = async (appContext: AppContext) => {
  const { req, res, pathname, query } = appContext.ctx;
  const appProps = await NextApp.getInitialProps(appContext);
  const cookies = req?.headers.cookie ?? '';
  const isLoginPage = pathname === '/login';
  const isLogoutPage = pathname === '/logout';
  const isRedirectPage = pathname === '/redirecting';
  const idTokenFromQuery = getIdTokenFromQuery(query);
  const isUserLoginWithToken = idTokenFromQuery && isLoginPage;
  const isPathnameToRedirect = isPathnameToRedirectIfAuthenticated(pathname);
  const user = await getUserData(req, res);

  const shouldLogout =
    isUserLoginWithToken || isLogoutPage || (!user && isPathnameToRedirect && !isRedirectPage);

  /* NOTE: We sometimes get a device_id query param in the URL (from the website) that
   * we want to store in a cookie and later set the amplitude device id to that value.
   * This is so we can track the user that came from the website.
   */
  // Check if running on the server
  if (req) {
    const parsedUrl = parse(req.url ?? '', true);
    const { device_id: amplitudeDeviceId } = parsedUrl.query;

    if (amplitudeDeviceId && typeof amplitudeDeviceId === 'string' && res) {
      setCookie({ name: 'amplitude_device_id', res, value: amplitudeDeviceId });
    }
    // if the device_id is in the 'next' query param, get it and set it from that.
    const { next } = parsedUrl.query;
    if (next && typeof next === 'string') {
      const nextUrl = new URL(next, `https://${process.env.HOST}`);
      const deviceIdFromNextUrl = nextUrl.searchParams.get('device_id');
      if (deviceIdFromNextUrl && typeof deviceIdFromNextUrl === 'string' && res) {
        setCookie({ name: 'amplitude_device_id', res, value: deviceIdFromNextUrl });
      }
    }
  } else {
    // Client-side handling
    const params = new URLSearchParams(window.location.search);
    const deviceId = params.get('device_id');
    if (deviceId) {
      setCookie({ name: 'amplitude_device_id', value: deviceId });
    }
  }

  if (shouldLogout) {
    await authSignOut({ redirect: false, req, res });
  }

  if (isUserLoginWithToken) {
    return { ...appProps, cookies };
  }

  if (isLogoutPage) {
    await cleanUpLastAuthenticatedEmailForWorkOSProvider(req, res);
    const queryParams = getNextAndParamsFromQuery(query);
    if (queryParams) {
      redirectToLogin(queryParams, res);
    } else {
      redirectToUrl(process.env.NEXT_PUBLIC_WWW_URL, res);
    }
    return {};
  }

  if (isPathnameToRedirect && res && user) {
    updateLastAuthenticatedEmail(user.email, res);
    setCookie({ name: 'session', res, value: user.token });
    serverRedirectToInternalUrl(res, query);
  }

  return { ...appProps, cookies, user };
};

export default appWithTranslation<any>(Application, nextI18NextConfig);
