import { createIcon } from '@chakra-ui/react';

export const IconTwitterX: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconTwitterX',
  path: (
    <path
      d="M13.6467 10.4686L20.932 2H19.2056L12.8798 9.3532L7.82736 2H2L9.64023 13.1193L2 22H3.72648L10.4067 14.2348L15.7424 22H21.5698L13.6462 10.4686H13.6467ZM11.282 13.2173L10.5079 12.1101L4.34855 3.29968H7.00032L11.971 10.4099L12.7451 11.5172L19.2064 20.7594H16.5546L11.282 13.2177V13.2173Z"
      fill="currentColor"
    />
  ),
});
