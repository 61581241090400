import { createIcon } from '@chakra-ui/react';

export const IconZoho: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconZoho',
  path: (
    <>
      <g clipPath="url(#clip0_1775_12561)">
        <path
          d="M24.0004 8.76709V14.4577L23.2129 15.2265V9.65771L24.0004 8.76709Z"
          fill="#E79225"
        />
        <path
          d="M18.8391 8.77178L18.0234 9.65771L18.0656 9.71396L23.1797 9.69521L23.2266 9.65771L24 8.76709L18.8391 8.77178Z"
          fill="#FFF16D"
        />
        <path
          d="M18.0234 9.65771H23.2266V15.2312H18.0234V9.65771Z"
          fill="url(#paint0_linear_1775_12561)"
        />
        <path
          d="M22.434 11.6641C22.3309 11.4062 22.1762 11.1719 21.9746 10.9703C21.7824 10.7734 21.5668 10.6234 21.323 10.525C21.084 10.4266 20.8262 10.375 20.5449 10.375C20.2637 10.375 20.0012 10.4266 19.7621 10.525C19.5184 10.6234 19.3027 10.7734 19.1105 10.9703C18.909 11.1719 18.759 11.4062 18.6559 11.6641C18.5527 11.9219 18.5059 12.2031 18.5059 12.5125C18.5059 12.8125 18.5574 13.0938 18.6605 13.3563C18.7637 13.6188 18.9137 13.8531 19.1152 14.0594C19.3027 14.2516 19.5184 14.3969 19.7574 14.4953C19.9965 14.5937 20.2637 14.6453 20.5496 14.6453C20.8262 14.6453 21.084 14.5937 21.323 14.4953C21.5621 14.3969 21.7824 14.2516 21.9746 14.0594C22.1762 13.8531 22.3309 13.6187 22.434 13.3609C22.5371 13.1031 22.5887 12.8172 22.5887 12.5172C22.5887 12.2031 22.5371 11.9219 22.434 11.6641ZM21.3605 13.5016C21.159 13.7406 20.8918 13.8625 20.5449 13.8625C20.198 13.8625 19.9262 13.7406 19.7246 13.5016C19.523 13.2625 19.4246 12.9297 19.4246 12.5078C19.4246 12.0766 19.5277 11.7437 19.7246 11.5C19.9262 11.2563 20.1934 11.1391 20.5449 11.1391C20.8918 11.1391 21.159 11.2609 21.3605 11.5C21.5574 11.7437 21.6605 12.0766 21.6605 12.5078C21.6605 12.9297 21.5621 13.2625 21.3605 13.5016Z"
          fill="white"
        />
        <path
          d="M17.5121 8.30322V8.33135L18.1777 13.4454L17.7887 14.5235L17.7371 14.486L17.0527 9.58291L17.0668 9.51729L17.4934 8.33135L17.5121 8.30322Z"
          fill="#009ADA"
        />
        <path
          d="M12.3889 9.01572L17.5123 8.30322L17.0811 9.53135L17.0201 9.59697L12.2295 10.3001L12.2529 9.42354L12.3889 9.01572Z"
          fill="#91C9ED"
        />
        <path
          d="M17.0809 9.53125L17.7887 14.5234L12.7402 15.2313L12.0605 10.4078L12.3559 10.1781L17.0809 9.53125Z"
          fill="url(#paint1_linear_1775_12561)"
        />
        <path
          d="M16.2236 10.4873C16.2002 10.3326 16.1486 10.2154 16.0643 10.1357C15.9939 10.0748 15.9096 10.042 15.8111 10.042C15.7877 10.042 15.7596 10.042 15.7314 10.0467C15.6002 10.0654 15.5018 10.1264 15.4408 10.2248C15.3939 10.2951 15.3752 10.3842 15.3752 10.4873C15.3752 10.5248 15.3799 10.567 15.3846 10.6092L15.5674 11.9076L14.1143 12.1232L13.9314 10.8248C13.908 10.6748 13.8564 10.5576 13.7721 10.4779C13.7018 10.4123 13.6174 10.3795 13.5236 10.3795C13.5002 10.3795 13.4768 10.3795 13.4533 10.3842C13.3174 10.4029 13.2143 10.4639 13.1486 10.5623C13.1018 10.6326 13.083 10.7217 13.083 10.8248C13.083 10.8623 13.0877 10.9045 13.0924 10.9514L13.5893 14.3311C13.6127 14.4857 13.6643 14.6029 13.758 14.6826C13.8283 14.7436 13.9127 14.7717 14.0158 14.7717C14.0439 14.7717 14.0721 14.7717 14.1002 14.767C14.2268 14.7482 14.3205 14.6873 14.3814 14.5889C14.4236 14.5186 14.4424 14.4342 14.4424 14.3357C14.4424 14.2982 14.4377 14.2561 14.433 14.2139L14.2314 12.8779L15.6846 12.6623L15.8861 13.9982C15.9096 14.1529 15.9611 14.2701 16.0502 14.3451C16.1205 14.4061 16.2049 14.4389 16.3033 14.4389C16.3268 14.4389 16.3549 14.4389 16.383 14.4342C16.5143 14.4154 16.6127 14.3545 16.6736 14.2561C16.7158 14.1857 16.7393 14.1014 16.7393 13.9982C16.7393 13.9607 16.7346 13.9186 16.7299 13.8764L16.2236 10.4873Z"
          fill="white"
        />
        <path
          d="M7.59395 7L5.77051 11.3313L6.01895 13.2344L6.03301 13.2297L8.08145 8.62187L8.07207 8.52344L7.63145 7.06094L7.59395 7Z"
          fill="#66BF6B"
        />
        <path
          d="M7.59375 7L8.06719 8.58906L8.07656 8.62187L12.5859 10.6422L12.6 10.6328L12.2156 9.11406L7.59375 7Z"
          fill="#98D0A0"
        />
        <path
          d="M8.06699 8.58887L12.5998 10.6326L10.5514 15.2779L6.01855 13.2342L8.06699 8.58887Z"
          fill="url(#paint2_linear_1775_12561)"
        />
        <path
          d="M11.2266 11.0077C11.1281 10.7452 10.9922 10.5202 10.8141 10.3327C10.6359 10.1452 10.4203 9.99521 10.1672 9.8874C9.91406 9.77959 9.66094 9.72803 9.40313 9.72803H9.38906C9.12656 9.72803 8.86875 9.78896 8.61563 9.90146C8.34844 10.0187 8.11875 10.178 7.92188 10.389C7.725 10.5952 7.56563 10.8483 7.44375 11.139C7.32188 11.4249 7.25625 11.7155 7.24219 12.0015V12.0999C7.24219 12.353 7.28438 12.6015 7.37344 12.8452C7.46719 13.103 7.60313 13.3233 7.78125 13.5108C7.95938 13.6983 8.17969 13.8483 8.44219 13.9562C8.69063 14.064 8.94375 14.1155 9.20156 14.1155H9.20625C9.46406 14.1155 9.72188 14.0593 9.975 13.9515C10.2422 13.8343 10.4766 13.6702 10.6734 13.4593C10.8703 13.253 11.0344 13.0046 11.1562 12.714C11.2781 12.4233 11.3438 12.1374 11.3578 11.8515V11.7671C11.3625 11.5093 11.3203 11.2562 11.2266 11.0077ZM10.3219 12.3577C10.1531 12.7608 9.92344 13.0374 9.64688 13.1874C9.49688 13.2671 9.34219 13.3093 9.1875 13.3093C9.05156 13.3093 8.90625 13.2765 8.76094 13.2155C8.44219 13.0796 8.24531 12.864 8.16094 12.5546C8.13281 12.4515 8.11875 12.3437 8.11875 12.2312C8.11875 12.0062 8.175 11.7577 8.2875 11.4905C8.46094 11.078 8.69063 10.7968 8.96719 10.6421C9.11719 10.5577 9.27188 10.5202 9.42656 10.5202C9.56719 10.5202 9.70781 10.553 9.85781 10.614C10.1719 10.7499 10.3688 10.9655 10.4531 11.2749C10.4813 11.3733 10.4953 11.4812 10.4953 11.5937C10.4953 11.828 10.4391 12.0812 10.3219 12.3577Z"
          fill="white"
        />
        <path
          d="M5.40957 7.73584L6.1502 12.6952L5.8127 14.439L5.76582 14.378L5.04395 9.5874V9.49365L5.3627 7.82021L5.40957 7.73584Z"
          fill="#760D16"
        />
        <path
          d="M0 10.2999L0.351562 8.54678L5.40938 7.73584L5.06719 9.52178V9.63896L0.0609375 10.3468L0 10.2999Z"
          fill="#EF463E"
        />
        <path
          d="M5.06719 9.52197L5.8125 14.4392L0.80625 15.222L0 10.3001L5.06719 9.52197Z"
          fill="url(#paint3_linear_1775_12561)"
        />
        <path
          d="M4.52812 13.6563C4.49062 13.6095 4.43437 13.5767 4.36875 13.5532C4.30312 13.5298 4.22344 13.5204 4.125 13.5204C4.03594 13.5204 3.93281 13.5298 3.82031 13.5485L2.49844 13.7735C2.5125 13.6704 2.56406 13.5392 2.65312 13.3751C2.75156 13.1923 2.90156 12.972 3.09375 12.7188C3.15937 12.6298 3.21094 12.5642 3.24844 12.5173C3.27188 12.4845 3.30937 12.4423 3.35625 12.3813C3.66094 11.9829 3.84375 11.6595 3.91875 11.4063C3.96094 11.261 3.98437 11.1157 3.99375 10.9704C3.99844 10.9282 3.99844 10.8907 3.99844 10.8532C3.99844 10.7501 3.98906 10.647 3.97031 10.5438C3.95625 10.4501 3.93281 10.3751 3.9 10.3142C3.86719 10.2532 3.82969 10.2063 3.78281 10.1782C3.73125 10.1454 3.65156 10.1313 3.55313 10.1313C3.47344 10.1313 3.375 10.1407 3.25781 10.1595L1.72969 10.422C1.54688 10.4548 1.40625 10.5063 1.32188 10.5907C1.25156 10.6563 1.21875 10.7407 1.21875 10.8345C1.21875 10.8579 1.21875 10.886 1.22344 10.9142C1.24688 11.0454 1.3125 11.1392 1.42031 11.186C1.48594 11.2142 1.56094 11.2282 1.65469 11.2282C1.71562 11.2282 1.78594 11.2235 1.86094 11.2095L3.13594 10.9845C3.13594 11.0079 3.14062 11.0313 3.14062 11.0501C3.14062 11.1298 3.12656 11.2095 3.09844 11.2845C3.06094 11.3923 2.96719 11.5423 2.8125 11.7345C2.77031 11.786 2.70469 11.8704 2.61562 11.9782C2.26875 12.3954 2.025 12.7517 1.875 13.047C1.76719 13.2532 1.69687 13.4501 1.65469 13.6517C1.63125 13.7688 1.61719 13.8767 1.61719 13.9845C1.61719 14.0595 1.62188 14.1345 1.63594 14.2048C1.65469 14.3079 1.67812 14.3923 1.71094 14.4579C1.74375 14.5235 1.79062 14.5751 1.84219 14.6032C1.89375 14.6313 1.96406 14.6407 2.06719 14.6407C2.19375 14.6407 2.36719 14.622 2.5875 14.5845L3.975 14.3454C4.21875 14.3032 4.39219 14.2423 4.49063 14.1626C4.57031 14.097 4.6125 14.0079 4.6125 13.9048C4.6125 13.8767 4.60781 13.8485 4.60312 13.8204C4.59375 13.7595 4.57031 13.7032 4.52812 13.6563Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_1775_12561"
          x1="278.159"
          x2="278.159"
          y1="17.6333"
          y2="550.175"
        >
          <stop offset="0.00562" stopColor="#FFE513" />
          <stop offset="1" stopColor="#FDB924" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_1775_12561"
          x1="37.9058"
          x2="558.052"
          y1="86.4471"
          y2="508.299"
        >
          <stop offset="0.00562" stopColor="#008CD2" />
          <stop offset="1" stopColor="#00649D" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_1775_12561"
          x1="335.094"
          x2="335.094"
          y1="194.404"
          y2="660.967"
        >
          <stop stopColor="#26A146" />
          <stop offset="1" stopColor="#008A52" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint3_linear_1775_12561"
          x1="254.204"
          x2="331.474"
          y1="56.3076"
          y2="541.229"
        >
          <stop stopColor="#D92231" />
          <stop offset="1" stopColor="#BA2234" />
        </linearGradient>
        <clipPath id="clip0_1775_12561">
          <rect fill="white" height="8.29688" transform="translate(0 7)" width="24" />
        </clipPath>
      </defs>
    </>
  ),
  viewBox: '0 0 25 25',
});
