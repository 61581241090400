import { FunctionComponent } from 'react';
import { Flat, HeaderVariantProps, Modern, Round, Sleek, Wave } from './variants';

export type HeaderVariant = 'flat' | 'round' | 'sleek' | 'wave' | 'modern';

export type HeaderProps = HeaderVariantProps & {
  variant: HeaderVariant;
};

export const Header: FunctionComponent<HeaderProps> = ({ variant, ...props }) => {
  switch (variant) {
    case 'flat': {
      return <Flat {...props} />;
    }

    case 'modern': {
      return <Modern {...props} />;
    }

    case 'round': {
      return <Round {...props} />;
    }

    case 'sleek': {
      return <Sleek {...props} />;
    }

    case 'wave': {
      return <Wave {...props} />;
    }

    default: {
      return null;
    }
  }
};
