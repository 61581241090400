import {
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalProps,
  Text,
  VStack,
  Box,
} from '@chakra-ui/react';
import { useCallback, useMemo, useRef } from 'react';
import { useStore } from 'zustand';
import { createStepStore, StepContext } from './useStep';

type Content = {
  buttonText: string;
  color: string;
  pageData: React.ReactNode;
};

export type StepperModalProps = Omit<ModalProps, 'children'> & {
  allowBackButton?: boolean;
  content: Content[];
  isClosable?: boolean;
  lastStepAction: () => void;
};

const ANIMATION_DURATION = '1000ms';

export const FullScreenWalkthrough = (props: StepperModalProps) => {
  const { content, isClosable = true, lastStepAction, allowBackButton, ...modalProps } = props;

  const stepStore = useRef(
    createStepStore({ currentStep: 0, numberOfSteps: content.length }),
  ).current;
  const initialRef = useRef(null);

  const { currentStep, setCurrentStep, numberOfSteps } = useStore(stepStore, (s) => s);

  const isLastStep = useMemo(() => currentStep === numberOfSteps - 1, [currentStep, numberOfSteps]);
  const handlePrevious = useCallback(
    () => setCurrentStep(currentStep - 1),
    [currentStep, setCurrentStep],
  );
  const handleContinue = useCallback(() => {
    if (isLastStep) {
      lastStepAction();
      setCurrentStep(0);
    } else {
      setCurrentStep(currentStep + 1);
    }
  }, [currentStep, isLastStep, lastStepAction, setCurrentStep]);
  const canGoToPrevStep = useMemo(() => currentStep > 0, [currentStep]);

  return (
    <StepContext.Provider value={stepStore}>
      <Modal
        closeOnEsc={isClosable}
        closeOnOverlayClick={isClosable}
        initialFocusRef={initialRef}
        isCentered
        size="full"
        {...modalProps}
      >
        <ModalContent>
          {isClosable && <ModalCloseButton />}
          <ModalBody
            backgroundColor={`${content[currentStep].color}.400`}
            display="flex"
            flexDirection="column"
            h="100vh"
            p={0}
            transition={`background-color ${ANIMATION_DURATION} ease-in-out`}
          >
            <Box
              display="flex"
              flex="1"
              pt={[0, 30, 50]}
              px={[0, '20%', '30%']}
              width="100vw"
              zIndex={0}
            >
              {content[currentStep].pageData}
            </Box>
            {canGoToPrevStep && allowBackButton && (
              <Button bottom="10" onClick={handlePrevious} position="absolute" zIndex={1}>
                Back
              </Button>
            )}
            <Box flex="0 0 auto" justifyContent="center" pb={[0, 30, 50]} width="full" zIndex={1}>
              <VStack>
                <Button
                  onClick={handleContinue}
                  textColor={`${content[currentStep].color}.700`}
                  transition={`color ${ANIMATION_DURATION} ease-in-out`}
                  variant="outline"
                >
                  {content[currentStep].buttonText}
                </Button>
                <Text color="whiteAlpha.600">{`${currentStep + 1}/${numberOfSteps}`}</Text>
              </VStack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </StepContext.Provider>
  );
};
