import { StyleConfig } from '@chakra-ui/react';

const variants = {
  primary: {
    _active: {
      bgColor: 'company.700',
    },
    _hover: {
      _disabled: {
        bgColor: 'company.500',
      },
      bgColor: 'company.600',
    },
    backgroundColor: 'company.500',
    color: 'onCompany',
  },
};

export const Button: StyleConfig = {
  baseStyle: {
    borderRadius: 'md',
    lineHeight: '1',
    variant: 'primary',
  },
  variants,
};
