import { createIcon } from '@chakra-ui/react';

export const IconSkype: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconSkype',
  path: (
    <path
      d="M23.973 14.5877C25.3272 6.4627 18.348 -0.412296 10.3272 1.04604C4.2855 -2.70396 -2.69366 4.37937 1.05633 10.421C-0.401998 18.4419 6.68133 25.5252 14.7022 23.9627C20.7438 27.7127 27.723 20.6294 23.973 14.5877ZM12.9313 20.7335C9.598 20.7335 6.26467 19.2752 6.26467 16.671C6.26467 15.8377 6.88967 15.2127 7.61883 15.2127C9.598 15.2127 9.598 18.1294 12.6188 18.1294C14.8063 18.1294 15.5355 16.9835 15.5355 16.1502C15.5355 13.1294 6.1605 15.0044 6.1605 9.37937C6.1605 6.35854 8.6605 4.17104 12.6188 4.48354C16.3688 4.69187 18.5563 6.35854 18.7647 7.92104C18.8688 8.9627 18.2438 9.69187 16.9938 9.69187C15.3272 9.69187 15.1188 7.4002 12.2022 7.4002C10.848 7.4002 9.80633 7.92104 9.80633 9.17104C9.80633 11.671 19.1813 10.2127 19.1813 15.7335C19.1813 18.6502 16.6813 20.7335 12.9313 20.7335Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 26 26',
});
