export enum Address {
  HOME = 'home',
  MAILING = 'mailing',
  WORK = 'work',
}

export enum Email {
  PERSONAL = 'personal',
  WORK = 'work',
}

export enum ImportantDate {
  ANNIVERSARY = 'anniversary',
  BIRTHDAY = 'birthday',
}

export enum Link {
  APPLEMUSIC = 'applemusic',
  BEHANCE = 'behance',
  CALENDLY = 'calendly',
  CASHAPP = 'cashapp',
  DISCORD = 'discord',
  DRIBBBLE = 'dribbble',
  FACEBOOK = 'facebook',
  FILE = 'file',
  GITHUB = 'github',
  INSTAGRAM = 'instagram',
  LINE = 'line',
  LINK = 'link',
  LINKEDIN = 'linkedin',
  PATREON = 'patreon',
  PAYPAL = 'paypal',
  PINTEREST = 'pinterest',
  SNAPCHAT = 'snapchat',
  SOUNDCLOUD = 'soundcloud',
  SPOTIFY = 'spotify',
  TELEGRAM = 'telegram',
  TIKTOK = 'tiktok',
  TWITCH = 'twitch',
  TWITTER = 'twitter',
  VENMO = 'venmo',
  VIMEO = 'vimeo',
  WEBSITE = 'website',
  WHATSAPP = 'whatsapp',
  XING = 'xing',
  YELP = 'yelp',
  YOUTUBE = 'youtube',
}

export enum Phone {
  DIRECT = 'direct',
  FAX = 'work,fax',
  HOME = 'home',
  MAIN = 'main',
  MOBILE = 'cell',
  OFFICE = 'office',
  WORK = 'work',
}
