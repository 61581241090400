import { Box, BoxProps, useMultiStyleConfig } from '@chakra-ui/react';
import { FieldType, Fields } from '@hihello/core';
import { Fragment, FunctionComponent } from 'react';
import { useStore } from 'zustand';
import { IconMobile } from '../../../../../Icons';
import { Labels } from '../../../../layout';
import { useCardStore } from '../../../../store';
import { SummaryItem } from './SummaryItem';

export type SummaryProps = BoxProps & {
  fields?: Fields[];
};

export const Summary: FunctionComponent<SummaryProps> = ({ fields, ...props }) => {
  const cardStore = useCardStore();
  const palette = useStore(cardStore, (state) => state.themePalette);
  const styles = useMultiStyleConfig('FieldListSummary', { palette });

  return (
    <Box __css={styles.container} {...props}>
      <Box __css={styles.list} as="ul">
        {fields ? (
          fields.map((field, index) => {
            const key = [index, field.type].join();

            if (field.type === FieldType.Phone && field.label === Labels.Phone.MOBILE) {
              return (
                <Fragment key={key}>
                  <SummaryItem
                    field={{
                      ...field,
                      label: Labels.Phone.DIRECT,
                    }}
                    icon={<IconMobile />}
                  />
                  <SummaryItem field={field} />
                </Fragment>
              );
            }

            return <SummaryItem key={key} field={field} />;
          })
        ) : (
          <>
            <SummaryItem />
            <SummaryItem />
            <SummaryItem />
          </>
        )}
      </Box>
    </Box>
  );
};
