import { createIcon } from '@chakra-ui/react';

export const IconMicrosoftTeams: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconMicrosoftTeams',
  path: (
    <>
      <path
        d="M6.88952 3.59778C7.22643 3.53535 7.53877 3.37882 7.79044 3.14631C8.04212 2.9138 8.22283 2.6148 8.3117 2.28388C8.40056 1.95297 8.39393 1.60366 8.29259 1.27635C8.19125 0.949046 7.99933 0.657108 7.73903 0.434303C7.47873 0.211497 7.16067 0.0669244 6.82164 0.0173036C6.48262 -0.0323172 6.13647 0.0150409 5.82323 0.153901C5.51 0.292762 5.24246 0.517452 5.05157 0.801988C4.86068 1.08652 4.75423 1.41928 4.74452 1.76178H5.62802C6.32477 1.76178 6.88952 2.32653 6.88952 3.02328V3.59778ZM3.66827 9.40128H5.62802C6.32477 9.40128 6.88952 8.83653 6.88952 8.13978V4.18503H8.97677C9.11576 4.18857 9.24767 4.2471 9.34356 4.34777C9.43945 4.44844 9.4915 4.58304 9.48827 4.72203V7.94253C9.5076 8.77641 9.19517 9.58388 8.6196 10.1876C8.04403 10.7913 7.25238 11.1418 6.41852 11.1623C5.20502 11.1323 4.16852 10.4198 3.66827 9.39978V9.40128ZM11.721 2.37228C11.721 2.53721 11.6885 2.70052 11.6254 2.85289C11.5623 3.00526 11.4698 3.1437 11.3532 3.26032C11.2366 3.37694 11.0981 3.46945 10.9458 3.53256C10.7934 3.59567 10.6301 3.62816 10.4651 3.62816C10.3002 3.62816 10.1369 3.59567 9.98455 3.53256C9.83218 3.46945 9.69373 3.37694 9.57711 3.26032C9.46049 3.1437 9.36799 3.00526 9.30487 2.85289C9.24176 2.70052 9.20927 2.53721 9.20927 2.37228C9.20927 2.0392 9.34159 1.71977 9.57711 1.48425C9.81263 1.24872 10.1321 1.11641 10.4651 1.11641C10.7982 1.11641 11.1177 1.24872 11.3532 1.48425C11.5887 1.71977 11.721 2.0392 11.721 2.37228ZM10.0425 9.48828C10.0125 9.48828 9.98252 9.48828 9.95252 9.48678C10.1528 8.99423 10.2501 8.46587 10.2383 7.93428V4.72953C10.2414 4.5422 10.203 4.35651 10.1258 4.18578H11.4698C11.7623 4.18578 12 4.42353 12 4.71603V7.53978C12 8.05656 11.7947 8.55217 11.4293 8.91758C11.0639 9.283 10.5683 9.48828 10.0515 9.48828H10.0418H10.0425Z"
        fill="currentColor"
      />
      <path
        d="M0.5115 2.51174H5.628C5.91075 2.51174 6.1395 2.74049 6.1395 3.02324V8.13974C6.1395 8.27539 6.08561 8.4055 5.98969 8.50142C5.89376 8.59735 5.76366 8.65124 5.628 8.65124H0.5115C0.444329 8.65124 0.377815 8.63801 0.315757 8.6123C0.253699 8.5866 0.197312 8.54892 0.149815 8.50142C0.102318 8.45392 0.0646409 8.39754 0.0389356 8.33548C0.0132303 8.27342 0 8.20691 0 8.13974L0 3.02249C0 2.73974 0.22875 2.51174 0.5115 2.51174ZM4.416 4.45874V3.91874H1.7235V4.45874H2.74125V7.24499H3.39375V4.45874H4.416Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: '0 0 12 12',
});
