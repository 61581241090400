import { FunctionComponent, PropsWithChildren } from 'react';
import {
  BadgeList,
  Box,
  Company,
  Department,
  FeaturedContent,
  FieldList,
  Header,
  Headline,
  Identity,
  Media,
  Name,
  PreferredName,
  Title,
} from './components';
import { CardComponents, CardSchemaComponentType } from './layout';

export type CardComponentProps = {
  node: CardComponents<CardSchemaComponentType>;
};

export const CardComponent: FunctionComponent<PropsWithChildren<CardComponentProps>> = ({
  node,
  ...props
}) => {
  switch (node.type) {
    case 'BadgeList': {
      return <BadgeList {...props} variant={node.variant} />;
    }

    case 'Box': {
      return <Box {...props} variant={node.variant} />;
    }

    case 'Company': {
      return <Company {...props} variant={node.variant} />;
    }

    case 'Department': {
      return <Department {...props} />;
    }

    case 'FeaturedContent': {
      return <FeaturedContent {...props} />;
    }

    case 'FieldList': {
      return (
        <FieldList
          {...props}
          fieldListFilter={
            node.include
              ? {
                  include: node.include,
                }
              : {
                  exclude: node.exclude,
                }
          }
          rules={node.rules}
          variant={node.variant}
        />
      );
    }

    case 'Header': {
      return <Header {...props} variant={node.variant} />;
    }

    case 'Headline': {
      return <Headline {...props} />;
    }

    case 'Identity': {
      return <Identity {...props} />;
    }

    case 'Media': {
      return <Media {...props} variant={node.variant} />;
    }

    case 'Name': {
      return <Name {...props} variant={node.variant} />;
    }

    case 'PreferredName': {
      return <PreferredName {...props} />;
    }

    case 'Title': {
      return <Title {...props} />;
    }

    default: {
      return null;
    }
  }
};
