export const semanticTokens = {
  colors: {
    'bg-accent': {
      _dark: 'brand.700',
      default: 'brand.600',
    },
    'bg-accent-dark': {
      _dark: 'brand.800',
      default: 'brand.700',
    },
    'bg-accent-darker': {
      _dark: 'brand.900',
      default: 'brand.800',
    },
    company: {
      _dark: 'brand.500',
      default: 'brand.400',
    },
    companyAlpha: {
      _dark: 'whiteAlpha.400',
      default: 'whiteAlpha.400',
    },
    onCompany: {
      _dark: 'white',
      default: 'white',
    },
    subtext: {
      _dark: 'gray.400',
      default: 'gray.500',
    },
  },
};
