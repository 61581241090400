import { createIcon } from '@chakra-ui/react';

export const IconPeopleOutlined: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconPeopleOutlined',
  path: (
    <path
      d="M6.66671 13.6654V11.4334C6.66671 10.578 7.33004 9.90537 8.31137 9.52137C7.6785 9.286 7.00859 9.16589 6.33337 9.16671C5.07337 9.16671 3.90937 9.57804 2.96871 10.2734C3.26038 11.0983 3.7501 11.8389 4.39487 12.4304C5.03965 13.0218 5.81976 13.4459 6.66671 13.6654ZM12.5867 11.224C12.3234 10.8687 11.4467 10.5 10.3334 10.5C8.99604 10.5 8.00004 11.0314 8.00004 11.4334V13.8334C8.92527 13.8339 9.8347 13.5936 10.6389 13.1361C11.4431 12.6786 12.1144 12.0196 12.5867 11.224ZM6.36671 8.16671C6.76453 8.16671 7.14606 8.00867 7.42737 7.72737C7.70867 7.44606 7.86671 7.06453 7.86671 6.66671C7.86671 6.26888 7.70867 5.88735 7.42737 5.60605C7.14606 5.32474 6.76453 5.16671 6.36671 5.16671C5.96888 5.16671 5.58735 5.32474 5.30605 5.60605C5.02474 5.88735 4.86671 6.26888 4.86671 6.66671C4.86671 7.06453 5.02474 7.44606 5.30605 7.72737C5.58735 8.00867 5.96888 8.16671 6.36671 8.16671ZM10.3334 8.83337C10.687 8.83337 11.0261 8.6929 11.2762 8.44285C11.5262 8.1928 11.6667 7.85366 11.6667 7.50004C11.6667 7.14642 11.5262 6.80728 11.2762 6.55723C11.0261 6.30718 10.687 6.16671 10.3334 6.16671C9.97975 6.16671 9.64061 6.30718 9.39057 6.55723C9.14052 6.80728 9.00004 7.14642 9.00004 7.50004C9.00004 7.85366 9.14052 8.1928 9.39057 8.44285C9.64061 8.6929 9.97975 8.83337 10.3334 8.83337ZM8.00004 15.1667C4.31804 15.1667 1.33337 12.182 1.33337 8.50004C1.33337 4.81804 4.31804 1.83337 8.00004 1.83337C11.682 1.83337 14.6667 4.81804 14.6667 8.50004C14.6667 12.182 11.682 15.1667 8.00004 15.1667Z"
      fill="currentColor"
      id="Vector"
    />
  ),
  viewBox: '0 0 15 15',
});
