import { IncomingMessage, ServerResponse } from 'http';
import { fetcher, getIdToken } from '../api/fetcher';
import { Claims } from '../api/generated';
import { logger } from './logger';
import { redirectIfUnauthorized, redirectToLogin } from './redirect';

export async function validateClaims(
  claims: Claims[],
  onErrorRedirect: string,
  ctx: { req?: IncomingMessage; res?: ServerResponse } = {},
): Promise<Claims[]> {
  const { req, res } = ctx;
  const sessionToken = getIdToken(req);

  if (!sessionToken) {
    redirectToLogin(onErrorRedirect, res);
    return [];
  }

  try {
    const userData = await fetcher<
      {
        user: {
          claims?: Array<Claims> | null;
        };
      },
      null
    >(
      `
        query GetUserProfile {
          user: me {
            claims
          }
        }
    `,
      undefined,
      { Authorization: `Bearer ${sessionToken}` },
    )();

    if (
      !userData.user.claims ||
      !claims.every(
        (claim) => claim === userData.user.claims?.find((userClaim) => userClaim === claim),
      )
    ) {
      if (res) {
        res.statusCode = 403;
      }

      throw new Error('Forbidden');
    }

    return userData.user.claims;
  } catch (error) {
    logger.error(error);
    redirectIfUnauthorized(error, onErrorRedirect, res);
    throw error;
  }
}
