import { createIcon } from '@chakra-ui/react';

export const IconTransfer: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconTransfer',
  path: (
    <>
      <path
        d="M1.33325 3.72892L3.72909 6.12476M14.111 3.72892L1.33325 3.72892L14.111 3.72892ZM1.33325 3.72892L3.72909 1.33309L1.33325 3.72892Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.111 11.715L11.7152 14.1108M1.33325 11.715H14.111H1.33325ZM14.111 11.715L11.7152 9.31917L14.111 11.715Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  viewBox: '0 0 16 16',
});
