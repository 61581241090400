import React, {
  useEffect,
  createContext,
  PropsWithChildren,
  useMemo,
  useState,
  useContext,
  useCallback,
  useRef,
} from 'react';
import { refreshToken, UserSession } from '../api';

export type AuthContextState = {
  signIn: (user: UserSession) => void;
  signOut: () => void;
  user: UserSession | null;
};

export type AuthProviderProps = PropsWithChildren<{
  initialUser?: UserSession | null;
  refreshTokenName?: string;
  sessionName?: string;
}>;

const AuthContext = createContext<AuthContextState>({
  signIn: () => null,
  signOut: () => null,
  user: null,
});

export const AuthProvider = ({
  children,
  initialUser = null,
  refreshTokenName = 'refreshToken',
  sessionName = 'session',
}: AuthProviderProps) => {
  const [user, setUser] = useState<UserSession | null>(initialUser);
  const nextTokenRefreshTimer = useRef<NodeJS.Timeout>();

  const scheduleNextTokenRefresh = useCallback(() => {
    const fiftyMinutes = 1000 * 60 * 50;
    nextTokenRefreshTimer.current = setInterval(() => {
      refreshToken({ refreshTokenName, sessionName });
    }, fiftyMinutes);
  }, [refreshTokenName, sessionName]);

  useEffect(() => {
    refreshToken({ refreshTokenName, sessionName });
    scheduleNextTokenRefresh();

    return () => {
      clearInterval(nextTokenRefreshTimer.current);
    };
  }, [refreshTokenName, scheduleNextTokenRefresh, sessionName]);

  const signIn = (newUser: UserSession) => {
    setUser(newUser);
  };

  const signOut = () => {
    setUser(null);
    clearInterval(nextTokenRefreshTimer.current);
  };

  const valueMemo = useMemo(() => ({ signIn, signOut, user }), [user]);

  return <AuthContext.Provider value={valueMemo}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthContext.Provider');
  }
  return context;
};
