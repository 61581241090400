import { FunctionComponent, useCallback } from 'react';
import { CardComponent } from './CardComponent';
import { CardComponents, CardSchema, CardSchemaComponentType } from './layout';

export type CardLayoutProps = {
  layout: CardSchema;
};

export const CardLayout: FunctionComponent<CardLayoutProps> = ({ layout }) => {
  const render = useCallback(
    (node: CardComponents<CardSchemaComponentType>, key: string | number) => {
      const keys = [key, node.type, 'variant' in node ? node.variant : null];

      return (
        <CardComponent key={keys.join()} node={node}>
          {node.children?.map((child, index) => render(child, [keys.join(), index].join()))}
        </CardComponent>
      );
    },
    [],
  );

  return <>{layout.data.map(render)}</>;
};
