import { createIcon } from '@chakra-ui/react';
import { Palette } from '@hihello/core';

export type DesignIconProps = {
  palette?: Palette;
};

export const DesignIconWave = createIcon({
  displayName: 'DesignIconWave',
  path: (
    <>
      <g clipPath="url(#clip0_1931_53838)">
        <path d="M0 -24H72V54H0V-24Z" fill="currentColor" />
        <path d="M72 72.5V39.18C44.16 29.9533 29.568 63.3176 0 41.7337V72.5H72Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1931_53838">
          <rect fill="white" height="72" rx="16" width="72" />
        </clipPath>
      </defs>
    </>
  ),
  viewBox: '0 0 72 72',
});
