import { createIcon } from '@chakra-ui/react';

export const IconMicrosoftBookings: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconMicrosoftBookings',
  path: (
    <path
      d="M7 7V4.343C7 4.18025 7.15325 4.06125 7.31175 4.099C8.6795 4.42425 9.7345 5.55025 9.954 6.95575C9.978 7.1095 9.86225 7.25 9.7065 7.25H7.25C7.112 7.25 7 7.138 7 7ZM6.25 7V1.25C6.25 1.112 6.138 1 6 1H3C2.862 1 2.75 1.112 2.75 1.25V4C2.75 5.795 4.205 7.25 6 7.25C6.138 7.25 6.25 7.138 6.25 7ZM2.75 8.25V10.75C2.75 10.888 2.862 11 3 11H6.25C6.25 11 6.4155 11 6.5 11C8.15425 11 9.53675 9.85125 9.90175 8.3085C9.939 8.15125 9.8185 8 9.657 8H3C2.862 8 2.75 8.112 2.75 8.25Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 12 12',
});
