import { FieldType, Fields } from '@hihello/core';
import { useMemo } from 'react';
import { useStore } from 'zustand';
import { FieldListRule, FieldListTransform, Labels } from '../../../layout';
import { useCardStore } from '../../../store';

type FieldText = {
  name?: string;
  primary: string | null;
  secondary: string | null;
};

type FieldTextInput = Record<
  'primary' | 'secondary',
  {
    hide: boolean;
    value: string | null;
  }
>;

const transform =
  (mutator: FieldListTransform, property: 'primary' | 'secondary') => (input: FieldTextInput) => {
    switch (mutator) {
      case 'capitalize': {
        const { value } = input[property];

        if (!value) {
          return input;
        }

        return {
          ...input,
          [property]: {
            ...input[property],
            value: `${value.substring(0, 1).toUpperCase()}${value
              .substring(1, value.length)
              .toLocaleLowerCase()}`,
          },
        };
      }

      case 'hide': {
        return {
          ...input,
          [property]: {
            ...input[property],
            hide: true,
          },
        };
      }

      case 'show': {
        return {
          ...input,
          [property]: {
            ...input[property],
            hide: false,
          },
        };
      }

      case 'usePrimaryText': {
        return {
          ...input,
          [property]: {
            ...input[property],
            value: input.primary.value,
          },
        };
      }

      case 'useSecondaryText': {
        return {
          ...input,
          [property]: {
            ...input[property],
            value: input.secondary.value,
          },
        };
      }

      default: {
        return input;
      }
    }
  };

export function useFieldText(field?: Fields, rules?: FieldListRule[]): FieldText | null {
  const cardStore = useCardStore();
  const {
    messages = {
      [`${FieldType.Address}.label.${Labels.Address.HOME}`]: 'home',
      [`${FieldType.Address}.label.${Labels.Address.MAILING}`]: 'mailing',
      [`${FieldType.Address}.label.${Labels.Address.WORK}`]: 'work',
      [`${FieldType.Address}.name`]: 'address',
      'clipboard.notification.dismiss': 'OK',
      'clipboard.notification.text': 'Copied to clipboard',
      'clipboard.request': 'Copy to clipboard: #{key}, Enter.',
      [`${FieldType.Email}.label.${Labels.Email.PERSONAL}`]: 'personal',
      [`${FieldType.Email}.label.${Labels.Email.WORK}`]: 'work',
      [`${FieldType.Email}.name`]: 'email',
      [`${FieldType.ImportantDate}.label.${Labels.ImportantDate.ANNIVERSARY}`]: 'anniversary',
      [`${FieldType.ImportantDate}.label.${Labels.ImportantDate.BIRTHDAY}`]: 'birthday',
      [`${FieldType.ImportantDate}.name`]: 'date',
      [`${FieldType.Nintendo}.name`]: 'nintendo friend code',
      [`${FieldType.Note}.name`]: 'note',
      [`${FieldType.Phone}.label.${Labels.Phone.MOBILE}`]: 'mobile',
      [`${FieldType.Phone}.label.${Labels.Phone.DIRECT}`]: 'direct',
      [`${FieldType.Phone}.label.${Labels.Phone.HOME}`]: 'home',
      [`${FieldType.Phone}.label.${Labels.Phone.MAIN}`]: 'main',
      [`${FieldType.Phone}.label.${Labels.Phone.OFFICE}`]: 'office',
      [`${FieldType.Phone}.label.${Labels.Phone.WORK}`]: 'work',
      [`${FieldType.Phone}.label.${Labels.Phone.FAX}`]: 'fax',
      [`${FieldType.Phone}.name`]: 'phone',
      [`${FieldType.Playstation}.name`]: 'psn online id',
      [`${FieldType.Signal}.name`]: 'signal',
      [`${FieldType.Skype}.name`]: 'date',
      [`${FieldType.Applemusic}.name`]: 'applemusic',
      [`${FieldType.Behance}.name`]: 'behance',
      [`${FieldType.Brightcove}.name`]: 'brightcove',
      [`${FieldType.Calendly}.name`]: 'calendly',
      [`${FieldType.Cashapp}.name`]: 'cashapp',
      [`${FieldType.Discord}.name`]: 'discord',
      [`${FieldType.Dribbble}.name`]: 'dribbble',
      [`${FieldType.Facebook}.name`]: 'facebook',
      [`${FieldType.File}.name`]: 'file',
      [`${FieldType.Github}.name`]: 'github',
      [`${FieldType.GoogleMeet}.name`]: 'meet',
      [`${FieldType.Instagram}.name`]: 'instagram',
      [`${FieldType.Line}.name`]: 'line',
      [`${FieldType.Link}.name`]: 'link',
      [`${FieldType.Linkedin}.name`]: 'linkedin',
      [`${FieldType.MicrosoftBookings}.name`]: 'bookings',
      [`${FieldType.MicrosoftTeams}.name`]: 'teams',
      [`${FieldType.Patreon}.name`]: 'patreon',
      [`${FieldType.Paypal}.name`]: 'paypal',
      [`${FieldType.Pinterest}.name`]: 'pinterest',
      [`${FieldType.Snapchat}.name`]: 'snapchat',
      [`${FieldType.Soundcloud}.name`]: 'soundcloud',
      [`${FieldType.Spotify}.name`]: 'spotify',
      [`${FieldType.Telegram}.name`]: 'telegram',
      [`${FieldType.Tiktok}.name`]: 'tiktok',
      [`${FieldType.Twitch}.name`]: 'twitch',
      [`${FieldType.Twitter}.name`]: 'twitter',
      [`${FieldType.Venmo}.name`]: 'venmo',
      [`${FieldType.Vimeo}.name`]: 'vimeo',
      [`${FieldType.Website}.name`]: 'website',
      [`${FieldType.Whatsapp}.name`]: 'whatsapp',
      [`${FieldType.Xing}.name`]: 'xing',
      [`${FieldType.Yelp}.name`]: 'yelp',
      [`${FieldType.Youtube}.name`]: 'youtube',
      [`${FieldType.Webex}.name`]: 'webex',
      [`${FieldType.Wechat}.name`]: 'wechat',
      [`${FieldType.Xbox}.name`]: 'xbox',
      [`${FieldType.Zelle}.name`]: 'zelle',
      [`${FieldType.Zoom}.name`]: 'zoom',
    },
  } = useStore(cardStore, (state) => ({
    messages: state.messages,
  }));

  const message = useMemo<FieldText | null>(() => {
    if (!field) {
      return null;
    }

    switch (field.type) {
      case FieldType.Address: {
        return {
          name: messages?.[`${field.type}.name`],
          primary: field.text,
          secondary: messages?.[`${field.type}.label.${field.label as Labels.Address}`] ?? null,
        };
      }

      case FieldType.Email: {
        return {
          name: messages?.[`${field.type}.name`],
          primary: field.text,
          secondary: messages?.[`${field.type}.label.${field.label as Labels.Email}`] ?? null,
        };
      }

      case FieldType.ImportantDate: {
        let primary;
        try {
          primary = new Intl.DateTimeFormat('en-US', {
            day: 'numeric',
            month: 'long',
            timeZone: 'UTC',
            year: 'numeric',
          }).format(new Date(field.text));
        } catch (e) {
          primary = field.text;
        }

        return {
          name: messages?.[`${field.type}.name`],
          primary,
          secondary:
            messages?.[`${field.type}.label.${field.label as Labels.ImportantDate}`] ?? null,
        };
      }

      case FieldType.Note: {
        return {
          name: messages?.[`${field.type}.name`],
          primary: field.text,
          secondary: null,
        };
      }

      case FieldType.Phone: {
        return {
          name: messages?.[`${field.type}.name`],
          primary: field.text,
          secondary: messages?.[`${field.type}.label.${field.label as Labels.Phone}`] ?? null,
        };
      }

      default: {
        return {
          name: messages?.[`${field.type}.name`],
          primary: 'uri' in field ? field.text ?? field.uri : field.text,
          secondary: messages?.[`${field.type}.name`],
        };
      }
    }
  }, [field, messages]);

  const fieldWithRules = useMemo(() => {
    if (!message || !rules) {
      return undefined;
    }

    // Get a list of transform functions that are bound to the respective property.
    const rulesTransforms = rules.reduce<Array<(input: FieldTextInput) => FieldTextInput>>(
      (previous, rule) => {
        if (!rule.transforms) {
          return previous;
        }

        return [
          ...previous,
          ...rule.transforms
            .filter(() => !rule.property || rule.property === 'primary')
            .map((mutator) => transform(mutator, 'primary')),
          ...rule.transforms
            .filter(() => !rule.property || rule.property === 'secondary')
            .map((mutator) => transform(mutator, 'secondary')),
        ];
      },
      [],
    );

    let input = {
      primary: {
        hide: false,
        value: message.primary,
      },
      secondary: {
        hide: false,
        value: message.secondary,
      },
    };

    rulesTransforms.forEach((ruleTransform) => {
      input = ruleTransform(input);
    });

    // Mutate the object back into a FieldText object as the final transform
    return {
      ...input,
      primary: input.primary.hide ? null : input.primary.value,
      secondary: input.secondary.hide ? null : input.secondary.value,
    };
  }, [message, rules]);

  if (!message || !rules) {
    return message;
  }

  return {
    ...message,
    ...fieldWithRules,
  };
}
