import { StyleConfig, SystemStyleFunction, SystemStyleObject } from '@chakra-ui/theme-tools';

export type NameVariants = 'primary' | 'secondary';

const baseStyle: SystemStyleObject = {
  '& &, & + &': {
    '&::before': {
      content: '" "',
    },

    display: 'inline',
  },
  display: 'inline',
};

const variants: Record<NameVariants, SystemStyleObject | SystemStyleFunction> = {
  primary: {
    fontSize: '1.75rem',
    fontWeight: 800,
    lineHeight: '2.25rem',
  },
  secondary: {
    '&::before': {
      content: '" "',
    },
    fontSize: '1.25rem',
    fontWeight: 300,
    lineHeight: '2rem',
  },
};

export const CardName: StyleConfig = {
  baseStyle,
  defaultProps: {
    colorScheme: 'brand',
  },
  variants,
};
