import amplitude, { AmplitudeClient, Callback } from 'amplitude-js';
import Cookies from 'js-cookie';

let instance: AmplitudeClient | null = null;

export type LogCallback = Callback;

export function getAmplitudeInstance(
  apiKey = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY ?? process.env.AMPLITUDE_API_KEY,
): AmplitudeClient | null {
  if (typeof window === 'undefined') {
    return null;
  }

  if (!instance) {
    instance = amplitude.getInstance();
    instance?.init(apiKey ?? '');
  }

  const amplitudeDeviceId = Cookies.get('amplitude_device_id');
  if (amplitudeDeviceId) {
    instance?.setDeviceId(amplitudeDeviceId);
    Cookies.remove('amplitude_device_id');
  }

  return instance;
}
