import { createIcon } from '@chakra-ui/react';

export const IconSortIndiscriminateSm: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconSortIndiscriminateSm',
  path: (
    <>
      <path d="M16.5 10.5H7.5L12 4.5L16.5 10.5Z" fill="currentColor" />
      <path d="M16.5 13.5H7.5L12 19.5L16.5 13.5Z" fill="currentColor" />
    </>
  ),
});
