import { FunctionComponent } from 'react';
import { useStore } from 'zustand';
import { BadgeListVariant } from '../../layout';
import { useCardStore } from '../../store';
import { Collapsible } from './variants';

export type BadgeListProps = {
  variant: BadgeListVariant;
};

export const BadgeList: FunctionComponent<BadgeListProps> = ({ variant, ...props }) => {
  const cardStore = useCardStore();
  const cardVariant = useStore(cardStore, (state) => state.variant);

  if (cardVariant === 'mini') {
    return null;
  }

  switch (variant) {
    case 'collapsible': {
      return <Collapsible {...props} />;
    }

    default: {
      return null;
    }
  }
};
