import { createIcon } from '@chakra-ui/react';

export const IconBehance: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconBehance',
  path: (
    <path
      d="M27.0833 2.4H18.625V0H27.0833V2.4ZM29.1375 14.4C28.6542 15.96 26.7208 18 22.975 18C19.2292 18 16.2083 15.96 16.2083 11.16C16.2083 6.48 18.9875 4.08 22.8542 4.08C26.6 4.08 28.8958 6.24 29.3792 9.36C29.5 9.96 29.5 10.8 29.5 12H19.8333C19.9542 15.84 24.0625 15.96 25.3917 14.4H29.1375ZM19.8333 9.6H25.875C25.7542 7.8 24.5458 6.96 22.8542 6.96C21.1625 6.96 20.1958 7.8 19.8333 9.6ZM8.35417 18H0.5V0H8.95833C15.6042 0.12 15.725 6.48 12.2208 8.28C16.3292 9.84 16.45 18 8.35417 18ZM4.125 7.2H8.475C11.4958 7.2 11.9792 3.6 8.1125 3.6H4.125V7.2ZM8.23333 10.8H4.125V14.4H8.1125C11.8583 14.4 11.6167 10.8 8.23333 10.8Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 30 18',
});
