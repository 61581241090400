import { createIcon } from '@chakra-ui/react';

export const IconEditThin: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconEditThin',
  path: (
    <path
      clipRule="evenodd"
      d="M20.7072 5.63214C21.0972 6.02208 21.0972 6.652 20.7072 7.04194L18.8775 8.87169L15.128 5.12221L16.9577 3.29246C17.3477 2.90251 17.9776 2.90251 18.3675 3.29246L20.7072 5.63214ZM3 21V17.2505L14.0585 6.19205L17.808 9.94153L6.74948 21H3Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
  viewBox: '0 0 24 24',
});
