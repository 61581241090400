import { createContext } from 'react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

type StepProps = {
  currentStep: number;
  numberOfSteps: number;
};

interface StepState extends StepProps {
  setCurrentStep: (step: number) => void;
}

type StepStore = ReturnType<typeof createStepStore>;

export const createStepStore = (initProps?: StepProps) => {
  const DEFAULT_PROPS: StepProps = {
    currentStep: 0,
    numberOfSteps: 0,
  };
  return create<StepState>()(
    immer((set) => ({
      ...DEFAULT_PROPS,
      ...initProps,
      setCurrentStep: (step: number) =>
        set((state) => {
          const newState = state;
          newState.currentStep = step;
        }),
    })),
  );
};

export const StepContext = createContext<StepStore | null>(null);
