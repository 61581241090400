import { useEffect, useRef, useState } from 'react';

type CopyToClipboardOptions = {
  isCopiedDelay?: number;
};

export const useCopyToClipboard = (
  text: string,
  options: CopyToClipboardOptions = { isCopiedDelay: 3000 },
) => {
  const [isCopied, setIsCopied] = useState(false);

  const hintTimeoutRef = useRef(0);

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(text);
    setIsCopied(true);
    window.clearTimeout(hintTimeoutRef.current);
    hintTimeoutRef.current = window.setTimeout(() => {
      setIsCopied(false);
    }, options.isCopiedDelay);
  };

  useEffect(
    () => () => {
      window.clearTimeout(hintTimeoutRef.current);
    },
    [],
  );

  return {
    copyToClipboard,
    isCopied,
  };
};
