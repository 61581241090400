export function hexToRgb(hex: string): [number, number, number] {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) {
    return [143, 96, 222];
  }

  return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
}

export function hexToHsl(hex: string): [number, number, number] {
  const [r, g, b] = hexToRgb(hex).map((value) => value / 255);
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const l = (max + min) / 2;
  let [h, s] = Array(3).fill(l);

  if (max === min) {
    h = 0;
    s = 0;
  } else {
    const diff = max - min;

    if (max === r) {
      h = (g - b) / diff + (g < b ? 6 : 0);
    }

    if (max === g) {
      h = (b - r) / diff + 2;
    }

    if (max === b) {
      h = (r - g) / diff + 4;
    }

    s = l > 0.5 ? diff / (2 - max - min) : diff / (max + min);
  }

  return [Math.round((h / 6) * 360), Math.round(s * 100), Math.round(l * 100)];
}

export function darken(color: string, factor = 0): string {
  const [h, s, l] = hexToHsl(color);
  const darkened = Math.ceil(Math.min(1, Math.max(0, (l / 100) * (1 - factor))) * 100);
  return `hsl(${h}, ${s}%, ${darkened}%)`;
}

export function lighten(color: string, factor = 0): string {
  const [h, s, l] = hexToHsl(color);
  const lightened = Math.ceil(Math.min(1, Math.max(0, (l / 100) * (1 + factor))) * 100);
  return `hsl(${h}, ${s}%, ${lightened}%)`;
}

export function hslToRgb(h: number, s: number, l: number): [number, number, number] {
  let [r, g, b] = [0, 0, 0];
  let [hue, saturation, luminance] = [h, s, l];

  if (!Number.isFinite(hue)) {
    hue = 0;
  }

  if (!Number.isFinite(saturation)) {
    saturation = 0;
  }

  if (!Number.isFinite(luminance)) {
    luminance = 0;
  }

  hue /= 60;

  if (hue < 0) {
    hue = 6 - (-hue % 6);
  }

  hue %= 6;

  saturation = Math.max(0, Math.min(1, saturation / 100));
  luminance = Math.max(0, Math.min(1, luminance / 100));

  const c = (1 - Math.abs(2 * luminance - 1)) * saturation;
  const x = c * (1 - Math.abs((hue % 2) - 1));

  if (hue < 1) {
    r = c;
    g = x;
    b = 0;
  } else if (hue < 2) {
    r = x;
    g = c;
    b = 0;
  } else if (hue < 3) {
    r = 0;
    g = c;
    b = x;
  } else if (hue < 4) {
    r = 0;
    g = x;
    b = c;
  } else if (hue < 5) {
    r = x;
    g = 0;
    b = c;
  } else {
    r = c;
    g = 0;
    b = x;
  }

  const m = luminance - c / 2;
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return [r, g, b];
}

export function rgbToHex(r: number, g: number, b: number): string {
  function componentToHex(c: number): string {
    const hex = c.toString(16);

    return hex.length === 1 ? `0${hex}` : hex;
  }

  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}

function rgbToHexMultiplier(rgb: number[], multiplier: number): string {
  const multipliedRgb: number[] = rgb.map((component) =>
    Math.max(0, Math.min(255, Math.round(component * multiplier))),
  );
  return `#${multipliedRgb.map((component) => component.toString(16).padStart(2, '0')).join('')}`;
}

export function generateChakraColor(color: string): Record<string, string> {
  const hexWithoutHash = color.replace('#', '');

  const baseRgb: number[] = [
    parseInt(hexWithoutHash.slice(0, 2), 16),
    parseInt(hexWithoutHash.slice(2, 4), 16),
    parseInt(hexWithoutHash.slice(4, 6), 16),
  ];

  const themeColor: Record<string, string> = {};

  for (let i = 4; i >= 1; i -= 1) {
    const multiplier = 1 + i / 10;
    const key = String(100 * (5 - i));
    themeColor[key] = rgbToHexMultiplier(baseRgb, multiplier);
  }

  themeColor['500'] = `#${hexWithoutHash}`;

  for (let i = 1; i <= 5; i += 1) {
    const multiplier = 1 - i / 10;
    const key = String(500 + i * 100);
    themeColor[key] = rgbToHexMultiplier(baseRgb, multiplier);
  }

  return themeColor;
}

export const loadingPalette = {
  dark: {
    accent: '#1A202C',
    brand: '#1A202C',
    onAccent: '#718096',
    onBrand: '#E2E8F0',
  },
  light: {
    accent: '#E2E8F0',
    brand: '#CBD5E0',
    onAccent: '#1A202C',
    onBrand: '#171923',
  },
};
