import { Flex, FlexProps } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { CardLayout } from './CardLayout';
import { CardSchema, defaultLayout } from './layout';
import { CardStore, CardStoreProps } from './store';

export type CardProps = FlexProps &
  CardStoreProps & {
    designId: string;
    goesByText?: string;
    layout?: CardSchema | null;
    variant?: CardStoreProps['variant'];
    z?: number;
  };

export const Card: FunctionComponent<CardProps> = ({
  children,
  details,
  designId,
  goesByText,
  layout,
  messages,
  onFieldSelect,
  onFieldTextCopiedToClipboard,
  themePalette,
  variant = 'default',
  z,
  ...props
}) => (
  <CardStore
    details={details}
    messages={messages}
    onFieldSelect={onFieldSelect}
    onFieldTextCopiedToClipboard={onFieldTextCopiedToClipboard}
    themePalette={themePalette}
    variant={variant}
  >
    <Flex
      bg="#f2f2f2"
      borderRadius="2xl"
      boxShadow={variant === 'mini' ? 'xl' : 'sm'}
      designid={designId}
      direction="column"
      maxW={variant === 'mini' ? 'none' : '27.5rem'}
      minW={variant === 'mini' ? 'auto' : '20rem'}
      overflow="hidden"
      transform={`translateZ(0) ${variant === 'mini' ? 'scale(0.60235)' : ''}`}
      transformOrigin="top left"
      w={variant === 'mini' ? '26.5625rem' : 'full'}
      {...props}
    >
      <CardLayout layout={layout ?? defaultLayout} />
      {children}
    </Flex>
  </CardStore>
);
