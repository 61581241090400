import { createIcon } from '@chakra-ui/react';

export const IconX: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconX',
  path: (
    <path
      d="M7.68621 7.37208L8.00048 7.68635L8.31475 7.37208L11.3005 4.38635L11.6146 4.70048L8.62888 7.68621L8.31461 8.00048L8.62888 8.31475L11.6146 11.3005L11.3005 11.6146L8.31475 8.62888L8.00048 8.31461L7.68621 8.62888L4.70048 11.6146L4.38635 11.3005L7.37208 8.31475L7.68635 8.00048L7.37208 7.68621L4.38635 4.70048L4.70048 4.38635L7.68621 7.37208Z"
      fill="#381770"
      stroke="currentColor"
      strokeWidth="0.888889"
    />
  ),
  viewBox: '0 0 16 16',
});
