import { SkeletonText } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { Tbody, Td, Tr } from './components';

type TableContentLoadingStateProps = {
  cellComponents?: JSX.Element[];
  colCount?: number;
  rowCount?: number;
};

export const TableContentLoadingState: FunctionComponent<TableContentLoadingStateProps> = ({
  cellComponents = [],
  colCount = 3,
  rowCount = 8,
}) => {
  const rows = Array.from({ length: rowCount }, (_, i) => i);
  const cols = Array.from({ length: colCount }, (_, i) => i);

  return (
    <Tbody>
      {rows.map((row) => (
        <Tr key={row}>
          {cols.map((col, i) => (
            <Td key={col} p={2.5} px={4}>
              {cellComponents?.[i] ? (
                cellComponents[i]
              ) : (
                <SkeletonText noOfLines={1} skeletonHeight="4" spacing="4" />
              )}
            </Td>
          ))}
        </Tr>
      ))}
    </Tbody>
  );
};
