import {
  Stack,
  StackProps,
  StylesProvider,
  ThemingProps,
  useDimensions,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { Color } from '@hihello/core';
import { FunctionComponent, useRef, useState } from 'react';
import { Logo } from '../Logo';
import { SidebarExpandHandle } from './SidebarExpandHandler';

export type SidebarProps = StackProps &
  ThemingProps<'Sidebar'> & {
    handleExpandToggle?: () => void;
    hideLogo?: boolean;
    isExpanded?: boolean;
    isToggleable?: boolean;
    palette?: Color;
  };

export const Sidebar: FunctionComponent<SidebarProps> = ({
  children,
  handleExpandToggle = () => null,
  hideLogo,
  isExpanded = false,
  isToggleable,
  size = 'full',
  variant = 'default',
  ...props
}) => {
  const styles = useMultiStyleConfig('Sidebar', {
    size,
    variant,
  });

  const logoRef = useRef<HTMLDivElement>(null);
  const dimensions = useDimensions(logoRef);

  const [expandHandleOpacity, setExpandHandleOpacity] = useState(0);

  const handleMouseOut = () => {
    setExpandHandleOpacity(0);
  };

  const handleMouseOver = () => {
    setExpandHandleOpacity(1);
  };

  return (
    <StylesProvider value={styles}>
      <Stack
        __css={styles.container}
        as="nav"
        gap="0"
        onBlur={handleMouseOut}
        onFocus={handleMouseOver}
        onMouseOut={handleMouseOut}
        onMouseOver={handleMouseOver}
        role="group"
        {...props}
      >
        {!hideLogo && <Logo ref={logoRef} size={size} sx={styles.logo} />}
        {isToggleable && (
          <SidebarExpandHandle
            __css={styles.handler}
            isExpanded={isExpanded}
            onClick={handleExpandToggle}
            opacity={expandHandleOpacity}
          />
        )}
        <Stack
          __css={styles.list}
          flex="1"
          spacing={{ base: 4, sm: 6 }}
          style={{ maxHeight: `calc(100% - ${dimensions?.marginBox.height ?? 0}px)` }}
        >
          {children}
        </Stack>
      </Stack>
    </StylesProvider>
  );
};
