import { createIcon } from '@chakra-ui/react';

export const IconPasteboard: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconPasteboard',
  path: (
    <path
      d="M12.1389 13.3889C12.1389 13.9412 11.6912 14.3889 11.1389 14.3889H4.50001V5.66667C4.50001 5.11438 4.94773 4.66667 5.50001 4.66667H11.1389C11.6912 4.66667 12.1389 5.11438 12.1389 5.66667V13.3889ZM12.1389 3.27778H4.50001C4.13165 3.27778 3.77838 3.42411 3.51792 3.68457C3.25745 3.94504 3.11112 4.29831 3.11112 4.66667V14.3889C3.11112 14.7572 3.25745 15.1105 3.51792 15.371C3.77838 15.6314 4.13165 15.7778 4.50001 15.7778H12.1389C12.5073 15.7778 12.8605 15.6314 13.121 15.371C13.3815 15.1105 13.5278 14.7572 13.5278 14.3889V4.66667C13.5278 4.29831 13.3815 3.94504 13.121 3.68457C12.8605 3.42411 12.5073 3.27778 12.1389 3.27778ZM10.0556 1.19444C10.0556 0.810913 9.74465 0.5 9.36112 0.5H1.72223C1.35388 0.5 1.00061 0.646329 0.74014 0.906796C0.479672 1.16726 0.333344 1.52053 0.333344 1.88889V10.9167C0.333344 11.3002 0.644257 11.6111 1.02779 11.6111C1.41132 11.6111 1.72223 11.3002 1.72223 10.9167V2.88889C1.72223 2.3366 2.16995 1.88889 2.72223 1.88889H9.36112C9.74465 1.88889 10.0556 1.57798 10.0556 1.19444Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 14 16',
});
