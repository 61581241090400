import type { CookieSerializeOptions } from 'cookie';
import { deleteCookie } from 'cookies-next';
import { IncomingMessage, ServerResponse } from 'http';
import { parse, splitCookiesString } from 'set-cookie-parser';

interface AuthSignOutProps {
  nextUrl?: string;
  redirect?: boolean;
  req?: IncomingMessage;
  res?: ServerResponse;
}

export async function authSignOut(props: AuthSignOutProps) {
  const { nextUrl, redirect = true, req, res } = props;
  const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/signout`, {
    body: JSON.stringify({ nextUrl }),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  const data = await response.json();

  if (response.status >= 400) {
    throw new Error(data.code);
  }

  if (req && res) {
    const cookies = response.headers.get('set-cookie') ?? '';
    const parsedCookies = parse(splitCookiesString(cookies));
    parsedCookies.forEach(({ name, value, ...options }) => {
      const cookieOptions = options as CookieSerializeOptions;
      deleteCookie(name, {
        req,
        res,
        ...cookieOptions,
      });
    });
  }

  if (redirect) {
    window.location.assign(data.uri);
  }
}
