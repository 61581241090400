import { StyleConfig, SystemStyleFunction, SystemStyleObject } from '@chakra-ui/theme-tools';
import { ImageTransition } from '../../../components/Card/components';

const baseStyle: SystemStyleObject = {
  '&:not([src])': {
    visibility: 'hidden',
  },
  transitionDuration: '300ms',
  transitionProperty: 'transform',
  w: 'full',
};

const variants: Record<ImageTransition, SystemStyleObject | SystemStyleFunction> = {
  fade: (props) => ({
    opacity: props.isVisible ? 1 : 0,
    transitionProperty: 'opacity',
  }),
  scale: (props) => ({
    transform: props.isVisible ? 'scale(1)' : 'scale(0.01)',
  }),
  scaleX: (props) => ({
    transform: props.isVisible ? 'scaleX(1)' : 'scaleX(0.01)',
  }),
  scaleY: (props) => ({
    transform: props.isVisible ? 'scaleY(1)' : 'scaleY(0.01)',
  }),
};

export const CardImage: StyleConfig = {
  baseStyle,
  defaultProps: {
    colorScheme: 'brand',
  },
  variants,
};
