import { createIcon } from '@chakra-ui/react';

export const IconChatLine: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconChatLine',
  path: (
    <path
      clipRule="evenodd"
      d="M8.66667 2H12C13.7681 2 15.4638 2.70238 16.714 3.95262C17.9643 5.20286 18.6667 6.89856 18.6667 8.66667C18.6667 10.4348 17.9643 12.1305 16.714 13.3807C15.4638 14.631 13.7681 15.3333 12 15.3333V18.25C7.83333 16.5833 2 14.0833 2 8.66667C2 6.89856 2.70238 5.20286 3.95262 3.95262C5.20286 2.70238 6.89856 2 8.66667 2ZM6.33325 6.5H14.3333V7.5H6.33325V6.5ZM14.3333 9.5H6.33325V10.5H14.3333V9.5Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
  viewBox: '0 0 20 20',
});
