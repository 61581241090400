import { createIcon } from '@chakra-ui/react';

export const IconPauseCircleOutline: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconPauseCircleOutline',
  path: (
    <path
      d="M7.49999 13.3333H9.16666V6.66666H7.49999V13.3333ZM9.99999 1.66666C5.39999 1.66666 1.66666 5.39999 1.66666 9.99999C1.66666 14.6 5.39999 18.3333 9.99999 18.3333C14.6 18.3333 18.3333 14.6 18.3333 9.99999C18.3333 5.39999 14.6 1.66666 9.99999 1.66666ZM9.99999 16.6667C6.32499 16.6667 3.33332 13.675 3.33332 9.99999C3.33332 6.32499 6.32499 3.33332 9.99999 3.33332C13.675 3.33332 16.6667 6.32499 16.6667 9.99999C16.6667 13.675 13.675 16.6667 9.99999 16.6667ZM10.8333 13.3333H12.5V6.66666H10.8333V13.3333Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 20 20',
});
