import { Box, BoxProps, Skeleton, useStyleConfig } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { NameVariant } from '../../layout';
import { useNameProps } from '../../store';

export type NameProps = BoxProps & {
  variant: NameVariant;
};

export const Name: FunctionComponent<NameProps> = ({ children, variant, ...props }) => {
  const name = useNameProps(variant);
  const primaryStyles = useStyleConfig('CardName', { variant: 'primary' });
  const secondaryStyles = useStyleConfig('CardName', { variant: 'secondary' });

  if (name === null || name === '') {
    return null;
  }

  if (['display', 'full', 'maiden'].includes(variant)) {
    return (
      <Box
        __css={primaryStyles}
        as="span"
        className={`CardName CardName--${variant}`}
        w={name ? 'auto' : 'full'}
        {...props}
      >
        {name?.replaceAll(/-/g, '‑') ?? <Skeleton h="5" mb="1" w="65%" />}
        {children}
      </Box>
    );
  }

  return (
    <Box
      __css={secondaryStyles}
      as="span"
      className={`CardName CardName--${variant}`}
      w={name ? 'auto' : 'full'}
      {...props}
    >
      {name?.replaceAll(/-/g, '‑') ?? <Skeleton h="5" w="25%" />}
      {children}
    </Box>
  );
};
