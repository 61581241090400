import { theme as proTheme } from '@chakra-ui/pro-theme';
import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import { Dict } from '@chakra-ui/utils';
import * as components from './components';
import * as foundations from './foundations';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

export const theme: Dict<any> = extendTheme(
  {
    ...foundations,
    components: {
      ...components,
    },
    config,
  },
  proTheme,
);

export default theme;
