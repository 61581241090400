import { IncomingMessage, ServerResponse } from 'http';
import Cookies, { CookieAttributes } from 'js-cookie';

const cookieDomain = process.env.NEXT_PUBLIC_COOKIE_DOMAIN ?? undefined;
const sixMonths = 1000 * 60 * 60 * 24 * 180;

const defaultCookieOptions = {
  domain: cookieDomain,
  path: '/',
  sameSite: 'lax',
  secure: true,
} as CookieAttributes;

export function getCookie(name: string, req?: IncomingMessage) {
  if (req) {
    return req.headers.cookie
      ?.split('; ')
      .find((cookie) => cookie.startsWith(`${name}=`))
      ?.split('=')[1];
  }

  return Cookies.get(name);
}

function getPersistExpiresDate() {
  return new Date(Date.now() + sixMonths);
}

type SetCookieProps = {
  name: string;
  persist?: boolean;
  res?: ServerResponse;
  value?: string;
};

function setServerCookie({ name, persist, res, value }: SetCookieProps) {
  if (!res) {
    return;
  }

  const cookieOptions = {
    ...defaultCookieOptions,
    ...(!value && { expires: new Date(0) }),
    ...(value && persist && { expires: getPersistExpiresDate() }),
  } as CookieAttributes;

  const existingCookies = res.getHeader('Set-Cookie') as string[] | undefined;
  const newCookie = `${name}=${value || ''};${Object.keys(cookieOptions)
    .map((key) => `${key}=${cookieOptions[key]}`)
    .join(';')}`;

  res.setHeader('Set-Cookie', [...(existingCookies || []), newCookie]);
}

export function setCookie({ name, persist, res, value }: SetCookieProps) {
  const cookieOptions = {
    ...defaultCookieOptions,
    ...(value && persist && { expires: getPersistExpiresDate() }),
  } as CookieAttributes;

  if (res) {
    setServerCookie({ name, persist, res, value });
  } else if (value) {
    Cookies.set(name, value, cookieOptions);
  } else {
    Cookies.remove(name, cookieOptions);
  }
}
