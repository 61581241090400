import { Image, Skeleton } from '@chakra-ui/react';
import { ComponentProps, useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const LazyImage = (props: ComponentProps<typeof Image>) => {
  const [inView, setInView] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const imageRef = useRef<HTMLImageElement>(null);

  const callback = useCallback<IntersectionObserverCallback>((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setInView(true);
        observer.disconnect();
      }
    });
  }, []);

  const observer = useMemo(() => new IntersectionObserver(callback), [callback]);

  const skeletonRef = (el: HTMLDivElement) => {
    if (el) observer.observe(el);
  };

  useEffect(() => {
    if (!imageRef.current) return undefined;

    const loadFalse = () => setIsLoading(false);
    const currentImageRef = imageRef.current;
    currentImageRef.addEventListener('load', loadFalse);

    return () => {
      currentImageRef.removeEventListener('load', loadFalse);
    };
  });

  const imageProps = useMemo(() => {
    if (isLoading) {
      return {
        ...props,
        display: 'none',
      };
    }

    return props;
  }, [isLoading, props]);

  return (
    <>
      {(!inView || isLoading) && <Skeleton ref={skeletonRef} {...props} />}
      {inView && <Image ref={imageRef} {...imageProps} />}
    </>
  );
};
