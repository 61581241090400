import { OAuthProviderId, providersIssuers } from '@src/hooks';
import { ClientId } from '@src/lib/utils';

interface AuthWithOAuthProps {
  clientId?: ClientId;
  issuer: OAuthProviderId;
  nextUrl?: string;
}

export async function authWithOAuth(props: AuthWithOAuthProps): Promise<void> {
  const { clientId, issuer, nextUrl } = props;

  if (!providersIssuers[issuer]) {
    throw new Error('Invalid issuer');
  }

  const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/oauth`, {
    body: JSON.stringify({
      clientId,
      iss: providersIssuers[issuer],
      nextUrl,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  const data = await response.json();

  if (response.status >= 400) {
    throw new Error(data.code);
  }

  window.location.assign(data.uri);
}
