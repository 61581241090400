interface AuthWithTokenProps {
  clientId?: string;
  nextUrl?: string;
  token: string;
}

export async function authWithToken(props: AuthWithTokenProps): Promise<void> {
  const { nextUrl, token } = props;
  const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/token`, {
    body: JSON.stringify({ nextUrl, token }),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  const data = await response.json();

  if (response.status >= 400) {
    throw new Error(data.code);
  }

  window.location.assign(data.uri);
}
