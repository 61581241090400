import { useBoolean } from '@chakra-ui/react';

export function useCredentialsPersistence() {
  const [persistence, setPersistence] = useBoolean(true);

  return {
    persistence,
    setPersistence,
  };
}
