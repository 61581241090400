import { Input, InputGroup, InputProps, InputLeftElement, InputGroupProps } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

type ColorPickerInputProps = InputGroupProps & {
  defaultValue?: string;
  isDisabled?: boolean;
  onChange?: (value: string) => void;
  swatchProps?: InputProps;
  textProps?: InputProps;
  value?: string;
};

export const ColorPickerInput = ({
  defaultValue = '#000000',
  onChange,
  swatchProps,
  textProps,
  value,
  isDisabled,
  ...props
}: ColorPickerInputProps) => {
  const [color, setColor] = useState(value ?? defaultValue);
  const [colorTyped, setColorTyped] = useState(value ?? defaultValue);

  const handleColorSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = e.target.value;
    setColor(nextValue);
    setColorTyped(nextValue);
    onChange?.(nextValue);
  };

  useEffect(() => {
    setColor(value ?? defaultValue);
    setColorTyped(value ?? defaultValue);
  }, [defaultValue, value]);

  const handleColorTyped = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setColorTyped('#');
      return;
    }

    const nextValue = `#${e.target.value.replace(/#/g, '')}`;
    const isValidHex = /^#[0-9A-F]{6}$/i.test(nextValue);

    if (isValidHex) {
      setColor(nextValue);
      onChange?.(nextValue);
      setColorTyped(nextValue);
      return;
    }

    setColorTyped(/^#[0-9A-F]{0,6}$/i.test(nextValue) ? nextValue : colorTyped);
  };

  return (
    <InputGroup borderRadius="md" h="8" w="8.125rem" {...props}>
      <InputLeftElement h="full" w="9">
        <Input
          _focus={{ borderColor: color }}
          appearance="none"
          bg="none"
          borderColor="transparent"
          borderRadius="0.5rem 0 0 0.5rem"
          borderRightColor="gray.200"
          cursor="pointer"
          h="full"
          isDisabled={isDisabled}
          onChange={handleColorSelected}
          p="0"
          sx={{
            '::-moz-color-swatch': {
              border: 'none',
              borderRadius: '0.375rem 0 0 0.375rem',
            },
            '::-webkit-color-swatch': {
              border: 'none',
              borderRadius: '0.375rem 0 0 0.375rem',
            },
            '::-webkit-color-swatch-wrapper': {
              padding: '0',
            },
          }}
          type="color"
          value={color}
          w="full"
          z="-1"
          {...swatchProps}
        />
      </InputLeftElement>
      <Input
        _focus={{
          outline: 'none',
        }}
        color="blackAlpha.500"
        focusBorderColor={color}
        fontSize="sm"
        h="full"
        isDisabled={isDisabled}
        onChange={handleColorTyped}
        pl="3.125rem"
        pr="2"
        textTransform="uppercase"
        value={colorTyped}
        zIndex="1"
        {...textProps}
      />
    </InputGroup>
  );
};
