import { createIcon } from '@chakra-ui/react';

export const IconNintendoSwitch: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconNintendoSwitch',
  path: (
    <>
      <path
        d="M5.35198 0.0830536C2.86181 0.528187 0.858292 2.37084 0.226691 4.7932C-0.00109912 5.66794 -0.0166303 6.18036 0.00925499 12.9661C0.0247862 19.198 0.0299632 19.3429 0.133504 19.8243C0.708157 22.4174 2.55119 24.2652 5.17078 24.876C5.51246 24.9537 5.94734 24.9692 8.74812 24.9847C11.6473 25.0054 11.9475 25.0002 12.0252 24.9226C12.1029 24.845 12.108 23.846 12.108 12.5209C12.108 4.1255 12.0925 0.171045 12.0563 0.0985813C12.0045 0.00541306 11.9165 0.000236511 8.88273 0.00541306C6.41845 0.0105896 5.67813 0.0261173 5.35198 0.0830536ZM10.0372 12.5054V22.992L7.93015 22.9661C5.98875 22.9454 5.78167 22.935 5.39339 22.8367C3.72638 22.4071 2.48906 21.1234 2.1422 19.4516C2.02831 18.9288 2.02831 6.05096 2.13702 5.53854C2.44765 4.08409 3.45717 2.87808 4.81874 2.33461C5.50211 2.06028 5.81791 2.02922 8.03369 2.02405L10.0372 2.01887V12.5054Z"
        fill="currentColor"
      />
      <path
        d="M5.79729 5.18149C5.47113 5.2436 4.97413 5.49205 4.7101 5.72496C4.16651 6.19598 3.89731 6.86368 3.93872 7.65043C3.95943 8.05933 3.98532 8.16803 4.15098 8.49929C4.3943 9.00136 4.76187 9.36886 5.26405 9.6173C5.61091 9.78811 5.69892 9.80882 6.14933 9.82434C6.55831 9.83987 6.70327 9.82434 6.97765 9.73118C8.10108 9.35333 8.77927 8.2612 8.58772 7.14318C8.36511 5.81296 7.1019 4.92269 5.79729 5.18149Z"
        fill="currentColor"
      />
      <path
        d="M14.6293 0.0363293C14.6085 0.051857 14.593 5.66262 14.593 12.5053C14.593 23.7941 14.5982 24.938 14.6759 24.969C14.8156 25.0208 18.833 25.0001 19.33 24.9483C21.4319 24.7102 23.2853 23.4318 24.2948 21.5322C24.4243 21.2889 24.5951 20.88 24.6831 20.6264C25.0093 19.6533 24.9989 19.9121 24.9989 12.4742C24.9989 6.53736 24.9886 5.65227 24.9161 5.27442C24.4035 2.57774 22.3483 0.543575 19.6458 0.0777359C19.2834 0.015625 18.7243 9.72748e-05 16.9227 9.72748e-05C15.6802 9.72748e-05 14.6448 0.015625 14.6293 0.0363293ZM20.1584 11.3096C20.966 11.5218 21.6286 12.1378 21.8978 12.9245C22.0687 13.4111 22.0635 14.1254 21.8927 14.5705C21.5769 15.3883 20.9556 15.9628 20.1584 16.175C18.8641 16.5115 17.487 15.7299 17.1091 14.4463C16.9952 14.0529 17.0004 13.3904 17.1298 12.9866C17.5181 11.7185 18.8641 10.9732 20.1584 11.3096Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: '0 0 25 25',
});
