import { Input, InputProps, InputGroup, InputGroupProps, InputLeftElement } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { IconSearch } from '../Icons';

export type SearchInputProps = InputProps & {
  hoverColor?: string;
  wrapperProps?: InputGroupProps;
};

export const SearchInput: FunctionComponent<SearchInputProps> = ({
  id = 'SearchInput-input',
  wrapperProps,
  ...props
}) => (
  <InputGroup {...wrapperProps}>
    <InputLeftElement pointerEvents="none">
      <IconSearch color="gray.400" />
    </InputLeftElement>
    <Input id={id} placeholder="Search" {...props} type="search" />
  </InputGroup>
);
