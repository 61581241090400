import { createIcon } from '@chakra-ui/react';

export const IconCompanyAdmin: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconCompanyAdmin',
  path: (
    <>
      <path
        d="M14.372 5.2168C14.3904 5.0776 14.4 4.9384 14.4 4.8C14.4 2.8968 12.6856 1.3696 10.7832 1.628C10.2288 0.6416 9.1728 0 8 0C6.8272 0 5.7712 0.6416 5.2168 1.628C3.3104 1.3696 1.6 2.8968 1.6 4.8C1.6 4.9384 1.6096 5.0776 1.628 5.2168C0.6416 5.772 0 6.828 0 8C0 9.172 0.6416 10.228 1.628 10.7832C1.60954 10.9214 1.60019 11.0606 1.6 11.2C1.6 13.1032 3.3104 14.6264 5.2168 14.372C5.7712 15.3584 6.8272 16 8 16C9.1728 16 10.2288 15.3584 10.7832 14.372C12.6856 14.6264 14.4 13.1032 14.4 11.2C14.4 11.0616 14.3904 10.9224 14.372 10.7832C15.3584 10.228 16 9.172 16 8C16 6.828 15.3584 5.772 14.372 5.2168Z"
        fill="black"
      />
      <path
        d="M8.00001 8.66683V11.3335H5.33334C5.33334 10.6263 5.6143 9.94798 6.11439 9.44788C6.61449 8.94778 7.29277 8.66683 8.00001 8.66683ZM8.00001 8.3335C6.89501 8.3335 6.00001 7.4385 6.00001 6.3335C6.00001 5.2285 6.89501 4.3335 8.00001 4.3335C9.10501 4.3335 10 5.2285 10 6.3335C10 7.4385 9.10501 8.3335 8.00001 8.3335ZM11 9.66683H11.3333V11.3335H8.66668V9.66683H9.00001V9.3335C9.00001 9.06828 9.10537 8.81393 9.2929 8.62639C9.48044 8.43885 9.73479 8.3335 10 8.3335C10.2652 8.3335 10.5196 8.43885 10.7071 8.62639C10.8947 8.81393 11 9.06828 11 9.3335V9.66683ZM10.3333 9.66683V9.3335C10.3333 9.24509 10.2982 9.16031 10.2357 9.09779C10.1732 9.03528 10.0884 9.00016 10 9.00016C9.9116 9.00016 9.82682 9.03528 9.76431 9.09779C9.7018 9.16031 9.66668 9.24509 9.66668 9.3335V9.66683H10.3333Z"
        fill="white"
      />
    </>
  ),
  viewBox: '0 0 16 16',
});
