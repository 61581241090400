import type { IconProps } from '@chakra-ui/react';
import { SortDirections } from '@hihello/core';
import { FunctionComponent } from 'react';
import { IconSortIndiscriminateSm, IconArrowDownFilledSm } from '../../Icons';

type ThSortIconProps = IconProps & {
  'data-testid'?: string;
  sortDirection?: SortDirections;
};

export const ThSortIcon: FunctionComponent<ThSortIconProps> = ({ sortDirection, ...iconProps }) => {
  if (!sortDirection) {
    return <IconSortIndiscriminateSm color="gray.400" fontSize="1rem" {...iconProps} />;
  }

  return (
    <IconArrowDownFilledSm
      fontSize="md"
      transform={sortDirection === SortDirections.Desc ? 'rotate(180deg)' : undefined}
      transition="transform 400ms"
      {...iconProps}
    />
  );
};
