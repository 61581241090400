import {
  Box,
  BoxProps,
  HStack,
  Radio,
  RadioProps,
  Stack,
  StackProps,
  Text,
} from '@chakra-ui/react';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { IconInfoOutline } from '../Icons';

type RadioCardContentProps = StackProps & {
  info?: string;
  label: string;
  shouldEmphasizeLabel?: boolean;
};
const RadioCardContent: FunctionComponent<RadioCardContentProps> = ({
  info,
  label,
  shouldEmphasizeLabel,
}) => (
  <Stack gap={1}>
    <Text
      color={shouldEmphasizeLabel ? 'gray.700' : 'gray.500'}
      fontSize="md"
      fontWeight={shouldEmphasizeLabel ? 'semibold' : 'normal'}
    >
      {label}
    </Text>
    {info && (
      <HStack color="gray.500">
        <IconInfoOutline fontSize="xs" />
        <Text fontSize="xs">{info}</Text>
      </HStack>
    )}
  </Stack>
);

export type RadioCardProps = BoxProps & {
  hideRadio?: boolean;
  info?: string;
  label: string;
  radioProps?: RadioProps;
  value: string;
};
export const RadioCard: FunctionComponent<RadioCardProps> = ({
  hideRadio,
  info,
  label,
  radioProps,
  value,
  ...boxProps
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const [shouldEmphasizeLabel, setShouldEmphasizeLabel] = useState(false);

  useEffect(() => {
    setShouldEmphasizeLabel(!!inputRef.current?.checked);
  }, [inputRef.current?.checked, value]);

  if (hideRadio)
    return (
      <Box border="1px" borderColor="gray.100" borderRadius="md" p={4} pl={6} {...boxProps}>
        <RadioCardContent info={info} label={label} shouldEmphasizeLabel />
      </Box>
    );

  return (
    <Box
      border="1px"
      borderColor="gray.100"
      borderRadius="md"
      cursor={radioProps?.isDisabled ? 'not-allowed' : 'pointer'}
      p={4}
      {...boxProps}
    >
      <Radio spacing={6} value={value} {...radioProps} ref={inputRef}>
        <RadioCardContent info={info} label={label} shouldEmphasizeLabel={shouldEmphasizeLabel} />
      </Radio>
    </Box>
  );
};
