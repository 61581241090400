import { Button, Th as ChakraTh, Text, TextProps } from '@chakra-ui/react';
import type { IconProps, TableColumnHeaderProps } from '@chakra-ui/react';
import { SortDirections } from '@hihello/core';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useSortContext } from './Thead';
import { ThSortIcon } from './ThSortIcon';

const headerBorder = (withTopBorder: boolean = false) =>
  `inset 0 -1px 0 0 var(--chakra-colors-gray-200), inset 0 ${
    withTopBorder ? 1 : 0
  }px 0 0 var(--chakra-colors-gray-200)`;

const ThText: FunctionComponent<TextProps> = ({ children, ...props }) => (
  <Text as="div" fontSize="xs" fontWeight="700" textTransform="uppercase" {...props}>
    {children}
  </Text>
);

type ThProps = TableColumnHeaderProps & {
  initialSortDirection?: SortDirections;
  onSort?: (sortDirection: SortDirections | undefined) => void;
  sortIconProps?: IconProps & { 'data-testid'?: string };
  sortKey?: string;
  withNaturalSort?: boolean;
  withSort?: boolean;
  withTopBorder?: boolean;
};

export const Th: FunctionComponent<ThProps> = ({
  children,
  initialSortDirection,
  onSort,
  sortIconProps,
  sortKey,
  withNaturalSort,
  withSort,
  withTopBorder,
  ...tableColumnHeaderProps
}) => {
  const { columnsSortState, toggleSortColumn } = useSortContext();

  const [sortDirection, setSortDirection] = useState<SortDirections | undefined>(
    initialSortDirection,
  );

  const handleToggleSort = () => {
    if (!withSort) {
      return;
    }
    if (sortKey) {
      toggleSortColumn(sortKey);
    }

    let localSortDirection = sortDirection;
    if (!sortDirection) {
      localSortDirection = SortDirections.Asc;
    } else if (sortDirection === SortDirections.Asc) {
      localSortDirection = SortDirections.Desc;
    } else {
      localSortDirection = withNaturalSort ? undefined : SortDirections.Asc;
    }
    setSortDirection(localSortDirection);

    onSort?.(localSortDirection);
  };

  useEffect(() => {
    if (!!sortKey && columnsSortState[sortKey] === false) {
      setSortDirection(undefined);
    }
  }, [columnsSortState, sortKey, withNaturalSort]);

  const sortIconAriaLabel = useMemo(() => {
    if (!sortDirection) {
      return 'Sort indiscriminate';
    }
    return sortDirection === SortDirections.Asc ? 'Sort ascending' : 'Sort descending';
  }, [sortDirection]);

  return (
    <ChakraTh
      bg="chakra.body.background"
      borderY="none"
      boxShadow={headerBorder(withTopBorder)}
      fontWeight="700"
      textTransform="uppercase"
      {...tableColumnHeaderProps}
    >
      {withSort ? (
        <Button
          _hover={{ bg: 'transparent' }}
          height="auto"
          lineHeight="revert"
          onClick={handleToggleSort}
          px="0"
          variant="ghost"
          verticalAlign="baseline"
        >
          <ThText as="span" color={sortDirection ? 'gray.700' : 'gray.600'}>
            {children}
          </ThText>
          <ThSortIcon
            aria-label={sortIconAriaLabel}
            sortDirection={sortDirection}
            {...sortIconProps}
          />
        </Button>
      ) : (
        <ThText color="gray.600">{children}</ThText>
      )}
    </ChakraTh>
  );
};
