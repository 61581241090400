import { createIcon } from '@chakra-ui/react';

export const IconDelete: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconDelete',
  path: (
    <path
      d="M13.1667 4.00008H17.3333V5.66675H15.6667V16.5001C15.6667 16.7211 15.5789 16.9331 15.4226 17.0893C15.2663 17.2456 15.0543 17.3334 14.8333 17.3334H3.16666C2.94564 17.3334 2.73368 17.2456 2.5774 17.0893C2.42112 16.9331 2.33332 16.7211 2.33332 16.5001V5.66675H0.666656V4.00008H4.83332V1.50008C4.83332 1.27907 4.92112 1.06711 5.0774 0.910826C5.23368 0.754545 5.44564 0.666748 5.66666 0.666748H12.3333C12.5543 0.666748 12.7663 0.754545 12.9226 0.910826C13.0789 1.06711 13.1667 1.27907 13.1667 1.50008V4.00008ZM6.49999 8.16675V13.1667H8.16666V8.16675H6.49999ZM9.83332 8.16675V13.1667H11.5V8.16675H9.83332ZM6.49999 2.33341V4.00008H11.5V2.33341H6.49999Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 18 18',
});
