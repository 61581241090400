import { createIcon } from '@chakra-ui/react';

export const IconAccount: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconAccount',
  path: (
    <>
      <path
        clipRule="evenodd"
        d="M28 15C28 21.9036 22.4036 27.5 15.5 27.5C8.59644 27.5 3 21.9036 3 15C3 8.09644 8.59644 2.5 15.5 2.5C22.4036 2.5 28 8.09644 28 15ZM18.2473 11.1538C18.2473 12.6711 17.0173 13.9011 15.5 13.9011C13.9827 13.9011 12.7527 12.6711 12.7527 11.1538C12.7527 9.63658 13.9827 8.40659 15.5 8.40659C17.0173 8.40659 18.2473 9.63658 18.2473 11.1538ZM10.0665 17.456C9.65561 17.7975 9.45604 18.3206 9.45604 18.8549V19.533C9.45604 20.5951 10.317 21.456 11.3791 21.456H19.6209C20.683 21.456 21.544 20.5951 21.544 19.533V18.8549C21.544 18.3206 21.3444 17.7974 20.933 17.4565C19.5044 16.2729 17.5806 15.5495 15.4875 15.5495C13.3948 15.5495 11.4902 16.2727 10.0665 17.456Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M18.2334 11.4286C18.0955 12.8168 16.9242 13.9011 15.4997 13.9011C14.0751 13.9011 12.9038 12.8168 12.766 11.4286C12.757 11.5189 12.7524 11.6106 12.7524 11.7033C12.7524 13.2205 13.9824 14.4505 15.4997 14.4505C17.017 14.4505 18.2469 13.2205 18.2469 11.7033C18.2469 11.6106 18.2424 11.5189 18.2334 11.4286Z"
        fill="currentColor"
      />
      <path
        d="M9.47462 18.5714C9.4619 18.6649 9.45557 18.7597 9.45557 18.8549V18.5714H9.47462Z"
        fill="currentColor"
      />
      <path
        d="M21.5244 18.5714C21.5372 18.6649 21.5435 18.7597 21.5435 18.8549V18.5714H21.5244Z"
        fill="currentColor"
      />
      <path
        d="M21.5435 19.533C21.5435 20.5951 20.6825 21.4561 19.6204 21.4561H11.3786C10.3166 21.4561 9.45557 20.5951 9.45557 19.533V20.0824C9.45557 21.1445 10.3166 22.0055 11.3786 22.0055H19.6204C20.6825 22.0055 21.5435 21.1445 21.5435 20.0824V19.533Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: '0 0 31 30',
});
