import { createIcon } from '@chakra-ui/react';

export const IconRepeatClock: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconRepeatClock',
  path: (
    <path
      d="M7.32883 6.33534V8.79033C7.32883 9.02383 7.45558 9.24397 7.65572 9.36406L9.73712 10.5982C9.97728 10.7383 10.2842 10.6583 10.4243 10.4248C10.5643 10.1846 10.491 9.87774 10.2508 9.73764L8.33618 8.59687V6.32867C8.32951 6.06183 8.10269 5.83501 7.82917 5.83501C7.55565 5.83501 7.32883 6.06183 7.32883 6.33534ZM14 6.83568V3.30663C14 3.00643 13.6398 2.85966 13.433 3.07314L12.2455 4.26061C11.6206 3.63566 10.8655 3.15621 10.0342 2.85641C9.20278 2.55661 8.31551 2.44383 7.43557 2.52611C4.64035 2.77961 2.33879 5.02779 2.03192 7.82301C1.86971 9.39032 2.33011 10.9586 3.31381 12.1895C4.29751 13.4204 5.72566 14.2152 7.29016 14.4026C8.85467 14.5899 10.4301 14.1548 11.6766 13.191C12.9232 12.2271 13.7409 10.8119 13.9533 9.25065C14 8.85037 13.6865 8.50347 13.2862 8.50347C12.9526 8.50347 12.6724 8.75031 12.6324 9.07719C12.3456 11.4054 10.3375 13.2066 7.92923 13.1733C5.45423 13.1399 3.36615 11.0519 3.32613 8.57019C3.2861 5.96843 5.40086 3.83365 7.99595 3.83365C9.28348 3.83365 10.4509 4.36068 11.2982 5.20124L9.9039 6.59552C9.69042 6.809 9.83719 7.16924 10.1374 7.16924H13.6664C13.8532 7.16924 14 7.02247 14 6.83568Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 16 17',
});
