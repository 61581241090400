export enum AuthMethod {
  EmailAndPassword = 'emailAndPassword',
  OAuth = 'oauth',
  Token = 'token',
}

export enum OAuthProviderId {
  Apple = 'apple.com',
  Facebook = 'facebook.com',
  Google = 'google.com',
  Microsoft = 'microsoft.com',
  WorkOS = 'workos.com',
}

export type LoginProviderTypes = 'email' | OAuthProviderId;

export const providersIssuers = {
  [OAuthProviderId.Apple]: 'https://appleid.apple.com',
  [OAuthProviderId.Facebook]: 'https://www.facebook.com/v17.0/dialog/oauth',
  [OAuthProviderId.Google]: 'https://accounts.google.com',
  [OAuthProviderId.Microsoft]: 'https://login.microsoftonline.com/common/v2.0',
};
