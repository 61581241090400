import { createIcon } from '@chakra-ui/react';

export const IconCards: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconCards',
  path: (
    <>
      <path
        d="M13.4333 6.1238C13.4333 6.53489 13.27 6.92914 12.9793 7.21982C12.6887 7.5105 12.2944 7.6738 11.8833 7.6738C11.4722 7.6738 11.078 7.5105 10.7873 7.21982C10.4966 6.92914 10.3333 6.53489 10.3333 6.1238C10.3333 5.71272 10.4966 5.31847 10.7873 5.02779C11.078 4.73711 11.4722 4.5738 11.8833 4.5738C12.2944 4.5738 12.6887 4.73711 12.9793 5.02779C13.27 5.31847 13.4333 5.71272 13.4333 6.1238ZM18.6667 6.4613C18.6667 6.14964 18.6667 5.9263 18.6358 5.72964C18.5813 5.3849 18.4557 5.05526 18.2671 4.76161C18.0785 4.46796 17.8309 4.21672 17.54 4.0238C17.475 3.98047 17.3925 4.03714 17.4033 4.11464C17.4867 4.73464 17.5008 5.47047 17.5033 6.27297V13.7263C17.5008 14.5296 17.4867 15.2655 17.4033 15.8855C17.3925 15.963 17.475 16.0188 17.54 15.9763C17.8309 15.7834 18.0785 15.5321 18.2671 15.2385C18.4557 14.9448 18.5813 14.6152 18.6358 14.2705C18.6667 14.0738 18.6667 13.8505 18.6667 13.5388V6.4613ZM3.1625 6.5113C3.16248 6.38213 3.16303 6.25297 3.16417 6.1238C3.1675 5.3788 3.185 4.69547 3.26417 4.11464C3.27417 4.03714 3.19167 3.9813 3.12667 4.0238C2.83581 4.21672 2.5882 4.46796 2.39956 4.76161C2.21092 5.05526 2.08536 5.3849 2.03083 5.72964C2 5.9263 2 6.14964 2 6.4613V13.538C2 13.8496 2 14.073 2.03083 14.2696C2.08531 14.6144 2.21085 14.944 2.3995 15.2377C2.58814 15.5314 2.83577 15.7826 3.12667 15.9755C3.19167 16.0188 3.27417 15.9621 3.26333 15.8846C3.185 15.3038 3.1675 14.6205 3.16333 13.8763C3.16299 13.7469 3.16299 13.6174 3.16333 13.488L3.1625 6.5113Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M6.92593 2.0864C7.59653 2 8.45206 2 9.5117 2H11.1453C12.2049 2 13.0596 2 13.7311 2.0864C14.4292 2.1768 15.0165 2.3696 15.483 2.8176C15.9495 3.2656 16.1494 3.8296 16.2435 4.4992C16.3335 5.144 16.3335 5.9648 16.3335 6.9824V13.0176C16.3335 14.0352 16.3335 14.856 16.2435 15.5008C16.1494 16.1704 15.9486 16.7344 15.483 17.1824C15.0165 17.6304 14.4292 17.8232 13.7311 17.9136C13.0613 18 12.2058 18 11.1461 18H9.5117C8.45206 18 7.59736 18 6.92593 17.9136C6.22784 17.8232 5.64054 17.6304 5.17404 17.1824C4.70753 16.7344 4.5076 16.1704 4.41347 15.5008C4.3235 14.856 4.3235 14.0352 4.3235 13.0176V6.9824C4.3235 5.9648 4.3235 5.144 4.41347 4.4992C4.5076 3.8296 4.70836 3.2656 5.17404 2.8176C5.64054 2.3696 6.22784 2.1768 6.92593 2.0864ZM5.50142 14.536C5.48643 14.1072 5.48559 13.596 5.48559 12.976V7.024C5.48559 5.9552 5.48726 5.2104 5.56557 4.648C5.64221 4.1016 5.78216 3.8128 5.99625 3.6064C6.21118 3.4008 6.5119 3.2664 7.08087 3.1928C7.66567 3.1176 8.44123 3.116 9.55418 3.116H11.1036C12.2158 3.116 12.9922 3.1176 13.5778 3.1928C14.1459 3.2664 14.4467 3.4008 14.6607 3.6064C14.8757 3.8128 15.0156 4.1024 15.0923 4.648C15.1706 5.2104 15.1722 5.9552 15.1722 7.024V10.7232L15.1697 10.7208C15.1722 9.79953 15.1722 9.79756 15.1722 9V8.17669C8.3335 12.5 5.50142 14.536 5.50142 14.536Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </>
  ),
  viewBox: '0 0 20 20',
});
