import { Box, BoxProps, Flex, Icon } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { useStore } from 'zustand';
import { IconTextBubble } from '../../../Icons';
import { useCardStore, useNameProps } from '../../store';

export type PreferredNameProps = BoxProps;

export const PreferredName: FunctionComponent<PreferredNameProps> = ({ children, ...props }) => {
  const cardStore = useCardStore();
  const variant = useStore(cardStore, (state) => state.variant);
  const name = useNameProps('preferred');
  const pronouns = useNameProps('pronouns');

  if ((name === null && pronouns === null) || variant === 'mini') {
    return null;
  }

  return (
    <Box
      alignItems="center"
      className="CardPreferredName"
      color="#9a9a9a"
      display="flex"
      flexWrap="wrap"
      sx={{ '& + &': { marginLeft: '0.5rem' } }}
      {...props}
    >
      {(name || pronouns) && <Icon as={IconTextBubble} fill="#9A9A9A" h="0.875rem" w="0.875rem" />}
      {name && (
        <Flex>
          <Box as="span" fontSize="1.1rem" fontWeight={300} lineHeight="1.5rem" paddingLeft="2">
            Goes by{' '}
            <Box as="span" fontSize="1.1rem" fontWeight={600} lineHeight="1.5rem">
              {name}
            </Box>
          </Box>
        </Flex>
      )}
      {pronouns && (
        <Box
          as="span"
          fontSize="1.1rem"
          fontStyle="italic"
          fontWeight={600}
          lineHeight="1.5rem"
          paddingLeft="1"
        >
          ({pronouns})
        </Box>
      )}
      {children}
    </Box>
  );
};

PreferredName.displayName = 'Preferred';
