import { Flex, FlexProps, IconButton, Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { computePaginationOptions } from './helpers';
import { PaginationButton, type PaginationButtonProps } from './PaginationButton';

export type PaginationProps = FlexProps & {
  brandColor?: PaginationButtonProps['brandColor'];
  currentPage: number;
  onPageChange: (page: number) => void;
  pages: number;
};

export const Pagination = ({
  brandColor,
  currentPage,
  onPageChange,
  pages,
  ...props
}: PaginationProps) => {
  const pageOptions = useMemo(
    () => computePaginationOptions({ currentPage, pages }),
    [currentPage, pages],
  );

  if (pages < 2) {
    return null;
  }

  return (
    <Flex alignItems="center" data-testid="pagination" {...props}>
      <PaginationButton
        brandColor={brandColor}
        isDisabled={currentPage <= 1}
        isPreviousButton
        onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
        type="button"
      />
      {pageOptions.map(({ key, pageNumber, type }) => {
        if (type === 'placeholder') {
          return (
            <IconButton
              key={key}
              aria-label="..."
              bg="transparent"
              data-testid="pagination-placeholder"
              flexShrink="0"
              h="8"
              icon={<Text>...</Text>}
              minW="0"
              pointerEvents="none"
              w="8"
            />
          );
        }

        return (
          <PaginationButton
            key={key}
            brandColor={brandColor}
            isActive={pageNumber === currentPage}
            onClick={() => onPageChange(pageNumber)}
            pageNumber={pageNumber}
          />
        );
      })}
      <PaginationButton
        brandColor={brandColor}
        isDisabled={currentPage >= pages}
        isNextButton
        onClick={() => onPageChange(Math.min(currentPage + 1, pages))}
        type="button"
      />
    </Flex>
  );
};

Pagination.displayName = 'Pagination';
