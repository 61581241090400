import { createIcon } from '@chakra-ui/react';

export const IconList: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconList',
  path: (
    <path
      d="M11 4H21V6H11V4ZM11 8H17V10H11V8ZM11 14H21V16H11V14ZM11 18H17V20H11V18ZM3 4H9V10H3V4ZM5 6V8H7V6H5ZM3 14H9V20H3V14ZM5 16V18H7V16H5Z"
      fill="currentColor"
    />
  ),
});
