import { Box, BoxProps, Skeleton, useMultiStyleConfig } from '@chakra-ui/react';
import { FieldType, Fields } from '@hihello/core';
import { FunctionComponent, useMemo } from 'react';
import { useStore } from 'zustand';
import { IconFax, IconMobile } from '../../../../../Icons';
import { FieldListRule, Labels } from '../../../../layout';
import { useCardStore } from '../../../../store';
import { useFieldActions, useFieldText } from '../../hooks';
import { DetailItemAction } from './DetailItemAction';

export type DetailItemProps = BoxProps & {
  field?: Fields;
  rules?: FieldListRule[];
};

export const DetailItem: FunctionComponent<DetailItemProps> = ({ field, rules, ...props }) => {
  const cardStore = useCardStore();
  const palette = useStore(cardStore, (state) => state.themePalette);
  const styles = useMultiStyleConfig('FieldListDetail', { palette });
  const actions = useFieldActions(field);
  const messages = useFieldText(field, rules);

  const isMobile = useMemo(
    () => field?.type === FieldType.Phone && field?.label === Labels.Phone.MOBILE,
    [field],
  );

  const isSmsOnly = useMemo(
    () => field?.type === FieldType.Phone && 'isSmsOnly' in field && !!field?.isSmsOnly,
    [field],
  );

  const isFax = useMemo(
    () => field?.type === FieldType.Phone && field?.label === Labels.Phone.FAX,
    [field],
  );

  return (
    <Box __css={styles.listItem} as="li" {...props} {...actions}>
      <Box __css={styles.actions}>
        {field?.type === FieldType.Phone && ((isMobile && !isSmsOnly) || isFax) ? (
          <DetailItemAction
            field={{
              ...field,
              label: Labels.Phone.DIRECT,
            }}
            icon={isMobile ? <IconMobile /> : <IconFax />}
          />
        ) : (
          <DetailItemAction field={field} />
        )}
      </Box>
      <Box flex="1" overflow="hidden" textOverflow="ellipsis">
        {messages?.primary !== null && (
          <Box __css={styles.textPrimary}>
            {messages?.primary ?? <Skeleton h="4" mb="3" width="70%" />}
          </Box>
        )}
        {messages?.secondary !== null && (
          <Box __css={styles.textSecondary}>
            {messages?.secondary ?? <Skeleton h="3" mb="3" width="25%" />}
          </Box>
        )}
      </Box>
    </Box>
  );
};
