import { Box, BoxProps } from '@chakra-ui/react';
import { FieldType } from '@hihello/core';
import { FunctionComponent, useMemo } from 'react';

type VideoProvider = FieldType.Youtube | FieldType.Vimeo | FieldType.Brightcove;

export type VideoWidgetProps = BoxProps & {
  provider: VideoProvider;
  text: string;
  url: string;
};

const providerVideoIdMatchers: Record<VideoProvider, RegExp> = {
  [FieldType.Vimeo]: /vimeo.*\/(\d+)/i,
  [FieldType.Youtube]: /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/i,
  [FieldType.Brightcove]: /^https?:\/\/players.brightcove\.net\/(\d+)\/.*\?videoId=(\d+)/i,
};

const providerVideoSourceUrl: Record<VideoProvider, string> = {
  [FieldType.Vimeo]: 'https://player.vimeo.com/video/[id]',
  [FieldType.Youtube]: 'https://www.youtube.com/embed/[id]',
  [FieldType.Brightcove]:
    'https://players.brightcove.net/[uid]/default_default/index.html?videoId=[id]&autoplay=0',
};

export const VideoWidget: FunctionComponent<VideoWidgetProps> = ({
  provider,
  text,
  url,
  ...props
}) => {
  const embedUrl = useMemo(() => {
    const providerUrlMatcher = providerVideoIdMatchers[provider];
    const matchArr = providerUrlMatcher.exec(url);
    if (provider === FieldType.Brightcove && matchArr?.length) {
      const uid = matchArr[1];
      const id = matchArr[2];
      return providerVideoSourceUrl[provider].replace('[uid]', uid).replace('[id]', id);
    }
    const id = providerUrlMatcher.exec(url)?.[1];
    return id ? providerVideoSourceUrl[provider].replace('[id]', id) : undefined;
  }, [provider, url]);

  if (!embedUrl) {
    return null;
  }

  return (
    <Box className="CardVideoWidget" paddingBottom="56.25%" position="relative" {...props}>
      <Box
        allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        as="iframe"
        borderRadius="lg"
        frameBorder="0"
        h="full"
        left="0"
        position="absolute"
        src={embedUrl}
        title={text}
        top="0"
        w="full"
      />
    </Box>
  );
};
