import { SystemStyleObject, StyleConfig, SystemStyleFunction } from '@chakra-ui/theme-tools';

export type LogoSizes = 'full' | 'sm';

const baseStyle: SystemStyleFunction = () => ({
  '.Logo__Hello': {
    flexShrink: 0,
    h: '1.2125rem',
    maxW: '3.375rem',
    svg: {
      h: 'full',
      w: 'full',
    },
    transition: 'max-width 0.2s ease-in-out',
    w: 'full',
    willChange: 'max-width',
  },
  '.Logo__Hi': {
    flexShrink: 0,
    h: '2.5rem',
    svg: {
      h: 'full',
      w: 'full',
    },
    w: '2.5rem',
  },
  alignItems: 'center',
  display: 'flex',
  gap: '0',
  overflow: 'hidden',
});

const sizes: Record<LogoSizes, SystemStyleObject> = {
  full: {
    '.Logo__Hi': {
      h: '2rem',
      w: '2rem',
    },
    gap: '0.625rem',
  },
  sm: {
    '.Logo__Hello': {
      maxW: '0',
    },
  },
};

export const Logo: StyleConfig = {
  baseStyle,
  defaultProps: {
    colorScheme: 'white',
  },
  sizes,
};
