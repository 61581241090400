import { createIcon } from '@chakra-ui/react';

export const IconXing: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconXing',
  path: (
    <>
      <path
        d="M17.4931 0.407715C16.9502 0.407715 16.7143 0.748451 16.5196 1.10042C16.5196 1.10042 8.69391 14.9807 8.43555 15.4375C8.45052 15.4638 13.599 24.907 13.599 24.907C13.7787 25.229 14.0558 25.5997 14.6137 25.5997H18.242C18.4592 25.5997 18.6314 25.5173 18.725 25.3676C18.8224 25.214 18.8186 25.0081 18.7175 24.8059L13.5953 15.445C13.5915 15.4375 13.5915 15.4301 13.5953 15.4226L21.6419 1.20152C21.743 0.999322 21.7467 0.797127 21.6494 0.639865C21.5557 0.490091 21.3835 0.407715 21.1663 0.407715L17.4931 0.407715Z"
        fill="currentColor"
      />
      <path
        d="M2.2316 5.38397C2.01442 5.38397 1.82721 5.4626 1.73734 5.61238C1.63999 5.7659 1.65497 5.96809 1.75981 6.17029L4.2161 10.4239C4.21985 10.4314 4.21985 10.4388 4.2161 10.4463L0.355676 17.2498C0.254578 17.452 0.258322 17.6505 0.355676 17.8077C0.449284 17.9575 0.610291 18.0549 0.831208 18.0549H4.46323C5.00616 18.0549 5.26826 17.6879 5.45548 17.3547C5.45548 17.3547 9.22979 10.6785 9.37582 10.4201C9.36084 10.3977 6.87834 6.06544 6.87834 6.06544C6.69861 5.74343 6.42527 5.38397 5.86736 5.38397H2.2316Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: '0 0 22 26',
});
