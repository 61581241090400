import { FieldType, Fields, UrlField, UrlFieldTypes } from '@hihello/core';
import { useMemo } from 'react';
import { useStore } from 'zustand';
import { CardSchemaFilter, NameVariant } from '../layout';
import { getDisplayName, getFullName } from '../utils';
import { useCardStore } from './store';

export function useAvatarProps() {
  const cardStore = useCardStore();
  const avatar = useStore(cardStore, (state) => {
    if (!state.details) {
      return undefined;
    }

    const { details } = state;

    return {
      accessibleText: getDisplayName(details),
      avatar: details.avatars?.[0],
    };
  });

  return avatar;
}

export enum FieldPickingStrategy {
  AllOfType,
  FirstOfType,
}

export function useFields(
  filter: CardSchemaFilter<FieldType> = { exclude: [] },
  strategy: FieldPickingStrategy = FieldPickingStrategy.FirstOfType,
  preserveOrder = false,
): Fields[] | undefined {
  const cardStore = useCardStore();
  const fields = useStore(cardStore, (state) => state.details?.fields);

  return useMemo(() => {
    if (!fields) {
      return undefined;
    }
    const unfilteredFields = fields;

    if (filter.include && filter.include.length > 0) {
      const filteredFields = filter.include
        .map((fieldType) => {
          if (strategy === FieldPickingStrategy.AllOfType) {
            return fields.filter((field) => field.type === fieldType.toUpperCase());
          }

          if (strategy === FieldPickingStrategy.FirstOfType) {
            const firstOfType = fields.find((field) => field.type === fieldType.toUpperCase());
            return firstOfType ? [firstOfType] : [];
          }

          if (preserveOrder) {
            return fields.sort((a, b) => fields.indexOf(a) - fields.indexOf(b));
          }
          return fields;
        })
        .flat();

      if (preserveOrder) {
        return filteredFields.sort(
          (a, b) => unfilteredFields.indexOf(a) - unfilteredFields.indexOf(b),
        );
      }
      return filteredFields;
    }

    if (filter.exclude && filter.exclude.length > 0) {
      const exclude = new Set(filter.exclude);
      const excludeUpper = new Set(Array.from(exclude).map((val) => val.toUpperCase()));

      return fields.filter((field) => {
        const isExcludedField = excludeUpper.has(field.type);

        if (isExcludedField && strategy === FieldPickingStrategy.FirstOfType) {
          excludeUpper.delete(field.type);
        }

        return !isExcludedField;
      });
    }

    return fields;
  }, [fields, filter, strategy, preserveOrder]);
}

export function useFeaturedFields(): UrlField[] | undefined {
  const fields = useFields(
    {
      include: UrlFieldTypes,
    },
    FieldPickingStrategy.AllOfType,
    true,
  ) as UrlField[];

  return useMemo(() => {
    if (!fields) {
      return undefined;
    }

    return fields.filter((field) => {
      if (!UrlFieldTypes.includes(field.type)) {
        return false;
      }

      return field.widget?.enabled ?? false;
    });
  }, [fields]);
}

const defaultAccessibleText = 'Company logo';

export function useLogoProps() {
  const cardStore = useCardStore();
  const logo = useStore(cardStore, (state) => {
    if (!state.details?.logo?.uri) {
      return undefined;
    }

    const { details } = state;

    return {
      alt: details.company ?? defaultAccessibleText,
      placeholder: details?.logo?.variants?.imageSmall ?? details?.logo?.uri,
      src: details?.logo?.variants?.imageLarge ?? details?.logo?.uri,
    };
  });

  return logo;
}

export function useNameProps(variant: NameVariant) {
  const cardStore = useCardStore();
  const name = useStore(cardStore, (state) => {
    if (!state.details) {
      return undefined;
    }

    const { details } = state;

    switch (variant) {
      case 'accreditations': {
        return details.name?.accreditations ?? null;
      }

      case 'display': {
        return getDisplayName(details);
      }

      case 'full': {
        return getFullName(details);
      }

      case 'maiden': {
        return details.name?.maiden ? `(${details.name.maiden})` : null;
      }

      case 'preferred': {
        return details.name?.preferred ?? null;
      }

      case 'pronouns': {
        return details.name?.pronouns ?? null;
      }

      default: {
        return null;
      }
    }
  });

  return name;
}
