export enum OAuthAccountStatus {
  NOT_SYNCING = 'NOT_SYNCING',
  SYNCED_TOO_RECENTLY = 'SYNCED_TOO_RECENTLY',
  SYNCING_NOW = 'SYNCING_NOW',
}

export enum OAuthAccountType {
  EXCHANGE = 'exchange',
  GOOGLE = 'google',
  HUBSPOT = 'hubspot',
  SALESFORCE = 'salesforce',
  ZOOM = 'zoom',
}

export type Syncable = {
  external_category: string | null;
  id: string;
  last_sync: number;
  recreate_contacts_if_missing: boolean;
  sync_from_external: boolean;
  sync_to_external: boolean;
  update_contacts_on_change: boolean;
};

export type UserGroup = {
  card_as_template_id: string | null;
  id: string;
  last_sync: number;
  sync_users: boolean;
};

export type OAuthAccount = {
  application: OAuthAccountType;
  cards: Syncable[];
  connected_at: number;
  email_address: string | null;
  id: string;
  name: string | null;
  refresh_token: string | null;
  tags: Syncable[];
  user_groups: UserGroup[];
};

type OAuthAccountInput = {
  application: OAuthAccountType;
  email_address: string | null;
  name: string | null;
};

export type OAuthAccountAddInput = OAuthAccountInput & {
  instance_url: string | null;
  refresh_token: string;
};

export type OAuthAccountUpdateInput = OAuthAccountInput & {
  id: string;
  refresh_token: string | null;
};

export type OAuthAccountToggleInput<K extends string = never> = {
  external_category: string | null;
  oauth_account_id: string;
  recreate_contacts_if_missing: boolean;
  sync_from_external: boolean;
  sync_to_external: boolean;
  update_contacts_on_change: boolean;
} & {
  [key in K]: string;
};

export type OAuthAccountToggleCardInput = OAuthAccountToggleInput<'card_id'>;

export type OAuthAccountToggleTagInput = OAuthAccountToggleInput<'tag_id'>;
