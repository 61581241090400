import { FunctionComponent } from 'react';
import { useAvatarProps, useLogoProps } from '../../store';
import { Avatar } from '../Avatar';
import { Image } from '../Image';

export type MediaVariant = 'avatar' | 'logo';

export type MediaProps = {
  variant: MediaVariant;
};

export const Media: FunctionComponent<MediaProps> = ({ variant, ...props }) => {
  const avatar = useAvatarProps();
  const logo = useLogoProps();

  switch (variant) {
    case 'avatar': {
      if (!avatar) {
        return null;
      }

      return <Avatar {...props} {...avatar} />;
    }

    case 'logo': {
      if (!logo) {
        return null;
      }

      return <Image {...props} {...logo} />;
    }

    default: {
      return null;
    }
  }
};
