import { CardDetails } from '@hihello/core';

export function getFullName(
  details?: CardDetails,
  options: {
    includeAccreditations?: boolean;
    includeMaidenName?: boolean;
  } = {},
): string {
  if (!details) {
    return '';
  }
  const fullName = [
    details.name?.prefix,
    details.name?.given,
    details.name?.middle,
    details.name?.family,
    details.name?.suffix,
    options.includeMaidenName && details.name?.maiden ? `(${details.name.maiden})` : null,
    options.includeAccreditations ? details.name?.accreditations : null,
  ]
    .filter(Boolean)
    .join(' ')
    .trim();

  if (fullName) {
    return fullName;
  }

  return '';
}

export function getDisplayName(details: CardDetails): string {
  return (
    [details.name?.given, details.name?.family].filter(Boolean).join(' ') || getFullName(details)
  );
}
