import { useEffect, useState } from 'react';
import type { FieldValues, UseFormGetValues, UseFormReset } from 'react-hook-form';

export type UseFormDefaultValuesProps<T extends FieldValues> = {
  getValues: UseFormGetValues<T>;
  reset: UseFormReset<T>;
};

export function useFormDefaultValues<T extends FieldValues>(
  formId: string,
  formData: T | undefined,
  profileLoaded: boolean,
  { getValues, reset }: UseFormDefaultValuesProps<T>,
) {
  const [hasValues, setHasValues] = useState(false);

  useEffect(() => {
    setHasValues(false);
  }, [formId, profileLoaded]);

  useEffect(() => {
    if (!formData || hasValues) {
      return;
    }

    reset(formData, { keepDefaultValues: false });
    setHasValues(true);
  }, [formData, getValues, hasValues, reset]);

  return {
    hasValues,
  };
}
