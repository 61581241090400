import { createIcon } from '@chakra-ui/react';

export const IconCardPlus: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconCardPlus',
  path: (
    <>
      <path
        d="M11.389 2.22211V0H12.5001V2.22211H14.7223V3.33333H13.8493H12.5001V5.55556H11.389V3.33333H10.0398H9.16675V2.22211H11.389Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M12.5 7.778L12.5 12.2224C12.2661 12.155 12.0363 12.176 11.7909 12.1985C11.6622 12.2103 11.5291 12.2224 11.3889 12.2224C10.0321 12.2224 9.19334 12.694 8.23689 13.2318C8.17696 13.2655 8.11658 13.2994 8.05558 13.3336C7.02612 13.9097 5.65098 14.4443 4.72319 14.4447H4.72226H4.72189C4.08486 14.4445 3.42149 13.9783 2.82078 13.5562C2.71162 13.4795 2.60453 13.4043 2.50005 13.3336C1.99797 12.9937 1.7597 12.6909 1.53094 12.4003L1.47256 12.3263C1.41445 12.1998 1.38894 12.1513 1.38894 12.2224L1.38894 3.33355C1.38894 2.8313 2.00913 2.22244 2.50005 2.22244L6.94447 2.22244V1.11133H2.50005C1.27275 1.11133 0.277832 2.08707 0.277832 3.33355V13.3336V17.778C0.277832 18.7825 1.51821 20.0002 2.50005 20.0002H11.3889C12.3707 20.0002 13.6111 18.7825 13.6111 17.778V16.6667V14.4447L13.6111 7.778H12.5ZM3.56825 18.8891H11.3889L3.61115 18.8889L3.56825 18.8891Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </>
  ),
  viewBox: '0 0 15 20',
});
