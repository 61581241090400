import { Box, useMultiStyleConfig } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { useStore } from 'zustand';
import { useCardStore } from '../../../store';
import { Media } from '../../Media';
import { HeaderVariantProps } from './props';

export const Sleek: FunctionComponent<HeaderVariantProps> = ({ children, ...props }) => {
  const cardStore = useCardStore();
  const palette = useStore(cardStore, (state) => state.themePalette);
  const styles = useMultiStyleConfig('CardHeaderSleek', { palette });

  return (
    <Box __css={styles.container} as="header" className="CardHeaderSleek" {...props}>
      <Box __css={styles.avatar}>
        <Media variant="avatar" />
      </Box>
      <Box __css={styles.children}>{children}</Box>
    </Box>
  );
};
