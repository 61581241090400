import { createIcon } from '@chakra-ui/react';

export const IconVimeo: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconVimeo',
  path: (
    <path
      d="M21.9404 8.11715C19.6409 13.0992 13.9882 19.9017 10.3474 19.9017C6.80247 19.9017 6.32343 12.4286 4.40724 7.44648C3.44914 4.95543 2.87429 5.53029 1.0539 6.77581L0 5.43448C2.58686 3.13505 5.17371 0.548194 6.80247 0.356575C8.62286 0.164956 9.67676 1.41048 10.06 3.99734C10.6349 7.44648 11.4013 12.8118 12.6469 12.8118C13.7008 12.8118 16.1918 8.59619 16.3834 7.06324C16.575 4.85962 14.7547 4.76381 13.1259 5.43448C15.7128 -2.90095 26.3476 -1.368 21.9404 8.11715Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 23 21',
});
