import { createIcon } from '@chakra-ui/react';

export const IconPlaystationNetwork: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconPlaystationNetwork',
  path: (
    <path
      clipRule="evenodd"
      d="M36.6637 22.2055C35.9349 23.1355 34.1783 23.7997 34.1783 23.7997L21.0041 28.6014V25.0524L30.7026 21.5412C31.8051 21.1427 31.9733 20.5733 31.0763 20.2696C30.1794 19.966 28.5723 20.0609 27.4698 20.4594L21.0041 22.7749V19.0929L21.3778 18.9601C21.3778 18.9601 23.2465 18.2958 25.8627 17.9922C28.4788 17.7075 31.693 18.0301 34.2157 18.998C37.0748 19.928 37.3925 21.2755 36.6637 22.2055ZM22.2561 16.1702V7.09817C22.2561 6.03534 22.0693 5.04843 21.0789 4.78272C20.3314 4.536 19.8642 5.2572 19.8642 6.32003V29.019L13.8283 27.0641V0C16.3884 0.474477 20.1258 1.6322 22.1253 2.31545C27.2268 4.09948 28.9647 6.30105 28.9647 11.2925C28.9647 16.1702 26.0122 18.0111 22.2561 16.1702ZM2.78441 24.6728C-0.130747 23.8377 -0.616605 22.0916 0.710163 21.1047C1.9435 20.1747 4.03643 19.4915 4.03643 19.4915L12.6698 16.3789V19.928L6.44703 22.1865C5.34451 22.5851 5.17632 23.1545 6.07329 23.4581C6.97026 23.7618 8.57733 23.6669 9.67986 23.2683L12.6698 22.1675V25.356C12.4829 25.394 12.2773 25.4319 12.0718 25.4509C9.08188 25.9254 5.90511 25.7166 2.78441 24.6728Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
  viewBox: '0 0 37 29',
});
