import { createIcon } from '@chakra-ui/react';

export const IconLoopOnce: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconLoopOnce',
  path: (
    <>
      <path
        clipRule="evenodd"
        d="M3.43663 11.4039C3.89991 10.9381 4.63992 10.5833 5.72025 10.5833H18.0131C19.1808 10.5833 19.9843 10.9462 20.4894 11.4213C20.9986 11.9002 21.25 12.5337 21.25 13.1667C21.25 13.7996 20.9986 14.4331 20.4894 14.912C19.9843 15.3871 19.1808 15.75 18.0131 15.75H16.1849L16.1844 17.25H18.0131C19.5033 17.25 20.6933 16.7795 21.5171 16.0046C22.337 15.2335 22.75 14.2004 22.75 13.1667C22.75 12.1329 22.337 11.0998 21.5171 10.3287C20.6933 9.55381 19.5033 9.08333 18.0131 9.08333H5.72025C4.29341 9.08333 3.15303 9.56192 2.37309 10.3461C1.59932 11.1241 1.22565 12.1588 1.25123 13.1853C1.27683 14.213 1.70238 15.2404 2.52966 16.0081C3.36141 16.7799 4.55504 17.25 6.05249 17.25H12C12.4142 17.25 12.75 16.9142 12.75 16.5C12.75 16.0858 12.4142 15.75 12 15.75H6.05249C4.87662 15.75 4.06527 15.3867 3.54998 14.9086C3.03022 14.4263 2.76668 13.787 2.75076 13.148C2.73482 12.5079 2.96719 11.8759 3.43663 11.4039Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12 19.75C12.4142 19.75 12.75 19.4142 12.75 19V14C12.75 13.5858 12.4142 13.25 12 13.25C11.5858 13.25 11.25 13.5858 11.25 14V19C11.25 19.4142 11.5858 19.75 12 19.75Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <circle fill="currentColor" r="4.58333" transform="matrix(1 0 0 -1 7.41683 8.58366)" />
      <path
        d="M8.72455 10.1003C8.83122 10.1003 8.91122 10.1247 8.96455 10.1736C9.01788 10.2225 9.04455 10.2914 9.04455 10.3803C9.04455 10.5714 8.93788 10.6669 8.72455 10.6669H6.49788C6.28011 10.6669 6.17122 10.5714 6.17122 10.3803C6.17122 10.2914 6.19788 10.2225 6.25122 10.1736C6.309 10.1247 6.39122 10.1003 6.49788 10.1003H7.26455V6.77359L6.57788 7.20693C6.53344 7.23359 6.489 7.24693 6.44455 7.24693C6.369 7.24693 6.30233 7.21359 6.24455 7.14693C6.19122 7.08026 6.16455 7.0047 6.16455 6.92026C6.16455 6.81804 6.209 6.73804 6.29788 6.68026L7.32455 6.02693C7.42677 5.9647 7.52455 5.93359 7.61788 5.93359C7.71566 5.93359 7.79566 5.9647 7.85788 6.02693C7.92011 6.08915 7.95122 6.17359 7.95122 6.28026V10.1003H8.72455Z"
        fill="white"
      />
      <path
        clipRule="evenodd"
        d="M18.5303 12.9697C18.2374 12.6768 17.7626 12.6768 17.4697 12.9697L14.4697 15.9697C14.1768 16.2626 14.1768 16.7374 14.4697 17.0303L17.4697 20.0303C17.7626 20.3232 18.2374 20.3232 18.5303 20.0303C18.8232 19.7374 18.8232 19.2626 18.5303 18.9697L16.0607 16.5L18.5303 14.0303C18.8232 13.7374 18.8232 13.2626 18.5303 12.9697Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </>
  ),
  viewBox: '0 0 24 24',
});
