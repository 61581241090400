import { FieldType, TemplateFieldState } from '../api';

export enum PermissionSetting {
  Relaxed = 'relaxed',
  Standard = 'standard',
  Strict = 'strict',
}

type PreparedTemplatePermissionProps = {
  companyColor: string;
  companyLogoUri: string;
  companyLogoUriValue: string;
  companyName: string;
  permissionSetting?: PermissionSetting;
};

const preparedTemplateWithRelaxedSettings = ({
  companyColor,
  companyLogoUri,
  companyLogoUriValue,
  companyName,
}: PreparedTemplatePermissionProps) => ({
  design: {
    isEditable: false,
    state: TemplateFieldState.Required,
  },
  details: {
    avatar: {
      avatars: [],
      isEditable: true,
      state: TemplateFieldState.Optional,
    },
    badge: {
      badges: [],
      isEditable: true,
      state: TemplateFieldState.Optional,
    },
    bannedFields: Object.values(FieldType).filter(
      (value) =>
        ![
          'PHONE',
          'EMAIL',
          'ADDRESS',
          'LINKEDIN',
          'WEBSITE',
          'LINK',
          'YOUTUBE',
          'FILE',
          'INSTAGRAM',
          'FACEBOOK',
          'TWITTER',
        ].includes(value),
    ),
    company: {
      isEditable: true,
      name: companyName,
      state: TemplateFieldState.Optional,
    },
    department: {
      isEditable: true,
      state: TemplateFieldState.Optional,
    },
    headline: {
      isEditable: true,
      state: TemplateFieldState.Optional,
    },
    logo: {
      isEditable: true,
      logos: companyLogoUri ? [{ uri: companyLogoUriValue }] : [],
      state: TemplateFieldState.Optional,
    },
    name: {
      accreditations: {
        isEditable: true,
        state: TemplateFieldState.Optional,
      },
      family: {
        isEditable: true,
        state: TemplateFieldState.Optional,
      },
      given: {
        isEditable: true,
        state: TemplateFieldState.Required,
      },
      maiden: {
        isEditable: true,
        state: TemplateFieldState.Optional,
      },
      middle: {
        isEditable: true,
        state: TemplateFieldState.Optional,
      },
      preferred: {
        isEditable: true,
        state: TemplateFieldState.Optional,
      },
      prefix: {
        isEditable: true,
        state: TemplateFieldState.Optional,
      },
      pronouns: {
        isEditable: true,
        state: TemplateFieldState.Optional,
      },
      suffix: {
        isEditable: true,
        state: TemplateFieldState.Optional,
      },
    },
    title: {
      isEditable: true,
      state: TemplateFieldState.Optional,
    },
  },
  name: 'Default Template',
  theme: {
    isEditable: true,
    palette: {
      dark: {
        accent: companyColor,
        brand: companyColor,
        onAccent: '#FFFFFF',
        onBrand: '#FFFFFF',
      },
      light: {
        accent: companyColor,
        brand: companyColor,
        onAccent: '#FFFFFF',
        onBrand: '#FFFFFF',
      },
    },
    state: TemplateFieldState.Optional,
  },
});

const preparedTemplateWithStandardSettings = ({
  companyColor,
  companyLogoUri,
  companyLogoUriValue,
  companyName,
}: PreparedTemplatePermissionProps) => ({
  design: {
    isEditable: false,
    state: TemplateFieldState.Required,
  },
  details: {
    avatar: {
      avatars: [],
      isEditable: true,
      state: TemplateFieldState.Required,
    },
    badge: {
      badges: [],
      isEditable: true,
      state: TemplateFieldState.Optional,
    },
    bannedFields: Object.values(FieldType).filter(
      (value) =>
        ![
          'PHONE',
          'EMAIL',
          'ADDRESS',
          'LINKEDIN',
          'WEBSITE',
          'LINK',
          'YOUTUBE',
          'FILE',
          'INSTAGRAM',
        ].includes(value),
    ),
    company: {
      isEditable: false,
      name: companyName,
      state: TemplateFieldState.Required,
    },
    department: {
      isEditable: true,
      state: TemplateFieldState.Required,
    },
    headline: {
      isEditable: true,
      state: TemplateFieldState.Optional,
    },
    logo: {
      isEditable: false,
      logos: companyLogoUri ? [{ uri: companyLogoUriValue }] : [],
      state: TemplateFieldState.Required,
    },
    name: {
      accreditations: {
        isEditable: true,
        state: TemplateFieldState.Optional,
      },
      family: {
        isEditable: true,
        state: TemplateFieldState.Required,
      },
      given: {
        isEditable: true,
        state: TemplateFieldState.Required,
      },
      maiden: {
        isEditable: true,
        state: TemplateFieldState.Optional,
      },
      middle: {
        isEditable: true,
        state: TemplateFieldState.Optional,
      },
      preferred: {
        isEditable: true,
        state: TemplateFieldState.Optional,
      },
      prefix: {
        isEditable: true,
        state: TemplateFieldState.Optional,
      },
      pronouns: {
        isEditable: true,
        state: TemplateFieldState.Optional,
      },
      suffix: {
        isEditable: true,
        state: TemplateFieldState.Optional,
      },
    },
    title: {
      isEditable: true,
      state: TemplateFieldState.Required,
    },
  },
  name: 'Default Template',
  theme: {
    isEditable: false,
    palette: {
      dark: {
        accent: companyColor,
        brand: companyColor,
        onAccent: '#FFFFFF',
        onBrand: '#FFFFFF',
      },
      light: {
        accent: companyColor,
        brand: companyColor,
        onAccent: '#FFFFFF',
        onBrand: '#FFFFFF',
      },
    },
    state: TemplateFieldState.Required,
  },
});

const preparedTemplateWithStrictSettings = ({
  companyColor,
  companyLogoUri,
  companyLogoUriValue,
  companyName,
}: PreparedTemplatePermissionProps) => ({
  design: {
    isEditable: false,
    state: TemplateFieldState.Required,
  },
  details: {
    avatar: {
      avatars: [],
      isEditable: true,
      state: TemplateFieldState.Required,
    },
    badge: {
      badges: [],
      isEditable: false,
      state: TemplateFieldState.Prohibited,
    },
    bannedFields: Object.values(FieldType).filter((value) => !['PHONE', 'EMAIL'].includes(value)),
    company: {
      isEditable: false,
      name: companyName,
      state: TemplateFieldState.Required,
    },
    department: {
      isEditable: true,
      state: TemplateFieldState.Optional,
    },
    headline: {
      isEditable: false,
      state: TemplateFieldState.Required,
    },
    logo: {
      isEditable: false,
      logos: companyLogoUri ? [{ uri: companyLogoUriValue }] : [],
      state: TemplateFieldState.Required,
    },
    name: {
      accreditations: {
        isEditable: false,
        state: TemplateFieldState.Prohibited,
      },
      family: {
        isEditable: true,
        state: TemplateFieldState.Required,
      },
      given: {
        isEditable: true,
        state: TemplateFieldState.Required,
      },
      maiden: {
        isEditable: false,
        state: TemplateFieldState.Prohibited,
      },
      middle: {
        isEditable: false,
        state: TemplateFieldState.Prohibited,
      },
      preferred: {
        isEditable: false,
        state: TemplateFieldState.Prohibited,
      },
      prefix: {
        isEditable: false,
        state: TemplateFieldState.Prohibited,
      },
      pronouns: {
        isEditable: false,
        state: TemplateFieldState.Prohibited,
      },
      suffix: {
        isEditable: false,
        state: TemplateFieldState.Prohibited,
      },
    },
    title: {
      isEditable: true,
      state: TemplateFieldState.Required,
    },
  },
  name: 'Default Template',
  theme: {
    isEditable: false,
    palette: {
      dark: {
        accent: companyColor,
        brand: companyColor,
        onAccent: '#FFFFFF',
        onBrand: '#FFFFFF',
      },
      light: {
        accent: companyColor,
        brand: companyColor,
        onAccent: '#FFFFFF',
        onBrand: '#FFFFFF',
      },
    },
    state: TemplateFieldState.Required,
  },
});

export const getTemplateFromPermissionSetting = ({
  companyColor,
  companyLogoUri,
  companyLogoUriValue,
  companyName,
  permissionSetting,
}: PreparedTemplatePermissionProps) => {
  if (permissionSetting === PermissionSetting.Relaxed) {
    return preparedTemplateWithRelaxedSettings({
      companyColor,
      companyLogoUri,
      companyLogoUriValue,
      companyName,
    });
  }
  if (permissionSetting === PermissionSetting.Standard) {
    return preparedTemplateWithStandardSettings({
      companyColor,
      companyLogoUri,
      companyLogoUriValue,
      companyName,
    });
  }
  return preparedTemplateWithStrictSettings({
    companyColor,
    companyLogoUri,
    companyLogoUriValue,
    companyName,
  });
};
