import { StyleConfig, SystemStyleObject } from '@chakra-ui/theme-tools';

type Variants = 'full';

const baseStyle: SystemStyleObject = {};

const variants: Record<Variants, SystemStyleObject> = {
  full: {
    maxWidth: 'full',
    minHeight: '100%',
    px: 0,
    py: 0,
  },
};

export const Container: StyleConfig = {
  baseStyle,
  variants,
};
