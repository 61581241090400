import { GlobalStyles, SystemStyleFunction } from '@chakra-ui/theme-tools';

const global: SystemStyleFunction = () => ({
  'html, body, #__next': {
    '@supports(height: -webkit-fill-available)': {
      minHeight: '-webkit-fill-available',
    },
    minHeight: '100vh',
  },
});

export const styles: GlobalStyles = {
  global,
};
