import { ColorHues } from '@chakra-ui/react';

const banana: ColorHues = {
  50: '#FFF7E2',
  100: '#FFE6A8',
  200: '#FFDE8A',
  300: '#FFD66D',
  400: '#FFCD50',
  500: '#FFC631',
  600: '#FFB907',
  700: '#DB9D00',
  800: '#AF7D00',
  900: '#835E00',
};

const beach: ColorHues = {
  50: '#EAF9F7',
  100: '#C1ECE7',
  200: '#ACE6DF',
  300: '#97E0D8',
  400: '#82DAD0',
  500: '#6DD3C7',
  600: '#4AC9BA',
  700: '#35B1A2',
  800: '#2A8D82',
  900: '#206A61',
};

const blackAlpha: ColorHues = {
  50: 'rgba(0, 0, 0, 0.04)',
  100: 'rgba(0, 0, 0, 0.06)',
  200: 'rgba(0, 0, 0, 0.08)',
  300: 'rgba(0, 0, 0, 0.16)',
  400: 'rgba(0, 0, 0, 0.24)',
  500: 'rgba(0, 0, 0, 0.34)',
  600: 'rgba(0, 0, 0, 0.48)',
  700: 'rgba(0, 0, 0, 0.54)',
  800: 'rgba(0, 0, 0, 0.80)',
  900: 'rgba(0, 0, 0, 0.92)',
};

const doughnut: ColorHues = {
  50: '#FDEEFA',
  100: '#F8CBF0',
  200: '#F6BAEC',
  300: '#F3A8E7',
  400: '#F197E2',
  500: '#EE85DD',
  600: '#E857D0',
  700: '#E228C3',
  800: '#BB1AA0',
  900: '#8C1378',
};

const gray: ColorHues = {
  50: '#F7FAFC',
  100: '#EDF2F7',
  200: '#E2E8F0',
  300: '#CBD5E0',
  400: '#A0AEC0',
  500: '#718096',
  600: '#4A5568',
  700: '#2D3748',
  800: '#1A202C',
  900: '#171923',
};

const lightGray: ColorHues = {
  50: '#FFFFFF',
  100: '#F1F1F1',
  200: '#F2F2F2',
  300: '#F7FAFC',
  400: '#EDF2F7',
  500: '#E2E8F0',
  600: '#CBD5E0',
  700: '#A0AEC0',
  800: '#718096',
  900: '#4A5568',
};

const jeans: ColorHues = {
  50: '#E1E6F5',
  100: '#A5B5E1',
  200: '#879CD7',
  300: '#6983CD',
  400: '#4B6BC3',
  500: '#3A59AE',
  600: '#314B94',
  700: '#293F7B',
  800: '#213262',
  900: '#19264A',
};

const sky: ColorHues = {
  50: '#EBF8FF',
  100: '#BEE3F8',
  200: '#90CDF4',
  300: '#63B3ED',
  400: '#4299E1',
  500: '#628AF8',
  600: '#3467F5',
  700: '#0B47ED',
  800: '#0939BD',
  900: '#072B8E',
};

const tangerine: ColorHues = {
  50: '#FFEEE3',
  100: '#FFCDAA',
  200: '#FFBD8D',
  300: '#FFAC71',
  400: '#FF9C55',
  500: '#FF8C39',
  600: '#FF710C',
  700: '#DE5D00',
  800: '#B24A00',
  900: '#853800',
};

const thyme: ColorHues = {
  50: '#E0F4EA',
  100: '#A3DFBF',
  200: '#84D4A9',
  300: '#65C994',
  400: '#47BE7E',
  500: '#38A169',
  600: '#318B5B',
  700: '#29744C',
  800: '#215D3D',
  900: '#18462D',
};

const tomato: ColorHues = {
  50: '#FCE3E1',
  100: '#F6ABA5',
  200: '#F38F88',
  300: '#F0736A',
  400: '#ED574C',
  500: '#EA3A2E',
  600: '#DB2316',
  700: '#B61D12',
  800: '#92170E',
  900: '#6D110B',
};

const violet: ColorHues = {
  50: '#EFE8FA',
  100: '#CEBAF1',
  200: '#BEA3EC',
  300: '#AE8CE7',
  400: '#9D75E3',
  500: '#8F60DE',
  600: '#7239D6',
  700: '#5D26BB',
  800: '#4A1F96',
  900: '#381770',
};

const whiteAlpha: ColorHues = {
  50: 'rgba(255, 255, 255, 0.04)',
  100: 'rgba(255, 255, 255, 0.06)',
  200: 'rgba(255, 255, 255, 0.08)',
  300: 'rgba(255, 255, 255, 0.16)',
  400: 'rgba(255, 255, 255, 0.24)',
  500: 'rgba(255, 255, 255, 0.34)',
  600: 'rgba(255, 255, 255, 0.48)',
  700: 'rgba(255, 255, 255, 0.54)',
  800: 'rgba(255, 255, 255, 0.80)',
  900: 'rgba(255, 255, 255, 0.92)',
};

export const colors = {
  banana,
  beach,
  blackAlpha,
  brand: violet,
  company: lightGray,
  doughnut,
  gray,
  jeans,
  sky,
  tangerine,
  thyme,
  tomato,
  violet,
  whiteAlpha,
};
