import { createIcon } from '@chakra-ui/react';

export const IconPayPal: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconPayPal',
  path: (
    <>
      <path
        d="M4.00755 18.4773L6.61458 3.50612C6.65815 3.25596 6.88222 3.0727 7.14581 3.0727H13.9745C15.1436 3.0727 18.1113 3.41885 18.1113 6.62078C18.1113 9.8227 15.4134 12.5919 13.1651 12.5919H9.60044C9.06492 12.5919 8.61095 12.9698 8.53323 13.4797L7.74696 18.6384C7.7081 18.8934 7.48084 19.0823 7.21308 19.0823H4.53972C4.2061 19.0823 3.95242 18.7939 4.00755 18.4773Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M9.39609 13.8217L8.39821 20.4793C8.35129 20.7924 8.60361 21.0727 8.93227 21.0727H11.6161C11.8856 21.0727 12.1138 20.8813 12.1506 20.6244L12.7416 16.5018C12.7784 16.2448 13.0063 16.0535 13.2758 16.0535H15.7736C17.9319 16.0535 20.0003 12.9381 20.0003 10.3419C20.0003 8.89791 19.823 8.2965 18.9211 7.48616C18.7768 7.35647 18.7069 7.43594 18.6506 7.57596C18.6009 10.7397 15.9324 13.4573 13.7051 13.4573H10.2959C9.94601 13.4573 9.62593 13.5956 9.39609 13.8217Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </>
  ),
});
