import confetti from 'canvas-confetti';

export const popConfetti = (zIndex?: number) => {
  confetti({
    colors: [
      '#8F60DE',
      '#EE85DD',
      '#EA3A2E',
      '#F16F00',
      '#FFC631',
      '#3BB55D',
      '#6DD3C7',
      '#628AF8',
      '#3A59AE',
    ],
    origin: {
      y: 1,
    },
    particleCount: 175,
    spread: 180,
    zIndex,
  });
};
