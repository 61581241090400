import { createIcon } from '@chakra-ui/react';

export const IconRolodex: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconRolodex',
  path: (
    <>
      <path
        d="M7 4.25C7 3.69772 7.44772 3.25 8 3.25C8.55228 3.25 9 3.69772 9 4.25V6.25C9 6.80228 8.55228 7.25 8 7.25C7.44772 7.25 7 6.80228 7 6.25V4.25Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M6.5 5.75V6.25C6.5 7.07843 7.17157 7.75 8 7.75C8.82843 7.75 9.5 7.07843 9.5 6.25V5.75H14.5V6.25C14.5 7.07843 15.1716 7.75 16 7.75C16.8284 7.75 17.5 7.07843 17.5 6.25V5.75H20C21.1046 5.75 22 6.64543 22 7.75V18.75C22 19.8546 21.1046 20.75 20 20.75H4C2.89543 20.75 2 19.8546 2 18.75V7.75C2 6.64543 2.89543 5.75 4 5.75H6.5ZM5.99984 11.75C5.99984 10.6454 6.89527 9.75 7.99984 9.75C9.10441 9.75 9.99984 10.6454 9.99984 11.75C9.99984 12.8546 9.10441 13.75 7.99984 13.75C6.89527 13.75 5.99984 12.8546 5.99984 11.75ZM19.4998 11.75C19.776 11.75 19.9998 11.9739 19.9998 12.25C19.9998 12.5261 19.776 12.75 19.4998 12.75H14.4998C14.2237 12.75 13.9998 12.5261 13.9998 12.25C13.9998 11.9739 14.2237 11.75 14.4998 11.75H19.4998ZM19.4998 13.75C19.776 13.75 19.9998 13.9739 19.9998 14.25C19.9998 14.5261 19.776 14.75 19.4998 14.75H14.4998C14.2237 14.75 13.9998 14.5261 13.9998 14.25C13.9998 13.9739 14.2237 13.75 14.4998 13.75H19.4998ZM19.9998 16.25C19.9998 15.9739 19.776 15.75 19.4998 15.75H14.4998C14.2237 15.75 13.9998 15.9739 13.9998 16.25C13.9998 16.5261 14.2237 16.75 14.4998 16.75H19.4998C19.776 16.75 19.9998 16.5261 19.9998 16.25ZM7.99984 14.25C9.86732 14.25 11.4359 15.5298 11.8764 17.2601C12.0127 17.7953 11.5521 18.25 10.9998 18.25H4.99984C4.44755 18.25 3.98701 17.7953 4.12326 17.2601C4.56378 15.5298 6.13236 14.25 7.99984 14.25Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M16 3.25C15.4477 3.25 15 3.69772 15 4.25V6.25C15 6.80228 15.4477 7.25 16 7.25C16.5523 7.25 17 6.80228 17 6.25V4.25C17 3.69772 16.5523 3.25 16 3.25Z"
        fill="currentColor"
      />
    </>
  ),
});
