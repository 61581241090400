import { StyleConfig, SystemStyleFunction } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleFunction = ({ colorMode, ...props }) => ({
  '& + &': {
    marginLeft: '0.5rem',
  },

  color: props.palette?.[colorMode]?.brand ?? '#8f60de',
  fontSize: '1.1rem',
  fontWeight: 800,
  lineHeight: '1.5rem',
});

export const CardDepartment: StyleConfig = {
  baseStyle,
  defaultProps: {
    colorScheme: 'brand',
  },
};
