// @ts-check

/**
 * @type {import('next-i18next').UserConfig}
 * */
module.exports = {
  i18n: {
    defaultLocale: 'en',
    locales: ['en'],
  },
  parseMissingKeyHandler: () => undefined,
  reloadOnPrerender: process.env.NEXT_PUBLIC_NODE_ENV === 'development',
  saveMissing: true,
  serializeConfig: false,
};
