import type { TableProps } from '@chakra-ui/react';
import { Table as ChakraTable } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

export type { TableProps };

const Table: FunctionComponent<TableProps> = ({ children, ...tableProps }) => (
  <ChakraTable overflowY="auto" variant="simple" w="full" {...tableProps}>
    {children}
  </ChakraTable>
);

export { Table };
