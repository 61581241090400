import {
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
} from '@chakra-ui/react';
import { forwardRef, useRef } from 'react';
import { HiEye, HiEyeOff } from 'react-icons/hi';

export type PasswordInputProps = InputProps & {
  maskPasswordLabel?: string;
  revealPasswordLabel?: string;
};

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  (
    { maskPasswordLabel = 'Mask password', revealPasswordLabel = 'Reveal password', ...props },
    ref,
  ) => {
    const { isOpen, onToggle } = useDisclosure();
    const inputRef = useRef<HTMLInputElement>(null);
    const mergeRef = useMergeRefs(inputRef, ref);

    const onClickReveal = () => {
      onToggle();
      if (inputRef.current) {
        inputRef.current.focus({ preventScroll: true });
      }
    };

    return (
      <InputGroup>
        <Input
          ref={mergeRef}
          autoComplete="current-password"
          type={isOpen ? 'text' : 'password'}
          {...props}
        />
        <InputRightElement>
          <IconButton
            aria-label={isOpen ? maskPasswordLabel : revealPasswordLabel}
            icon={isOpen ? <HiEyeOff /> : <HiEye />}
            onClick={onClickReveal}
            variant="link"
          />
        </InputRightElement>
      </InputGroup>
    );
  },
);

PasswordInput.displayName = 'PasswordInput';
