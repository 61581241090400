import { createIcon } from '@chakra-ui/react';

export const IconDownload: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconDownload',
  path: (
    <path
      d="M8 11.9167L3.3125 7.22925L4.625 5.86987L7.0625 8.30737V0.666748H8.9375V8.30737L11.375 5.86987L12.6875 7.22925L8 11.9167ZM2.375 15.6667C1.85938 15.6667 1.41781 15.483 1.05031 15.1155C0.682814 14.748 0.499377 14.3067 0.500002 13.7917V10.9792H2.375V13.7917H13.625V10.9792H15.5V13.7917C15.5 14.3074 15.3163 14.7489 14.9488 15.1164C14.5813 15.4839 14.14 15.6674 13.625 15.6667H2.375Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 16 16',
});
