import { Skeleton } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { Th, Thead, Tr } from './components';
import { Table } from './Table';
import { TableContentLoadingState } from './TableContentLoadingState';

type TableLoadingStateProps = {
  cellComponents?: JSX.Element[];
  colCount?: number;
  rowCount?: number;
};

export const TableLoadingState: FunctionComponent<TableLoadingStateProps> = ({
  cellComponents = [],
  colCount = 3,
  rowCount = 8,
}) => {
  const cols = Array.from({ length: colCount }, (_, i) => i);

  return (
    <Table>
      <Thead>
        <Tr>
          {cols.map((col) => (
            <Th key={col}>
              <Skeleton height="1rem" width="6.25rem" />
            </Th>
          ))}
        </Tr>
      </Thead>
      <TableContentLoadingState
        cellComponents={cellComponents}
        colCount={colCount}
        rowCount={rowCount}
      />
    </Table>
  );
};
