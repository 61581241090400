import { Box, BoxProps } from '@chakra-ui/react';
import { FieldType, UrlField } from '@hihello/core';
import { FunctionComponent } from 'react';
import { useStore } from 'zustand';
import { useCardStore, useFeaturedFields } from '../../store';
import { VideoWidget } from './widgets';

type WidgetProps = { field: UrlField };

const Widget: FunctionComponent<WidgetProps> = ({ field }) => {
  switch (field.type) {
    case FieldType.Youtube: {
      return <VideoWidget provider={FieldType.Youtube} text={field.text} url={field.uri} />;
    }
    case FieldType.Vimeo: {
      return <VideoWidget provider={FieldType.Vimeo} text={field.text} url={field.uri} />;
    }
    case FieldType.Brightcove: {
      return <VideoWidget provider={FieldType.Brightcove} text={field.text} url={field.uri} />;
    }

    default: {
      return null;
    }
  }
};

export type FeaturedContentProps = BoxProps;

export const FeaturedContent: FunctionComponent<FeaturedContentProps> = (props) => {
  const cardStore = useCardStore();
  const variant = useStore(cardStore, (state) => state.variant);
  const fields = useFeaturedFields();

  if (!fields?.length || variant === 'mini') {
    return null;
  }

  return (
    <Box className="CardFeaturedContent" {...props}>
      {fields?.map((field) => (
        <Box
          key={`${field.label}-${field.uri}`}
          marginBottom="1.5rem"
          sx={{ '&:last-child': { marginBottom: 0 } }}
        >
          <Widget field={field} />
        </Box>
      ))}
    </Box>
  );
};
