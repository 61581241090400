import { createIcon } from '@chakra-ui/react';

export const IconZoom: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconZoom',
  path: (
    <path
      d="M0.992004 3.753V7.123C0.995004 7.885 1.6725 8.498 2.499 8.4955H7.8455C7.997 8.4955 8.12 8.383 8.12 8.2465V4.8765C8.116 4.115 7.4385 3.5015 6.613 3.5045H1.2655C1.1145 3.5045 0.992004 3.6165 0.992004 3.753ZM8.46 5.068L10.668 3.5865C10.8595 3.4405 11.008 3.477 11.008 3.741V8.259C11.008 8.5595 10.8265 8.523 10.668 8.4135L8.46 6.935V5.068Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 12 12',
});
