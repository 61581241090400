import { useToast } from '@chakra-ui/react';
import { FieldType, Fields, UrlField, logger } from '@hihello/core';
import copy from 'copy-to-clipboard';
import { parsePhoneNumber } from 'libphonenumber-js';
import {
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEvent as ReactMouseEvent,
  MouseEventHandler,
  useCallback,
} from 'react';
import { useStore } from 'zustand';
import { Labels } from '../../../layout';
import { useCardStore } from '../../../store';
import { Type } from '../../../utils';

function handleAddressField(field: Type<FieldType.Address>) {
  window.open(`https://www.google.com/maps/search/${encodeURIComponent(field.text)}`, '_blank');
}

function handleClipboardField(
  field: Fields,
  options: {
    message: string;
    onCopy?: () => void;
  },
) {
  copy(field.text, {
    ...options,
    debug: process.env.NODE_ENV === 'development',
    format: 'text/plain',
  });
}

function handleEmailField(field: Type<FieldType.Email>) {
  window.location.assign(`mailto:${field.text}`);
}

function handlePhoneField(field: Type<FieldType.Phone>) {
  const phoneNumWithExt = [field.e164, field.extension].filter(Boolean).join(';ext=');

  if (!phoneNumWithExt) {
    return;
  }

  try {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumWithExt);
    const uri = parsedPhoneNumber?.getURI();

    if (!uri) {
      return;
    }

    const assignTo =
      field.label === Labels.Phone.MOBILE && 'isSmsOnly' in field && !!field.isSmsOnly
        ? uri.replace(/^tel:/, 'sms:')
        : uri;

    window.location.assign(assignTo);
  } catch {
    logger.error('Failed to parse phone number:', phoneNumWithExt);
  }
}

function handleUrlField(field: UrlField) {
  window.open(field.uri, '_blank');
}

export function useFieldActions(field?: Fields) {
  const cardStore = useCardStore();
  const toast = useToast();
  const { messages, onFieldSelect, onFieldTextCopiedToClipboard, variant } = useStore(
    cardStore,
    (state) => ({
      messages: state.messages,
      onFieldSelect: state.onFieldSelect,
      onFieldTextCopiedToClipboard: state.onFieldTextCopiedToClipboard,
      variant: state.variant,
    }),
  );

  const handleFieldSelect = useCallback(
    (event: ReactMouseEvent<HTMLElement, MouseEvent> | KeyboardEvent<HTMLElement>) => {
      if (!field) {
        return;
      }

      if (variant === 'mobile') {
        window.postMessage(JSON.stringify({ data: field, type: 'clickField' }), '*');
        return;
      }

      if ('uri' in field) {
        handleUrlField(field as UrlField);
      } else {
        switch (field.type) {
          case FieldType.Address: {
            handleAddressField(field as Type<FieldType.Address>);
            break;
          }

          case FieldType.Email: {
            handleEmailField(field as Type<FieldType.Email>);
            break;
          }

          case FieldType.Phone: {
            handlePhoneField(field as Type<FieldType.Phone>);
            break;
          }

          default: {
            handleClipboardField(field, {
              message: messages?.['clipboard.request'] ?? 'Copy to clipboard',
              onCopy: () => {
                toast({
                  description: messages?.['clipboard.notification.text'] ?? 'Copied to clipboard',
                  duration: 3000,
                  isClosable: true,
                  status: 'success',
                });
                onFieldTextCopiedToClipboard?.(field.type);
              },
            });
          }
        }
      }
      onFieldSelect?.(field, event);
    },
    [field, messages, onFieldSelect, onFieldTextCopiedToClipboard, toast, variant],
  );

  const handleClick = useCallback<MouseEventHandler<HTMLElement>>(
    (event) => {
      handleFieldSelect(event);
    },
    [handleFieldSelect],
  );

  const handleKeyUp = useCallback<KeyboardEventHandler<HTMLElement>>(
    (event) => {
      if (!['Enter', ' '].includes(event.key)) {
        return;
      }

      handleFieldSelect(event);
    },
    [handleFieldSelect],
  );

  return {
    onClick: handleClick,
    onKeyUp: handleKeyUp,
  };
}
