import { createIcon } from '@chakra-ui/react';

export const IconArrowRight: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconArrowRight',
  path: (
    <path
      d="M3.7812 4.99999L0.481201 1.69999L1.42387 0.757324L5.66653 4.99999L1.42387 9.24266L0.481201 8.29999L3.7812 4.99999Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 15 15',
});
