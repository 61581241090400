import { anatomy, PartsStyleFunction, MultiStyleConfig } from '@chakra-ui/theme-tools';

const parts = anatomy('cardheader--flat').parts('container', 'avatar', 'children');

const baseStyle: PartsStyleFunction<typeof parts> = ({ colorMode, ...props }) => ({
  avatar: {
    '&:empty': {
      marginTop: 'calc(-100% + 6rem)',
    },
    '.CardAvatar': {
      h: 'full',
      img: {
        h: 'full',
        objectFit: 'cover',
        w: 'full',
      },
      position: 'absolute',
      top: 0,
      w: 'full',
    },
    bg: props.palette?.[colorMode]?.brand ?? '#8f60de',
    clipPath: 'circle(100% at 50% 0)',
    paddingTop: '100%',
    position: 'relative',
    transitionDuration: '300ms',
    transitionProperty: 'background-color',
    w: 'full',
    willChange: 'background-color',
  },
  children: {
    marginTop: '-4rem',
    objectFit: 'cover',
    w: 'full',
  },
  container: {
    '.CardIdentity': {
      '.CardBox, .CardBox > :not(.CardName):not(.CardDepartment)': {
        lineHeight: '1.75rem',
      },
      '.CardDepartment': {
        display: 'inline',
        lineHeight: '1.5rem',
      },
      '.CardImage': {
        h: '3.75rem',
        maxW: 'full',
        objectFit: 'contain',
      },
      '.CardName': {
        display: 'inline-block',
      },
      '.chakra-skeleton': {
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      bg: 'bg-surface',
      borderRadius: '0.5rem',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
      m: '0.75rem auto',
      minH: '4rem',
      overflow: 'hidden',
      p: '0.5rem',
      position: 'relative',
      textAlign: 'center',
      w: '70%',
    },
    marginBottom: '1.5rem',
  },
});

export const CardHeaderSleek: MultiStyleConfig<typeof parts> = {
  baseStyle,
  defaultProps: {
    colorScheme: 'brand',
  },
  parts: ['avatar', 'container', 'children'],
};
