import { createIcon } from '@chakra-ui/react';

export const IconDocument: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconDocument',
  path: (
    <>
      <path
        d="M1.42855 10.0001V1.42879H7.14276V5.71445C7.14389 6.09298 7.29476 6.45568 7.56242 6.72334C7.83008 6.991 8.19279 7.14187 8.57131 7.143H12.857V10.0001H14.2855V5.71445C14.288 5.62058 14.2702 5.52727 14.2332 5.44096C14.1962 5.35464 14.141 5.27737 14.0712 5.21446L9.07131 0.214526C9.00842 0.144773 8.93115 0.0894919 8.84483 0.0524972C8.75851 0.0155025 8.66519 -0.00232556 8.57131 0.000242883H1.42855C1.05002 0.00137339 0.687323 0.152244 0.419662 0.419905C0.152001 0.687566 0.0011305 1.05027 0 1.42879V10.0001H1.42855ZM8.57131 1.7145L12.5713 5.71445H8.57131V1.7145Z"
        fill="currentColor"
      />
      <path
        d="M12.8576 18.5712H7.14349V19.9997H12.8576C13.2361 19.9986 13.5988 19.8477 13.8665 19.5801C14.1341 19.3124 14.285 18.9497 14.2861 18.5712V10H12.8576V18.5712Z"
        fill="currentColor"
      />
      <path
        d="M1.42906 18.5714H7.14334V20H1.42906C1.05053 19.9989 0.68782 19.848 0.420156 19.5803C0.152491 19.3127 0.0016188 18.95 0.000488281 18.5714V10H1.42906V18.5714Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: '0 0 24 24',
});
