import { Box as ChakraBox, BoxProps as ChakraBoxProps, useStyleConfig } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { useStore } from 'zustand';
import { BoxVariant } from '../../layout';
import { useCardStore } from '../../store';

export type BoxProps = ChakraBoxProps & {
  variant?: BoxVariant;
};

export const Box: FunctionComponent<BoxProps> = ({ variant, ...props }) => {
  const cardStore = useCardStore();
  const palette = useStore(cardStore, (state) => state.themePalette);
  const styles = useStyleConfig('CardBox', {
    palette,
    variant,
  });

  return <ChakraBox __css={styles} className="CardBox" {...props} />;
};
