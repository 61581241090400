import { ClientError } from 'graphql-request';
import { ServerResponse } from 'http';
import { GraphQLErrorCode, hasGraphQLErrorCode } from './errors';

function getAuthUrl() {
  return process.env.AUTH_URL ?? process.env.NEXT_PUBLIC_AUTH_URL;
}

export function redirectToUrl(url: string, res?: ServerResponse) {
  if (res) {
    res.statusCode = 302;
    res.setHeader('Location', url);
    res.end();
  } else {
    window.location.href = url;
  }
}

export function redirectToLogin(next?: string, res?: ServerResponse) {
  const nextUrl = `${getAuthUrl()}/login${next ? `?next=${encodeURIComponent(next)}` : ''}`;
  redirectToUrl(nextUrl, res);
}

export function redirectIfUnauthorized<T = unknown>(
  error: T | ClientError,
  next: string,
  res?: ServerResponse,
) {
  if (!hasGraphQLErrorCode(error as object, [GraphQLErrorCode.Unauthorized])) {
    return;
  }

  redirectToLogin(next, res);
}
