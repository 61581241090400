import { createIcon } from '@chakra-ui/react';

export const IconCalendar: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconCalendar',
  path: (
    <path
      d="M24.3054 3.83317H23.3325C23.0564 3.83317 22.8325 3.60931 22.8325 3.33317V1.4165C22.8325 1.14036 22.6087 0.916504 22.3325 0.916504H20.3867C20.1105 0.916504 19.8867 1.14036 19.8867 1.4165V3.33317C19.8867 3.60931 19.6628 3.83317 19.3867 3.83317H8.60335C8.32721 3.83317 8.10335 3.60931 8.10335 3.33317V1.4165C8.10335 1.14036 7.87949 0.916504 7.60335 0.916504H5.65752C5.38138 0.916504 5.15752 1.14036 5.15752 1.4165V3.33317C5.15752 3.60931 4.93366 3.83317 4.65752 3.83317H3.6846C2.04967 3.83317 0.73877 5.14567 0.73877 6.74984V27.1665C0.73877 27.9401 1.04913 28.6819 1.60158 29.2289C2.15403 29.7759 2.90332 30.0832 3.6846 30.0832H24.3054C25.0867 30.0832 25.836 29.7759 26.3885 29.2289C26.9409 28.6819 27.2513 27.9401 27.2513 27.1665V6.74984C27.2513 5.97629 26.9409 5.23442 26.3885 4.68744C25.836 4.14046 25.0867 3.83317 24.3054 3.83317ZM24.3054 26.6665C24.3054 26.9426 24.0816 27.1665 23.8054 27.1665H4.1846C3.90846 27.1665 3.6846 26.9426 3.6846 26.6665V11.6248C3.6846 11.3487 3.90846 11.1248 4.1846 11.1248H23.8054C24.0816 11.1248 24.3054 11.3487 24.3054 11.6248V26.6665Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 28 31',
});
