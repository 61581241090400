import { createIcon } from '@chakra-ui/react';

export const IconTwitch: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconTwitch',
  path: (
    <path
      d="M1.43555 0L0 4.00374V20.0015H5.25V23H8.11289L10.9758 20.0015H15.2742L21 14.0045V0H1.43555ZM3.33867 2.00187H19.0887V12.9993L15.75 16.4961H10.5L7.63711 19.4946V16.4961H3.33867V2.00187ZM8.58867 12.0026H10.5V5.99701H8.58867V12.0026ZM13.8387 12.0026H15.75V5.99701H13.8387V12.0026Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 21 23',
});
