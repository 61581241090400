import { createIcon } from '@chakra-ui/react';

export const IconShieldCheckFilled: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconShieldCheckFilled',
  path: (
    <path
      d="M12.0338 12.2433L12.0338 12.2433L8 14.9327L3.96624 12.2433L3.96619 12.2433C2.94562 11.5631 2.33333 10.4185 2.33333 9.19332V2.53466C2.33333 2.3787 2.44172 2.24349 2.59452 2.20934C2.59455 2.20933 2.59458 2.20933 2.59461 2.20932L8 1.00812L13.4054 2.20932C13.4054 2.20933 13.4055 2.20934 13.4055 2.20935C13.5583 2.24351 13.6667 2.37871 13.6667 2.53466V9.19266C13.6667 10.4186 13.0537 11.5638 12.0338 12.2433ZM11.2036 5.24554L10.9679 5.01003L10.7323 5.2456L7.66802 8.30928L6.01837 6.65962L5.78267 6.42392L5.54696 6.65962L4.6043 7.60229L4.3686 7.83799L4.6043 8.07369L7.43296 10.9024L7.66867 11.1381L7.90437 10.9024L12.147 6.65969L12.3828 6.42391L12.147 6.1882L11.2036 5.24554Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.666667"
    />
  ),
  viewBox: '0 0 16 16',
});
