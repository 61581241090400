/* eslint-disable sort-keys */
import { FieldType } from '@hihello/core';
import { CardSchema } from './schema';

export const defaultLayout: CardSchema = {
  id: 'classic',
  data: [
    {
      type: 'Box',
      variant: 'surface',
      children: [
        {
          type: 'Header',
          variant: 'wave',
          children: [
            {
              type: 'Box',
              variant: 'margin',
              children: [
                {
                  type: 'Identity',
                  children: [
                    {
                      type: 'Box',
                      children: [
                        {
                          type: 'Name',
                          children: [
                            {
                              type: 'Name',
                              variant: 'maiden',
                            },
                            {
                              type: 'Name',
                              variant: 'accreditations',
                            },
                          ],
                          variant: 'full',
                        },
                      ],
                    },
                    {
                      type: 'Box',
                      children: [
                        {
                          type: 'Title',
                        },
                      ],
                    },
                    {
                      type: 'Box',
                      children: [
                        {
                          type: 'Department',
                        },
                      ],
                    },
                    {
                      type: 'Box',
                      children: [
                        {
                          type: 'Company',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'Box',
          children: [
            {
              type: 'Box',
              variant: 'margin',
              children: [
                {
                  type: 'Headline',
                },
              ],
            },
            {
              type: 'Box',
              variant: 'margin',
              children: [
                {
                  type: 'PreferredName',
                },
              ],
            },
            {
              type: 'Box',
              variant: 'margin',
              children: [
                {
                  type: 'BadgeList',
                  variant: 'collapsible',
                },
              ],
            },
            {
              type: 'FieldList',
              variant: 'detail',
              exclude: [],
              rules: [
                { property: 'secondary', transforms: ['hide'] },
                { field: FieldType.Address, property: 'secondary', transforms: ['show'] },
                { field: FieldType.Email, property: 'secondary', transforms: ['show'] },
                { field: FieldType.ImportantDate, property: 'secondary', transforms: ['show'] },
                { field: FieldType.Phone, property: 'secondary', transforms: ['show'] },
              ],
            },
            {
              type: 'Box',
              variant: 'margin',
              children: [
                {
                  type: 'FeaturedContent',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  type: 'card',
  version: 1,
};
