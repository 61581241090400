import { createIcon } from '@chakra-ui/react';

export const IconActiveDirectory: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconActiveDirectory',
  path: (
    <path
      d="M20 4L11.3819 5.34036V12.1323L20 12.0595V4ZM4.00476 12.8692L4.00515 18.6488L10.5383 19.6006L10.5331 12.9142L4.00476 12.8692ZM11.3134 12.9619L11.3255 19.7039L19.9919 21L19.994 12.977L11.3134 12.9619ZM4 6.40704L4.00605 12.1838L10.5392 12.1444L10.5362 5.46358L4 6.40704Z"
      fill="currentColor"
    />
  ),
});
