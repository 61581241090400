import { createIcon } from '@chakra-ui/react';

export const IconLockClosed: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconLockClosed',
  path: (
    <path
      d="M13 16.25H9.75C8.88805 16.25 8.0614 16.5924 7.4519 17.2019C6.84241 17.8114 6.5 18.638 6.5 19.5V30.875C6.5 31.737 6.84241 32.5636 7.4519 33.1731C8.0614 33.7826 8.88805 34.125 9.75 34.125H29.25C30.112 34.125 30.9386 33.7826 31.5481 33.1731C32.1576 32.5636 32.5 31.737 32.5 30.875V19.5C32.5 18.638 32.1576 17.8114 31.5481 17.2019C30.9386 16.5924 30.112 16.25 29.25 16.25H26M13 16.25V11.375C13 9.65109 13.6848 7.99779 14.9038 6.77881C16.1228 5.55982 17.7761 4.875 19.5 4.875C21.2239 4.875 22.8772 5.55982 24.0962 6.77881C25.3152 7.99779 26 9.65109 26 11.375V16.25M13 16.25H26M19.5 22.75V27.625"
      fill="white"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3.25"
    />
  ),
  viewBox: '0 0 39 39',
});
