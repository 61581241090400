import { logger } from '@hihello/core';
import { v4 as uuid } from 'uuid';

export enum MimeType {
  JPEG = 'image/jpeg',
  MOV = 'video/quicktime',
  MP4 = 'video/mp4',
  PDF = 'application/pdf',
  PNG = 'image/png',
}

export type LocalMedia<T extends MimeType = MimeType> = {
  data: string;
  filename: string;
  includesAudio?: boolean;
  loop?: boolean;
  type: T;
};

type Media = {
  uri: string;
  variants?: {
    animatedGif?: string | null;
    imageLarge?: string | null;
    imageSmall?: string | null;
    videoLarge?: string | null;
  };
};

export function isVideo(media?: Media | null): boolean {
  if (!media?.uri) {
    return false;
  }

  try {
    const { pathname } = new URL(media.uri);
    return /(^video\/)|((mov|mp4)$)/.test(pathname) || !!media.variants?.videoLarge;
  } catch (error) {
    logger.error(error);
    return false;
  }
}

export function isImage(media?: Media | null): boolean {
  if (!media?.uri) {
    return false;
  }

  try {
    const { pathname } = new URL(media.uri);
    return /(^image\/)|((jpg|png|webp|gif)$)/.test(pathname) || !media.variants?.videoLarge;
  } catch (error) {
    logger.error(error);
    return false;
  }
}

export async function getMimeTypeFromObjectUrl(uri: string) {
  const res = await fetch(uri);
  const blob = await res.blob();
  const { type } = blob;

  if (type.startsWith('video/quicktime')) {
    return MimeType.MOV;
  }

  if (type.startsWith('video/mp4')) {
    return MimeType.MP4;
  }

  return MimeType.JPEG;
}

export async function getMimeTypeByUri(uri: string): Promise<MimeType> {
  if (uri.startsWith('data:')) {
    const mimeType = uri
      .split(',')
      .shift()
      ?.match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)
      ?.shift();

    if (mimeType?.startsWith('application/pdf')) {
      return MimeType.PDF;
    }

    if (mimeType?.startsWith('image/jpeg')) {
      return MimeType.JPEG;
    }

    if (mimeType?.startsWith('image/png')) {
      return MimeType.PNG;
    }

    if (mimeType?.startsWith('video/quicktime')) {
      return MimeType.MOV;
    }

    if (mimeType?.startsWith('video/mp4')) {
      return MimeType.MP4;
    }
  }

  if (isVideo({ uri })) {
    return MimeType.MP4;
  }

  if (uri.startsWith('blob:')) {
    return getMimeTypeFromObjectUrl(uri);
  }

  return MimeType.JPEG;
}

export async function getLocalMediaForFile<T extends MimeType>(file: File): Promise<LocalMedia<T>> {
  function getExtensionByContentType(type: string): string {
    switch (type) {
      case MimeType.JPEG: {
        return 'jpg';
      }

      case MimeType.PDF: {
        return 'pdf';
      }

      case MimeType.PNG: {
        return 'png';
      }

      case MimeType.MP4: {
        return 'mp4';
      }

      case MimeType.MOV: {
        return 'mov';
      }

      default: {
        throw new Error('Unsupported file type.');
      }
    }
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      try {
        const filename = uuid();
        const extension = getExtensionByContentType(file.type);
        const data = reader.result?.toString();
        const type = file.type as T;

        if (!data || data === 'data:,') {
          reject();
          return;
        }

        resolve({
          data,
          filename: `${filename}.${extension}`,
          includesAudio: false,
          loop: true,
          type,
        });
      } catch (error) {
        reject(error);
      }
    });

    reader.readAsDataURL(file);
  });
}
