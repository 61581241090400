import { createIcon } from '@chakra-ui/react';

export const IconHamburgerMenu: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconHamburgerMenu',
  path: (
    <path
      d="M2 19.2838H22V17.0031H2V19.2838ZM2 13.5821H22V11.3014H2V13.5821ZM2 5.59961V7.88031H22V5.59961H2Z"
      fill="currentColor"
    />
  ),
});
