import { createIcon } from '@chakra-ui/react';

export const IconSMS: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconSMS',
  path: (
    <path
      d="M18.167 16.1667H5.70866C5.20033 16.1667 4.71282 15.9648 4.35337 15.6053C3.99393 15.2459 3.79199 14.7584 3.79199 14.25V5.56754L1.13741 2.40504C1.00324 2.24212 0.916992 2.02171 0.916992 1.79171C0.916992 1.53754 1.01796 1.29379 1.19768 1.11406C1.3774 0.934341 1.62116 0.833374 1.87533 0.833374H18.167C18.6753 0.833374 19.1628 1.03531 19.5223 1.39475C19.8817 1.7542 20.0837 2.24171 20.0837 2.75004V14.25C20.0837 14.7584 19.8817 15.2459 19.5223 15.6053C19.1628 15.9648 18.6753 16.1667 18.167 16.1667ZM7.14616 3.70837C7.01908 3.70837 6.8972 3.75886 6.80734 3.84872C6.71748 3.93858 6.66699 4.06046 6.66699 4.18754V5.14587C6.66699 5.27296 6.71748 5.39483 6.80734 5.4847C6.8972 5.57456 7.01908 5.62504 7.14616 5.62504H16.7295C16.8566 5.62504 16.9785 5.57456 17.0683 5.4847C17.1582 5.39483 17.2087 5.27296 17.2087 5.14587V4.18754C17.2087 4.06046 17.1582 3.93858 17.0683 3.84872C16.9785 3.75886 16.8566 3.70837 16.7295 3.70837H7.14616ZM7.14616 7.54171C7.01908 7.54171 6.8972 7.59219 6.80734 7.68205C6.71748 7.77191 6.66699 7.89379 6.66699 8.02087V8.97921C6.66699 9.10629 6.71748 9.22817 6.80734 9.31803C6.8972 9.40789 7.01908 9.45837 7.14616 9.45837H16.7295C16.8566 9.45837 16.9785 9.40789 17.0683 9.31803C17.1582 9.22817 17.2087 9.10629 17.2087 8.97921V8.02087C17.2087 7.89379 17.1582 7.77191 17.0683 7.68205C16.9785 7.59219 16.8566 7.54171 16.7295 7.54171H7.14616ZM7.14616 11.375C7.01908 11.375 6.8972 11.4255 6.80734 11.5154C6.71748 11.6052 6.66699 11.7271 6.66699 11.8542V12.8125C6.66699 12.9396 6.71748 13.0615 6.80734 13.1514C6.8972 13.2412 7.01908 13.2917 7.14616 13.2917H11.9378C12.0649 13.2917 12.1868 13.2412 12.2766 13.1514C12.3665 13.0615 12.417 12.9396 12.417 12.8125V11.8542C12.417 11.7271 12.3665 11.6052 12.2766 11.5154C12.1868 11.4255 12.0649 11.375 11.9378 11.375H7.14616Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 21 17',
});
