import { createIcon } from '@chakra-ui/react';

export const IconThreePeople: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconThreePeople',
  path: (
    <>
      <path
        d="M16.9263 13.2413C16.9946 12.9109 17.0298 12.5685 17.0281 12.2177C17.0274 12.0839 17.0215 11.9514 17.0103 11.8203C16.8622 10.0763 15.8032 8.59447 14.3127 7.84909C13.893 7.63917 13.439 7.48766 12.9615 7.40517C12.6805 7.35663 12.3914 7.33198 12.0963 7.3334C11.8867 7.3344 11.6803 7.34854 11.4777 7.37503C10.8697 7.45456 10.2968 7.6454 9.78017 7.92638C8.244 8.76183 7.20465 10.3941 7.21347 12.2666C7.22756 14.9783 9.43496 17.1639 12.1452 17.1509C12.22 17.1505 12.2943 17.1484 12.3682 17.1447C12.6209 17.1321 12.8685 17.1004 13.1096 17.0511C13.2901 16.9975 13.4727 16.9489 13.6572 16.9054C14.2251 16.7183 14.747 16.4304 15.2018 16.0629C15.6669 15.6872 16.0618 15.2284 16.3642 14.709L16.3508 14.7108L16.3661 14.7058C16.6265 14.2577 16.8181 13.7647 16.9263 13.2413Z"
        fill="currentColor"
      />
      <path
        d="M18.0621 14.1573C18.3268 14.0133 18.5767 13.8457 18.8091 13.6573C19.2815 13.2742 19.6813 12.8051 19.9847 12.2739C20.3981 11.5502 20.6326 10.7113 20.6283 9.81767C20.6154 7.10654 18.4074 4.92039 15.6966 4.93339C15.4387 4.93463 15.1856 4.95575 14.9386 4.99529C14.3232 5.09384 13.7462 5.30683 13.23 5.61169C13.023 5.73394 12.8257 5.87096 12.6397 6.02131C12.7975 6.03431 12.9537 6.05319 13.108 6.07773C13.5551 6.14883 13.9864 6.26742 14.3967 6.42819C16.7074 7.33378 18.3488 9.57775 18.3614 12.2113L18.3614 12.2643C18.3598 12.7199 18.3094 13.164 18.2151 13.5918C18.1728 13.7837 18.1216 13.9724 18.0621 14.1573Z"
        fill="currentColor"
      />
      <path
        d="M16.1138 3.61218C19.3643 3.81428 21.9458 6.50594 21.9617 9.81128C21.9647 10.4534 21.8708 11.0734 21.6936 11.6572C23.1468 10.7989 24.1181 9.21328 24.1095 7.40288C24.1078 7.06399 24.0719 6.7333 24.005 6.41408C23.5366 4.17957 21.5497 2.50722 19.1777 2.5186C18.0162 2.52417 16.9509 2.93311 16.1138 3.61218Z"
        fill="currentColor"
      />
      <path
        d="M16.5364 16.6528C16.7648 16.424 16.9756 16.1775 17.1665 15.9157C17.416 15.8565 17.6596 15.7823 17.8964 15.6942C18.3876 15.6057 18.8933 15.5582 19.4098 15.5558C24.1802 15.5328 28.0689 19.3512 28.092 23.9879C28.0926 24.1159 28.0754 24.1932 27.8832 24.3273C27.8164 24.3738 27.7349 24.4208 27.6371 24.4675C27.3436 24.6077 26.9032 24.7448 26.2748 24.8559C26.2567 24.8592 26.2385 24.8623 26.2202 24.8655C26.0922 24.8874 25.9602 24.9078 25.8243 24.9267C25.1052 20.4222 21.2406 16.9144 16.5364 16.6528Z"
        fill="currentColor"
      />
      <path
        d="M24.4946 25.0607C23.814 21.0517 20.2297 17.9498 15.9287 17.9705C15.2717 17.9737 14.6323 18.0496 14.0179 18.1903C13.8274 18.2511 13.6328 18.3029 13.4347 18.3454C13.2534 18.4004 13.0748 18.4612 12.8988 18.5274C12.8741 18.5368 12.8494 18.5462 12.8248 18.5557C12.4553 18.6987 12.0983 18.8661 11.7557 19.0557C11.9431 19.0444 12.1319 19.0382 12.322 19.0372C12.3768 19.037 12.4315 19.0371 12.4861 19.0378C16.5978 19.084 20.1364 21.6107 21.6112 25.1635C21.7889 25.5917 21.9367 26.0348 22.0519 26.4903C22.1238 26.7746 22.1829 27.0637 22.2288 27.357C22.425 27.3312 22.6134 27.3026 22.7937 27.2707C23.4221 27.1596 23.8624 27.0225 24.156 26.8823C24.2538 26.8356 24.3353 26.7886 24.4021 26.7421C24.5943 26.608 24.6115 26.5307 24.6109 26.4027C24.6109 26.398 24.6108 26.3934 24.6108 26.3888C24.6078 25.9373 24.5682 25.4937 24.4946 25.0607Z"
        fill="currentColor"
      />
      <path
        d="M20.8982 27.4833C20.8428 27.1512 20.7674 26.8252 20.6733 26.5067C20.5375 26.0465 20.3626 25.6019 20.1524 25.1766C18.7477 22.3334 15.7671 20.354 12.3284 20.3706C12.1016 20.3716 11.8769 20.3814 11.6545 20.3995C11.1163 20.4434 10.5921 20.5361 10.0864 20.673C9.88576 20.7274 9.688 20.7887 9.49345 20.8567C9.43559 20.8769 9.37802 20.8977 9.32074 20.9191C8.46151 21.2401 7.66771 21.6921 6.96437 22.2494C4.98324 23.8191 3.71974 26.2241 3.73301 28.8864C3.73365 29.0144 3.75163 29.0917 3.94529 29.2238C4.21411 29.4073 4.71973 29.5969 5.55907 29.7369C7.22959 30.0154 9.58865 30.0085 12.3783 29.995C15.1673 29.9816 17.526 29.9656 19.1934 29.6707C20.0312 29.5226 20.5348 29.3281 20.8018 29.1421C20.9894 29.0113 21.0103 28.9344 21.0106 28.8118C21.0106 28.8089 21.0106 28.806 21.0106 28.803C21.0084 28.3547 20.97 27.9137 20.8982 27.4833Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: '0 0 32 32',
});
