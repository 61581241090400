import { Box, Grid } from '@chakra-ui/react';
import { BlobShape } from './BlobShape';
import { blobShape1, blobShape2, blobShape3 } from './helpers';

export const BlobBackground = () => (
  <Grid
    bg="violet.500"
    h="100%"
    left="0"
    overflow="hidden"
    position="absolute"
    templateColumns="repeat(3, 1fr)"
    top="0"
    w="100%"
  >
    <Box>
      <BlobShape
        filter="drop-shadow(6px 0px 45px rgba(74, 31, 150, 0.30))"
        height="55vh"
        left="-2vw"
        position="absolute"
        shapePaths={[
          {
            animationOptions: { delay: 1, duration: 6 },
            color: '#9469d4',
            paths: [blobShape1, blobShape2, blobShape1],
          },
        ]}
        top="20vh"
        transform="rotate(100deg)"
        width="auto"
      />
      <BlobShape
        filter="drop-shadow(6px 0px 45px rgba(74, 31, 150, 0.30))"
        height="45vh"
        left="-5vw"
        position="absolute"
        shapePaths={[
          {
            animationOptions: { delay: 0, duration: 5 },
            color: '#9D75E3',
            paths: [blobShape2, blobShape3, blobShape2],
          },
        ]}
        top="-10vh"
        transform="rotate(270deg)"
        width="auto"
      />
      <BlobShape
        bottom="-10vh"
        filter="drop-shadow(6px 0px 45px rgba(74, 31, 150, 0.30))"
        height="55vh"
        left="-5vw"
        position="absolute"
        shapePaths={[
          {
            animationOptions: { delay: 0, duration: 7 },
            color: '#9D75E3',
            paths: [blobShape2, blobShape3, blobShape2],
          },
        ]}
        transform="rotate(180deg)"
        width="auto"
      />
    </Box>

    <Box>
      <BlobShape
        filter="drop-shadow(6px 0px 45px rgba(74, 31, 150, 0.40))"
        height="50vh"
        position="absolute"
        shapePaths={[
          {
            animationOptions: { delay: 1, duration: 5 },
            color: '#9469d4',
            paths: [blobShape1, blobShape2, blobShape1],
          },
        ]}
        top="50vh"
        width="auto"
      />
      <BlobShape
        filter="drop-shadow(6px 0px 45px rgba(74, 31, 150, 0.40))"
        height="60vh"
        position="absolute"
        shapePaths={[
          {
            animationOptions: { delay: 0, duration: 7 },
            color: '#9D75E3',
            paths: [blobShape2, blobShape3, blobShape2],
          },
        ]}
        top="-5vh"
        transform="rotate(30deg)"
        width="auto"
      />
      <BlobShape
        bottom="5vh"
        filter="drop-shadow(6px 0px 45px rgba(74, 31, 150, 0.40))"
        height="50vh"
        position="absolute"
        shapePaths={[
          {
            animationOptions: { delay: 0, duration: 4 },
            color: '#9D75E3',
            paths: [blobShape2, blobShape3, blobShape2],
          },
        ]}
        transform="rotate(270deg) translateX(-50%)"
        width="auto"
      />
    </Box>

    <Box>
      <BlobShape
        filter="drop-shadow(6px 0px 45px rgba(74, 31, 150, 0.40))"
        height="55vh"
        position="absolute"
        shapePaths={[
          {
            animationOptions: { delay: 1, duration: 6 },
            color: '#9469d4',
            paths: [blobShape1, blobShape2, blobShape1],
          },
        ]}
        top="20vh"
        width="auto"
      />
      <BlobShape
        filter="drop-shadow(6px 0px 45px rgba(74, 31, 150, 0.40))"
        height="45vh"
        position="absolute"
        right="-5vw"
        shapePaths={[
          {
            animationOptions: { delay: 0, duration: 5 },
            color: '#9D75E3',
            paths: [blobShape2, blobShape3, blobShape2],
          },
        ]}
        top="-10vh"
        transform="rotate(0)"
        width="auto"
      />
      <BlobShape
        bottom="-10vh"
        filter="drop-shadow(6px 0px 45px rgba(74, 31, 150, 0.40))"
        height="45vh"
        position="absolute"
        right="-5vw"
        shapePaths={[
          {
            animationOptions: { delay: 0, duration: 7 },
            color: '#9D75E3',
            paths: [blobShape2, blobShape3, blobShape2],
          },
        ]}
        transform="rotate(180deg)"
        width="auto"
      />
    </Box>
  </Grid>
);
