/* eslint-disable no-param-reassign */
import { CardDetails, Fields, FieldType, Palette } from '@hihello/core';
import { produce } from 'immer';
import {
  createContext,
  MouseEvent,
  KeyboardEvent,
  useContext,
  useEffect,
  useRef,
  FunctionComponent,
  PropsWithChildren,
} from 'react';
import { createStore, StoreApi } from 'zustand';
import { Labels } from '../layout';

type FieldTypesWithLabel =
  | `${FieldType.Address}.label.${Labels.Address}`
  | `${FieldType.Email}.label.${Labels.Email}`
  | `${FieldType.ImportantDate}.label.${Labels.ImportantDate}`
  | `${FieldType.Phone}.label.${Labels.Phone}`;

export type CardState = {
  details?: CardDetails;
  messages?: Record<`${FieldType}.name`, string> &
    Record<`${FieldTypesWithLabel}`, string> &
    Record<'clipboard.request' | `clipboard.notification.${'dismiss' | 'text'}`, string>;
  onFieldSelect?: (
    field: Fields,
    event?: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>,
  ) => void;
  onFieldTextCopiedToClipboard?: (type: FieldType) => void;
  themePalette?: Palette;
  variant?: 'default' | 'mini' | 'mobile';
  // snackbarQueue?: ReturnType<typeof createSnackbarQueue>;
};

export type CardStateSetters = {
  updateDetails?: (details: CardDetails) => void;
  updateThemePalette?: (palette: Palette) => void;
};

export type CardStoreState = CardState & CardStateSetters;

const CardStoreContext = createContext<StoreApi<CardStoreState>>(
  createStore<CardStoreState>(() => ({
    variant: 'default',
  })),
);

export const useCardStore = () => {
  const cardStore = useContext(CardStoreContext);
  return cardStore;
};

export type CardStoreProps = CardState;

export const CardStore: FunctionComponent<PropsWithChildren<CardState>> = ({
  children,
  details,
  messages,
  onFieldSelect,
  onFieldTextCopiedToClipboard,
  themePalette,
  variant,
}) => {
  const store = useRef(
    createStore<CardStoreState>((set) => ({
      details,
      messages,
      onFieldSelect,
      onFieldTextCopiedToClipboard,
      updateDetails: (updatedDetails: CardDetails) =>
        set(
          produce((state) => {
            state.details = updatedDetails;
          }),
        ),
      updateThemePalette: (updatedPalette: Palette) =>
        set(
          produce((state) => {
            state.themePalette = updatedPalette;
          }),
        ),
      variant,
    })),
  );

  useEffect(() => {
    if (details) {
      const storeState = store.current.getState();
      storeState.updateDetails?.(details);
    }
  }, [details]);

  useEffect(() => {
    if (themePalette) {
      const storeState = store.current.getState();
      storeState.updateThemePalette?.(themePalette);
    }
  }, [themePalette]);

  return <CardStoreContext.Provider value={store.current}>{children}</CardStoreContext.Provider>;
};
