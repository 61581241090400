import { createIcon } from '@chakra-ui/react';

export const IconCalendly: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconCalendly',
  path: (
    <>
      <path
        d="M24.9038 1.24098H20.913V0.653084C20.913 0.290391 20.62 0 20.2552 0C19.8893 0 19.5975 0.290391 19.5975 0.653084V1.24098H8.33913V0.653084C8.33913 0.290391 8.04613 0 7.68137 0C7.31542 0 7.02362 0.290391 7.02362 0.653084V1.24098H3.09623C1.38966 1.24098 0 2.61826 0 4.30964V25.9302C0 27.6215 1.38966 28.9988 3.09623 28.9988H24.9026C26.6175 28.9988 28.0072 27.6215 27.9988 25.9219V4.30964C28 2.61826 26.6103 1.24098 24.9038 1.24098ZM26.2851 25.9219C26.2851 26.6792 25.6668 27.2908 24.9038 27.2908H3.09623C2.33204 27.2908 1.71494 26.6781 1.71494 25.9219V4.30964C1.71494 3.55225 2.33323 2.94065 3.09623 2.94065H7.02242V5.17963C7.02242 5.54232 7.31542 5.83271 7.68018 5.83271C8.04613 5.83271 8.3463 5.53521 8.33793 5.17252V2.94065H19.5951V5.17963C19.5951 5.54232 19.8881 5.83271 20.2528 5.83271C20.6188 5.83271 20.9106 5.53521 20.9106 5.17252V2.94065H24.9014C25.6656 2.94065 26.2827 3.55344 26.2827 4.30964V25.9219H26.2851Z"
        fill="currentColor"
      />
      <path
        d="M14.7924 11.165C15.8891 11.165 16.9379 11.568 17.7511 12.3005C17.8564 12.4048 18.0358 12.3977 18.1326 12.2839C18.2379 12.1796 18.2307 12.0018 18.1159 11.9058C17.2058 11.0844 16.0266 10.6328 14.7912 10.6328C12.0597 10.6328 9.84131 12.8315 9.84131 15.5386C9.84131 18.2458 12.0597 20.4445 14.7912 20.4445C16.0266 20.4445 17.2046 19.9929 18.1159 19.1561C18.2211 19.0589 18.2295 18.8906 18.1326 18.778C18.0346 18.6737 17.8648 18.6654 17.7511 18.7614C16.9379 19.4939 15.8903 19.8969 14.7924 19.8969C12.3623 19.8969 10.3866 17.94 10.3866 15.5303C10.3866 13.1207 12.3623 11.165 14.7924 11.165Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </>
  ),
  viewBox: '0 0 28 29',
});
