import { createIcon } from '@chakra-ui/react';

export const IconCsv: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconCsv',
  path: (
    <path
      clipRule="evenodd"
      d="M2.33366 2.3335H11.667C12.3087 2.3335 12.8337 2.8585 12.8337 3.50016V10.5002C12.8337 11.1418 12.3087 11.6668 11.667 11.6668H2.33366C1.69199 11.6668 1.16699 11.1418 1.16699 10.5002L1.17283 3.50016C1.17283 2.8585 1.69199 2.3335 2.33366 2.3335ZM2.33417 10.5002H8.75083V8.16683H2.33417V10.5002ZM8.75083 7.5835H2.33417V5.25016H8.75083V7.5835ZM9.33341 10.5002H11.6667V5.25016H9.33341V10.5002Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
  viewBox: '0 0 14 14',
});
