import { createIcon } from '@chakra-ui/react';

export const IconDownCarat: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconDownCarat',
  path: (
    <path
      d="M7.64693 9.13402L8.00048 9.48758L8.35403 9.13402L11.3005 6.18758L11.536 6.42314L8.00048 9.9587L4.46492 6.42314L4.70048 6.18758L7.64693 9.13402Z"
      fill="currentColor"
      stroke="currentColor"
    />
  ),
  viewBox: '0 0 16 16',
});
