import { createIcon } from '@chakra-ui/react';

export const IconInfoFilled: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconInfoFilled',
  path: (
    <path
      d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM11 15.5V17.5H13V15.5H11ZM11 7.5V13.5H13V7.5H11Z"
      fill="currentColor"
    />
  ),
});
