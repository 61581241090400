import { createIcon } from '@chakra-ui/react';

export const IconQrCode: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconQrCode',
  path: (
    <>
      <path d="M10.6458 12.5208V11.5833H11.5833V12.5208H10.6458Z" fill="currentColor" />
      <path d="M7.83325 10.6458V9.70825H8.77075V10.6458H7.83325Z" fill="currentColor" />
      <path
        d="M7.83325 13.4583H9.70825V12.5208H8.77075V11.5833H7.83325V13.4583Z"
        fill="currentColor"
      />
      <path d="M11.5833 11.5833V9.70825H12.5208V11.5833H11.5833Z" fill="currentColor" />
      <path
        d="M12.5208 11.5833H13.4583V13.4583H11.5833V12.5208H12.5208V11.5833Z"
        fill="currentColor"
      />
      <path
        d="M11.5833 8.77075V7.83325H13.4583V9.70825H12.5208V8.77075H11.5833Z"
        fill="currentColor"
      />
      <path
        d="M10.6458 8.77075H9.70825V10.6458H8.77075V11.5833H10.6458V8.77075Z"
        fill="currentColor"
      />
      <path d="M7.83325 8.77075V7.83325H9.70825V8.77075H7.83325Z" fill="currentColor" />
      <path d="M2.20825 9.70825H4.08325V11.5833H2.20825V9.70825Z" fill="currentColor" />
      <path
        d="M5.95825 13.4583H0.333252V7.83325H5.95825V13.4583ZM1.27075 12.5208H5.02075V8.77075H1.27075V12.5208Z"
        fill="currentColor"
      />
      <path d="M9.70825 2.20825H11.5833V4.08325H9.70825V2.20825Z" fill="currentColor" />
      <path
        d="M13.4583 5.95825H7.83325V0.333252H13.4583V5.95825ZM8.77075 5.02075H12.5208V1.27075H8.77075V5.02075Z"
        fill="currentColor"
      />
      <path d="M2.20825 2.20825H4.08325V4.08325H2.20825V2.20825Z" fill="currentColor" />
      <path
        d="M5.95825 5.95825H0.333252V0.333252H5.95825V5.95825ZM1.27075 5.02075H5.02075V1.27075H1.27075V5.02075Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: '0 0 14 14',
});
