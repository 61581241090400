import { getCookie, setCookie } from '@hihello/core';
import { getLoginProviders, hasWorkOSProvider } from '@src/lib/api/getLoginProviders';
import { IncomingMessage, ServerResponse } from 'http';

const LAST_AUTHENTICATED_EMAIL_STORAGE_ID = 'lastAuthenticatedEmail';

export function updateLastAuthenticatedEmail(email?: string, res?: ServerResponse) {
  setCookie({
    name: LAST_AUTHENTICATED_EMAIL_STORAGE_ID,
    persist: Boolean(email),
    res,
    value: email,
  });
}

export const getLastAuthenticatedEmail = (req?: IncomingMessage) =>
  getCookie(LAST_AUTHENTICATED_EMAIL_STORAGE_ID, req);

export const cleanUpLastAuthenticatedEmailForWorkOSProvider = async (
  req?: IncomingMessage,
  res?: ServerResponse,
) => {
  const lastAuthenticatedEmail = getLastAuthenticatedEmail(req);
  if (lastAuthenticatedEmail) {
    const loginProviders = await getLoginProviders(lastAuthenticatedEmail);
    if (hasWorkOSProvider(loginProviders)) {
      updateLastAuthenticatedEmail(undefined, res);
    }
  }
};

export function cleanupHiHelloStorage() {
  const storageItems = Object.keys(localStorage);
  storageItems.forEach((key) => {
    if (key.startsWith('hihello::')) {
      localStorage.removeItem(key);
    }
  });
}
