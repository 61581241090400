import { createIcon } from '@chakra-ui/react';

export const IconBar: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconBar',
  path: (
    <>
      <g clipPath="url(#clip0_1634_6694)">
        <path
          d="M15.9375 16.4688H1.59375C1.31196 16.4688 1.04171 16.3568 0.842449 16.1576C0.643192 15.9583 0.53125 15.688 0.53125 15.4062V1.0625C0.53125 0.921604 0.587221 0.786478 0.68685 0.68685C0.786478 0.587221 0.921604 0.53125 1.0625 0.53125C1.2034 0.53125 1.33852 0.587221 1.43815 0.68685C1.53778 0.786478 1.59375 0.921604 1.59375 1.0625V15.4062H15.9375C16.0784 15.4062 16.2135 15.4622 16.3132 15.5618C16.4128 15.6615 16.4688 15.7966 16.4688 15.9375C16.4688 16.0784 16.4128 16.2135 16.3132 16.3132C16.2135 16.4128 16.0784 16.4688 15.9375 16.4688Z"
          fill="#A0AEC0"
        />
        <path
          d="M5.52344 14.1562H4.19531C3.8783 14.1562 3.57426 14.0303 3.3501 13.8062C3.12593 13.582 3 13.278 3 12.9609V7.91406C3 7.59705 3.12593 7.29301 3.3501 7.06885C3.57426 6.84468 3.8783 6.71875 4.19531 6.71875H5.52344C5.84045 6.71875 6.14449 6.84468 6.36865 7.06885C6.59282 7.29301 6.71875 7.59705 6.71875 7.91406V12.9609C6.71875 13.278 6.59282 13.582 6.36865 13.8062C6.14449 14.0303 5.84045 14.1562 5.52344 14.1562ZM10.3047 14.1562H8.97656C8.65955 14.1562 8.35551 14.0303 8.13135 13.8062C7.90718 13.582 7.78125 13.278 7.78125 12.9609V6.32031C7.78125 6.0033 7.90718 5.69926 8.13135 5.4751C8.35551 5.25093 8.65955 5.125 8.97656 5.125H10.3047C10.6217 5.125 10.9257 5.25093 11.1499 5.4751C11.3741 5.69926 11.5 6.0033 11.5 6.32031V12.9609C11.5 13.278 11.3741 13.582 11.1499 13.8062C10.9257 14.0303 10.6217 14.1562 10.3047 14.1562ZM15.074 14.1562H13.7459C13.4288 14.1562 13.1248 14.0303 12.9006 13.8062C12.6765 13.582 12.5505 13.278 12.5505 12.9609V4.19531C12.5505 3.8783 12.6765 3.57426 12.9006 3.3501C13.1248 3.12593 13.4288 3 13.7459 3H15.074C15.391 3 15.695 3.12593 15.9192 3.3501C16.1434 3.57426 16.2693 3.8783 16.2693 4.19531V12.9609C16.2693 13.278 16.1434 13.582 15.9192 13.8062C15.695 14.0303 15.391 14.1562 15.074 14.1562Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1634_6694">
          <rect fill="white" height="17" width="17" />
        </clipPath>
      </defs>
    </>
  ),
  viewBox: '0 0 17 17',
});
