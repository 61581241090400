import { createIcon } from '@chakra-ui/react';

export const IconClose: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconClose',
  path: (
    <path
      d="M11.9997 10.5857L16.9497 5.63574L18.3637 7.04974L13.4137 11.9997L18.3637 16.9497L16.9497 18.3637L11.9997 13.4137L7.04974 18.3637L5.63574 16.9497L10.5857 11.9997L5.63574 7.04974L7.04974 5.63574L11.9997 10.5857Z"
      fill="currentColor"
    />
  ),
});
