const functions = {
  getQrCodeForIdentity: 'server-HttpsGetQrCodeForIdentityV1',
  getSharedVCard: 'server-HttpsGetSharedVcardV1',
};

export const serverFunctions = Object.entries(functions).reduce(
  (acc, [key, name]) => {
    acc[key as keyof typeof functions] = `${process.env.NEXT_PUBLIC_API_URL}/${name}`;
    return acc;
  },
  {} as Record<keyof typeof functions, string>,
);
