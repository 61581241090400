import { createIcon } from '@chakra-ui/react';

export const IconContacts: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconContacts',
  path: (
    <path
      d="M17.8333 2H5.33333C4.89131 2 4.46738 2.17559 4.15482 2.48816C3.84226 2.80072 3.66667 3.22464 3.66667 3.66667V6.16667H2V7.83333H3.66667V9.5H2V11.1667H3.66667V12.8333H2V14.5H3.66667V17C3.66667 17.442 3.84226 17.8659 4.15482 18.1785C4.46738 18.4911 4.89131 18.6667 5.33333 18.6667H17.8333C18.0543 18.6667 18.2663 18.5789 18.4226 18.4226C18.5789 18.2663 18.6667 18.0543 18.6667 17.8333V2.83333C18.6667 2.61232 18.5789 2.40036 18.4226 2.24408C18.2663 2.0878 18.0543 2 17.8333 2ZM11.1667 4.49917C12.54 4.49917 13.6667 5.625 13.6667 6.99917C13.6643 7.66154 13.4001 8.29611 12.9318 8.76456C12.4635 9.23301 11.829 9.49737 11.1667 9.5C9.79417 9.5 8.66667 8.3725 8.66667 6.99917C8.66667 5.625 9.79417 4.49917 11.1667 4.49917ZM16.1667 15.3333H6.16667V14.7083C6.16667 12.8592 8.42083 10.9583 11.1667 10.9583C13.9125 10.9583 16.1667 12.8592 16.1667 14.7083V15.3333Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 20 20',
});
