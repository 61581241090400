import { createIcon } from '@chakra-ui/react';

export const IconLogin: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconLogin',
  path: (
    <path
      clipRule="evenodd"
      d="M1.79922 1.7998C1.95835 1.7998 2.11096 1.86302 2.22348 1.97554C2.336 2.08806 2.39922 2.24067 2.39922 2.3998V9.5998C2.39922 9.75893 2.336 9.91155 2.22348 10.0241C2.11096 10.1366 1.95835 10.1998 1.79922 10.1998C1.64009 10.1998 1.48748 10.1366 1.37495 10.0241C1.26243 9.91155 1.19922 9.75893 1.19922 9.5998V2.3998C1.19922 2.24067 1.26243 2.08806 1.37495 1.97554C1.48748 1.86302 1.64009 1.7998 1.79922 1.7998ZM6.42342 3.7756C6.5359 3.88812 6.59909 4.04071 6.59909 4.1998C6.59909 4.3589 6.5359 4.51149 6.42342 4.624L5.64762 5.3998H10.1992C10.3583 5.3998 10.511 5.46302 10.6235 5.57554C10.736 5.68806 10.7992 5.84067 10.7992 5.9998C10.7992 6.15893 10.736 6.31155 10.6235 6.42407C10.511 6.53659 10.3583 6.5998 10.1992 6.5998H5.64762L6.42342 7.37561C6.53271 7.48877 6.59319 7.64033 6.59182 7.79765C6.59046 7.95496 6.52735 8.10545 6.41611 8.2167C6.30487 8.32794 6.15438 8.39104 5.99706 8.39241C5.83974 8.39378 5.68818 8.3333 5.57502 8.224L3.77502 6.424C3.66254 6.31149 3.59935 6.1589 3.59935 5.9998C3.59935 5.84071 3.66254 5.68812 3.77502 5.5756L5.57502 3.7756C5.68754 3.66312 5.84012 3.59993 5.99922 3.59993C6.15832 3.59993 6.3109 3.66312 6.42342 3.7756Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
  viewBox: '0 0 12 12',
});
