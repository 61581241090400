import { Box, BoxProps, Skeleton } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { useStore } from 'zustand';
import { TypographyVariant } from '../../layout';
import { useCardStore } from '../../store';

export type CompanyProps = BoxProps & TypographyVariant;

export const Company: FunctionComponent<CompanyProps> = ({
  children,
  variant = 'italic',
  ...props
}) => {
  const cardStore = useCardStore();
  const { details, cardVariant } = useStore(cardStore, (state) => ({
    cardVariant: state.variant,
    details: state.details,
  }));

  if (details?.company === null || (!details?.company && cardVariant === 'mini')) {
    return null;
  }

  return (
    <Box
      className="CardCompany"
      fontSize="1.1rem"
      fontStyle={variant}
      fontWeight={300}
      lineHeight="1.5rem"
      sx={{ '& + &': { marginLeft: '0.5rem' } }}
      variant={variant}
      {...props}
    >
      {details?.company ?? <Skeleton h="5" mb="3" width="40%" />}
      {children}
    </Box>
  );
};
