import { Center, CenterProps, Icon } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { IconChevronLeft, IconChevronRight } from '../Icons';

export type SidebarExpandHandleProps = CenterProps & {
  isExpanded: boolean;
};

export const SidebarExpandHandle: FunctionComponent<SidebarExpandHandleProps> = ({
  'aria-label': ariaLabel = 'Expand Sidebar',
  isExpanded,
  ...props
}) => (
  <Center as="button" h={8} w={8} {...props} aria-label={ariaLabel}>
    <Center
      bg="white"
      borderColor="gray.300"
      borderRadius="full"
      borderWidth="1px"
      color="black"
      h="6"
      position="relative"
      w="6"
    >
      <Icon as={isExpanded ? IconChevronLeft : IconChevronRight} />
    </Center>
  </Center>
);
