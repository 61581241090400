import { createIcon } from '@chakra-ui/react';

export const IconCashApp: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconCashApp',
  path: (
    <path
      d="M15.9263 8.925C16.1138 9.1125 16.4251 9.1125 16.6013 8.925L17.5388 7.95C17.7376 7.7625 17.7263 7.425 17.5163 7.2225C16.7796 6.58083 15.9232 6.09142 14.9963 5.7825L15.2926 4.3575C15.3563 4.04625 15.1238 3.75375 14.8126 3.75375H12.9976C12.8841 3.75497 12.7745 3.79512 12.6872 3.86748C12.5998 3.93984 12.5399 4.04002 12.5176 4.15125L12.2551 5.41875C9.84008 5.5425 7.79258 6.76875 7.79258 9.28125C7.79258 11.4562 9.48383 12.39 11.2726 13.0312C12.9638 13.6762 13.8601 13.9162 13.8601 14.8237C13.8601 15.7575 12.9676 16.305 11.6476 16.305C10.4476 16.305 9.18758 15.9037 8.21258 14.925C8.1673 14.8796 8.11352 14.8437 8.05432 14.8191C7.99513 14.7946 7.93167 14.7819 7.86758 14.7819C7.80349 14.7819 7.74003 14.7946 7.68084 14.8191C7.62164 14.8437 7.56786 14.8796 7.52258 14.925L6.51008 15.9375C6.41482 16.0331 6.36133 16.1625 6.36133 16.2975C6.36133 16.4325 6.41482 16.5619 6.51008 16.6575C7.29758 17.4337 8.29508 17.9962 9.43208 18.3075L9.15458 19.6462C9.09083 19.9575 9.31958 20.2462 9.63083 20.25L11.4496 20.265C11.5643 20.2655 11.6757 20.2261 11.7646 20.1536C11.8535 20.0811 11.9145 19.98 11.9371 19.8675L12.1996 18.5963C15.1051 18.4013 16.8751 16.8 16.8751 14.4637C16.8751 12.3112 15.1126 11.4037 12.9751 10.665C11.7526 10.2112 10.6951 9.9 10.6951 8.96625C10.6951 8.05875 11.6813 7.69875 12.6713 7.69875C13.9313 7.69875 15.1426 8.22 15.9338 8.9325L15.9263 8.925Z"
      fill="currentColor"
    />
  ),
});
