import { createIcon } from '@chakra-ui/react';

export const IconAudioOn: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconAudioOn',
  path: (
    <>
      <path
        d="M1 14.8286V7.28571H6.02857L12.3143 1V21.1143L6.02857 14.8286H1Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M17.3433 2.25732C20.9423 3.16917 23.629 6.76292 23.629 11.0573C23.629 15.3517 20.9423 18.9455 17.3433 19.8573"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M17.3433 7.28589C18.7829 7.67668 19.8575 9.21686 19.8575 11.0573C19.8575 12.8978 18.7829 14.438 17.3433 14.8287"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </>
  ),
});
