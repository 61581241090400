import { createIcon } from '@chakra-ui/react';

export const IconLockBuilding: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconLockBuilding',
  path: (
    <>
      <path
        d="M14.372 5.2168C14.3904 5.0776 14.4 4.9384 14.4 4.8C14.4 2.8968 12.6856 1.3696 10.7832 1.628C10.2288 0.6416 9.1728 0 8 0C6.8272 0 5.7712 0.6416 5.2168 1.628C3.3104 1.3696 1.6 2.8968 1.6 4.8C1.6 4.9384 1.6096 5.0776 1.628 5.2168C0.6416 5.772 0 6.828 0 8C0 9.172 0.6416 10.228 1.628 10.7832C1.60954 10.9214 1.60019 11.0606 1.6 11.2C1.6 13.1032 3.3104 14.6264 5.2168 14.372C5.7712 15.3584 6.8272 16 8 16C9.1728 16 10.2288 15.3584 10.7832 14.372C12.6856 14.6264 14.4 13.1032 14.4 11.2C14.4 11.0616 14.3904 10.9224 14.372 10.7832C15.3584 10.228 16 9.172 16 8C16 6.828 15.3584 5.772 14.372 5.2168Z"
        fill="black"
      />
      <g clipPath="url(#clip0_1425_20240)">
        <path
          clipRule="evenodd"
          d="M8.15755 4.3999C8.35058 4.39984 8.53649 4.47274 8.67802 4.60398C8.81956 4.73523 8.90625 4.91512 8.92073 5.1076L8.92264 5.16498V10.5205H9.30518V6.88639C9.30517 6.84163 9.32085 6.79827 9.34951 6.76388C9.37817 6.72949 9.41799 6.70624 9.46202 6.69818L9.49645 6.69512H10.0703C10.2633 6.69506 10.4492 6.76796 10.5907 6.89921C10.7323 7.03045 10.819 7.21034 10.8334 7.40282L10.8354 7.4602V10.5205H11.2179C11.3154 10.5206 11.4092 10.5579 11.4801 10.6249C11.551 10.6918 11.5936 10.7833 11.5994 10.8806C11.6051 10.9779 11.5734 11.0738 11.5108 11.1486C11.4482 11.2233 11.3595 11.2714 11.2627 11.2829L11.2179 11.2856H4.33213C4.23463 11.2855 4.14085 11.2481 4.06995 11.1812C3.99905 11.1143 3.95638 11.0228 3.95067 10.9255C3.94496 10.8281 3.97662 10.7323 4.0392 10.6575C4.10178 10.5827 4.19055 10.5347 4.28737 10.5232L4.33213 10.5205H4.71467V5.16498C4.71461 4.97196 4.78751 4.78605 4.91876 4.64452C5.05 4.50298 5.2299 4.41629 5.42238 4.40182L5.47976 4.3999H8.15755ZM7.39247 8.99035H6.24484V9.75542H7.39247V8.99035ZM7.39247 7.4602H6.24484V8.22527H7.39247V7.4602ZM7.39247 5.93005H6.24484V6.69512H7.39247V5.93005Z"
          fill="white"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_1425_20240">
          <rect fill="white" height="9" transform="translate(4 4)" width="9" />
        </clipPath>
      </defs>
    </>
  ),
  viewBox: '0 0 16 16',
});
