import { Box, useMultiStyleConfig } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { useStore } from 'zustand';
import { useCardStore } from '../../../store';
import { Company } from '../../Company';
import { Department } from '../../Department';
import { Media } from '../../Media';
import { Name } from '../../Name';
import { Title } from '../../Title';
import { HeaderVariantProps } from './props';

export const Modern: FunctionComponent<HeaderVariantProps> = ({ ...props }) => {
  const cardStore = useCardStore();
  const palette = useStore(cardStore, (state) => state.themePalette);
  const styles = useMultiStyleConfig('CardHeaderModern', { palette });

  return (
    <Box __css={styles.container} as="header" className="CardHeaderModern" {...props}>
      <Box __css={styles.column}>
        <Media variant="logo" />
        <Box className="CardNameContainer">
          <Name variant="full" />
          <Name variant="maiden" />
          <Name variant="accreditations" />
        </Box>
        <Box __css={styles.details}>
          <Media variant="avatar" />
          <Title />
          <Department />
          <Company />
        </Box>
      </Box>
    </Box>
  );
};
