import { createIcon } from '@chakra-ui/react';

export const IconNote: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconNote',
  path: (
    <path
      d="M20.8889 11.5556H3.11111C2.5 11.5556 2 12.0556 2 12.6667C2 13.2778 2.5 13.7778 3.11111 13.7778H20.8889C21.5 13.7778 22 13.2778 22 12.6667C22 12.0556 21.5 11.5556 20.8889 11.5556ZM3.11111 19.3333H14.2222C14.8333 19.3333 15.3333 18.8333 15.3333 18.2222C15.3333 17.6111 14.8333 17.1111 14.2222 17.1111H3.11111C2.5 17.1111 2 17.6111 2 18.2222C2 18.8333 2.5 19.3333 3.11111 19.3333ZM20.8889 6H3.11111C2.5 6 2 6.5 2 7.11111V7.12222C2 7.73333 2.5 8.23333 3.11111 8.23333H20.8889C21.5 8.23333 22 7.73333 22 7.12222V7.11111C22 6.5 21.5 6 20.8889 6Z"
      fill="currentColor"
    />
  ),
});
