import { createIcon } from '@chakra-ui/react';

export const IconDribbble: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconDribbble',
  path: (
    <path
      d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM21.9 11.4C19.3 11 17 11 14.8 11.3C14.6 10.7 14.3 10.2 14 9.6C16.3 8.6 18.2 7.2 19.5 5.5C20.9 7.2 21.8 9.2 21.9 11.4ZM18 4.2C16.8 5.8 15.1 7 13 7.9C12 6 10.9 4.2 9.6 2.4C10.4 2.2 11.2 2.1 12 2.1C14.3 2.1 16.4 2.9 18 4.2ZM7.5 3.2C8.8 4.9 10 6.7 11 8.6C8.6 9.3 5.7 9.6 2.4 9.7C3.1 6.8 5 4.5 7.5 3.2ZM2.1 12V11.7C5.9 11.7 9.3 11.3 12 10.4C12.2 10.9 12.5 11.4 12.7 11.8C9.3 12.9 6.5 15 4.4 18.3C3 16.6 2.1 14.4 2.1 12ZM5.9 19.8C7.9 16.7 10.4 14.7 13.5 13.8C14.4 16.2 15.1 18.7 15.5 21.3C12.2 22.5 8.6 21.9 5.9 19.8ZM17.5 20.2C17.1 17.8 16.4 15.5 15.6 13.3C17.5 13 19.5 13.1 21.8 13.5C21.3 16.3 19.8 18.7 17.5 20.2Z"
      fill="currentColor"
    />
  ),
});
