import { authSignOut } from '@src/lib/api/authSignOut';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useNextParam } from './useNextParam';

export type SignOutUserSessionProps = {
  isSignUp?: boolean;
  path?: string;
};

export const useSignOutUserSession = () => {
  const router = useRouter();
  const getNextParam = useNextParam();

  const signOutUserSession = useCallback(
    async (path?: string, isSignUp: boolean = false) => {
      let next = getNextParam();
      if (path) {
        const query: Record<string, string> = {
          next: encodeURIComponent(`${path}?${next}`),
        };
        const configId = router.query.configurationId || router.query.configuration_id;
        if (configId) {
          query.configurationId = configId.toString();
        }
        next = new URLSearchParams(query).toString();
      }

      const nextUrl = next
        ? `/auth/${isSignUp ? 'signup' : 'login'}?${next}`
        : process.env.NEXT_PUBLIC_WWW_URL;
      await authSignOut({ nextUrl });
    },
    [router.query, getNextParam],
  );

  return signOutUserSession;
};
