import { Box, BoxProps } from '@chakra-ui/react';
import { Media, VideoMedia } from '@hihello/core';
import { FunctionComponent, HTMLAttributes, useEffect, useMemo, useState } from 'react';
import { useStore } from 'zustand';
import { useCardStore } from '../../store';
import { getMimeTypeByUri, MimeType } from '../../utils';
import { Image, ImageTransition } from '../Image';
import { AvatarVideo } from './AvatarVideo';

export type AvatarProps = BoxProps &
  HTMLAttributes<HTMLImageElement | HTMLVideoElement> & {
    accessibleText: string;
    avatar?: Media;
    transition?: ImageTransition;
  };

export const Avatar: FunctionComponent<AvatarProps> = ({ accessibleText, avatar, ...props }) => {
  const [mimeType, setMimeType] = useState<MimeType | undefined>();
  const cardStore = useCardStore();
  const cardVariant = useStore(cardStore, (state) => state.variant);

  useEffect(() => {
    const checkMime = async () => {
      const mime = avatar?.uri ? await getMimeTypeByUri(avatar?.uri) : undefined;
      setMimeType(mime);
    };

    checkMime();
  }, [avatar?.uri]);

  const isImage = useMemo(
    () => (mimeType ? [MimeType.JPEG, MimeType.PNG].includes(mimeType) : false),
    [mimeType],
  );

  const isVideo = useMemo(
    () => (mimeType ? [MimeType.MP4, MimeType.MOV].includes(mimeType) : false),
    [mimeType],
  );

  const imageVariant = useMemo(
    () => (cardVariant === 'mini' ? avatar?.variants?.imageSmall : avatar?.variants?.imageLarge),
    [cardVariant, avatar?.variants?.imageSmall, avatar?.variants?.imageLarge],
  );

  if (!avatar) {
    return null;
  }

  return (
    <Box className="CardAvatar" position="relative">
      {isImage && (
        <Image
          {...props}
          alt={accessibleText}
          height="full"
          objectFit="cover"
          placeholder={avatar?.variants?.imageSmall ?? avatar.uri}
          src={imageVariant ?? avatar.uri}
          width="full"
        />
      )}
      {isVideo && (
        <AvatarVideo
          {...props}
          hasAudioTrack={(avatar as VideoMedia).includesAudio}
          loop={(avatar as VideoMedia).loop}
          sources={[
            {
              src: (avatar as VideoMedia)?.variants?.videoLarge ?? avatar.uri,
              type: 'video/mp4',
            },
          ]}
        />
      )}
    </Box>
  );
};
