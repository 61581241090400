import { createIcon } from '@chakra-ui/react';

export const IconZelle: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconZelle',
  path: (
    <path
      d="M15.0965 7.73815L9.37298 7.68397V11.4763H18.8534L9 24.0926V27.3025H15.0965V31H18.7516V27.3025H24.9702C25.0244 27.1535 25.038 26.7539 24.7125 26.3883C24.1632 25.7788 23.7292 25.1422 23.3291 24.3431C23.146 23.9842 23.0103 23.5576 22.9561 23.5237C21.7287 23.4695 14.6896 23.4831 14.6964 23.4357L24.5497 10.7449V7.73815H18.7516V4H15.0965V7.73815Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 35 35',
});
