import { Thead as ChakraThead } from '@chakra-ui/react';
import type { TableHeadProps } from '@chakra-ui/react';
import { createContext, useContext, useMemo, forwardRef } from 'react';
import { useGroupToggle } from '../../../hooks';

type TheadProps = TableHeadProps & {
  initialSortColumn?: string;
  sortableColumns?: string[];
};

type SortContextType = {
  columnsSortState: Record<string, boolean>;
  toggleSortColumn: (column: string) => void;
};

export const SortContext = createContext<SortContextType | undefined>(undefined);

export const Thead = forwardRef<HTMLTableSectionElement, TheadProps>(
  ({ sortableColumns, initialSortColumn, ...tableHeadProps }, ref) => {
    const { state, toggle } = useGroupToggle(sortableColumns, initialSortColumn);

    const value = useMemo(
      () => ({ columnsSortState: state, toggleSortColumn: toggle }),
      [state, toggle],
    );

    return (
      <SortContext.Provider value={value}>
        <ChakraThead zIndex={1} {...tableHeadProps} ref={ref} />
      </SortContext.Provider>
    );
  },
);

export const useSortContext = (): SortContextType => {
  const context = useContext(SortContext);
  if (!context) {
    throw new Error('useSortContext must be used within a SortContext.Provider');
  }
  return context;
};
