import { anatomy, PartsStyleFunction, MultiStyleConfig } from '@chakra-ui/theme-tools';

const parts = anatomy('cardheader--flat').parts(
  'avatar',
  'container',
  'contents',
  'divider',
  'logo',
  'placeholder',
  'wave',
);

const baseStyle: PartsStyleFunction<typeof parts> = ({ colorMode, ...props }) => ({
  container: {
    '.CardBox, .CardBox > :not(.CardName)': {
      fontSize: '1.25rem',
      lineHeight: '2rem',
    },
    '.CardIdentity': {
      borderLeft: '1px dashed black',
      paddingLeft: '1.5rem',
    },
  },
  contents: {
    '.CardAvatar': {
      aspectRatio: '1',
    },
    bg: props.palette?.[colorMode]?.brand ?? '#8f60de',
    color: props.palette?.[colorMode]?.onBrand ?? '#ffffff',
    minH: '12rem',
    position: 'relative',
    transitionDuration: '300ms',
    transitionProperty: 'background-color',
    willChange: 'background-color',
  },
  divider: {
    color: props.palette?.[colorMode]?.brand ?? '#8f60de',
    display: 'block',
    path: {
      bg: props.palette?.[colorMode]?.onBrand ?? '#ffffff',
      fg: props.palette?.[colorMode]?.brand ?? '#8f60de',
      transitionDuration: '300ms',
      transitionProperty: 'fill',
    },
    w: 'full',
  },
  logo: {
    bottom: 0,
    img: {
      boxSizing: 'border-box',
      display: 'block',
      h: 'full',
      left: '50%',
      objectFit: 'contain',
      objectPosition: '75% 50%',
      p: '0 1rem',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      w: 'full',
      zIndex: 1,
    },
    left: '65%',
    position: 'absolute',
    right: 0,
    top: '30%',
  },
  wave: {
    bottom: '-2px',
    left: '-2px',
    position: 'absolute',
    w: 'calc(100% + 4px)',
  },
});

export const CardHeaderWave: MultiStyleConfig<typeof parts> = {
  baseStyle,
  defaultProps: {
    colorScheme: 'brand',
  },
  parts: ['avatar', 'container', 'contents', 'divider', 'logo', 'placeholder', 'wave'],
};
