import { createIcon } from '@chakra-ui/react';

export const IconCardsAnalytics: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconCardsAnalytics',
  path: (
    <g id="Icons/Navigation">
      <g id="Icon">
        <path
          d="M9 2L3 2C3 1.72386 3.22386 1.5 3.5 1.5H9.5C9.77614 1.5 10 1.72386 10 2V10C10 10.2762 9.77614 10.5 9.5 10.5V2.5C9.5 2.22386 9.27614 2 9 2Z"
          fill="currentColor"
        />
        <path
          d="M2 3V5.67647C2.33333 6.11765 3.25 7 4.25 7C4.84653 7 5.38613 6.69857 5.9503 6.38342C6.56832 6.03818 7.21584 5.67647 8 5.67647C8.4 5.67647 8.83333 5.85294 9 5.94118V3C9 2.72386 8.77614 2.5 8.5 2.5H2.5C2.22386 2.5 2 2.72386 2 3Z"
          fill="currentColor"
        />
        <path
          d="M9 11C9 11.2762 8.77614 11.5 8.5 11.5H2.5C2.22386 11.5 2 11.2762 2 11V6.17648C2.33333 6.61766 3.25 7.50001 4.25 7.50001C4.84653 7.50001 5.38613 7.19859 5.9503 6.88343C6.56832 6.53819 7.21584 6.17648 8 6.17648C8.4 6.17648 8.83333 6.35295 9 6.44119V11Z"
          fill="currentColor"
        />
      </g>
    </g>
  ),
  viewBox: '0 0 12 13',
});
