import { createIcon } from '@chakra-ui/react';

export const IconCompany: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconCompany',
  path: (
    <path
      clipRule="evenodd"
      d="M10.3501 2C10.779 1.99986 11.1922 2.16186 11.5067 2.45352C11.8212 2.74517 12.0139 3.14493 12.046 3.57265L12.0503 3.70016V15.6013H12.9004V7.52553C12.9003 7.42605 12.9352 7.32971 12.9989 7.25329C13.0626 7.17686 13.1511 7.1252 13.2489 7.10729L13.3254 7.10049H14.6006C15.0295 7.10036 15.4426 7.26235 15.7572 7.55401C16.0717 7.84566 16.2643 8.24542 16.2965 8.67315L16.3008 8.80066V15.6013H17.1508C17.3675 15.6016 17.5759 15.6845 17.7335 15.8333C17.891 15.982 17.9858 16.1853 17.9985 16.4016C18.0112 16.6179 17.9409 16.8309 17.8018 16.997C17.6627 17.1632 17.4655 17.2699 17.2503 17.2955L17.1508 17.3015H1.84915C1.63248 17.3012 1.42408 17.2183 1.26652 17.0695C1.10897 16.9208 1.01416 16.7175 1.00146 16.5012C0.988764 16.2849 1.05914 16.0719 1.19821 15.9058C1.33727 15.7396 1.53454 15.6329 1.74969 15.6073L1.84915 15.6013H2.69925V3.70016C2.69911 3.27123 2.86111 2.8581 3.15277 2.54359C3.44443 2.22907 3.84419 2.03642 4.27192 2.00425L4.39944 2H10.3501ZM8.64991 12.201H6.09962V13.9012H8.64991V12.201ZM8.64991 8.80066H6.09962V10.5008H8.64991V8.80066ZM8.64991 5.40033H6.09962V7.10049H8.64991V5.40033Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
  viewBox: '0 0 20 20',
});
