import { createIcon } from '@chakra-ui/react';

export const IconVenmo: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconVenmo',
  path: (
    <path
      d="M17.9139 4.88372C18.4302 5.73489 18.6618 6.61256 18.6618 7.72093C18.6618 11.2558 15.6446 15.8465 13.1958 19.0698H7.60416L5.3623 5.65814L10.26 5.19303L11.4507 14.7358C12.5577 12.9302 13.9265 10.093 13.9265 8.16C13.9265 7.10093 13.7451 6.38093 13.4614 5.78791L17.9139 4.88372Z"
      fill="currentColor"
    />
  ),
});
