import { createIcon } from '@chakra-ui/react';

export const IconMobile: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconMobile',
  path: (
    <path
      d="M45.2308 0H10.7692C4.82462 0 0 4.83636 0 10.7955V84.2046C0 90.1636 4.82462 95 10.7692 95H45.2308C51.1754 95 56 90.1636 56 84.2046V10.7955C56 4.83636 51.1754 0 45.2308 0ZM47.3846 77.7273H28H8.61539V11.5H47.3846V77.7273Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 56 95',
});
