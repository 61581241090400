import { Box, BoxProps, Skeleton } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { useStore } from 'zustand';
import { useCardStore } from '../../store';

export type TitleProps = BoxProps;

export const Title: FunctionComponent<TitleProps> = ({ children, ...props }) => {
  const cardStore = useCardStore();
  const { details, variant } = useStore(cardStore, (state) => ({
    details: state.details,
    variant: state.variant,
  }));

  if (details?.title === null || (!details?.title && variant === 'mini')) {
    return null;
  }

  return (
    <Box
      as="span"
      className="CardTitle"
      fontSize="1.1rem"
      fontWeight={600}
      lineHeight="1.5rem"
      sx={{ '& + &': { marginLeft: '0.5rem' } }}
      w={details?.title ? 'auto' : 'full'}
      whiteSpace="pre-wrap"
      {...props}
    >
      {details?.title ?? <Skeleton h="5" mb="3" mt="1" width="50%" />}
      {children}
    </Box>
  );
};
