import { createIcon } from '@chakra-ui/react';

export const IconPaint: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconPaint',
  path: (
    <path
      d="M12 2C17.522 2 22 5.978 22 10.889C21.9992 12.3622 21.4136 13.7748 20.3717 14.8165C19.3299 15.8581 17.9172 16.4435 16.444 16.444H14.478C13.556 16.444 12.811 17.189 12.811 18.111C12.811 18.533 12.978 18.922 13.233 19.211C13.5 19.511 13.667 19.9 13.667 20.333C13.667 21.256 12.9 22 12 22C6.478 22 2 17.522 2 12C2 6.478 6.478 2 12 2ZM10.811 18.111C10.8106 17.6293 10.9052 17.1523 11.0893 16.7072C11.2735 16.2622 11.5436 15.8578 11.8842 15.5172C12.2248 15.1766 12.6292 14.9065 13.0742 14.7223C13.5193 14.5382 13.9963 14.4436 14.478 14.444H16.444C17.3866 14.4435 18.2905 14.0689 18.9572 13.4026C19.6239 12.7363 19.9989 11.8326 20 10.89C20 7.139 16.468 4 12 4C9.93558 3.99812 7.95034 4.79436 6.45938 6.22225C4.96841 7.65014 4.08715 9.59913 3.99986 11.6617C3.91256 13.7243 4.62599 15.7408 5.99097 17.2895C7.35595 18.8383 9.2668 19.7994 11.324 19.972C10.9892 19.4093 10.812 18.7668 10.811 18.112V18.111ZM7.5 12C7.10218 12 6.72064 11.842 6.43934 11.5607C6.15804 11.2794 6 10.8978 6 10.5C6 10.1022 6.15804 9.72064 6.43934 9.43934C6.72064 9.15804 7.10218 9 7.5 9C7.89782 9 8.27936 9.15804 8.56066 9.43934C8.84196 9.72064 9 10.1022 9 10.5C9 10.8978 8.84196 11.2794 8.56066 11.5607C8.27936 11.842 7.89782 12 7.5 12ZM16.5 12C16.1022 12 15.7206 11.842 15.4393 11.5607C15.158 11.2794 15 10.8978 15 10.5C15 10.1022 15.158 9.72064 15.4393 9.43934C15.7206 9.15804 16.1022 9 16.5 9C16.8978 9 17.2794 9.15804 17.5607 9.43934C17.842 9.72064 18 10.1022 18 10.5C18 10.8978 17.842 11.2794 17.5607 11.5607C17.2794 11.842 16.8978 12 16.5 12ZM12 9C11.6022 9 11.2206 8.84196 10.9393 8.56066C10.658 8.27936 10.5 7.89782 10.5 7.5C10.5 7.10218 10.658 6.72064 10.9393 6.43934C11.2206 6.15804 11.6022 6 12 6C12.3978 6 12.7794 6.15804 13.0607 6.43934C13.342 6.72064 13.5 7.10218 13.5 7.5C13.5 7.89782 13.342 8.27936 13.0607 8.56066C12.7794 8.84196 12.3978 9 12 9Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
});
