import { createIcon } from '@chakra-ui/react';

export const IconDiamond: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconDiamond',
  path: (
    <path
      d="M2.50019 9.66115e-07C2.40741 4.07945e-06 2.31646 0.0258214 2.23752 0.0745645C2.15858 0.123308 2.09475 0.193054 2.05319 0.276001L0.0531865 4.276C0.00993341 4.36212 -0.00750909 4.45891 0.00295826 4.55471C0.0134256 4.65052 0.0513568 4.74125 0.112187 4.816L6.61219 12.816C6.65907 12.8737 6.71822 12.9202 6.78534 12.9521C6.85246 12.9841 6.92586 13.0006 7.00019 13.0006C7.07452 13.0006 7.14791 12.9841 7.21503 12.9521C7.28215 12.9202 7.3413 12.8737 7.38819 12.816L13.8882 4.816C13.9492 4.74135 13.9873 4.65067 13.9979 4.55486C14.0086 4.45906 13.9913 4.36222 13.9482 4.276L11.9482 0.276001C11.9065 0.192902 11.8426 0.123055 11.7634 0.0743015C11.6843 0.0255481 11.5931 -0.000181722 11.5002 9.66115e-07H2.50019ZM1.30919 4L2.80919 1H4.85919L4.10919 4H1.30919ZM1.55019 5H4.13319L5.74619 10.164L1.55019 5ZM5.18019 5H8.82019L7.00019 10.823L5.18019 5ZM9.86819 5H12.4502L8.25419 10.164L9.86819 5ZM12.6912 4H9.89119L9.14119 1H11.1912L12.6912 4ZM8.86019 4H5.14019L5.89019 1H8.11019L8.86019 4Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 14 13',
});
