import { useQuery, UseQueryOptions } from 'react-query';
import { fetcher } from './fetcher';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  Boolean: boolean;
  Float: number;
  ID: string;
  Int: number;
  String: string;
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any;
};

/** Account model. */
export type Account = {
  __typename?: 'Account';
  color?: Maybe<Palette>;
  emails?: Maybe<Array<Scalars['String']>>;
  features?: Maybe<AccountFeatures>;
  id: Scalars['String'];
  name?: Maybe<Name>;
  notificationSettings?: Maybe<AccountNotificationSettings>;
  photo?: Maybe<Scalars['String']>;
  plan?: Maybe<Plan>;
  stripe?: Maybe<Stripe>;
  subscriptionStatus?: Maybe<Scalars['String']>;
  type?: Maybe<Type>;
};

/** Account Features model. */
export type AccountFeatures = {
  __typename?: 'AccountFeatures';
  analytics: Scalars['Boolean'];
  badges: Scalars['Boolean'];
  cardScanning: Scalars['Boolean'];
  cardSharing: Scalars['Boolean'];
  customColors: Scalars['Boolean'];
  customDesign: Scalars['Boolean'];
  customUrl: Scalars['Boolean'];
  fileAttachments: Scalars['Boolean'];
  googleSync: Scalars['Boolean'];
  hihelloBranding: Scalars['Boolean'];
  liveAvatars: Scalars['Boolean'];
  maxCards: Scalars['Float'];
  maxEmailShares: Scalars['Float'];
  maxScans: Scalars['Float'];
  maxSmsShares: Scalars['Float'];
  notes: Scalars['Boolean'];
  qrLogo: Scalars['Boolean'];
  smsViaHihello: Scalars['Boolean'];
  tags: Scalars['Boolean'];
  widgets: Scalars['Boolean'];
  zohoSync: Scalars['Boolean'];
};

/** Account Notification Settings model. */
export type AccountNotificationSettings = {
  __typename?: 'AccountNotificationSettings';
  email: EmailNotificationSettings;
};

/** Advanced External Directory model. */
export type AdvancedDirectory = {
  __typename?: 'AdvancedDirectory';
  /**
   * Groups that are currently syncing from the external directory. Note that clients should
   * only be querying this field if absolutely necessary, since including this field will greatly slow down the
   * query. This is because it fetches all external directory groups and counts of their members, and requires
   * querying external providers (Entra, Google) to do so.
   */
  groups: Array<ExternalDirectoryGroup>;
  /** The connection to the external directory used for single sign-on */
  saml?: Maybe<AdvancedDirectoryConnection>;
  /** The connection to the external directory used for user provisioning */
  scim?: Maybe<AdvancedDirectoryConnection>;
};

/** Describes a connection to an advanced external directory */
export type AdvancedDirectoryConnection = {
  __typename?: 'AdvancedDirectoryConnection';
  /** The current status of the connection */
  status: DirectoryConnectionStatus;
  /** The type of external directory described by this connection */
  type: WorkOsProvider;
};

/** Aggregation type for card analytics */
export enum AggregateType {
  Card = 'card',
  Organization = 'organization',
  Template = 'template',
}

export type AnalyticEvent = {
  cardSaveEventData?: InputMaybe<CardSaveEventDataInput>;
  channel?: InputMaybe<AnalyticEventChannel>;
  contactSaveEventData?: InputMaybe<ContactSaveEventDataInput>;
  eventType: AnalyticEventType;
  fieldSelectEventData?: InputMaybe<FieldSelectEventDataInput>;
  object: AnalyticEventObject;
  sharerId?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<AnalyticEventSubject>;
  time: Scalars['Timestamp'];
};

export enum AnalyticEventChannel {
  AppleWallet = 'APPLE_WALLET',
  EmailSignature = 'EMAIL_SIGNATURE',
  VirtualBackground = 'VIRTUAL_BACKGROUND',
  Widget = 'WIDGET',
}

export type AnalyticEventObject = {
  id: Scalars['String'];
  type: AnalyticEventObjectType;
};

export enum AnalyticEventObjectType {
  Card = 'CARD',
}

export type AnalyticEventSubject = {
  id: Scalars['String'];
  type: AnalyticEventSubjectType;
};

export enum AnalyticEventSubjectType {
  Organization = 'ORGANIZATION',
  User = 'USER',
}

export enum AnalyticEventType {
  CardSave = 'CARD_SAVE',
  CardView = 'CARD_VIEW',
  ContactSave = 'CONTACT_SAVE',
  FieldSelect = 'FIELD_SELECT',
}

/** Mode (aggregate vs compare) for card analytics */
export enum AnalyticMode {
  Aggregate = 'aggregate',
  Compare = 'compare',
}

/** AuditLog model. */
export type AuditLog = {
  __typename?: 'AuditLog';
  event?: Maybe<EventTypes>;
  id: Scalars['ID'];
  performedAt: Scalars['Float'];
  target?: Maybe<User>;
  user?: Maybe<User>;
};

export enum AuthenticationProvider {
  Apple = 'APPLE',
  EmailAndPassword = 'EMAIL_AND_PASSWORD',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
  Microsoft = 'MICROSOFT',
  Okta = 'OKTA',
  Workos = 'WORKOS',
}

export type AutomatedEmailSignatureConfigurationGraph = {
  __typename?: 'AutomatedEmailSignatureConfigurationGraph';
  /** Disclaimer to be appended to the email signature */
  disclaimer?: Maybe<AutomatedEmailSignatureDisclaimer>;
  emailClientType?: Maybe<EmailClients>;
  gmailSetting?: Maybe<AutomatedEmailSignatureGmailSettings>;
  setupCompleted: Scalars['Boolean'];
};

export type AutomatedEmailSignatureDisclaimer = {
  __typename?: 'AutomatedEmailSignatureDisclaimer';
  /**
   * The content of the disclaimer. This could be HTML or plain text, as whatever
   * it is will be inserted directly into the email signature as HTML.
   */
  content: Scalars['String'];
  /**
   * Whether or not to include a divider between the disclaimer and the rest
   * of the email signature.
   */
  divider: Scalars['Boolean'];
};

export type AutomatedEmailSignatureDisclaimerInput = {
  /**
   * The content of the disclaimer. This could be HTML or plain text, as whatever
   * it is will be inserted directly into the email signature as HTML.
   */
  content: Scalars['String'];
  /**
   * Whether or not to include a divider between the disclaimer and the rest
   * of the email signature.
   */
  divider: Scalars['Boolean'];
};

export type AutomatedEmailSignatureGmailSettings = {
  __typename?: 'AutomatedEmailSignatureGmailSettings';
  googleWorkspaceAdminEmail: Array<Scalars['String']>;
  magicLink?: Maybe<Scalars['String']>;
  magicLinkValidity?: Maybe<Scalars['Boolean']>;
};

/** Automated Email Signature Failure reasons */
export enum AutomatedEmailSignatureJobFailureReason {
  AccountNotOrganization = 'ACCOUNT_NOT_ORGANIZATION',
  AdminInvalid = 'ADMIN_INVALID',
  AdminNotFound = 'ADMIN_NOT_FOUND',
  AppNotInstalled = 'APP_NOT_INSTALLED',
  CardNotOwned = 'CARD_NOT_OWNED',
  CardNotOwnedOrganization = 'CARD_NOT_OWNED_ORGANIZATION',
  CardNotValid = 'CARD_NOT_VALID',
  ClientNotFound = 'CLIENT_NOT_FOUND',
  DomainNotOwned = 'DOMAIN_NOT_OWNED',
  EmailAlias = 'EMAIL_ALIAS',
  EmailNotVerified = 'EMAIL_NOT_VERIFIED',
  FailureAuthenticate = 'FAILURE_AUTHENTICATE',
  MemberNotInOrganization = 'MEMBER_NOT_IN_ORGANIZATION',
  NotSso = 'NOT_SSO',
  NotValidEmail = 'NOT_VALID_EMAIL',
  Others = 'OTHERS',
  UnableGetDomains = 'UNABLE_GET_DOMAINS',
}

export type AutomatedEmailSignatureLayoutMetric = {
  IMAGELOGO: Scalars['Int'];
  LOGO: Scalars['Int'];
  QRCODE: Scalars['Int'];
  SQUARE: Scalars['Int'];
  TEXT: Scalars['Int'];
  __typename?: 'AutomatedEmailSignatureLayoutMetric';
};

export type AutomatedEmailSignaturePaginatedResponse = {
  __typename?: 'AutomatedEmailSignaturePaginatedResponse';
  automatedEmailSignatures: Array<AutomatedEmailSignatureUser>;
  totalCount: Scalars['Int'];
};

export type AutomatedEmailSignatureSearchInput = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirections>;
  sortField?: InputMaybe<AutomatedEmailSignatureSortField>;
};

export type AutomatedEmailSignatureSearchOutput = {
  __typename?: 'AutomatedEmailSignatureSearchOutput';
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  results: Array<AutomatedEmailSignatureUser>;
  totalFound: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export enum AutomatedEmailSignatureSetupState {
  Completed = 'COMPLETED',
  Started = 'STARTED',
  Suspended = 'SUSPENDED',
}

/** Fields that can be used to sort email signatures lists. */
export enum AutomatedEmailSignatureSortField {
  AccountName = 'ACCOUNT_NAME',
  Email = 'EMAIL',
  JobStatus = 'JOB_STATUS',
  SignatureType = 'SIGNATURE_TYPE',
}

export type AutomatedEmailSignatureSubscriptionData = {
  __typename?: 'AutomatedEmailSignatureSubscriptionData';
  jobStatus: JobStatus;
  syncMessage?: Maybe<AutomatedEmailSignatureJobFailureReason>;
  userAccountId: Scalars['String'];
};

export type AutomatedEmailSignatureUser = {
  __typename?: 'AutomatedEmailSignatureUser';
  cards?: Maybe<Array<Card>>;
  defaultCard?: Maybe<Card>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  photo?: Maybe<ImageMedia>;
  status?: Maybe<AutomatedEmailSignaturesJob>;
};

/** AutomatedEmailSignatureJob model. */
export type AutomatedEmailSignaturesJob = {
  __typename?: 'AutomatedEmailSignaturesJob';
  accountId: Scalars['String'];
  card?: Maybe<Card>;
  emailAddress: Scalars['String'];
  emailClient?: Maybe<EmailClients>;
  emailSignatureType?: Maybe<EmailSignatureSupported>;
  exportedEmailSignatureUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  identityId?: Maybe<Scalars['String']>;
  jobStatus?: Maybe<JobStatus>;
  runId: Scalars['String'];
  syncMessage?: Maybe<AutomatedEmailSignatureJobFailureReason>;
  syncTime: Scalars['Float'];
};

export type AutomatedEmailSignaturesJobInput = {
  cardId: Scalars['String'];
  emailSignatureType?: InputMaybe<EmailSignatureSupported>;
  userId: Scalars['String'];
};

export type AutomatedEmailSignaturesUsage = {
  __typename?: 'AutomatedEmailSignaturesUsage';
  emailClientType?: Maybe<EmailClients>;
  failureCount?: Maybe<Scalars['Int']>;
  incompleteCount?: Maybe<Scalars['Int']>;
  layout?: Maybe<AutomatedEmailSignatureLayoutMetric>;
  organization?: Maybe<Organization>;
  state: AutomatedEmailSignatureSetupState;
  successCount?: Maybe<Scalars['Int']>;
};

export type AvatarRecoveryData = {
  __typename?: 'AvatarRecoveryData';
  type: RecoveryType;
  value: Array<Media>;
};

export type BadgeRecoveryData = {
  __typename?: 'BadgeRecoveryData';
  type: RecoveryType;
  value: Array<ImageMedia>;
};

/** Base Field model. */
export type BaseField = {
  __typename?: 'BaseField';
  label?: Maybe<Scalars['String']>;
  templateFieldId?: Maybe<Scalars['ID']>;
  text: Scalars['String'];
  type: FieldType;
};

/** Base Field model. */
export type BaseFieldInput = {
  e164?: InputMaybe<Scalars['String']>;
  extension?: InputMaybe<Scalars['String']>;
  isSmsOnly?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  templateFieldId?: InputMaybe<Scalars['ID']>;
  text?: InputMaybe<Scalars['String']>;
  type: FieldType;
  uri?: InputMaybe<Scalars['String']>;
  widget?: InputMaybe<WidgetInput>;
};

/** Boolean flag value type */
export type BooleanFlagValue = {
  __typename?: 'BooleanFlagValue';
  flagValue: Scalars['Boolean'];
  type: FeatureFlagTypes;
};

/** Card model. */
export type Card = {
  __typename?: 'Card';
  /**
   * This will be used by the client to identify which flow to show in the A-B testing for closing the loop
   * @deprecated use closingLoopVariantValue instead
   */
  closingLoopVariant: FeatureFlagVariationsV2;
  /** This will be used by the client to identify which flow to show for this card for closing the loop */
  closingLoopVariantValue: Scalars['String'];
  compliesWithTemplate?: Maybe<Scalars['Boolean']>;
  /**
   * Configured by the card owner to customize the closing the loop flow when potential connections
   * view and interact with this card.
   */
  contactExchangeConfig?: Maybe<ContactExchangeConfig>;
  contactExternalSyncStatus?: Maybe<ContactExternalSyncStatus>;
  createdAt: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  design?: Maybe<Design>;
  details: CardDetails;
  /** Disabled indicates that the card or the owner account has been disabled. This is typically because the card and account are linked to a user in an external directory that is no longer being synced into HiHello. */
  disabled: Scalars['Boolean'];
  /** External card details has the same shape as details but hold the values that are synced from an external directory */
  externalCardDetails?: Maybe<CardDetails>;
  /**
   * Indicates that this card was automatically created for a user synced via an external directory - this field being true does not necessarily mean that the card is actively being synced.
   * If a user is removed from an external directory or the external directory is disconnected, the card will be disabled.
   */
  externallySynced?: Maybe<Scalars['Boolean']>;
  /** Flag to indicate if the card has unsynced fields */
  hasUnsyncedField?: Maybe<Scalars['Boolean']>;
  hihelloBranding?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** Existing active invitations to claim the card. */
  invitations?: Maybe<Array<OrganizationCardInvitation>>;
  isMyCard: Scalars['Boolean'];
  /**
   * Custom lead capture consent agreement, if the owner of the card has enabled and configured one.
   * If this property exists, the consent agreement must be acknowledged before a lead (aka card recipient)
   * is captured as a HiHello contact.
   * @deprecated Use ContactExchangeConfig > consentAgreement/requireCustomConsentAgreement instead
   */
  leadCaptureConsentAgreement?: Maybe<LeadCaptureConsentAgreement>;
  name: Scalars['String'];
  order: Scalars['Int'];
  owner?: Maybe<Entity>;
  /** Paused indicates that the owner or an editor of the card has paused the card. */
  paused: Scalars['Boolean'];
  /** Public is a computed field that indicates if the card can be viewed by the public. This is false if either the card is paused or disabled, or if the owner account is disabled. */
  public: Scalars['Boolean'];
  recoveryData?: Maybe<CardRecoveryData>;
  template?: Maybe<Template>;
  theme: CardTheme;
  trackingCodes: Array<TrackingCode>;
  updatedAt: Scalars['Float'];
  url?: Maybe<Url>;
  users?: Maybe<Array<UserCardAccess>>;
};

export type CardAddInput = {
  description?: InputMaybe<Scalars['String']>;
  designId?: InputMaybe<Scalars['String']>;
  details: CardDetailsInput;
  name: Scalars['String'];
  public?: InputMaybe<Scalars['Boolean']>;
  templateId?: InputMaybe<Scalars['String']>;
  theme: CardThemeInput;
  trackingCodes?: InputMaybe<Array<TrackingCodeInput>>;
};

export type CardAnalyticsData = {
  __typename?: 'CardAnalyticsData';
  cardsCount: Scalars['Int'];
  data: Array<Maybe<CardAnalyticsDataGroup>>;
  totalAdds: Scalars['Int'];
  totalSaves: Scalars['Int'];
  totalViews: Scalars['Int'];
};

export type CardAnalyticsDataGroup = {
  __typename?: 'CardAnalyticsDataGroup';
  cardId?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['String']>;
  timeSeries: Array<CardAnalyticsDataPoint>;
};

export type CardAnalyticsDataPoint = {
  __typename?: 'CardAnalyticsDataPoint';
  cardSaves: Scalars['Int'];
  cardViews: Scalars['Int'];
  contactAdds: Scalars['Int'];
  date: Scalars['String'];
};

export type CardAnalyticsFilter = {
  cardId?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['String']>;
};

export type CardAndTemplatesSearchInput = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  searchTerm: Scalars['String'];
};

export type CardAndTemplatesSearchOutput = {
  __typename?: 'CardAndTemplatesSearchOutput';
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  results: Array<CardOrTemplate>;
  totalPages: Scalars['Int'];
};

/** Card Detail models. */
export type CardDetails = {
  __typename?: 'CardDetails';
  avatars: Array<Media>;
  badges: Array<ImageMedia>;
  businessCard?: Maybe<ImageMedia>;
  company?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  fields: Array<Fields>;
  headline?: Maybe<Scalars['String']>;
  logo?: Maybe<ImageMedia>;
  name?: Maybe<Name>;
  title?: Maybe<Scalars['String']>;
};

export type CardDetailsInput = {
  avatars?: InputMaybe<Array<MediaInput>>;
  badges?: InputMaybe<Array<MediaInput>>;
  businessCard?: InputMaybe<MediaInput>;
  company?: InputMaybe<Scalars['String']>;
  department?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<Array<BaseFieldInput>>;
  headline?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<MediaInput>;
  name: NameInput;
  title?: InputMaybe<Scalars['String']>;
};

/**
 * Fields that can be used to filter the cards search.
 * Template ID - filter by template id
 * Status - filter by status (values: ACTIVE, PAUSED)
 */
export enum CardFilterField {
  /** @deprecated Use STATUS */
  State = 'STATE',
  Status = 'STATUS',
  TemplateId = 'TEMPLATE_ID',
}

export type CardFilterFieldInput = {
  field: CardFilterField;
  value?: InputMaybe<Scalars['String']>;
};

/** An invitation to claim a card, from the context of an organization. */
export type CardInvitation = {
  __typename?: 'CardInvitation';
  /** If true the person claiming the card will be able to edit its contents, in accordance with template rules if applicable. */
  canEdit: Scalars['Boolean'];
  /** The card to which this invitation applies. */
  card: Card;
};

/** An invitation to claim a card, from the context of an organization. */
export type CardInvitationInput = {
  /** If true the person claiming the card will be able to edit its contents, in accordance with template rules if applicable. */
  canEdit: Scalars['Boolean'];
  /** The id of the card. */
  cardId: Scalars['String'];
};

export type CardOrTemplate = Card | Template;

/** Inputs to modify a card's permissions */
export type CardPermissionsUpdateInput = {
  /** The id of the card to modify */
  id: Scalars['ID'];
  /**
   * Pending invitations to claim the card. Removing an entry from
   * this list will delete that invitation.
   */
  invitations?: InputMaybe<Array<OrganizationCardInvitationInput>>;
  /**
   * Users who have access to the card. Removing an entry from this
   * list will remove that user's access to the card.
   */
  users?: InputMaybe<Array<UserCardAccessInput>>;
};

/** Card Restore Resync Model */
export type CardRecoveryData = {
  __typename?: 'CardRecoveryData';
  avatars?: Maybe<AvatarRecoveryData>;
  badges?: Maybe<BadgeRecoveryData>;
  color?: Maybe<ColorRecoveryData>;
  company?: Maybe<StringRecoveryData>;
  department?: Maybe<StringRecoveryData>;
  design?: Maybe<DesignRecoveryData>;
  fields?: Maybe<Array<FieldRecoveryData>>;
  headline?: Maybe<StringRecoveryData>;
  logo?: Maybe<LogoRecoveryData>;
  name?: Maybe<NameRecoveryData>;
  title?: Maybe<StringRecoveryData>;
};

export type CardSaveEventDataInput = {
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  vcf?: InputMaybe<Scalars['Boolean']>;
};

/** Card Scan Account Status model. */
export type CardScanAccountStatus = {
  __typename?: 'CardScanAccountStatus';
  maxScans: Scalars['Int'];
  remainingScans: Scalars['Int'];
  scans: Array<CardScanUser>;
  timestampRange?: Maybe<Scalars['Timestamp']>;
};

/** Card Scan Job model. */
export type CardScanJob = {
  __typename?: 'CardScanJob';
  account: Account;
  completedAt?: Maybe<Scalars['Timestamp']>;
  createdAt: Scalars['Timestamp'];
  errorReason?: Maybe<JobErrorReason>;
  id: Scalars['ID'];
  ocrSuggestion?: Maybe<CardDetails>;
  reviewerAccount?: Maybe<Account>;
  reviewerSuggestion?: Maybe<CardDetails>;
  state: JobState;
  storageUri: Scalars['String'];
  submittedAt?: Maybe<Scalars['Timestamp']>;
  taskId?: Maybe<Scalars['String']>;
  type: JobType;
};

/** Card Scan Job model. */
export type CardScanJobAnalytics = {
  __typename?: 'CardScanJobAnalytics';
  completedNum: Scalars['Float'];
  endTime: Scalars['Float'];
  rejectedNum: Scalars['Float'];
  reviewer?: Maybe<User>;
  startTime: Scalars['Float'];
  totalNum: Scalars['Float'];
};

export type CardScanJobCreateInput = {
  associatedIdentityId?: InputMaybe<Scalars['String']>;
  privateNote?: InputMaybe<Scalars['String']>;
  sendCardBack?: InputMaybe<Scalars['Boolean']>;
  storageUri: Scalars['String'];
};

export type CardScanJobInput = {
  errorReason?: InputMaybe<JobErrorReason>;
  id: Scalars['ID'];
  reviewerSuggestion: CardDetailsInput;
  state: JobState;
  type: JobType;
};

export type CardScanJobSubscriptionData = {
  __typename?: 'CardScanJobSubscriptionData';
  id: Scalars['ID'];
  state: JobState;
};

/** Card Scan User Output model. */
export type CardScanUser = {
  __typename?: 'CardScanUser';
  completedAt?: Maybe<Scalars['Timestamp']>;
  contact?: Maybe<Contact>;
  createdAt: Scalars['Timestamp'];
  errorReason?: Maybe<JobErrorReason>;
  id: Scalars['ID'];
  state: JobState;
  storageUri: Scalars['String'];
};

/** Fields that can be used to search the cards by. */
export enum CardSearchField {
  CardDataGivenFamilyName = 'CARD_DATA_GIVEN_FAMILY_NAME',
  CardDataTitle = 'CARD_DATA_TITLE',
  CardName = 'CARD_NAME',
  /** @deprecated Use CARD_NAME */
  Name = 'NAME',
  TemplateName = 'TEMPLATE_NAME',
}

export type CardSettingsUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  public?: InputMaybe<Scalars['Boolean']>;
  trackingCodes?: InputMaybe<Array<TrackingCodeInput>>;
};

/** Fields that can be used to sort the cards search results. Note: this is used by the v2 getCards query. The v1 getCards query uses CardSortFields. */
export enum CardSortField {
  CardDataGivenFamilyName = 'CARD_DATA_GIVEN_FAMILY_NAME',
  CardDataTitle = 'CARD_DATA_TITLE',
  CardName = 'CARD_NAME',
  CreatedAt = 'CREATED_AT',
  /** @deprecated Use CARD_NAME */
  Name = 'NAME',
  Status = 'STATUS',
  TemplateName = 'TEMPLATE_NAME',
  UpdatedAt = 'UPDATED_AT',
}

/** Fields that can be used to sort card lists. Note: this is used by the v1 getCards query. The v2 getCards query uses CardSortField. */
export enum CardSortFields {
  CardName = 'CARD_NAME',
  FamilyName = 'FAMILY_NAME',
  Title = 'TITLE',
}

/** Profile theme model. */
export type CardTheme = {
  __typename?: 'CardTheme';
  favicon?: Maybe<ImageMedia>;
  palette: Palette;
  qrLogos: Array<QrLogoMedia>;
};

export type CardThemeInput = {
  palette?: InputMaybe<PaletteInput>;
  qrLogos?: InputMaybe<Array<MediaInput>>;
};

/** Input for transferring ownership of a card to another user. */
export type CardTransferInput = {
  /**
   * ID of the card to transfer. This must be an individual-owned card and the person making
   * the request must be the owner.
   */
  cardId: Scalars['String'];
  /**
   * If provided, an email will be sent to this address to allow the other party to take
   * ownership of the card.  You must provide either email or phone and cannot provide both.
   */
  email?: InputMaybe<Scalars['String']>;
  /**
   * If provided, an SMS/MMS/WhatsApp message will be sent to this number to allow the other
   * party to take ownership of the card.  You must provide either email or phone and cannot
   * provide both.
   */
  phone?: InputMaybe<Scalars['String']>;
  /**
   * If true, any contacts that are associated with the transferred card will be removed from
   * the account that currently owns the card and added to the account that takes ownership of
   * the card, at the point when the receiving account takes ownership.  They will still be
   * associated with the card in that case.  If false, the contacts will be disassociated with
   * the card when it changes ownership but will remaining in the originating card owner's
   * account.
   */
  transferContacts: Scalars['Boolean'];
};

export type CardUpdateInput = {
  customUrl?: InputMaybe<CustomUrlInput>;
  description?: InputMaybe<Scalars['String']>;
  designId?: InputMaybe<Scalars['String']>;
  details?: InputMaybe<CardDetailsInput>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  public?: InputMaybe<Scalars['Boolean']>;
  templateId?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<CardThemeInput>;
  trackingCodes?: InputMaybe<Array<TrackingCodeInput>>;
};

export type CardsFromCsvInput = {
  csvUri: Scalars['String'];
  templateId: Scalars['String'];
};

export type CardsFromCsvOutput = {
  __typename?: 'CardsFromCsvOutput';
  attempts: Scalars['Int'];
  cards: Array<Card>;
  errors: Array<Scalars['String']>;
  successes: Scalars['Int'];
};

export type CardsFromTemplateInput = {
  templateId: Scalars['String'];
  users: Array<NameAndEmailInput>;
};

/** If a contactSyncConnectionId is included, the returned cards will include data about the contact syncing settings for each returned card to the provided ContactSyncConnection. */
export type CardsSearchInput = {
  contactSyncConnectionId?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<CardFilterFieldInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<CardSearchField>>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirections>;
  sortField?: InputMaybe<CardSortField>;
};

export type CardsSearchOutput = {
  __typename?: 'CardsSearchOutput';
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  results: Array<Card>;
  totalFound: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** Assigned claims */
export enum Claims {
  AnalyticsViewer = 'ANALYTICS_VIEWER',
  AuditlogViewer = 'AUDITLOG_VIEWER',
  CardMover = 'CARD_MOVER',
  CardPublicIdUpdater = 'CARD_PUBLIC_ID_UPDATER',
  CardScanAnalyticsViewer = 'CARD_SCAN_ANALYTICS_VIEWER',
  CardscanReviewer = 'CARDSCAN_REVIEWER',
  CardscanViewer = 'CARDSCAN_VIEWER',
  EmailSender = 'EMAIL_SENDER',
  Employee = 'EMPLOYEE',
  FeatureFlagCreator = 'FEATURE_FLAG_CREATOR',
  MarketingReportProcessor = 'MARKETING_REPORT_PROCESSOR',
  OrgAnalyticsSender = 'ORG_ANALYTICS_SENDER',
  OrgOnboarder = 'ORG_ONBOARDER',
  OrgUpdater = 'ORG_UPDATER',
  SuccessDashboardViewer = 'SUCCESS_DASHBOARD_VIEWER',
  ToolUser = 'TOOL_USER',
  UserDeleter = 'USER_DELETER',
  UserImpersonator = 'USER_IMPERSONATOR',
  UserUpdater = 'USER_UPDATER',
  UserViewer = 'USER_VIEWER',
  WorkosInitializer = 'WORKOS_INITIALIZER',
}

/** Input for creating a static contact by closing the loop on a contact. */
export type CloseContactLoopInput = {
  /** Channel the non-HiHello user received the card through. */
  channel?: InputMaybe<AnalyticEventChannel>;
  /**
   * The id of the consent form that was checked in order to capture this contact, if any.
   * Corresponds to the LeadCaptureConsentAgreement object defined by the owner of the card being shared.
   */
  consentFormId?: InputMaybe<Scalars['ID']>;
  /**
   * Message the non-HiHello user wants to send back to the owner of the card
   * when connecting.
   */
  message?: InputMaybe<Scalars['String']>;
  /** Public ID of the card a non-HiHello user received. */
  publicId: Scalars['String'];
};

export type CloseContactLoopOutput = {
  __typename?: 'CloseContactLoopOutput';
  exchangeTokenId: Scalars['ID'];
  url: Scalars['String'];
};

/** Color model. */
export type Color = {
  __typename?: 'Color';
  accent: Scalars['String'];
  brand: Scalars['String'];
  onAccent: Scalars['String'];
  onBrand: Scalars['String'];
};

/** Color input */
export type ColorInput = {
  accent: Scalars['String'];
  brand: Scalars['String'];
  onAccent: Scalars['String'];
  onBrand: Scalars['String'];
};

export type ColorRecoveryData = {
  __typename?: 'ColorRecoveryData';
  type: RecoveryType;
  value: Palette;
};

/** Contact model. */
export type Contact = {
  __typename?: 'Contact';
  addedAt: Scalars['Timestamp'];
  contactCards: Array<ContactCard>;
  id: Scalars['ID'];
  notes: Array<ContactNote>;
  updatedAt: Scalars['Timestamp'];
};

/**
 * Input for creating a static contact. Takes in the details of the contact and the method of adding the contact.
 *
 * Only one of closeLoopInput or manualInput should be specified.
 */
export type ContactAddInput = {
  closeLoopInput?: InputMaybe<CloseContactLoopInput>;
  details: CardDetailsInput;
  manualInput?: InputMaybe<ManualContactInput>;
};

/**
 * ContactCard model. Basically a wrapper around the Card model, with extra contacts specific
 * fields (e.g. tags, associatedCard, addedAt)
 */
export type ContactCard = {
  __typename?: 'ContactCard';
  addedAt: Scalars['Timestamp'];
  associatedCard?: Maybe<Card>;
  canUpdateAssociatedCard: Scalars['Boolean'];
  design?: Maybe<Design>;
  details: CardDetails;
  isLiveContact: Scalars['Boolean'];
  /**
   * Returns true if this is a live contact, and the owner of the live contact card is a HiHello organization.
   * We use this logic as a heuristic to "verify" the company listed on the contact.
   * Note: this is not a guarantee that the company is verified, just that they are an organization that uses HiHello.
   */
  isPartOfVerifiedCompany: Scalars['Boolean'];
  source: ContactSource;
  tags: Array<Tag>;
  theme: CardTheme;
};

/**
 * Contact exchange configuration. Currently configurable on a per-organization account basis, though we envision
 * expanding it to be on a per-template and/or per-card basis as well.
 */
export type ContactExchangeConfig = {
  __typename?: 'ContactExchangeConfig';
  /** Whether to allow recipients of cards to send their information back. */
  allowInformationExchange: Scalars['Boolean'];
  /** Custom lead capture consent agreement. */
  consentAgreement?: Maybe<LeadCaptureConsentAgreement>;
  /** The contact information option recipients have for connecting with cards. */
  exchangeOption: ContactExchangeOption;
  id: Scalars['ID'];
  /**
   * Whether the custom consent agreement must be acknowledged when a lead is captured (via a new contact)
   * through card sharing done by members of the organization.
   */
  requireCustomConsentAgreement: Scalars['Boolean'];
  /** Whether to show the card first in the contact exchange. */
  showCardFirst: Scalars['Boolean'];
};

/** Contact exchange configuration add input. */
export type ContactExchangeConfigAddInput = {
  /** Whether to allow recipients of cards to send their information back. */
  allowInformationExchange: Scalars['Boolean'];
  /** Custom lead capture consent agreement. */
  consentAgreement?: InputMaybe<LeadCaptureConsentAgreementInput>;
  /** The contact information option recipients have for connecting with cards. */
  exchangeOption: ContactExchangeOption;
  /**
   * Whether the custom consent agreement must be acknowledged when a lead is captured (via a new contact)
   * through card sharing done by members of the organization.
   */
  requireCustomConsentAgreement: Scalars['Boolean'];
  /** Whether to show the card first in the contact exchange. */
  showCardFirst: Scalars['Boolean'];
};

/** Contact exchange configuration update input. */
export type ContactExchangeConfigUpdateInput = {
  /** Whether to allow recipients of cards to send their information back. */
  allowInformationExchange?: InputMaybe<Scalars['Boolean']>;
  /** Custom lead capture consent agreement. Will be deleted if this is null. */
  consentAgreement?: InputMaybe<LeadCaptureConsentAgreementInput>;
  /** The contact information option recipients have for connecting with cards. */
  exchangeOption?: InputMaybe<ContactExchangeOption>;
  id: Scalars['ID'];
  /**
   * Whether the custom consent agreement must be acknowledged when a lead is captured (via a new contact)
   * through card sharing done by members of the organization.
   */
  requireCustomConsentAgreement?: InputMaybe<Scalars['Boolean']>;
  /** Whether to show the card first in the contact exchange. */
  showCardFirst?: InputMaybe<Scalars['Boolean']>;
};

/** Option to configure what information a user can send back when they connect with a card. */
export enum ContactExchangeOption {
  Both = 'BOTH',
  Email = 'EMAIL',
  Phone = 'PHONE',
}

export type ContactExternalSyncStatus = {
  __typename?: 'ContactExternalSyncStatus';
  externalCategory?: Maybe<ExternalContactCategory>;
  lastSync?: Maybe<Scalars['Timestamp']>;
  /** True if syncing is currently active on the particular card; false if it is not. */
  syncStatus: Scalars['Boolean'];
};

/**
 * Fields that can be used to filter the contact search.
 * Associated Card ID - filter by associated card
 * Live - filter by live contacts
 * Recent - filter by most recently added contacts ("recent" is defined as added within the last two weeks)
 * Scan - filter by scanned contacts
 * Tag ID - filter by tag
 * Exclude Tag ID - filter by excluding a tag
 */
export enum ContactFilterField {
  AssociatedCardId = 'ASSOCIATED_CARD_ID',
  ExcludeTagId = 'EXCLUDE_TAG_ID',
  Live = 'LIVE',
  Recent = 'RECENT',
  Scan = 'SCAN',
  TagId = 'TAG_ID',
}

export type ContactFilterFieldInput = {
  field: ContactFilterField;
  value?: InputMaybe<Scalars['String']>;
};

export type ContactNote = {
  __typename?: 'ContactNote';
  addedAt: Scalars['Timestamp'];
  id: Scalars['ID'];
  text: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
};

/** Input for adding a note to a contact. */
export type ContactNoteAddInput = {
  contactId: Scalars['String'];
  text: Scalars['String'];
};

/** Input for updating an existing note on a contact. */
export type ContactNoteUpdateInput = {
  contactId: Scalars['String'];
  id: Scalars['String'];
  text: Scalars['String'];
};

/**
 * Output of the addStaticContact or updateContact mutations.
 * For adding a static contact, will return the newly created contact if the manualInput parameter is specified or token
 * information if the closeLoopInput parameter is specified.
 * For updating a contact, will return the updated contact if the id is the contact ID. Otherwise if the id is a token ID
 * that corresponds to a contact, this is inferred to be an update triggered by closing the loop, and the token information
 * will be returned.
 */
export type ContactResult = CloseContactLoopOutput | Contact;

export type ContactSaveEventDataInput = {
  company?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  identityBeingSavedTo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Fields that can be used to search the contacts by. */
export enum ContactSearchField {
  AssociatedCardName = 'ASSOCIATED_CARD_NAME',
  Company = 'COMPANY',
  FamilyName = 'FAMILY_NAME',
  GivenName = 'GIVEN_NAME',
  Title = 'TITLE',
}

/** Fields that can be used to sort the contacts search results. */
export enum ContactSortField {
  AddedAt = 'ADDED_AT',
  AssociatedCardName = 'ASSOCIATED_CARD_NAME',
  Company = 'COMPANY',
  FamilyName = 'FAMILY_NAME',
  GivenName = 'GIVEN_NAME',
  Title = 'TITLE',
}

/** The source of a contact connection. */
export enum ContactSource {
  /** The contact was added via an external address book. */
  ExternalAddressBook = 'EXTERNAL_ADDRESS_BOOK',
  /** The contact was added via a HiHello card link or QR code. */
  HiHelloShare = 'HI_HELLO_SHARE',
  /** The contact was added manually. */
  Manual = 'MANUAL',
  /** The contact was added via bluetooth. */
  Nearby = 'NEARBY',
  /** The contact was added via a paper business card scan. */
  Scan = 'SCAN',
}

export type ContactSyncConnection = {
  __typename?: 'ContactSyncConnection';
  application: OAuthApplicationTypeContact;
  connectedAt: Scalars['Timestamp'];
  emailAddress?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Whether or not the address book is currently connected. This is based on the presence of a refresh token. */
  isConnected: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  newCardSyncSettings: NewCardSyncSettings;
  /** @deprecated Use isConnected instead */
  refreshToken?: Maybe<Scalars['String']>;
  syncCardsFromHiHello: Array<SyncExportSetting>;
  syncToHiHello: SyncImportSetting;
};

/**
 * Input for updating a contact. Card details and palette are only allowed to be updated if the contact is static.
 * The associated card ID can be updated for live or static contacts.
 */
export type ContactUpdateInput = {
  associatedCardId?: InputMaybe<Scalars['String']>;
  details?: InputMaybe<CardDetailsInput>;
  id: Scalars['ID'];
  palette?: InputMaybe<PaletteInput>;
  tagIdsToAdd?: InputMaybe<Array<Scalars['String']>>;
  tagIdsToRemove?: InputMaybe<Array<Scalars['String']>>;
};

export type ContactsSearchInput = {
  filters?: InputMaybe<Array<ContactFilterFieldInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<ContactSearchField>>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirections>;
  sortField?: InputMaybe<ContactSortField>;
};

export type ContactsSearchOutput = {
  __typename?: 'ContactsSearchOutput';
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  results: Array<Contact>;
  totalFound: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** ISO 3166-1 alpha-2 country codes supported for local phone number interpretation */
export enum CountryCode {
  Ac = 'AC',
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  Ao = 'AO',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Ax = 'AX',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cw = 'CW',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sx = 'SX',
  Sy = 'SY',
  Sz = 'SZ',
  Ta = 'TA',
  Tc = 'TC',
  Td = 'TD',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Xk = 'XK',
  Ye = 'YE',
  Yt = 'YT',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW',
}

/** Custom Url model. */
export type CustomUrl = {
  __typename?: 'CustomUrl';
  hidden: Scalars['Boolean'];
  slug: Scalars['String'];
  subdomain?: Maybe<Scalars['String']>;
};

export type CustomUrlInput = {
  hidden: Scalars['Boolean'];
  slug: Scalars['String'];
  subdomain?: InputMaybe<Scalars['String']>;
};

/** Customer Support debugging data. */
export type CustomerSupportData = {
  appVersion?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  os?: InputMaybe<Scalars['String']>;
  osVersion?: InputMaybe<Scalars['String']>;
  supportType?: InputMaybe<CustomerSupportType>;
};

/** Type of customer support request (support team or success team). */
export enum CustomerSupportType {
  Success = 'SUCCESS',
  Support = 'SUPPORT',
}

/** Date intervals for card analytics */
export enum DateInterval {
  Day = 'day',
  Month = 'month',
  Week = 'week',
}

/** Design model. */
export type Design = {
  __typename?: 'Design';
  definition?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Design input. */
export type DesignInput = {
  designId?: InputMaybe<Scalars['String']>;
  externalSource?: InputMaybe<Scalars['String']>;
  isEditable: Scalars['Boolean'];
  state: TemplateFieldState;
};

export type DesignRecoveryData = {
  __typename?: 'DesignRecoveryData';
  type: RecoveryType;
  value?: Maybe<Design>;
};

/** Designs model. */
export type Designs = {
  __typename?: 'Designs';
  available: Array<Design>;
  unavailable: Array<Design>;
};

export type Directory = AdvancedDirectory | StandardDirectory;

/** The current status of a directory connection */
export enum DirectoryConnectionStatus {
  /** An active connection exists to the directory */
  Connected = 'connected',
  /** The connection has been enabled but there is not an active, configured connection to it */
  Enabled = 'enabled',
  /** The customer has requested this connection but it has not been enabled yet by HiHello */
  Requested = 'requested',
}

export enum DirectoryConnectionType {
  AdvancedBoth = 'ADVANCED_BOTH',
  AdvancedSaml = 'ADVANCED_SAML',
  AdvancedScim = 'ADVANCED_SCIM',
  Standard = 'STANDARD',
}

/** External directories we support via Standard and/or Advanced Directory Syncing */
export enum DirectoryProvider {
  AccessPeopleHr = 'ACCESS_PEOPLE_HR',
  AdpOpenidConnect = 'ADP_OPENID_CONNECT',
  Auth0 = 'AUTH0',
  Bamboohr = 'BAMBOOHR',
  BreatheHr = 'BREATHE_HR',
  Cas = 'CAS',
  CezanneHr = 'CEZANNE_HR',
  Classlink = 'CLASSLINK',
  Cloudflare = 'CLOUDFLARE',
  Cyberark = 'CYBERARK',
  Duo = 'DUO',
  EntraId = 'ENTRA_ID',
  Fourth = 'FOURTH',
  GenericSaml = 'GENERIC_SAML',
  GenericScim = 'GENERIC_SCIM',
  Google = 'GOOGLE',
  Hibob = 'HIBOB',
  Jumpcloud = 'JUMPCLOUD',
  Keycloak = 'KEYCLOAK',
  Lastpass = 'LASTPASS',
  LoginGov = 'LOGIN_GOV',
  MicrosoftAdFs = 'MICROSOFT_AD_FS',
  Miniorange = 'MINIORANGE',
  Netiq = 'NETIQ',
  Okta = 'OKTA',
  Onelogin = 'ONELOGIN',
  OpenidConnect = 'OPENID_CONNECT',
  Oracle = 'ORACLE',
  Pingfederate = 'PINGFEDERATE',
  Pingone = 'PINGONE',
  Rippling = 'RIPPLING',
  Salesforce = 'SALESFORCE',
  Sftp = 'SFTP',
  ShibbolethGenericSaml = 'SHIBBOLETH_GENERIC_SAML',
  ShibbolethUnsolicitedSaml = 'SHIBBOLETH_UNSOLICITED_SAML',
  Vmware = 'VMWARE',
  Workday = 'WORKDAY',
}

/** Available EmailClients. */
export enum EmailClients {
  Gmail = 'GMAIL',
  Outlook = 'OUTLOOK',
}

/** Email input. */
export type EmailInput = {
  attachments?: InputMaybe<Array<InputMaybe<MediaInput>>>;
  message: Scalars['String'];
  subject?: InputMaybe<Scalars['String']>;
};

/** Email notification settings. */
export type EmailNotificationSettings = {
  __typename?: 'EmailNotificationSettings';
  automaticHealRequest: Scalars['Boolean'];
  cardScanError: Scalars['Boolean'];
  cardScanSuccess: Scalars['Boolean'];
  healSuccess: Scalars['Boolean'];
  newContactNotifications: Scalars['Boolean'];
};

/** The type of email signature that's supported (QrCode, ImageLogo, Logo, Text, Square). */
export enum EmailSignatureSupported {
  Imagelogo = 'IMAGELOGO',
  Logo = 'LOGO',
  Qrcode = 'QRCODE',
  Square = 'SQUARE',
  Text = 'TEXT',
}

export enum EmailSignatureSupportedForPagination {
  Blank = 'BLANK',
  Imagelogo = 'IMAGELOGO',
  Logo = 'LOGO',
  Qrcode = 'QRCODE',
  Square = 'SQUARE',
  Text = 'TEXT',
}

/** Emoji model. This is a simple model used to represent an emoji with a unicode string. */
export type Emoji = {
  __typename?: 'Emoji';
  unicodeValue: Scalars['String'];
};

/** Emoji input model. */
export type EmojiInput = {
  unicodeValue: Scalars['String'];
};

export type Entity = Organization | User;

/** Audit event types. */
export enum EventTypes {
  CardMoved = 'CARD_MOVED',
  EmailSent = 'EMAIL_SENT',
  FeatureFlagCreate = 'FEATURE_FLAG_CREATE',
  FeatureFlagDelete = 'FEATURE_FLAG_DELETE',
  FeatureFlagUpdate = 'FEATURE_FLAG_UPDATE',
  MarketingReportProcessed = 'MARKETING_REPORT_PROCESSED',
  OrgCreated = 'ORG_CREATED',
  OrgUpdated = 'ORG_UPDATED',
  PasswordChange = 'PASSWORD_CHANGE',
  UserDeletion = 'USER_DELETION',
  UserImpersonation = 'USER_IMPERSONATION',
  UserUpdated = 'USER_UPDATED',
}

export enum ExternalContactCategory {
  Contact = 'CONTACT',
  Lead = 'LEAD',
}

/** External Directory model. */
export type ExternalDirectory = {
  __typename?: 'ExternalDirectory';
  /** Whether or not there is an active connection configured with this external directory. This does not mean that any groups are necessarily syncing, only that the necessary credentials are in place in order to begin syncing groups. */
  connected: Scalars['Boolean'];
  /** Groups that are currently syncing from the external directory. */
  groups: Array<ExternalDirectoryGroup>;
  /** Whether or not a dedicated SSO connection has been configured with this external directory. */
  ssoConnected: Scalars['Boolean'];
  /** The type of external directory connected. There can only be one per organization. */
  type: ExternalDirectoryType;
};

export type ExternalDirectoryGroup = {
  __typename?: 'ExternalDirectoryGroup';
  /** The external directory's ID for the group. */
  externalId: Scalars['ID'];
  /** Name of the group in the external directory. */
  name: Scalars['String'];
  /** Number of members of the group. */
  numMembers: Scalars['Int'];
  /** For each template in this array, a corresponding card will be created/maintained for each member of the group. Currently, we only support a single template. */
  templates?: Maybe<Array<Template>>;
};

/** Fields that can be used to sort card lists. */
export enum ExternalDirectoryGroupSortFields {
  Name = 'name',
}

export enum ExternalDirectoryRequestAction {
  Enable = 'ENABLE',
  None = 'NONE',
}

export enum ExternalDirectoryType {
  /** Google Workspace */
  Google = 'google',
  /** Manually managed directory of users */
  Manual = 'manual',
  /** Microsoft Active Directory */
  Microsoft = 'microsoft',
  /** Okta OpenID Connection */
  Okta = 'okta',
  /** WorkOS SAML/SCIM Connection */
  Workos = 'workos',
}

/** FeatureFlag model. */
export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  createdAt: Scalars['Float'];
  createdBy: Scalars['String'];
  default: FeatureFlagVariationsV2;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isProductFeature: Scalars['Boolean'];
  name: Scalars['String'];
  percentageTargetType?: Maybe<PercentageRollOutTargetTypes>;
  type: FeatureFlagTypes;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['String']>;
  variations: Array<FeatureFlagVariationsV2>;
};

export type FeatureFlagInputV2 = {
  default: FeatureFlagInputVariationsV2;
  description?: InputMaybe<Scalars['String']>;
  /**
   * Whether this feature flag is product driven. If undefined, it is assumed to be true.
   * Only product driven feature flags are surfaced in the getFlagValuesV2 query.
   */
  isProductFeature?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  type: FeatureFlagTypes;
  variations: Array<FeatureFlagInputVariationsV2>;
};

export type FeatureFlagInputVariationsV2 = {
  name: Scalars['String'];
  organizationTargets?: InputMaybe<OrganizationTargetTypes>;
  plansAndProfileTargeted?: InputMaybe<Array<FeatureFlagPlansAndProfile>>;
  usersTargeted: Array<Scalars['String']>;
  value: Scalars['String'];
};

/** Feature Flag Plans. */
export enum FeatureFlagPlansAndProfile {
  Basic = 'BASIC',
  Business = 'BUSINESS',
  BusinessExtraCardscan = 'BUSINESS_EXTRA_CARDSCAN',
  BusinessExtraCardscanIndividualProfile = 'BUSINESS_EXTRA_CARDSCAN_INDIVIDUAL_PROFILE',
  BusinessIndividualProfile = 'BUSINESS_INDIVIDUAL_PROFILE',
  Enterprise = 'ENTERPRISE',
  EnterpriseExtraCardscan = 'ENTERPRISE_EXTRA_CARDSCAN',
  EnterpriseExtraCardscanIndividualProfile = 'ENTERPRISE_EXTRA_CARDSCAN_INDIVIDUAL_PROFILE',
  EnterpriseIndividualProfile = 'ENTERPRISE_INDIVIDUAL_PROFILE',
  Free = 'FREE',
  Professional = 'PROFESSIONAL',
  ProfessionalExtraCardscan = 'PROFESSIONAL_EXTRA_CARDSCAN',
}

/** Feature Flag Types. */
export enum FeatureFlagTypes {
  Boolean = 'BOOLEAN',
  Json = 'JSON',
}

export type FeatureFlagUpdateInputV2 = {
  default?: InputMaybe<FeatureFlagInputVariationsV2>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** Whether this feature flag is product driven. Only product driven feature flags are surfaced in the getFlagValuesV2 query. */
  isProductFeature?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<FeatureFlagTypes>;
  variations?: InputMaybe<Array<FeatureFlagInputVariationsV2>>;
};

/** FeatureFlagVariations model. */
export type FeatureFlagUserOutputV2 = {
  __typename?: 'FeatureFlagUserOutputV2';
  /** whether the default value was used */
  defaultUsed: Scalars['Boolean'];
  /** Field that states the entity id (user id or organization id) */
  entityID: Scalars['String'];
  /** the flagid stored in firebase */
  id: Scalars['String'];
  /** programmer friendly name */
  name: Scalars['String'];
  /** Boolean that tells if it was rendered because of percentage rollout */
  percentageBased?: Maybe<Scalars['Boolean']>;
  profile: Profile;
  /** profile id that the flag is associated with */
  profileId: Scalars['String'];
  /** type of feature flag (JSON/Boolean) */
  type: FeatureFlagTypes;
  /** This contains 2 types of values: JSON value and boolean flag value in the form of {booleanValue: true/false; type:"Boolean"} and {JSONvalue: "<JSON string>"; type:"JSON"}. For boolean the value is boolean */
  value: FlagValueTypeV2;
};

/** FeatureFlag model. */
export type FeatureFlagV2 = {
  __typename?: 'FeatureFlagV2';
  createdAt: Scalars['Float'];
  createdBy: Scalars['String'];
  default: FeatureFlagVariationsV2;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isProductFeature: Scalars['Boolean'];
  name: Scalars['String'];
  percentageTargetType?: Maybe<PercentageRollOutTargetTypes>;
  type: FeatureFlagTypes;
  updatedAt?: Maybe<Scalars['Float']>;
  updatedBy?: Maybe<Scalars['String']>;
  variations: Array<FeatureFlagVariationsV2>;
};

/** FeatureFlagVariations model. */
export type FeatureFlagVariationsV2 = {
  __typename?: 'FeatureFlagVariationsV2';
  name: Scalars['String'];
  organizationTargets?: Maybe<OrganizationTargetTypes>;
  percentageValue?: Maybe<Scalars['Float']>;
  plansAndProfileTargeted?: Maybe<Array<FeatureFlagPlansAndProfile>>;
  usersTargeted: Array<Scalars['String']>;
  value: Scalars['String'];
};

export enum FeatureRequestType {
  Calendly = 'CALENDLY',
  Mailchimp = 'MAILCHIMP',
  OutlookAutomatedEmailSignature = 'OUTLOOK_AUTOMATED_EMAIL_SIGNATURE',
  Zoho = 'ZOHO',
}

export type FieldRecoveryData = {
  __typename?: 'FieldRecoveryData';
  type: RecoveryType;
  value: Fields;
};

export type FieldSelectEventDataInput = {
  fieldData: BaseFieldInput;
};

export enum FieldType {
  Address = 'ADDRESS',
  Applemusic = 'APPLEMUSIC',
  Behance = 'BEHANCE',
  Brightcove = 'BRIGHTCOVE',
  Calendly = 'CALENDLY',
  Cashapp = 'CASHAPP',
  Discord = 'DISCORD',
  Dribbble = 'DRIBBBLE',
  Email = 'EMAIL',
  Facebook = 'FACEBOOK',
  File = 'FILE',
  Github = 'GITHUB',
  GoogleMeet = 'GOOGLE_MEET',
  ImportantDate = 'IMPORTANT_DATE',
  Instagram = 'INSTAGRAM',
  Line = 'LINE',
  Link = 'LINK',
  Linkedin = 'LINKEDIN',
  MicrosoftBookings = 'MICROSOFT_BOOKINGS',
  MicrosoftTeams = 'MICROSOFT_TEAMS',
  Nintendo = 'NINTENDO',
  Note = 'NOTE',
  Patreon = 'PATREON',
  Paypal = 'PAYPAL',
  Phone = 'PHONE',
  Pinterest = 'PINTEREST',
  Playstation = 'PLAYSTATION',
  Signal = 'SIGNAL',
  Skype = 'SKYPE',
  Snapchat = 'SNAPCHAT',
  Soundcloud = 'SOUNDCLOUD',
  Spotify = 'SPOTIFY',
  Telegram = 'TELEGRAM',
  Tiktok = 'TIKTOK',
  Twitch = 'TWITCH',
  Twitter = 'TWITTER',
  Venmo = 'VENMO',
  Vimeo = 'VIMEO',
  Webex = 'WEBEX',
  Website = 'WEBSITE',
  Wechat = 'WECHAT',
  Whatsapp = 'WHATSAPP',
  Xbox = 'XBOX',
  Xing = 'XING',
  Yelp = 'YELP',
  Youtube = 'YOUTUBE',
  Zelle = 'ZELLE',
  Zoom = 'ZOOM',
}

export type Fields = BaseField | PhoneField | UrlField;

export type FlagValueTypeV2 = BooleanFlagValue | JsonFlagValue;

/** Icon model. */
export type Icon = {
  __typename?: 'Icon';
  color: Scalars['String'];
  design: Scalars['String'];
};

/** Icon input model. */
export type IconInput = {
  color: Scalars['String'];
  design: Scalars['String'];
};

/** Image media object */
export type ImageMedia = {
  __typename?: 'ImageMedia';
  uri: Scalars['String'];
  variants?: Maybe<ImageVariants>;
};

/** Image Variant model. */
export type ImageVariants = {
  __typename?: 'ImageVariants';
  imageLarge?: Maybe<Scalars['String']>;
  imageSmall?: Maybe<Scalars['String']>;
};

/** Iterable User JWT Token. */
export type IterableToken = {
  __typename?: 'IterableToken';
  expires: Scalars['Timestamp'];
  token: Scalars['String'];
};

/** JSON flag value type */
export type JsonFlagValue = {
  __typename?: 'JSONFlagValue';
  flagValue: Scalars['String'];
  type: FeatureFlagTypes;
};

/** Job Error Types. */
export enum JobErrorReason {
  Illegible = 'ILLEGIBLE',
  Language = 'LANGUAGE',
  NotCard = 'NOT_CARD',
  Other = 'OTHER',
}

/** Job State Types. */
export enum JobState {
  Completed = 'COMPLETED',
  Error = 'ERROR',
  NotSubmitted = 'NOT_SUBMITTED',
  Processing = 'PROCESSING',
  Transcribing = 'TRANSCRIBING',
}

/** Available JobStatus. */
export enum JobStatus {
  Failure = 'FAILURE',
  Incomplete = 'INCOMPLETE',
  Pending = 'PENDING',
  Success = 'SUCCESS',
}

/** Job Types. */
export enum JobType {
  GoogleVisionSingleImage = 'GOOGLE_VISION_SINGLE_IMAGE',
  HihelloReviewSingleImage = 'HIHELLO_REVIEW_SINGLE_IMAGE',
  ScaleAiSingleImage = 'SCALE_AI_SINGLE_IMAGE',
}

export type LastSyncStatusSync = {
  __typename?: 'LastSyncStatusSync';
  timestamp?: Maybe<Scalars['Timestamp']>;
};

/** Custom lead capture consent agreement model. */
export type LeadCaptureConsentAgreement = {
  __typename?: 'LeadCaptureConsentAgreement';
  displayText: Scalars['String'];
  id: Scalars['ID'];
  /** Currently allowing a maximum of 2 links per consent form. */
  links?: Maybe<Array<LeadCaptureConsentLink>>;
};

/** Custom lead capture consent agreement input. */
export type LeadCaptureConsentAgreementInput = {
  displayText: Scalars['String'];
  /**
   * Specify an id if there is an existing consent agreement object to modify.
   * If not specified, a new consent agreement will be created.
   */
  id?: InputMaybe<Scalars['ID']>;
  /** Currently allowing a maximum of 2 links per consent form. */
  links?: InputMaybe<Array<LeadCaptureConsentLinkInput>>;
};

/** Custom lead capture consent link model. */
export type LeadCaptureConsentLink = {
  __typename?: 'LeadCaptureConsentLink';
  id: Scalars['ID'];
  label: Scalars['String'];
  uri: Scalars['String'];
};

/** Custom lead capture consent link input. */
export type LeadCaptureConsentLinkInput = {
  /**
   * Tip: specify an id if there is an existing link object. This will help efficiency in
   * updating the existing link object rather than deleting and recreating it.
   */
  id?: InputMaybe<Scalars['ID']>;
  label: Scalars['String'];
  uri: Scalars['String'];
};

export type LogoRecoveryData = {
  __typename?: 'LogoRecoveryData';
  type: RecoveryType;
  value: ImageMedia;
};

/** Input for creating a static contact manually. */
export type ManualContactInput = {
  associatedCardId?: InputMaybe<Scalars['String']>;
  palette?: InputMaybe<PaletteInput>;
};

export type Media = ImageMedia | VideoMedia;

export type MediaInput = {
  includesAudio?: InputMaybe<Scalars['Boolean']>;
  loop?: InputMaybe<Scalars['Boolean']>;
  uri: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addCard: Card;
  addCardsFromCsv: CardsFromCsvOutput;
  addCardsFromTemplate: Array<Card>;
  /** Allows an admin to configure the contact exchange settings used when sharing cards for their organization. */
  addContactExchangeConfig: ContactExchangeConfig;
  addContactNote: ContactNote;
  addLiveContact: Scalars['Boolean'];
  /**
   * Stores and returns an invitation to join the organization from which this query
   * is called.
   */
  addOrganizationInvitation: OrganizationInvitation;
  addPurchaseToken: User;
  addSnapshotSeen: Scalars['Boolean'];
  /**
   * This mutation allows you to add a static contact to your account. A static contact is a contact that is not a
   * HiHello user and does not have a HiHello card. You can add a static contact by either manually entering their
   * information or automatically when a contact that has received a HiHello card sends back their information (this
   * is also known as closing the loop).
   */
  addStaticContact: ContactResult;
  /** This mutation adds a new tag to the current account. */
  addTag: Tag;
  addTemplate: Template;
  associateMessagingRegistrationToken: Scalars['Boolean'];
  changeCardPublicId: Scalars['String'];
  changeCardPublicIdUser: Card;
  /**
   * Whenever we need to push an email signature to Google Workspace, we need to be able to ask
   * Google, at the time of the push, what domains are owned by the organization so that we don't
   * push to a domain owned by another HiHello customer who is also using Automated Email Signatures.
   * To do that, we need the email address of a user within the Google Workspace who has access to
   * the list of domains owned by the organization. Calling this function will check if the email
   * address of the user making the request has that capability and, if it does, records that email
   * address for use in subsequent pushes. If that user subsequently loses that access, future pushes
   * will fail until this function is called again by a different user with that access.
   */
  checkAndSetIfUserIsGoogleAdmin: Scalars['Boolean'];
  claimCard: Card;
  completeAutomatedEmailSignatureSetup: Scalars['Boolean'];
  connectAddressBook: ContactSyncConnection;
  createCardScanJob: CardScanUser;
  createFeatureFlagV2: FeatureFlagV2;
  createOrganization: Organization;
  createPercentageFlagV2: FeatureFlagV2;
  deleteCard: Scalars['String'];
  deleteCardScanJob: Scalars['String'];
  deleteContact: Scalars['String'];
  deleteContactNote: Scalars['String'];
  /**
   * Delete all cards in the current profile that are in a disabled state.
   * This would be used to clean up cards that were created via standard
   * directory syncing but represent users that were removed from a syncing
   * group or belong to group/template combinations that are no longer syncing.
   */
  deleteDisabledCards: Array<Card>;
  deleteFeatureFlag: Scalars['String'];
  deleteMe: Scalars['Boolean'];
  deleteMessagingRegistrationToken: Scalars['String'];
  deleteTag: Scalars['String'];
  deleteTemplate: Scalars['String'];
  deleteUser: Scalars['String'];
  disconnectAddressBook: ContactSyncConnection;
  disconnectAutomatedEmailSignatures: Scalars['Boolean'];
  duplicateCard: Card;
  duplicateTag: Tag;
  duplicateTemplate: Template;
  exportContacts: Scalars['Boolean'];
  generateAutomatedEmailSignatureMagicToken: Scalars['String'];
  generateLoginToken: Token;
  generateOnboardingCsv: Array<Scalars['String']>;
  moveCard: Scalars['String'];
  processCardScanJob: CardScanJob;
  provisionWorkOS: Organization;
  reloadAutomatedEmailSignaturesJobs: Scalars['Int'];
  requestExternalDirectory: Scalars['Boolean'];
  requestExternalDirectoryV2: Organization;
  saveAnalyticEvent: Scalars['Boolean'];
  selectEmailClient: Scalars['Boolean'];
  sendAutomatedEmailSignatureOutlookEmail: Scalars['Boolean'];
  sendCustomerSupportEmail: Scalars['Boolean'];
  sendForgotPasswordEmail: Scalars['Boolean'];
  sendOrgAdminsSupportEmail: Scalars['Boolean'];
  sendOrgListAnalytics: Scalars['Boolean'];
  sendSharedCard: Scalars['Boolean'];
  sendWelcomeEmail: Scalars['String'];
  /**
   * Add, modify, or remove email signature disclaimers that are appended
   * to email signatures pushed through Automated Email Signatures
   */
  setEmailSignatureDisclaimer: Organization;
  setExternalDirectoryRequest: Scalars['Boolean'];
  setFeaturesRequested: Scalars['Boolean'];
  shareCard: Card;
  startEmailSignatureSync: Scalars['Boolean'];
  startEmailSignatureSyncPaginated: Scalars['Int'];
  /** This mutation kicks off workflows to sync contacts from HiHello to an external integration through Paragon. Returns a boolean of whether the workflow triggering was successful. */
  syncExistingContactsThroughParagon: Scalars['Boolean'];
  syncSubscription: User;
  /**
   * This mutation allows you to toggle syncing contacts to a specific external address book connection,
   * specifying the cards to which the changes should be applied in either or both of two ways:
   * 1) As a list of specific card IDs together with the changes you want to make to their syncing settings
   * 2) As a bulk action to apply either to all cards or to all cards attached to a list of templates you provide.
   *
   * Operations in the list of specific card IDs override operations in the bulk actions if they are in conflict.
   */
  toggleAddressBookExternalSync: Array<Card>;
  toggleAddressBookIncomingSync: ContactSyncConnection;
  toggleOutgoingNewCardSyncSettings: ContactSyncConnection;
  /**
   * Sends a link (by email or SMS/MMS/WhatsApp) to another person to allow them to take
   * ownership of a card that the requesting user currently owns.  This can only be used
   * for individual-owned cards.
   */
  transferCard: Card;
  updateCard: Card;
  updateCardOrder: Card;
  updateCardPermissions: Card;
  updateCardSettings: Card;
  /**
   * This mutation allows you to update a contact - only the associated card can be updated if the contact is live.
   * If the contact is static, the card details and palette can also be updated.
   */
  updateContact: ContactResult;
  /** Allows an admin to update the contact exchange settings used when sharing cards for their organization. */
  updateContactExchangeConfig: ContactExchangeConfig;
  updateContactNote: ContactNote;
  updateFeatureFlagV2: FeatureFlagV2;
  updateMe: User;
  updateMyEmail: User;
  /**
   * This mutation is for external customer use for organization admins/owners
   * to update properties of their organization
   */
  updateMyOrganization: Organization;
  /** This mutation is for internal employee use for updating organizations */
  updateOrganization: Organization;
  updatePercentageFlagV2: FeatureFlagV2;
  /** This mutation updates an existing tag. Only the tag owner can edit the tag details. */
  updateTag: Tag;
  updateTemplate: Template;
  updateUser: User;
};

export type MutationAddCardArgs = {
  data: CardAddInput;
};

export type MutationAddCardsFromCsvArgs = {
  data: CardsFromCsvInput;
};

export type MutationAddCardsFromTemplateArgs = {
  data: CardsFromTemplateInput;
};

export type MutationAddContactExchangeConfigArgs = {
  data: ContactExchangeConfigAddInput;
};

export type MutationAddContactNoteArgs = {
  data: ContactNoteAddInput;
};

export type MutationAddLiveContactArgs = {
  channel?: InputMaybe<AnalyticEventChannel>;
  consentFormId?: InputMaybe<Scalars['ID']>;
  recipientPublicId: Scalars['String'];
  senderCardId: Scalars['String'];
};

export type MutationAddOrganizationInvitationArgs = {
  cards: Array<CardInvitationInput>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  reusable: Scalars['Boolean'];
};

export type MutationAddPurchaseTokenArgs = {
  provider: SubscriptionProvider;
  token: Scalars['String'];
};

export type MutationAddSnapshotSeenArgs = {
  snapshotId: Scalars['String'];
};

export type MutationAddStaticContactArgs = {
  input: ContactAddInput;
};

export type MutationAddTagArgs = {
  data: TagAddInput;
};

export type MutationAddTemplateArgs = {
  data: TemplateAddInput;
};

export type MutationAssociateMessagingRegistrationTokenArgs = {
  deviceId: Scalars['String'];
  token: Scalars['String'];
};

export type MutationChangeCardPublicIdArgs = {
  cardId: Scalars['String'];
  publicId?: InputMaybe<Scalars['String']>;
};

export type MutationChangeCardPublicIdUserArgs = {
  cardId: Scalars['String'];
  publicId?: InputMaybe<Scalars['String']>;
};

export type MutationCheckAndSetIfUserIsGoogleAdminArgs = {
  magicLinkToken: Scalars['String'];
};

export type MutationClaimCardArgs = {
  name?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export type MutationConnectAddressBookArgs = {
  input: OAuthAddressBookAdditionInput;
};

export type MutationCreateCardScanJobArgs = {
  data: CardScanJobCreateInput;
};

export type MutationCreateFeatureFlagV2Args = {
  featureToCreate: FeatureFlagInputV2;
};

export type MutationCreateOrganizationArgs = {
  cardIdsToMoveIntoOrgAndGrantEditorAccess?: InputMaybe<Array<Scalars['String']>>;
  cardIdsToMoveIntoOrgAndGrantNoAccess?: InputMaybe<Array<Scalars['String']>>;
  cardIdsToMoveIntoOrgAndGrantSharerAccess?: InputMaybe<Array<Scalars['String']>>;
  organization: OrganizationAddInput;
};

export type MutationCreatePercentageFlagV2Args = {
  featureToCreate: PercentageFlagInputV2;
};

export type MutationDeleteCardArgs = {
  id: Scalars['String'];
};

export type MutationDeleteCardScanJobArgs = {
  id: Scalars['String'];
};

export type MutationDeleteContactArgs = {
  id: Scalars['String'];
};

export type MutationDeleteContactNoteArgs = {
  contactId: Scalars['String'];
  id: Scalars['String'];
};

export type MutationDeleteFeatureFlagArgs = {
  id: Scalars['String'];
};

export type MutationDeleteMessagingRegistrationTokenArgs = {
  token: Scalars['String'];
};

export type MutationDeleteTagArgs = {
  id: Scalars['String'];
};

export type MutationDeleteTemplateArgs = {
  id: Scalars['String'];
};

export type MutationDeleteUserArgs = {
  id: Scalars['String'];
};

export type MutationDisconnectAddressBookArgs = {
  id: Scalars['String'];
};

export type MutationDisconnectAutomatedEmailSignaturesArgs = {
  removeEmailSignature?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDuplicateCardArgs = {
  id: Scalars['String'];
};

export type MutationDuplicateTagArgs = {
  id: Scalars['String'];
};

export type MutationDuplicateTemplateArgs = {
  id: Scalars['String'];
  removeExternalSources?: InputMaybe<Scalars['Boolean']>;
};

export type MutationExportContactsArgs = {
  email?: InputMaybe<Scalars['String']>;
};

export type MutationGenerateLoginTokenArgs = {
  userId: Scalars['String'];
};

export type MutationGenerateOnboardingCsvArgs = {
  organizationId: Scalars['String'];
};

export type MutationMoveCardArgs = {
  accountId: Scalars['String'];
  cardId: Scalars['String'];
  moveAssociatedContacts?: InputMaybe<Scalars['Boolean']>;
  removeSharersAndEditors?: InputMaybe<Scalars['Boolean']>;
};

export type MutationProcessCardScanJobArgs = {
  data: CardScanJobInput;
};

export type MutationProvisionWorkOsArgs = {
  domains: Array<Scalars['String']>;
  organizationId: Scalars['String'];
};

export type MutationReloadAutomatedEmailSignaturesJobsArgs = {
  jobsToReload: Array<AutomatedEmailSignaturesJobInput>;
};

export type MutationRequestExternalDirectoryArgs = {
  connectionType: WorkOsConnectionType;
  interestedProvider: WorkOsProvider;
};

export type MutationRequestExternalDirectoryV2Args = {
  connectionType: DirectoryConnectionType;
  interestedProvider: DirectoryProvider;
};

export type MutationSaveAnalyticEventArgs = {
  event: AnalyticEvent;
};

export type MutationSelectEmailClientArgs = {
  emailClient: EmailClients;
};

export type MutationSendAutomatedEmailSignatureOutlookEmailArgs = {
  emailToSend: Scalars['String'];
};

export type MutationSendCustomerSupportEmailArgs = {
  content: EmailInput;
  supportData?: InputMaybe<CustomerSupportData>;
};

export type MutationSendForgotPasswordEmailArgs = {
  email?: InputMaybe<Scalars['String']>;
};

export type MutationSendOrgAdminsSupportEmailArgs = {
  content: EmailInput;
  organizationId: Scalars['String'];
};

export type MutationSendOrgListAnalyticsArgs = {
  bccEmail?: InputMaybe<Array<Scalars['String']>>;
  emailToSend: Array<Scalars['String']>;
  orgAccountId: Scalars['String'];
};

export type MutationSendSharedCardArgs = {
  channel?: InputMaybe<AnalyticEventChannel>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  publicId: Scalars['String'];
};

export type MutationSendWelcomeEmailArgs = {
  type: Scalars['String'];
  userId: Scalars['String'];
};

export type MutationSetEmailSignatureDisclaimerArgs = {
  disclaimer?: InputMaybe<AutomatedEmailSignatureDisclaimerInput>;
};

export type MutationSetExternalDirectoryRequestArgs = {
  action: ExternalDirectoryRequestAction;
  connectionType: WorkOsConnectionType;
  interestedProvider: WorkOsProvider;
  organizationAccountId: Scalars['String'];
};

export type MutationSetFeaturesRequestedArgs = {
  customRequest?: InputMaybe<Scalars['String']>;
  featureRequested?: InputMaybe<FeatureRequestType>;
};

export type MutationShareCardArgs = {
  input: ShareCardInput;
};

export type MutationStartEmailSignatureSyncArgs = {
  organizationAccountsAndEmailSignatureType: Array<AutomatedEmailSignaturesJobInput>;
};

export type MutationStartEmailSignatureSyncPaginatedArgs = {
  defaultEmailSignatureType?: InputMaybe<EmailSignatureSupportedForPagination>;
  individualEmailSignaturesToSetup: Array<AutomatedEmailSignaturesJobInput>;
};

export type MutationSyncExistingContactsThroughParagonArgs = {
  integrationType: ParagonIntegrationType;
};

export type MutationSyncSubscriptionArgs = {
  productId?: InputMaybe<Scalars['String']>;
  provider: SubscriptionProvider;
  subscriptionIdentifier: Scalars['String'];
};

export type MutationToggleAddressBookExternalSyncArgs = {
  bulkCards?: InputMaybe<SyncAllOptionsInput>;
  contactSyncConnectionId: Scalars['String'];
  singleCards?: InputMaybe<Array<ToggleAddressBookConnectionInput>>;
};

export type MutationToggleAddressBookIncomingSyncArgs = {
  contactSyncConnectionId: Scalars['String'];
  syncFromExternal: Scalars['Boolean'];
};

export type MutationToggleOutgoingNewCardSyncSettingsArgs = {
  externalCategory?: InputMaybe<ExternalContactCategory>;
  id: Scalars['String'];
  syncToExternal?: InputMaybe<Scalars['Boolean']>;
};

export type MutationTransferCardArgs = {
  input: CardTransferInput;
};

export type MutationUpdateCardArgs = {
  data: CardUpdateInput;
};

export type MutationUpdateCardOrderArgs = {
  cardId: Scalars['String'];
  order: Scalars['Int'];
};

export type MutationUpdateCardPermissionsArgs = {
  data: CardPermissionsUpdateInput;
};

export type MutationUpdateCardSettingsArgs = {
  data: CardSettingsUpdateInput;
};

export type MutationUpdateContactArgs = {
  closeLoopInput?: InputMaybe<CloseContactLoopInput>;
  data: ContactUpdateInput;
};

export type MutationUpdateContactExchangeConfigArgs = {
  data: ContactExchangeConfigUpdateInput;
};

export type MutationUpdateContactNoteArgs = {
  data: ContactNoteUpdateInput;
};

export type MutationUpdateFeatureFlagV2Args = {
  featureToUpdateInput: FeatureFlagUpdateInputV2;
};

export type MutationUpdateMeArgs = {
  data: UserSettingsInput;
};

export type MutationUpdateMyEmailArgs = {
  email: Scalars['String'];
};

export type MutationUpdateMyOrganizationArgs = {
  data: MyOrganizationUpdateInput;
};

export type MutationUpdateOrganizationArgs = {
  data: OrganizationUpdateInput;
};

export type MutationUpdatePercentageFlagV2Args = {
  featureToUpdateInput: PercentageFlagUpdateInputV2;
};

export type MutationUpdateTagArgs = {
  data: TagUpdateInput;
};

export type MutationUpdateTemplateArgs = {
  data: TemplateUpdateInput;
};

export type MutationUpdateUserArgs = {
  data: UserInput;
  id: Scalars['String'];
};

export type MyOrganizationUpdateInput = {
  /** Custom lead capture consent agreement configurable by an organization admin/owner. */
  leadCaptureConsentAgreement?: InputMaybe<LeadCaptureConsentAgreementInput>;
  leadCaptureConsentRequired?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  newOwnerId?: InputMaybe<Scalars['String']>;
  onboardingDone?: InputMaybe<Scalars['Boolean']>;
  palette?: InputMaybe<PaletteInput>;
  qrLogo?: InputMaybe<Scalars['String']>;
  stripe?: InputMaybe<StripeInput>;
  supportAdminIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Name model. */
export type Name = {
  __typename?: 'Name';
  accreditations?: Maybe<Scalars['String']>;
  family?: Maybe<Scalars['String']>;
  given?: Maybe<Scalars['String']>;
  givenFamily?: Maybe<Scalars['String']>;
  maiden?: Maybe<Scalars['String']>;
  middle?: Maybe<Scalars['String']>;
  preferred?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
};

export type NameAndEmailInput = {
  email: Scalars['String'];
  name: Scalars['String'];
};

/** Name model. */
export type NameInput = {
  accreditations?: InputMaybe<Scalars['String']>;
  family?: InputMaybe<Scalars['String']>;
  given?: InputMaybe<Scalars['String']>;
  maiden?: InputMaybe<Scalars['String']>;
  middle?: InputMaybe<Scalars['String']>;
  preferred?: InputMaybe<Scalars['String']>;
  prefix?: InputMaybe<Scalars['String']>;
  pronouns?: InputMaybe<Scalars['String']>;
  suffix?: InputMaybe<Scalars['String']>;
};

export type NameRecoveryData = {
  __typename?: 'NameRecoveryData';
  accreditations?: Maybe<StringRecoveryData>;
  family?: Maybe<StringRecoveryData>;
  given?: Maybe<StringRecoveryData>;
  maiden?: Maybe<StringRecoveryData>;
  middle?: Maybe<StringRecoveryData>;
  preferred?: Maybe<StringRecoveryData>;
  prefix?: Maybe<StringRecoveryData>;
  pronouns?: Maybe<StringRecoveryData>;
  suffix?: Maybe<StringRecoveryData>;
};

export type NewCardSyncSettings = {
  __typename?: 'NewCardSyncSettings';
  externalCategory?: Maybe<ExternalContactCategory>;
  syncToExternal: Scalars['Boolean'];
};

export type OAuthAddressBookAdditionInput = {
  application: OAuthApplicationTypeContact;
  emailAddress: Scalars['String'];
  instanceUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  refreshToken: Scalars['String'];
};

export enum OAuthApplicationTypeContact {
  Exchange = 'EXCHANGE',
  GoogleContacts = 'GOOGLE_CONTACTS',
  Hubspot = 'HUBSPOT',
  Salesforce = 'SALESFORCE',
}

/** Organization */
export type Organization = {
  __typename?: 'Organization';
  /** @deprecated Use admins instead */
  adminIds: Array<Scalars['String']>;
  /** The users who are admins of this organization. */
  admins?: Maybe<Array<User>>;
  /** Configuration settings for automated email signatures on this organization. */
  automatedEmailSignatureConfiguration?: Maybe<AutomatedEmailSignatureConfigurationGraph>;
  /** Configures how potential connections view and interact with organization cards. */
  contactExchangeConfig?: Maybe<ContactExchangeConfig>;
  /** The default country associated with this organization, if one has been assigned. */
  countryCode?: Maybe<CountryCode>;
  /** The total number of users (owner, admins, members) belonging to the organization. */
  currentUsers: Scalars['Int'];
  /** A description of the organization. */
  description?: Maybe<Scalars['String']>;
  /** Optional source of user/card data and authentication for this organization. */
  directory?: Maybe<Directory>;
  /** Whether or not the organization is disabled. */
  disabled: Scalars['Boolean'];
  /** A default email address associated with the organization. */
  email?: Maybe<Scalars['String']>;
  /** @deprecated Should be removed from clients */
  emailSignatureExportType?: Maybe<EmailSignatureSupported>;
  /**
   * Optional external source of user/card data for this organization.
   * @deprecated Use directory instead
   */
  externalDirectory?: Maybe<ExternalDirectory>;
  /** Various feature settings for this organization. */
  features: ProfileFeatures;
  /**
   * If a provider is set here, then users will be forced to sign in using the
   * given authentication provider before they can claim cards belonging to this
   * organization.
   */
  forceAuthenticationProvider?: Maybe<AuthenticationProvider>;
  /**
   * If true, users will be forced to sign in with Microsoft SSO before they can
   * claim cards belonging to this organization.
   * @deprecated Use forceAuthenticationProvider: MICROSOFT instead
   */
  forceMicrosoftSso: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Outstanding unexpired invitations to join this organization. */
  invitations?: Maybe<Array<OrganizationInvitation>>;
  /**
   * Custom lead capture consent agreement configurable by an organization admin/owner.
   * @deprecated Use ContactExchangeSettings > consentAgreement instead
   */
  leadCaptureConsentAgreement?: Maybe<LeadCaptureConsentAgreement>;
  /**
   * This value is configurable by an organization admin/owner. When enabled, the consent agreement must be
   * acknowledged when a lead is captured (via a new contact) through card sharing done by members of the
   * organization.
   * @deprecated Use ContactExchangeSettings > requireCustomConsentAgreement instead
   */
  leadCaptureConsentRequired: Scalars['Boolean'];
  /** The maximum number of seats currently provisioned for this organization. */
  maxUsers: Scalars['Int'];
  /** @deprecated Use users instead */
  memberIds: Array<Scalars['String']>;
  /**
   * If false, users belonging to this organization will not be able to create their
   * own cards in their individual profile. If they already had cards before joining
   * the organization, they will still have access to them.
   */
  membersCanCreateCards: Scalars['Boolean'];
  /**
   * If false, users belonging to this organization will not have access to the
   * corporate directory.  In other words, they won't be able to see the cards of
   * other organization members unless they have live-connected with those cards.
   */
  membersCanSeeCards: Scalars['Boolean'];
  /**
   * If false, the "Go Mobile" QR code will not able to be generated for any users
   * belonging to this organization, and functionality that sends login tokens through
   * other means (e.g. email) will be disabled.
   */
  membersCanUseLoginTokens: Scalars['Boolean'];
  /** Name of the organization. */
  name: Scalars['String'];
  /**
   * These flags allow disabling certain emails from being sent to users in the organization.
   * In the future it might also have settings to disable certain in-app notifications.
   */
  notificationSettings: AccountNotificationSettings;
  /** The total number of cards belonging to the organization. */
  numCards: Scalars['Int'];
  /** The total number of contacts attached to cards belonging to the organization. */
  numContacts: Scalars['Int'];
  /** The total number of templates belonging to the organization. */
  numTemplates: Scalars['Int'];
  /**
   * If true then the owner/admins of the organization will be presented with an onboarding
   * wizard in the web app to help them set up their organization.
   */
  onboardingNeeded: Scalars['Boolean'];
  /** The user who owns the organization. */
  owner?: Maybe<User>;
  /**
   * The email addresses of the owners of the organization.  We currently limit to 1 owner, so
   * this will be at most an array of 1.
   */
  ownerEmails: Array<Scalars['String']>;
  /** @deprecated Use owner instead */
  ownerIds: Array<Scalars['String']>;
  /** A photo representing the organization. */
  photo?: Maybe<ImageMedia>;
  /** The plan associated with this organization. */
  plan: Plan;
  /** The ID of the profile associated with this organization. */
  profileId: Scalars['String'];
  /**
   * Default logo for including in QR codes for cards belonging to this organization.  This
   * logo is applied if neither the card nor the template has a logo set.
   */
  qrLogo?: Maybe<Scalars['String']>;
  /** For organizations linked to a Stripe customer, the Stripe customer attributes are here. */
  stripe?: Maybe<Stripe>;
  /** Information about the subscription associated with this organization. */
  subscription?: Maybe<SubscriptionInfo>;
  /** @deprecated Use subscription.status instead. */
  subscriptionStatus?: Maybe<SubscriptionStatus>;
  /**
   * A convenience field equivalent to requesting owner.id and admins.id and creating an array
   * from the two of them.
   */
  supportAdminIds: Array<Scalars['String']>;
  /** Default theme for branding the organization itself. */
  theme: ProfileTheme;
  /**
   * All of the users (owner, admins, members) who belong to the organization, together with
   * their role.
   */
  users?: Maybe<Array<UserOrganizationAccess>>;
};

/** Type of access a user has to an organization */
export enum OrganizationAccessType {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  None = 'NONE',
  Owner = 'OWNER',
}

export type OrganizationAddInput = {
  adminIds?: InputMaybe<Array<Scalars['String']>>;
  countryCode?: InputMaybe<CountryCode>;
  email: Scalars['String'];
  maxUsers: Scalars['Float'];
  memberIds?: InputMaybe<Array<Scalars['String']>>;
  membersCanCreateCards?: InputMaybe<Scalars['Boolean']>;
  membersCanSeeCards: Scalars['Boolean'];
  membersCanUseLoginTokens?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  ownerIds: Array<Scalars['String']>;
  plan?: InputMaybe<Plan>;
  supportEmail?: InputMaybe<Scalars['String']>;
};

/** An invitation to claim a card, from the context of that card. */
export type OrganizationCardInvitation = {
  __typename?: 'OrganizationCardInvitation';
  /** If true the person claiming the card will be able to edit its contents, in accordance with template rules if applicable. */
  canEdit: Scalars['Boolean'];
  /** Time the invitation was created. */
  createdAt: Scalars['Timestamp'];
  /**
   * If the invitation was sent to an email address through HiHello,
   * this is that address.
   */
  email?: Maybe<Scalars['String']>;
  /** The invitation will no longer be valid after this time. */
  expiresAt: Scalars['Timestamp'];
  /** A unique identifier of the invitation. */
  id: Scalars['String'];
  /** The link for accepting this invitation. */
  link: Scalars['String'];
  /** The name of the person being sent the invitation. */
  name?: Maybe<Scalars['String']>;
  /** The organization the claimer would be joining. */
  organization?: Maybe<Organization>;
  /**
   * If the invitation was sent via SMS through HiHello,
   * this is the phone number to which it was sent.
   */
  phone?: Maybe<Scalars['String']>;
  /**
   * If false, the link will be made invalid when it is used once.
   * Otherwise it can be used by multiple users to claim the same card.
   */
  reusable: Scalars['Boolean'];
  /** The last update time of the invitation. */
  updatedAt: Scalars['Timestamp'];
};

/** Allows modifying or removing pending invitations to claim a card. */
export type OrganizationCardInvitationInput = {
  /** If true the person claiming the card will be able to edit its contents, in accordance with template rules if applicable. */
  canEdit?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier of the invitation. */
  id: Scalars['String'];
  /**
   * If false, the link will be made invalid when it is used once.
   * Otherwise it can be used by multiple users to claim the same card.
   */
  reusable?: InputMaybe<Scalars['Boolean']>;
};

/** Invitation to join an organization. */
export type OrganizationInvitation = {
  __typename?: 'OrganizationInvitation';
  /** One or more cards that will be claimed as part of joining the organization. */
  cards: Array<CardInvitation>;
  /** Time the invitation was created. */
  createdAt: Scalars['Timestamp'];
  /**
   * If the invitation was sent to an email address through HiHello,
   * this is that address.
   */
  email?: Maybe<Scalars['String']>;
  /** The invitation will no longer be valid after this time. */
  expiresAt: Scalars['Timestamp'];
  /** The link for accepting this invitation. */
  link: Scalars['String'];
  /** The name of the person being sent the invitation. */
  name?: Maybe<Scalars['String']>;
  /** The organization the claimer would be joining. */
  organization?: Maybe<Organization>;
  /**
   * If the invitation was sent via SMS through HiHello,
   * this is the phone number to which it was sent.
   */
  phone?: Maybe<Scalars['String']>;
  /**
   * If false, the link will be made invalid when it is used once.
   * Otherwise it can be used by multiple users to claim the same card.
   */
  reusable: Scalars['Boolean'];
  /** The last update time of the invitation. */
  updatedAt: Scalars['Timestamp'];
};

export enum OrganizationSearchFields {
  Email = 'EMAIL',
  Name = 'NAME',
}

export enum OrganizationSortFields {
  Name = 'NAME',
}

/** Feature Flag OrganizationTargetTypes. */
export enum OrganizationTargetTypes {
  Both = 'BOTH',
  Individual = 'INDIVIDUAL',
  Self = 'SELF',
}

export type OrganizationUpdateInput = {
  authConfiguration?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  maxUsers?: InputMaybe<Scalars['Float']>;
  membersCanCreateCards?: InputMaybe<Scalars['Boolean']>;
  membersCanSeeCards?: InputMaybe<Scalars['Boolean']>;
  membersCanUseLoginTokens?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  newAdminIds?: InputMaybe<Array<Scalars['String']>>;
  newMemberIds?: InputMaybe<Array<Scalars['String']>>;
  newOwnerId?: InputMaybe<Scalars['String']>;
  plan?: InputMaybe<Plan>;
  removeUserIds?: InputMaybe<Array<Scalars['String']>>;
  stripe?: InputMaybe<StripeInput>;
  subscriptionStatus?: InputMaybe<SubscriptionStatus>;
  supportAdminIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Includes an organization user and the level of access a user has to that organization. */
export type OrganizationUserAccess = {
  __typename?: 'OrganizationUserAccess';
  access: OrganizationAccessType;
  organization: Organization;
};

export type OrganizationUserSearchInput = {
  organizationId?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirections>;
  sortField?: InputMaybe<OrganizationUserSortField>;
};

export type OrganizationUserSearchOutput = {
  __typename?: 'OrganizationUserSearchOutput';
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  results: Array<UserOrganizationAccess>;
  totalFound: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** Fields that can be used to sort organization user list */
export enum OrganizationUserSortField {
  AccountName = 'ACCOUNT_NAME',
  Email = 'EMAIL',
  Role = 'ROLE',
  UserId = 'USER_ID',
}

export type OrganizationsSearchInput = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<OrganizationSearchFields>>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirections>;
  sortField?: InputMaybe<OrganizationSortFields>;
};

export type OrganizationsSearchOutput = {
  __typename?: 'OrganizationsSearchOutput';
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  results: Array<Organization>;
  totalFound: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type OutlookAddInOutput = {
  __typename?: 'OutlookAddInOutput';
  cardURL?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<AutomatedEmailSignatureDisclaimer>;
  email?: Maybe<Scalars['String']>;
  emailSignatureURL?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Palette model. */
export type Palette = {
  __typename?: 'Palette';
  dark: Color;
  light: Color;
};

/** Palette input. */
export type PaletteInput = {
  dark: ColorInput;
  light: ColorInput;
};

/** Type of Paragon integration */
export enum ParagonIntegrationType {
  Dynamics = 'DYNAMICS',
}

export type PercentageFlagInputV2 = {
  default: PercentageFlagInputVariationsV2;
  description?: InputMaybe<Scalars['String']>;
  /**
   * Whether this feature flag is product driven. If undefined, it is assumed to be true.
   * Only product driven feature flags are surfaced in the getFlagValuesV2 query.
   */
  isProductFeature?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  percentageTargetType: PercentageRollOutTargetTypes;
  type: FeatureFlagTypes;
  variations: Array<PercentageFlagInputVariationsV2>;
};

export type PercentageFlagInputVariationsV2 = {
  name: Scalars['String'];
  organizationTargets?: InputMaybe<OrganizationTargetTypes>;
  percentageValue: Scalars['Float'];
  usersTargeted: Array<Scalars['String']>;
  value: Scalars['String'];
};

export type PercentageFlagUpdateInputV2 = {
  default?: InputMaybe<PercentageFlagInputVariationsV2>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  /** Whether this feature flag is product driven. Only product driven feature flags are surfaced in the getFlagValuesV2 query. */
  isProductFeature?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  percentageTargetType?: InputMaybe<PercentageRollOutTargetTypes>;
  type?: InputMaybe<FeatureFlagTypes>;
  variations?: InputMaybe<Array<PercentageFlagInputVariationsV2>>;
};

/** Available PercentageRollOutTargetTypes */
export enum PercentageRollOutTargetTypes {
  FreeUsers = 'FREE_USERS',
  FreeUsersInAnOrg = 'FREE_USERS_IN_AN_ORG',
  Organizations = 'ORGANIZATIONS',
  ProfessionalUsers = 'PROFESSIONAL_USERS',
}

/** Phone Field model. */
export type PhoneField = {
  __typename?: 'PhoneField';
  e164: Scalars['String'];
  extension?: Maybe<Scalars['String']>;
  isSmsOnly?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  templateFieldId?: Maybe<Scalars['ID']>;
  text: Scalars['String'];
  type: FieldType;
};

/** Available Plans. */
export enum Plan {
  Basic = 'BASIC',
  Business = 'BUSINESS',
  BusinessExtraCardscan = 'BUSINESS_EXTRA_CARDSCAN',
  Enterprise = 'ENTERPRISE',
  EnterpriseExtraCardscan = 'ENTERPRISE_EXTRA_CARDSCAN',
  Free = 'FREE',
  Professional = 'PROFESSIONAL',
  ProfessionalExtraCardscan = 'PROFESSIONAL_EXTRA_CARDSCAN',
}

/** Profile (Graph of HiHelloAccount) */
export type Profile = {
  __typename?: 'Profile';
  access: OrganizationAccessType;
  canCreateCards: Scalars['Boolean'];
  /** @deprecated Should be removed from clients */
  emailSignatureExportType?: Maybe<EmailSignatureSupported>;
  emails: Array<Scalars['String']>;
  features: ProfileFeatures;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  notificationSettings: AccountNotificationSettings;
  photo?: Maybe<ImageMedia>;
  plan: Plan;
  stripe?: Maybe<Stripe>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
  theme: ProfileTheme;
  type: Type;
};

/** Profile Features model. */
export type ProfileFeatures = {
  __typename?: 'ProfileFeatures';
  analytics: Scalars['Boolean'];
  badges: Scalars['Boolean'];
  cardScanning: Scalars['Boolean'];
  cardSharing: Scalars['Boolean'];
  customColors: Scalars['Boolean'];
  customDesign: Scalars['Boolean'];
  customUrl: Scalars['Boolean'];
  fileAttachments: Scalars['Boolean'];
  googleSync: Scalars['Boolean'];
  hihelloBranding: Scalars['Boolean'];
  liveAvatars: Scalars['Boolean'];
  maxCards: Scalars['Float'];
  maxEmailShares: Scalars['Float'];
  maxScans: Scalars['Float'];
  maxSmsShares: Scalars['Float'];
  notes: Scalars['Boolean'];
  qrLogo: Scalars['Boolean'];
  smsViaHihello: Scalars['Boolean'];
  tags: Scalars['Boolean'];
  widgets: Scalars['Boolean'];
  zohoSync: Scalars['Boolean'];
};

/** Profile theme model. */
export type ProfileTheme = {
  __typename?: 'ProfileTheme';
  palette: Palette;
};

/** User provider model. */
export type ProviderData = {
  __typename?: 'ProviderData';
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  provider?: Maybe<AuthenticationProvider>;
  /** @deprecated Use provider instead */
  providerId?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
};

export type ProviderDataInput = {
  displayName: Scalars['String'];
  email: Scalars['String'];
  providerId: Scalars['String'];
  uid: Scalars['String'];
};

/** QR Logo media object */
export type QrLogoMedia = {
  __typename?: 'QRLogoMedia';
  type: QrLogoMediaType;
  uri: Scalars['String'];
  variants?: Maybe<ImageVariants>;
};

/** QRLogo Media Types (HiHello, Org, Custom, Empty) */
export enum QrLogoMediaType {
  Custom = 'CUSTOM',
  Empty = 'EMPTY',
  Hihello = 'HIHELLO',
  Organization = 'ORGANIZATION',
  Template = 'TEMPLATE',
}

export type Query = {
  __typename?: 'Query';
  checkIfGoogleAdminInFileIsValid: Scalars['Boolean'];
  checkIfValidMagicLinkToken: Scalars['Boolean'];
  checkout: StripeCheckoutSession;
  checkoutWithDealCode: StripeCheckoutSession;
  /** @deprecated Use deleteUser mutation instead */
  deleteUser: Scalars['String'];
  getAllDomainsForGoogleWorkspace?: Maybe<Array<Scalars['String']>>;
  getAllFeaturesRequested: Array<FeatureRequestType>;
  getAllFlags: Array<FeatureFlag>;
  getAuditLogsByType: Array<AuditLog>;
  getAutomatedEmailSignatures: AutomatedEmailSignaturePaginatedResponse;
  getAutomatedEmailSignaturesFeatureUsage: Array<AutomatedEmailSignaturesUsage>;
  getAutomatedEmailSignaturesOutlookUsage: Scalars['Boolean'];
  getAutomatedEmailSignaturesWithSearch: AutomatedEmailSignatureSearchOutput;
  /** Temporarily, until we can support multiple templates tied to a single external group, an external group that already has a template association will not be returned from this query. */
  getAvailableExternalGroups: Array<ExternalDirectoryGroup>;
  getCard: Card;
  getCardAnalytics: CardAnalyticsData;
  getCardAnalyticsCSV: Scalars['String'];
  getCardScanAccountStatus: CardScanAccountStatus;
  getCardScanAnalytics: Array<CardScanJobAnalytics>;
  getCardScanJobForReview: CardScanJob;
  getCardScanJobsForReview: Array<CardScanJob>;
  getCardWithTemplatePreview: Card;
  getCards: Array<Card>;
  getCardsV2: CardsSearchOutput;
  getCheckoutSession: StripeCheckoutSession;
  getConnectedAddressBooks: Array<ContactSyncConnection>;
  getContact: Contact;
  getContacts: ContactsSearchOutput;
  getCurrentReviewerAnalytics: CardScanJobAnalytics;
  getDesigns: Designs;
  getEmailSignatureJobForOutlook?: Maybe<OutlookAddInOutput>;
  getEmailSignatureJobForOutlookV2?: Maybe<OutlookAddInOutput>;
  getExternalDirectoryEnabledList: WorkOsConnection;
  getFlagById: FeatureFlag;
  /**
   * Only product driven feature flags are surfaced in this query. All other flags
   * (at this point, namely SAML/SCIM ones) are excluded.
   */
  getFlagValuesV2: Array<FeatureFlagUserOutputV2>;
  getIterableToken: IterableToken;
  /**
   * For internal use only, if the idOrStripeOrEmail parameter is provided.
   * Otherwise, if the current user has access to an organization profile, then that organization is returned.
   */
  getOrganization: Organization;
  /** Retrieves the corporate directory, aka one's organization contacts. */
  getOrganizationDirectory: CardsSearchOutput;
  getOrganizationUsers: OrganizationUserSearchOutput;
  getOrganizations: OrganizationsSearchOutput;
  getSharedCard: Card;
  getSingleCardScanJobsForReview?: Maybe<CardScanJob>;
  getStripeBillingPortalSession: Scalars['String'];
  getTag: Tag;
  getTags: TagsSearchOutput;
  getTemplate: Template;
  getTemplates: Array<Template>;
  getTotalCardScanJobsInQueue: Scalars['Float'];
  getUser: User;
  getUsers: UsersSearchOutput;
  getWorkOSAdminPortalLink: Scalars['String'];
  /**
   * Determines if the required Google marketplace application for HiHello automated email
   * signatures has been installed by the organization that owns a particular domain.
   * If a magic token is provided, that domain will be the domain of the user making the
   * request.  If not, it will be the domain of the Google Workspace admin (falling back
   * on the domain of the user making the request if there is no Google Workspace admin
   * stored in the database.)
   */
  hasInstalledGoogleApp: Scalars['Boolean'];
  isCustomCardUrlAvailable: Scalars['Boolean'];
  me: User;
  searchCardsAndTemplates: CardAndTemplatesSearchOutput;
  /**
   * Sends an email to the authenticated user with a magic link to sign in.
   * Returns the email address the link was sent to.
   * If the user has no email address, an error is thrown.
   */
  sendLoginToken: Scalars['String'];
};

export type QueryCheckIfValidMagicLinkTokenArgs = {
  token: Scalars['String'];
};

export type QueryCheckoutArgs = {
  interval: SubscriptionInterval;
  plan: Plan;
  quantity?: InputMaybe<Scalars['Int']>;
};

export type QueryCheckoutWithDealCodeArgs = {
  dealCode: Scalars['String'];
  interval: SubscriptionInterval;
  plan: Plan;
};

export type QueryDeleteUserArgs = {
  id: Scalars['String'];
};

export type QueryGetAuditLogsByTypeArgs = {
  event: EventTypes;
  limit: Scalars['Int'];
  startAt?: InputMaybe<Scalars['String']>;
};

export type QueryGetAutomatedEmailSignaturesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  sortDirection?: InputMaybe<SortDirections>;
  sortField?: InputMaybe<AutomatedEmailSignatureSortField>;
  startAfter?: InputMaybe<Scalars['String']>;
};

export type QueryGetAutomatedEmailSignaturesWithSearchArgs = {
  input: AutomatedEmailSignatureSearchInput;
};

export type QueryGetAvailableExternalGroupsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirections>;
  sortField?: InputMaybe<ExternalDirectoryGroupSortFields>;
  startAfter?: InputMaybe<Scalars['String']>;
};

export type QueryGetCardArgs = {
  id: Scalars['String'];
};

export type QueryGetCardAnalyticsArgs = {
  dateInterval: DateInterval;
  endDate: Scalars['String'];
  filters: Array<CardAnalyticsFilter>;
  mode: AnalyticMode;
  startDate: Scalars['String'];
};

export type QueryGetCardAnalyticsCsvArgs = {
  accountId: Scalars['String'];
  aggregateDate: DateInterval;
  aggregateType: AggregateType;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type QueryGetCardScanAccountStatusArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  startAt?: InputMaybe<Scalars['String']>;
};

export type QueryGetCardScanAnalyticsArgs = {
  endTime: Scalars['Float'];
  startTime: Scalars['Float'];
};

export type QueryGetCardScanJobForReviewArgs = {
  id: Scalars['String'];
};

export type QueryGetCardScanJobsForReviewArgs = {
  limit: Scalars['Int'];
  startAt?: InputMaybe<Scalars['String']>;
  state: JobState;
  type: JobType;
};

export type QueryGetCardWithTemplatePreviewArgs = {
  cardId: Scalars['String'];
  templateId: Scalars['String'];
};

export type QueryGetCardsArgs = {
  includeInactive?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortDirection?: InputMaybe<SortDirections>;
  sortField?: InputMaybe<CardSortFields>;
  startAfter?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['String']>;
};

export type QueryGetCardsV2Args = {
  input: CardsSearchInput;
};

export type QueryGetCheckoutSessionArgs = {
  id: Scalars['String'];
};

export type QueryGetContactArgs = {
  id: Scalars['String'];
};

export type QueryGetContactsArgs = {
  input: ContactsSearchInput;
};

export type QueryGetDesignsArgs = {
  cardId?: InputMaybe<Scalars['String']>;
};

export type QueryGetEmailSignatureJobForOutlookArgs = {
  middleAccessToken: Scalars['String'];
};

export type QueryGetEmailSignatureJobForOutlookV2Args = {
  accessToken: Scalars['String'];
  errorMessage?: InputMaybe<Scalars['String']>;
};

export type QueryGetExternalDirectoryEnabledListArgs = {
  organizationAccountId: Scalars['String'];
};

export type QueryGetFlagByIdArgs = {
  flagId: Scalars['String'];
};

export type QueryGetFlagValuesV2Args = {
  perUser: Scalars['Boolean'];
};

export type QueryGetIterableTokenArgs = {
  key: Scalars['String'];
};

export type QueryGetOrganizationArgs = {
  idOrStripeOrEmail?: InputMaybe<Scalars['String']>;
};

export type QueryGetOrganizationDirectoryArgs = {
  input: CardsSearchInput;
  organizationId: Scalars['String'];
};

export type QueryGetOrganizationUsersArgs = {
  input: OrganizationUserSearchInput;
};

export type QueryGetOrganizationsArgs = {
  input: OrganizationsSearchInput;
};

export type QueryGetSharedCardArgs = {
  channel?: InputMaybe<AnalyticEventChannel>;
  logView?: InputMaybe<Scalars['Boolean']>;
  publicId: Scalars['String'];
  subdomain?: InputMaybe<Scalars['String']>;
};

export type QueryGetStripeBillingPortalSessionArgs = {
  returnUrl: Scalars['String'];
};

export type QueryGetTagArgs = {
  id: Scalars['String'];
};

export type QueryGetTagsArgs = {
  input: TagsSearchInput;
};

export type QueryGetTemplateArgs = {
  id: Scalars['String'];
};

export type QueryGetUserArgs = {
  idOrEmail: Scalars['String'];
};

export type QueryGetUsersArgs = {
  input: UsersSearchInput;
};

export type QueryGetWorkOsAdminPortalLinkArgs = {
  type: WorkOsAdminPortalLinkType;
};

export type QueryHasInstalledGoogleAppArgs = {
  magicToken?: InputMaybe<Scalars['String']>;
};

export type QueryIsCustomCardUrlAvailableArgs = {
  slug: Scalars['String'];
  subdomain?: InputMaybe<Scalars['String']>;
};

export type QuerySearchCardsAndTemplatesArgs = {
  input: CardAndTemplatesSearchInput;
};

export type QuerySendLoginTokenArgs = {
  destination?: InputMaybe<WebAppEndpoint>;
};

export enum RecoveryType {
  Restore = 'RESTORE',
  Resync = 'RESYNC',
}

/**
 * Input for sharing a card by sms or email.
 * Providing phone will send sms, providing whatsapp will send whatsapp, providing email will send
 * email. If both an email and either a phone or a whatsapp is provided, it will
 * be sent to both, but multiples of any one type are not supported (and for these purposes,
 * phone and whatsapp are lumped together as a single type.
 */
export type ShareCardInput = {
  /** ID of the card to be shared. */
  cardId: Scalars['String'];
  /** The card details to be sent (mainly to get the name, title, and company and contact information). */
  details: CardDetailsInput;
  /** Optional message to include with the card being sent. */
  message?: InputMaybe<Scalars['String']>;
};

/** Directions in which list sorting can occur. */
export enum SortDirections {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** Standard External Directory model. */
export type StandardDirectory = {
  __typename?: 'StandardDirectory';
  /** Whether or not there is an active connection configured with this external directory. This does not mean that any groups are necessarily syncing, only that the necessary credentials are in place in order to begin syncing groups. */
  connected: Scalars['Boolean'];
  /**
   * Groups that are currently syncing from the external directory. Note that clients should
   * only be querying this field if absolutely necessary, since including this field will greatly slow down the
   * query. This is because it fetches all external directory groups and counts of their members, and requires
   * querying external providers (Entra, Google) to do so.
   */
  groups: Array<ExternalDirectoryGroup>;
  /** The type of external directory connected. There can only be one per organization. */
  type: ExternalDirectoryType;
};

export type StringRecoveryData = {
  __typename?: 'StringRecoveryData';
  type: RecoveryType;
  value: Scalars['String'];
};

/** Stripe model. */
export type Stripe = {
  __typename?: 'Stripe';
  address?: Maybe<StripeAddress>;
  customerId: Scalars['String'];
  dashboard: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type StripeAddress = {
  __typename?: 'StripeAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type StripeAddressInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  state: Scalars['String'];
};

export type StripeCheckoutSession = {
  __typename?: 'StripeCheckoutSession';
  id: Scalars['String'];
  interval?: Maybe<SubscriptionInterval>;
  plan?: Maybe<Plan>;
};

export type StripeInput = {
  address: StripeAddressInput;
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  automatedEmailSignatureUpdate?: Maybe<AutomatedEmailSignatureSubscriptionData>;
  cardScanJobUpdate: CardScanJobSubscriptionData;
};

export enum SubscriptionCurrency {
  Usd = 'USD',
}

export type SubscriptionInfo = {
  __typename?: 'SubscriptionInfo';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<SubscriptionCurrency>;
  currentPeriodEndDate?: Maybe<Scalars['Timestamp']>;
  currentPeriodStartDate?: Maybe<Scalars['Timestamp']>;
  interval?: Maybe<SubscriptionInterval>;
  startDate?: Maybe<Scalars['Timestamp']>;
  status?: Maybe<SubscriptionStatus>;
};

export enum SubscriptionInterval {
  Day = 'Day',
  Month = 'Month',
  Week = 'Week',
  Year = 'Year',
}

export enum SubscriptionProvider {
  Apple = 'APPLE',
  Google = 'GOOGLE',
  Stripe = 'STRIPE',
}

/** Status of the user's paid subscription */
export enum SubscriptionStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  Incomplete = 'Incomplete',
  IncompleteExpired = 'IncompleteExpired',
  PastDue = 'PastDue',
  Trialing = 'Trialing',
  Unpaid = 'Unpaid',
}

export type SyncAllOptionsInput = {
  externalCategory?: InputMaybe<ExternalContactCategory>;
  syncToExternal: Scalars['Boolean'];
  templateIds?: InputMaybe<Array<Scalars['String']>>;
};

export type SyncExportSetting = {
  __typename?: 'SyncExportSetting';
  card: Card;
  externalCategory?: Maybe<Scalars['String']>;
  lastSync: LastSyncStatusSync;
  sync: Scalars['Boolean'];
};

export type SyncImportSetting = {
  __typename?: 'SyncImportSetting';
  lastSync: LastSyncStatusSync;
  sync: Scalars['Boolean'];
};

/** Tag model. */
export type Tag = {
  __typename?: 'Tag';
  /**
   * If the tag is used for external syncing, this field will return that sync information.
   * Otherwise, if it is not used for external syncing, this field will be null.
   */
  addressBookSync?: Maybe<SyncImportSetting>;
  /** Returns whether or not the current account can edit the tag. Externally synced tags cannot be edited and only the owner of the tag can edit it. */
  canEdit: Scalars['Boolean'];
  /**
   * Returns whether or not the current account has access to use the tag on contacts.
   *   If can use is true, it means they're able to use this tag. There may be some restrictions for specific contacts (ex: they can use their organization's tags, but only on contacts that org owns.).
   *   If can use is false, it means they cannot use this tag on any contacts because they either don't have access to or because it was automatically generated for external syncing.
   */
  canUse: Scalars['Boolean'];
  createdAt: Scalars['Timestamp'];
  description?: Maybe<Scalars['String']>;
  icon: TagIcon;
  id: Scalars['ID'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
};

/** Tag icon input model. */
export type TagAddInput = {
  /** An array of contact IDs to add the tag to after the tag is created. */
  contactIds?: InputMaybe<Array<Scalars['String']>>;
  /** A description for the tag. */
  description?: InputMaybe<Scalars['String']>;
  /** The name of the tag. */
  name: Scalars['String'];
  /** The icon for the tag. This can be either an icon or an emoji. */
  tagIcon?: InputMaybe<TagIconInput>;
};

/**
 * Fields that can be used to filter the tags search.
 * Note: Organization accounts can only query for tags owned by their account, so The OWNER_ACCOUNT_TYPE and ORGANIZATION_ID filters will be ignored for organization accounts.
 */
export enum TagFilterField {
  /**
   * Filter for tags that the current account has access to use. Only a value of true or null will be accepted for this filter. Only one can use filter can be specified in the query.
   *   This is a generic check for the current account's access to use specific tags, see notes on the canUse field in the Tag object for additional details.
   *   It does not check if each tag can be used on a specific contact. Use this filter with the CONTACT_ID filter for that scenario.
   */
  CanUse = 'CAN_USE',
  /**
   * Filter for tags that the current account has permissions to use on a specific contact. The CAN_USE filter must be specified as well to use this filter.
   *   Only one contact ID filter can be specified in the query.
   */
  ContactId = 'CONTACT_ID',
  /** Filter for tags owned by a specific organization. The OWNER_TYPE filter must be sent with a value of "organization" to use this filter. Multiple organization ID filters can be specified in the query. */
  OrganizationId = 'ORGANIZATION_ID',
  /**
   * Filter by the tag owner type, including "person" or "organization" (based on the Account Type enum in the API package). Only one owner type filter can be specified in the query.
   *   Filtering by "person" will only include tags owned by the current user.
   *   Filtering by "organization" will either include tags owned by all organizations the user is part of or only include tags owned by the organizations specified in any ORGANIZATION_ID filters.
   */
  OwnerType = 'OWNER_TYPE',
}

export type TagFilterFieldInput = {
  field: TagFilterField;
  value?: InputMaybe<Scalars['String']>;
};

export type TagIcon = Emoji | Icon;

/**
 * Tag icon input model.
 * For the tag icon, you can either provide an icon or an emoji.
 */
export type TagIconInput = {
  emoji?: InputMaybe<EmojiInput>;
  icon?: InputMaybe<IconInput>;
};

/** Fields that can be used to search the tags by. */
export enum TagSearchField {
  Name = 'NAME',
}

/** Fields that can be used to sort the tags search results. */
export enum TagSortField {
  /** Sort by the date the tag was added/created. */
  CreatedAt = 'CREATED_AT',
  /** Sort by the name of the tag. */
  Name = 'NAME',
  /**
   * Sort by the order of the tag.
   * This is a custom order specific to the current account. If returning tags from multiple accounts, the ordering will not be accurate.
   */
  Order = 'ORDER',
  /** Sort by the date the tag was last updated. */
  UpdatedAt = 'UPDATED_AT',
}

/**
 * Input for updating a tag.
 * Only the fields passed to the tag will be updated.
 * If the user does not have permission to edit the tag but still has permission to use the tag,
 *   they should only pass in the contactIdsToAdd and contactIdsToRemove fields to add or remove the tag from contacts.
 */
export type TagUpdateInput = {
  /** An array of contact IDs to add the tag to. */
  contactIdsToAdd?: InputMaybe<Array<Scalars['String']>>;
  /** An array of contact IDs to remove the tag from. */
  contactIdsToRemove?: InputMaybe<Array<Scalars['String']>>;
  /** The updated description of the tag. If null, the description will be removed. */
  description?: InputMaybe<Scalars['String']>;
  /** The ID of the tag. */
  id: Scalars['ID'];
  /** The updated tag name. This field is required on the tag and cannot be removed. */
  name?: InputMaybe<Scalars['String']>;
  /** The updated tag order. */
  order?: InputMaybe<Scalars['Int']>;
  /**
   * The updated icon for the tag. This can be either an icon or an emoji.
   * If switching from an icon to an emoji or vice versa, the one that is not being set will be removed.
   */
  tagIcon?: InputMaybe<TagIconInput>;
};

export type TagsSearchInput = {
  filters?: InputMaybe<Array<TagFilterFieldInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  searchFields?: InputMaybe<Array<TagSearchField>>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<SortDirections>;
  sortField?: InputMaybe<TagSortField>;
};

export type TagsSearchOutput = {
  __typename?: 'TagsSearchOutput';
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  results: Array<Tag>;
  totalFound: Scalars['Int'];
  totalPages: Scalars['Int'];
};

/** Template model. */
export type Template = {
  __typename?: 'Template';
  cards: Array<Card>;
  createdAt: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  design: TemplateDesign;
  details: TemplateDetails;
  /** For each group in this array, a corresponding card will be created/maintained based on this template for each member of that group. */
  externalDirectoryGroups: Array<ExternalDirectoryGroup>;
  /** Identifies fields in the template that are currently configured in a way that prevents manually creating cards from this template. Specifically, fields cannot be externally syncing, required, and non-editable, without a template default value, if the template is going to be used to create cards manually. */
  fieldsPreventingUnsyncedCards: Array<Scalars['String']>;
  /** True if one or more cards linked to this template is syncing content from an external source. */
  hasExternallySyncedCards: Scalars['Boolean'];
  /** True if one or more cards linked to this template is not created from an external source. */
  hasUnsyncedCards: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  /** The number of cards that use this template. */
  numCards: Scalars['Int'];
  order: Scalars['Float'];
  owner?: Maybe<Organization>;
  /** True if this template can be used for syncing content from an external source. */
  supportsExternalSyncing: Scalars['Boolean'];
  theme: TemplateTheme;
  trackingCodes: Array<TrackingCode>;
  updatedAt: Scalars['Float'];
};

export type TemplateAddInput = {
  description?: InputMaybe<Scalars['String']>;
  design?: InputMaybe<DesignInput>;
  details?: InputMaybe<TemplateDetailsInput>;
  /** For each group in this array, a corresponding card will be created/maintained based on this template for each member of that group. */
  externalDirectoryGroupExternalIds?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  theme?: InputMaybe<TemplateThemeInput>;
  trackingCodes?: InputMaybe<Array<TrackingCodeInput>>;
};

/** Avatar Template */
export type TemplateAvatar = {
  __typename?: 'TemplateAvatar';
  avatars: Array<TemplateMedia>;
  externalSource?: Maybe<Scalars['String']>;
  isEditable: Scalars['Boolean'];
  state: TemplateFieldState;
};

/** Badge Template */
export type TemplateBadge = {
  __typename?: 'TemplateBadge';
  badges: Array<ImageMedia>;
  externalSource?: Maybe<Scalars['String']>;
  isEditable: Scalars['Boolean'];
  state: TemplateFieldState;
};

/** Template Information FieldGroups Field model. */
export type TemplateBaseField = {
  __typename?: 'TemplateBaseField';
  externalSource?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  state: TemplateFieldState;
  text?: Maybe<Scalars['String']>;
  type: FieldType;
};

/** Display template model. */
export type TemplateDesign = {
  __typename?: 'TemplateDesign';
  design: Design;
  externalSource?: Maybe<Scalars['String']>;
  isEditable: Scalars['Boolean'];
  state: TemplateFieldState;
};

/** Template Details model. */
export type TemplateDetails = {
  __typename?: 'TemplateDetails';
  avatar: TemplateAvatar;
  badge: TemplateBadge;
  bannedFields: Array<FieldType>;
  company: TemplateInformationCompany;
  department: TemplateInformationDepartment;
  headline: TemplateInformationHeadline;
  logo: TemplateLogo;
  name: TemplateInformationName;
  templateFields: Array<TemplateFields>;
  title: TemplateInformationTitle;
};

export type TemplateDetailsInput = {
  avatar?: InputMaybe<TemplateDisplayAvatarInput>;
  badge?: InputMaybe<TemplateDisplayBadgeInput>;
  bannedFields?: InputMaybe<Array<FieldType>>;
  company?: InputMaybe<TemplateInformationCompanyInput>;
  department?: InputMaybe<TemplateInformationDepartmentInput>;
  headline?: InputMaybe<TemplateInformationHeadlineInput>;
  logo?: InputMaybe<TemplateDisplayLogoInput>;
  name?: InputMaybe<TemplateInformationNameInput>;
  templateFields?: InputMaybe<Array<TemplateFieldInput>>;
  title?: InputMaybe<TemplateInformationTitleInput>;
};

/** Template Display Avatar Input model. */
export type TemplateDisplayAvatarInput = {
  avatars: Array<TemplateDisplayAvatarsInput>;
  externalSource?: InputMaybe<Scalars['String']>;
  isEditable: Scalars['Boolean'];
  state: TemplateFieldState;
};

/** Template Display Avatars Input model. */
export type TemplateDisplayAvatarsInput = {
  includesAudio?: InputMaybe<Scalars['Boolean']>;
  loop?: InputMaybe<Scalars['Boolean']>;
  uri: Scalars['String'];
};

/** Template Display Badge Input model. */
export type TemplateDisplayBadgeInput = {
  badges: Array<TemplateDisplayBadgesInput>;
  externalSource?: InputMaybe<Scalars['String']>;
  isEditable: Scalars['Boolean'];
  state: TemplateFieldState;
};

/** Template Display Badges Input model. */
export type TemplateDisplayBadgesInput = {
  uri: Scalars['String'];
};

/** Template Display Logo Input model. */
export type TemplateDisplayLogoInput = {
  externalSource?: InputMaybe<Scalars['String']>;
  isEditable: Scalars['Boolean'];
  logos: Array<TemplateDisplayLogosInput>;
  state: TemplateFieldState;
};

/** Template Display Logos Input model. */
export type TemplateDisplayLogosInput = {
  uri: Scalars['String'];
};

/** Template field input model. */
export type TemplateFieldInput = {
  e164?: InputMaybe<Scalars['String']>;
  extension?: InputMaybe<Scalars['String']>;
  externalSource?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isEditable: Scalars['Boolean'];
  isSmsOnly?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  state: TemplateFieldState;
  text?: InputMaybe<Scalars['String']>;
  type: FieldType;
  uri?: InputMaybe<Scalars['String']>;
  widget?: InputMaybe<WidgetInput>;
};

export enum TemplateFieldState {
  Optional = 'OPTIONAL',
  Prohibited = 'PROHIBITED',
  Required = 'REQUIRED',
}

export type TemplateFields = TemplateBaseField | TemplatePhoneField | TemplateUrlField;

/** Template Information Company model. */
export type TemplateInformationCompany = {
  __typename?: 'TemplateInformationCompany';
  externalSource?: Maybe<Scalars['String']>;
  isEditable: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  state: TemplateFieldState;
};

/** Template Information Company Input model. */
export type TemplateInformationCompanyInput = {
  externalSource?: InputMaybe<Scalars['String']>;
  isEditable: Scalars['Boolean'];
  name?: InputMaybe<Scalars['String']>;
  state: TemplateFieldState;
};

/** Template Information Department model. */
export type TemplateInformationDepartment = {
  __typename?: 'TemplateInformationDepartment';
  externalSource?: Maybe<Scalars['String']>;
  isEditable: Scalars['Boolean'];
  state: TemplateFieldState;
  text?: Maybe<Scalars['String']>;
};

/** Template Information Department Input model. */
export type TemplateInformationDepartmentInput = {
  externalSource?: InputMaybe<Scalars['String']>;
  isEditable: Scalars['Boolean'];
  state: TemplateFieldState;
  text?: InputMaybe<Scalars['String']>;
};

/** Template Information Headline model. */
export type TemplateInformationHeadline = {
  __typename?: 'TemplateInformationHeadline';
  externalSource?: Maybe<Scalars['String']>;
  isEditable: Scalars['Boolean'];
  state: TemplateFieldState;
  text?: Maybe<Scalars['String']>;
};

/** Template Information Headline Input model. */
export type TemplateInformationHeadlineInput = {
  externalSource?: InputMaybe<Scalars['String']>;
  isEditable: Scalars['Boolean'];
  state: TemplateFieldState;
  text?: InputMaybe<Scalars['String']>;
};

/** Template Information Company model. */
export type TemplateInformationName = {
  __typename?: 'TemplateInformationName';
  accreditations: TemplateInformationNameField;
  family: TemplateInformationNameField;
  given: TemplateInformationNameField;
  maiden: TemplateInformationNameField;
  middle: TemplateInformationNameField;
  preferred: TemplateInformationNameField;
  prefix: TemplateInformationNameField;
  pronouns: TemplateInformationNameField;
  suffix: TemplateInformationNameField;
};

/** Name component model. */
export type TemplateInformationNameComponentInput = {
  externalSource?: InputMaybe<Scalars['String']>;
  isEditable: Scalars['Boolean'];
  state: TemplateFieldState;
  text?: InputMaybe<Scalars['String']>;
};

/** Template Information Name model. */
export type TemplateInformationNameField = {
  __typename?: 'TemplateInformationNameField';
  externalSource?: Maybe<Scalars['String']>;
  isEditable: Scalars['Boolean'];
  state: TemplateFieldState;
  text?: Maybe<Scalars['String']>;
};

/** Name model. */
export type TemplateInformationNameInput = {
  accreditations: TemplateInformationNameComponentInput;
  family: TemplateInformationNameComponentInput;
  given: TemplateInformationNameComponentInput;
  maiden: TemplateInformationNameComponentInput;
  middle: TemplateInformationNameComponentInput;
  preferred: TemplateInformationNameComponentInput;
  prefix: TemplateInformationNameComponentInput;
  pronouns: TemplateInformationNameComponentInput;
  suffix: TemplateInformationNameComponentInput;
};

/** Template Information Company model. */
export type TemplateInformationTitle = {
  __typename?: 'TemplateInformationTitle';
  externalSource?: Maybe<Scalars['String']>;
  isEditable: Scalars['Boolean'];
  state: TemplateFieldState;
  text?: Maybe<Scalars['String']>;
};

/** Template Information Title Input model. */
export type TemplateInformationTitleInput = {
  externalSource?: InputMaybe<Scalars['String']>;
  isEditable: Scalars['Boolean'];
  state: TemplateFieldState;
  text?: InputMaybe<Scalars['String']>;
};

/** Logo Template */
export type TemplateLogo = {
  __typename?: 'TemplateLogo';
  externalSource?: Maybe<Scalars['String']>;
  isEditable: Scalars['Boolean'];
  logos: Array<ImageMedia>;
  state: TemplateFieldState;
};

export type TemplateMedia = ImageMedia | VideoMedia;

/** Template Information FieldGroups Field model. */
export type TemplatePhoneField = {
  __typename?: 'TemplatePhoneField';
  e164?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  externalSource?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  isSmsOnly?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  state: TemplateFieldState;
  text?: Maybe<Scalars['String']>;
  type: FieldType;
};

/** Template Theme model. */
export type TemplateTheme = {
  __typename?: 'TemplateTheme';
  externalSource?: Maybe<Scalars['String']>;
  isEditable: Scalars['Boolean'];
  palette?: Maybe<Palette>;
  qrLogos: Array<QrLogoMedia>;
  state: TemplateFieldState;
};

/** Template Theme input. */
export type TemplateThemeInput = {
  externalSource?: InputMaybe<Scalars['String']>;
  isEditable: Scalars['Boolean'];
  palette?: InputMaybe<PaletteInput>;
  qrLogo?: InputMaybe<MediaInput>;
  state: TemplateFieldState;
};

export type TemplateUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  design?: InputMaybe<DesignInput>;
  details?: InputMaybe<TemplateDetailsInput>;
  /** For each group in this array, a corresponding card will be created/maintained based on this template for each member of that group. */
  externalDirectoryGroupExternalIds?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<TemplateThemeInput>;
  trackingCodes?: InputMaybe<Array<TrackingCodeInput>>;
};

/** Template Information FieldGroups Field model. */
export type TemplateUrlField = {
  __typename?: 'TemplateUrlField';
  externalSource?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  state: TemplateFieldState;
  text?: Maybe<Scalars['String']>;
  type: FieldType;
  uri?: Maybe<Scalars['String']>;
  widget?: Maybe<Widget>;
};

export type ToggleAddressBookConnectionInput = {
  cardId: Scalars['String'];
  externalCategory?: InputMaybe<ExternalContactCategory>;
  syncToExternal: Scalars['Boolean'];
};

/** Token model. */
export type Token = {
  __typename?: 'Token';
  token: Scalars['String'];
};

/** Tracking Code model. */
export type TrackingCode = {
  __typename?: 'TrackingCode';
  type: TrackingCodeProvider;
  value: Scalars['String'];
};

export type TrackingCodeInput = {
  type: TrackingCodeProvider;
  value: Scalars['String'];
};

/** Tracking Code Providers */
export enum TrackingCodeProvider {
  GoogleAnalytics = 'GOOGLE_ANALYTICS',
  GoogleAnalyticsFour = 'GOOGLE_ANALYTICS_FOUR',
  GoogleTagManager = 'GOOGLE_TAG_MANAGER',
}

/** Account Types (Person or Organization) */
export enum Type {
  Organization = 'ORGANIZATION',
  Person = 'PERSON',
}

/** Url model. */
export type Url = {
  __typename?: 'Url';
  custom?: Maybe<CustomUrl>;
  publicId?: Maybe<Scalars['String']>;
  publicUrl?: Maybe<Scalars['String']>;
};

/** Url Field model. */
export type UrlField = {
  __typename?: 'UrlField';
  label?: Maybe<Scalars['String']>;
  templateFieldId?: Maybe<Scalars['ID']>;
  text: Scalars['String'];
  type: FieldType;
  uri: Scalars['String'];
  widget: Widget;
};

/** Firebase user. */
export type User = {
  __typename?: 'User';
  canCreateCards: Scalars['Boolean'];
  canUpdateEmail: Scalars['Boolean'];
  canUseLoginTokens: Scalars['Boolean'];
  cards?: Maybe<Array<Card>>;
  claims: Array<Claims>;
  countryCode?: Maybe<CountryCode>;
  createdAt?: Maybe<Scalars['Timestamp']>;
  email?: Maybe<Scalars['String']>;
  /** @deprecated Should be removed from clients */
  emailSignatureExportType?: Maybe<EmailSignatureSupported>;
  /**
   * This flag should be used to decide whether or not to give the user the
   * option to verify their email address. In most cases, but not all, if it
   * is true, it means that the email address was already verified via a link
   * sent to that email or that the user logged in with SSO from a provider
   * trusted to have verified ownership of the email before allowing the user
   * to authenticate. But there are other scenarios where the email should not
   * be relied upon as verified but we still shouldn't show the user the option
   * to verify it (an example is a WorkOS-connected user.)
   */
  emailVerified?: Maybe<Scalars['Boolean']>;
  features: ProfileFeatures;
  hideGoMobile?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /**
   * If the user has not completed required onboarding steps, this will be false
   * as a signal to the client to direct the user to the onboarding flow when
   * entering the application.
   */
  isOnboarded?: Maybe<Scalars['Boolean']>;
  lastLoggedInAt?: Maybe<Scalars['Timestamp']>;
  loginMethods?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  notificationSettings: AccountNotificationSettings;
  numContacts: Scalars['Int'];
  organizations?: Maybe<Array<OrganizationUserAccess>>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<ImageMedia>;
  plan: Plan;
  providerData: Array<ProviderData>;
  purchaseTokens?: Maybe<Array<Scalars['String']>>;
  qrLogo?: Maybe<Scalars['String']>;
  snapshotsSeen?: Maybe<Array<Scalars['String']>>;
  stripe?: Maybe<Stripe>;
  subscription?: Maybe<SubscriptionInfo>;
  subscriptionProvider?: Maybe<SubscriptionProvider>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
  theme: ProfileTheme;
};

/** Includes a user and that user's access level to a card. */
export type UserCardAccess = {
  __typename?: 'UserCardAccess';
  canEdit: Scalars['Boolean'];
  user: User;
};

/** Allow modification of a user's access level to a card. */
export type UserCardAccessInput = {
  /** If true the person claiming the card will be able to edit its contents, in accordance with template rules if applicable. */
  canEdit: Scalars['Boolean'];
  /** The id of the user whose access is being modified */
  userId: Scalars['String'];
};

export type UserInput = {
  countryCode?: InputMaybe<CountryCode>;
  displayName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  hideGoMobile?: InputMaybe<Scalars['Boolean']>;
  providerToLink?: InputMaybe<ProviderDataInput>;
  providersToUnlink?: InputMaybe<Array<Scalars['String']>>;
  stripe?: InputMaybe<StripeInput>;
};

/** Includes a user and that user's access level to an organization. */
export type UserOrganizationAccess = {
  __typename?: 'UserOrganizationAccess';
  access: OrganizationAccessType;
  user: User;
};

export type UserSettingsInput = {
  hideGoMobile?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  palette?: InputMaybe<PaletteInput>;
  photo?: InputMaybe<Scalars['String']>;
  qrLogo?: InputMaybe<Scalars['String']>;
  stripe?: InputMaybe<StripeInput>;
};

export type UsersSearchInput = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  searchTerm: Scalars['String'];
};

export type UsersSearchOutput = {
  __typename?: 'UsersSearchOutput';
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  results: Array<User>;
  totalPages: Scalars['Int'];
};

/** Video media object */
export type VideoMedia = {
  __typename?: 'VideoMedia';
  includesAudio: Scalars['Boolean'];
  loop: Scalars['Boolean'];
  uri: Scalars['String'];
  variants?: Maybe<VideoVariants>;
};

/** Video Variant model. */
export type VideoVariants = {
  __typename?: 'VideoVariants';
  animatedGif?: Maybe<Scalars['String']>;
  imageLarge?: Maybe<Scalars['String']>;
  imageSmall?: Maybe<Scalars['String']>;
  videoLarge?: Maybe<Scalars['String']>;
};

/** Destinations within the web app */
export enum WebAppEndpoint {
  Analytics = 'ANALYTICS',
  Backgrounds = 'BACKGROUNDS',
}

/** Widget model. */
export type Widget = {
  __typename?: 'Widget';
  enabled: Scalars['Boolean'];
  image?: Maybe<ImageMedia>;
};

/** Widget model. */
export type WidgetInput = {
  enabled: Scalars['Boolean'];
  image?: InputMaybe<MediaInput>;
};

/** Type of connection to configure */
export enum WorkOsAdminPortalLinkType {
  Dsync = 'dsync',
  Sso = 'sso',
}

export type WorkOsConnection = {
  __typename?: 'WorkOSConnection';
  saml?: Maybe<WorkOsProvider>;
  scim?: Maybe<WorkOsProvider>;
};

export enum WorkOsConnectionType {
  Both = 'BOTH',
  Saml = 'SAML',
  Scim = 'SCIM',
}

export enum WorkOsProvider {
  AccessPeopleHr = 'ACCESS_PEOPLE_HR',
  AdpOpenidConnect = 'ADP_OPENID_CONNECT',
  Auth0 = 'AUTH0',
  Bamboohr = 'BAMBOOHR',
  BreatheHr = 'BREATHE_HR',
  Cas = 'CAS',
  CezanneHr = 'CEZANNE_HR',
  Classlink = 'CLASSLINK',
  Cloudflare = 'CLOUDFLARE',
  Cyberark = 'CYBERARK',
  Duo = 'DUO',
  EntraId = 'ENTRA_ID',
  Fourth = 'FOURTH',
  GenericSaml = 'GENERIC_SAML',
  GenericScim = 'GENERIC_SCIM',
  Google = 'GOOGLE',
  Hibob = 'HIBOB',
  Jumpcloud = 'JUMPCLOUD',
  Keycloak = 'KEYCLOAK',
  Lastpass = 'LASTPASS',
  LoginGov = 'LOGIN_GOV',
  MicrosoftAdFs = 'MICROSOFT_AD_FS',
  Miniorange = 'MINIORANGE',
  Netiq = 'NETIQ',
  Okta = 'OKTA',
  Onelogin = 'ONELOGIN',
  OpenidConnect = 'OPENID_CONNECT',
  Oracle = 'ORACLE',
  Pingfederate = 'PINGFEDERATE',
  Pingone = 'PINGONE',
  Rippling = 'RIPPLING',
  Salesforce = 'SALESFORCE',
  Sftp = 'SFTP',
  ShibbolethGenericSaml = 'SHIBBOLETH_GENERIC_SAML',
  ShibbolethUnsolicitedSaml = 'SHIBBOLETH_UNSOLICITED_SAML',
  Vmware = 'VMWARE',
  Workday = 'WORKDAY',
}

export type GetUserDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserDataQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    email?: string | null;
    id: string;
    name?: string | null;
    providerData: Array<{ __typename?: 'ProviderData'; providerId?: string | null }>;
  };
};

export const GetUserDataDocument = `
    query GetUserData {
  user: me {
    id
    name
    email
    providerData {
      providerId
    }
  }
}
    `;
export const useGetUserDataQuery = <TData = GetUserDataQuery, TError = unknown>(
  variables?: GetUserDataQueryVariables,
  options?: UseQueryOptions<GetUserDataQuery, TError, TData>,
) =>
  useQuery<GetUserDataQuery, TError, TData>(
    variables === undefined ? ['GetUserData'] : ['GetUserData', variables],
    fetcher<GetUserDataQuery, GetUserDataQueryVariables>(GetUserDataDocument, variables),
    options,
  );
useGetUserDataQuery.document = GetUserDataDocument;

useGetUserDataQuery.getKey = (variables?: GetUserDataQueryVariables) =>
  variables === undefined ? ['GetUserData'] : ['GetUserData', variables];
