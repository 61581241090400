import { createIcon } from '@chakra-ui/react';

export const IconDuplicate: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconDuplicate',
  path: (
    <path
      d="M7.25 12.75H2C1.60218 12.75 1.22064 12.592 0.93934 12.3107C0.658035 12.0294 0.5 11.6478 0.5 11.25V2.25C0.5 1.85218 0.658035 1.47064 0.93934 1.18934C1.22064 0.908035 1.60218 0.75 2 0.75H11V2.25H2V11.25H7.25V9.75L10.25 12L7.25 14.25V12.75ZM13.25 15.75V5.25H5V9.75H3.5V5.25C3.5 4.85218 3.65804 4.47064 3.93934 4.18934C4.22064 3.90804 4.60218 3.75 5 3.75H13.25C13.6478 3.75 14.0294 3.90804 14.3107 4.18934C14.592 4.47064 14.75 4.85218 14.75 5.25V15.75C14.75 16.1478 14.592 16.5294 14.3107 16.8107C14.0294 17.092 13.6478 17.25 13.25 17.25H5C4.60218 17.25 4.22064 17.092 3.93934 16.8107C3.65804 16.5294 3.5 16.1478 3.5 15.75V14.25H5V15.75H13.25Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 15 18',
});
