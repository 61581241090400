import { StyleConfig, SystemStyleFunction, SystemStyleObject } from '@chakra-ui/theme-tools';
import { BoxVariant } from '../../../components/Card/layout';

const baseStyle: SystemStyleObject = {
  '&:empty': {
    display: 'none',
  },
  display: 'block',
  fontFamily: 'var(--nunito-sans,  Nunito Sans)',
};

const defaultVariants: Record<BoxVariant, SystemStyleObject | SystemStyleFunction> = {
  margin: {
    margin: '1.5rem',
  },
  padding: {
    padding: '1.5rem',
  },
  rounded: {
    borderRadius: '0.25rem',
    overflow: 'hidden',
  },
  surface: () => ({
    '&:after': {
      content: 'linear-gradient(transparent, transparent)',
      display: 'block',
      height: 0,
    },
    '&:before': {
      content: 'linear-gradient(transparent, transparent)',
      display: 'block',
      height: 0,
    },
    bg: 'bg-surface',
  }),
};

export const CardBox: StyleConfig = {
  baseStyle,
  defaultProps: {
    colorScheme: 'brand',
  },
  variants: defaultVariants,
};
