import { Box, useMultiStyleConfig, useStyleConfig } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { useStore } from 'zustand';
import { useCardStore } from '../../../store';
import { Media } from '../../Media';
import { HeaderVariantProps } from './props';

export const Flat: FunctionComponent<HeaderVariantProps> = ({ children, ...props }) => {
  const cardStore = useCardStore();
  const palette = useStore(cardStore, (state) => state.themePalette);
  const styles = useMultiStyleConfig('CardHeaderFlat', { palette });
  const boxStyles = useStyleConfig('CardBox', { variant: 'margin' });

  return (
    <Box __css={styles.container} as="header" className="CardHeaderFlat" {...props}>
      <Box __css={styles.avatar}>
        <Media variant="avatar" />
        <Box __css={styles.divider} />
      </Box>
      <Box __css={{ ...boxStyles, ...styles.logo }}>
        <Media variant="logo" />
      </Box>
      {children}
    </Box>
  );
};
