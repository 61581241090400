import { createIcon } from '@chakra-ui/react';

export const IconCreditCard: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconCreditCard',
  path: (
    <path
      d="M25.6666 11.6667V23.3333C25.6666 23.6428 25.5437 23.9395 25.3249 24.1583C25.1061 24.3771 24.8093 24.5 24.4999 24.5H3.49992C3.1905 24.5 2.89375 24.3771 2.67496 24.1583C2.45617 23.9395 2.33325 23.6428 2.33325 23.3333V11.6667H25.6666ZM25.6666 9.33333H2.33325V4.66667C2.33325 4.35725 2.45617 4.0605 2.67496 3.84171C2.89375 3.62292 3.1905 3.5 3.49992 3.5H24.4999C24.8093 3.5 25.1061 3.62292 25.3249 3.84171C25.5437 4.0605 25.6666 4.35725 25.6666 4.66667V9.33333ZM17.4999 18.6667V21H22.1666V18.6667H17.4999Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 28 28',
});
