import { mode, StyleConfig, SystemStyleFunction } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleFunction = (props) => {
  const { colorScheme: c } = props;

  let color = mode(`${c}.600`, `${c}.300`)(props);

  if (['black', 'white'].includes(c)) {
    color = c;
  }

  return {
    color,
  };
};

export const Link: StyleConfig = {
  baseStyle,
  defaultProps: {
    colorScheme: 'brand',
  },
};
