import { createIcon } from '@chakra-ui/react';

export const IconZapier: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconZapier',
  path: (
    <>
      <g clipPath="url(#clip0_1639_28092)">
        <path
          d="M9.87494 8.00328C9.8752 8.54414 9.77764 9.08059 9.58696 9.58672C9.08064 9.77738 8.54407 9.87509 8.00305 9.87518H7.99672C7.43961 9.87448 6.90582 9.77264 6.41328 9.58696C6.22249 9.08077 6.12482 8.54424 6.125 8.00328V7.99672C6.12474 7.45597 6.22221 6.91961 6.4127 6.41352C6.91898 6.22251 7.45567 6.12476 7.99678 6.125H8.00311C8.54416 6.12474 9.08079 6.22247 9.58702 6.41346C9.77772 6.9195 9.87528 7.45588 9.875 7.99666V8.00323L9.87494 8.00328ZM15.3958 6.75002H11.0179L14.1134 3.65434C13.8704 3.31304 13.5994 2.99261 13.3032 2.69639V2.69615C13.0069 2.40025 12.6865 2.12947 12.3454 1.88662L9.24975 4.98231V0.604414C8.83803 0.535152 8.42126 0.500227 8.00375 0.5L7.99602 0.5C7.57133 0.500234 7.15537 0.536328 6.75002 0.604414V4.98231L3.65434 1.88662C3.31317 2.12941 2.99297 2.40038 2.69709 2.69668L2.69545 2.69785C2.3997 2.99371 2.12906 3.31364 1.88633 3.65434L4.98225 6.75002H0.604414C0.604414 6.75002 0.5 7.57227 0.5 7.99743V8.00258C0.5 8.42774 0.536035 8.8444 0.604414 9.24998H4.98231L1.88639 12.3457C2.3734 13.0291 2.9709 13.6266 3.65434 14.1136L6.75002 11.0177V15.3958C7.16129 15.4647 7.57755 15.4996 7.99455 15.5H8.00516C8.42217 15.4996 8.83843 15.4648 9.24969 15.3958V11.0177L12.3457 14.1136C12.6867 13.8707 13.007 13.5998 13.3032 13.3039L13.3039 13.3032C13.5997 13.0069 13.8705 12.6867 14.1134 12.3457L11.0175 9.24998H15.3958C15.464 8.8451 15.4995 8.42967 15.5 8.00545V7.99455C15.4995 7.57033 15.464 7.15491 15.3958 6.75002Z"
          fill="#FF4A00"
        />
      </g>
      <defs>
        <clipPath id="clip0_1639_28092">
          <rect fill="white" height="15" transform="translate(0.5 0.5)" width="15" />
        </clipPath>
      </defs>
    </>
  ),
  viewBox: '0 0 16 16',
});
