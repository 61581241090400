import {
  ClientId,
  getLastAuthenticatedEmail,
  updateLastAuthenticatedEmail,
  cleanupHiHelloStorage,
} from '@src/lib/utils';

interface AuthWithEmailAndPasswordProps {
  clientId?: ClientId;
  email: string;
  isSignUp?: boolean;
  nextUrl?: string;
  password: string;
  persist: boolean;
}

export async function authWithEmailAndPassword(
  props: AuthWithEmailAndPasswordProps,
): Promise<void> {
  const { clientId, email, isSignUp, nextUrl, password, persist } = props;
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/${isSignUp ? 'signup' : 'email'}`,
    {
      body: JSON.stringify({ clientId, email, nextUrl, password, persist }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    },
  );

  const data = await response.json();

  if (response.status >= 400) {
    throw new Error(data.code);
  }

  if (getLastAuthenticatedEmail() !== email) {
    cleanupHiHelloStorage();
    updateLastAuthenticatedEmail(email);
  }

  window.location.assign(data.uri);
}
