import { PhoneIcon } from '@chakra-ui/icons';
import { Icon, Input, InputGroup, InputLeftElement, InputProps } from '@chakra-ui/react';
import { type E164Number } from 'libphonenumber-js';
import { forwardRef } from 'react';
import PhoneNumberInput, { Props as ReactPhoneNumberInputProps } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

// const selectStyles = {
//   border: '0px',
//   height: '100%',
//   opacity: 0,
//   overflow: 'hidden',
//   padding: '0px',
//   position: 'absolute',
//   whiteSpace: 'nowrap',
//   width: '100%',
//   maxWidth: '40px',
// };

export type PhoneInputProps = Omit<
  ReactPhoneNumberInputProps<Omit<InputProps, 'onChange' | 'ref' | 'value'>>,
  'value'
> & { hideIcon?: boolean; value?: string };

const TextFieldInput = forwardRef<
  HTMLInputElement,
  {
    className?: string;
    hideIcon?: boolean;
    inputClassName?: string;
  }
>(({ className, inputClassName, hideIcon, ...props }, ref) => (
  <div style={{ flex: 1 }}>
    <InputGroup>
      {!hideIcon && (
        <InputLeftElement pointerEvents="none">
          <Icon as={PhoneIcon} color="gray.400" />
        </InputLeftElement>
      )}

      <Input
        {...props}
        ref={ref}
        className={[className, inputClassName].filter(Boolean).join(' ')}
        w="full"
      />
    </InputGroup>
  </div>
));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PhoneInput = forwardRef<any, PhoneInputProps>(
  ({ className, onChange, value, hideIcon, ...props }, ref) => (
    <PhoneNumberInput
      {...props}
      ref={ref}
      hideIcon={hideIcon}
      inputComponent={TextFieldInput}
      numberInputProps={{
        className,
      }}
      onChange={onChange}
      style={{ position: 'relative', width: '100%' }}
      value={value as E164Number}
    />
  ),
);

export default PhoneInput;
