import { Box, BoxProps, useMultiStyleConfig } from '@chakra-ui/react';
import { Fields } from '@hihello/core';
import { FunctionComponent } from 'react';
import type { FieldListRule } from '../../../../layout';
import { DetailItem } from './DetailItem';

export type DetailProps = BoxProps & {
  fields?: Fields[];
  rules?: FieldListRule[];
};

export const Detail: FunctionComponent<DetailProps> = ({ fields, rules, ...props }) => {
  const styles = useMultiStyleConfig('FieldListDetail');

  return (
    <Box __css={styles.container} {...props}>
      <Box __css={styles.list} as="ul">
        {fields ? (
          fields.map((field, index) => (
            <DetailItem
              key={[index, field.type].join()}
              field={field}
              rules={rules?.filter((rule) => {
                // The omission of the field property on a rule means that
                // the rule should be included for all fields.
                const shouldApplyToAllFields = !rule.field;
                if (shouldApplyToAllFields) {
                  return true;
                }
                const ruleField = rule.field as string;
                return field.type === ruleField.toUpperCase();
              })}
            />
          ))
        ) : (
          <>
            <DetailItem />
            <DetailItem />
            <DetailItem />
          </>
        )}
      </Box>
    </Box>
  );
};
