export async function requestPasswordResetLink(email: string): Promise<void> {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/auth-HttpsSendForgotPasswordEmailV1`,
    {
      body: JSON.stringify({ email }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    },
  );

  const data = await response.json();
  return data;
}
