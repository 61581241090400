import { createIcon } from '@chakra-ui/react';

export const IconCalendarAnalytics: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconCalendarAnalytics',
  path: (
    <g id="Icon / Regular">
      <path
        d="M12.541 2.62565H15.3743C15.5622 2.62565 15.7424 2.70028 15.8752 2.83312C16.0081 2.96596 16.0827 3.14612 16.0827 3.33398V14.6673C16.0827 14.8552 16.0081 15.0353 15.8752 15.1682C15.7424 15.301 15.5622 15.3757 15.3743 15.3757H2.62435C2.43649 15.3757 2.25632 15.301 2.12348 15.1682C1.99064 15.0353 1.91602 14.8552 1.91602 14.6673V3.33398C1.91602 3.14612 1.99064 2.96596 2.12348 2.83312C2.25632 2.70028 2.43649 2.62565 2.62435 2.62565H5.45768V1.20898H6.87435V2.62565H11.1243V1.20898H12.541V2.62565ZM11.1243 4.04232H6.87435V5.45898H5.45768V4.04232H3.33268V6.87565H14.666V4.04232H12.541V5.45898H11.1243V4.04232ZM14.666 8.29232H3.33268V13.959H14.666V8.29232Z"
        fill="currentColor"
        id="Vector"
      />
    </g>
  ),
  viewBox: '0 0 18 18',
});
