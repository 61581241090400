import { createIcon } from '@chakra-ui/react';

export const IconPersonOutline: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconPersonOutline',
  path: (
    <>
      <path
        d="M3 21.0002C3 16.4814 6.663 12.8184 11.1818 12.8184H12.8182C17.337 12.8184 21 16.4814 21 21.0002"
        fill="none"
        stroke="currentColor"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path
        d="M11.9999 12.8182C14.7111 12.8182 16.909 10.6203 16.909 7.90909C16.909 5.19787 14.7111 3 11.9999 3C9.2887 3 7.09082 5.19787 7.09082 7.90909C7.09082 10.6203 9.2887 12.8182 11.9999 12.8182Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </>
  ),
  viewBox: '0 0 24 24',
});
