import { createIcon } from '@chakra-ui/react';

export const IconExternalSyncOff: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconExternalSyncOff',
  path: (
    <>
      <path
        d="M1.33398 9.66687C1.33398 10.0608 1.41158 10.4509 1.56235 10.8149C1.71311 11.1789 1.93409 11.5096 2.21266 11.7882C2.77527 12.3508 3.53833 12.6669 4.33398 12.6669H12.334C12.9246 12.6679 13.4937 12.445 13.9264 12.0429C14.3591 11.6409 14.6232 11.0897 14.6655 10.5006C14.7078 9.91149 14.5251 9.32825 14.1543 8.86854C13.7834 8.40882 13.2521 8.10686 12.6673 8.02354C12.6738 6.88938 12.2671 5.79167 11.5231 4.9356C10.7791 4.07953 9.74885 3.5237 8.62487 3.37202C7.50088 3.22033 6.36012 3.48316 5.41583 4.11139C4.47154 4.73962 3.78835 5.69023 3.49398 6.78554C2.87073 6.96755 2.32331 7.34676 1.93385 7.86627C1.5444 8.38579 1.33391 9.01759 1.33398 9.66687Z"
        fill="white"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="1.33333"
      />
      <path
        d="M6.66797 7.3335L9.33464 10.0002M6.66797 10.0002L9.33464 7.3335"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.33333"
      />
    </>
  ),
  viewBox: '0 0 16 16',
});
