import { createIcon } from '@chakra-ui/react';

export const IconSnapchat: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconSnapchat',
  path: (
    <path
      d="M12 21.826C10.6744 21.826 9.88347 21.2578 9.18168 20.7677C8.65812 20.4112 8.19025 20.0659 7.63327 19.9768C6.35221 19.9099 5.97347 19.9768 5.28281 20.0993C5.16027 20.0993 5.01546 20.0659 4.95976 19.8654C4.75925 19.0299 4.70355 18.796 4.55873 18.7737C3.0883 18.5398 2.186 18.2056 2.00776 17.7934C1.97434 17.5372 2.05232 17.4258 2.17486 17.4258C3.3668 17.2142 4.42506 16.5903 5.31623 15.5432C6.01803 14.7412 6.35221 13.9614 6.38563 13.8834C6.56387 13.5269 6.59728 13.215 6.49703 12.97C6.30765 12.5132 5.66156 12.3127 4.95976 12.0788C4.58101 11.934 3.96833 11.6221 4.04631 11.1876C4.11315 10.8757 4.52532 10.6529 5.10458 10.6975C5.49446 10.8757 5.82865 10.9648 6.12942 10.9648C6.49703 10.9648 6.66412 10.8312 6.70868 10.7866C6.58615 8.82602 6.48589 7.12166 6.92033 6.15251C8.22367 3.23393 10.9752 3 12 3C13.0248 3 15.7763 3.23393 17.0797 6.15251C17.5141 7.12166 17.4139 8.82602 17.2913 10.7866C17.3359 10.8312 17.503 10.9648 17.8706 10.9648C18.1714 10.9648 18.5055 10.8757 18.8954 10.6975C19.4747 10.6529 19.8869 10.8757 19.9537 11.1876C20.0317 11.6221 19.419 11.934 19.0402 12.0788C18.3384 12.3127 17.6923 12.5132 17.503 12.97C17.4027 13.215 17.4361 13.5269 17.6144 13.8834C17.6478 13.9614 17.982 14.7412 18.6838 15.5432C19.5749 16.5903 20.6332 17.2142 21.8251 17.4258C21.9477 17.4258 22.0257 17.5372 21.9922 17.7934C21.814 18.2056 20.9117 18.5398 19.4413 18.7737C19.2965 18.796 19.2408 19.0299 19.0402 19.8654C18.9845 20.0659 18.8397 20.0993 18.7172 20.0993C18.0265 19.9768 17.6478 19.9099 16.3667 19.9768C15.8098 20.0659 15.3419 20.4112 14.8183 20.7677C14.1165 21.2578 13.3256 21.826 12 21.826Z"
      fill="currentColor"
    />
  ),
});
