import { createIcon } from '@chakra-ui/react';

export const IconCreditCardOutline: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconCreditCardOutline',
  path: (
    <path
      d="M11.6693 0.664062C12.1997 0.664062 12.7084 0.874776 13.0835 1.24985C13.4586 1.62492 13.6693 2.13363 13.6693 2.66406V9.33073C13.6693 9.86116 13.4586 10.3699 13.0835 10.7449C12.7084 11.12 12.1997 11.3307 11.6693 11.3307H2.33594C1.8055 11.3307 1.2968 11.12 0.921724 10.7449C0.546651 10.3699 0.335938 9.86116 0.335938 9.33073V2.66406C0.335938 2.13363 0.546651 1.62492 0.921724 1.24985C1.2968 0.874776 1.8055 0.664063 2.33594 0.664062H11.6693ZM12.3359 4.66406H1.66927V9.33073C1.66929 9.49402 1.72924 9.65162 1.83775 9.77364C1.94625 9.89567 2.09577 9.97362 2.25794 9.99273L2.33594 9.9974H11.6693C11.8326 9.99737 11.9902 9.93742 12.1122 9.82892C12.2342 9.72041 12.3122 9.5709 12.3313 9.40873L12.3359 9.33073V4.66406ZM10.3359 6.66406C10.5059 6.66425 10.6693 6.72931 10.7928 6.84596C10.9164 6.96261 10.9908 7.12203 11.0007 7.29166C11.0107 7.46129 10.9555 7.62831 10.8464 7.75862C10.7374 7.88892 10.5827 7.97266 10.4139 7.99273L10.3359 7.9974H8.33594C8.16602 7.99721 8.00258 7.93214 7.87902 7.8155C7.75547 7.69885 7.68111 7.53943 7.67116 7.3698C7.6612 7.20017 7.71639 7.03314 7.82545 6.90284C7.93451 6.77254 8.08921 6.6888 8.25794 6.66873L8.33594 6.66406H10.3359ZM11.6693 1.9974H2.33594C2.15913 1.9974 1.98956 2.06763 1.86453 2.19266C1.73951 2.31768 1.66927 2.48725 1.66927 2.66406V3.33073H12.3359V2.66406C12.3359 2.48725 12.2657 2.31768 12.1407 2.19266C12.0157 2.06763 11.8461 1.9974 11.6693 1.9974Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 14 12',
});
