import { StyleConfig, SystemStyleFunction } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleFunction = ({ colorMode, ...props }) => ({
  'a, a:hover, a:visited': {
    color: props.palette?.[colorMode]?.brand ?? '#8f60de',
    fontWeight: 600,
  },
  display: 'block',
  fontSize: '1.1rem',
  fontWeight: 300,
  letterSpacing: '0.03125rem',
  lineHeight: '1.5rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
});

export const CardHeadline: StyleConfig = {
  baseStyle,
  defaultProps: {
    colorScheme: 'brand',
  },
};
