import { Box, BoxProps, Skeleton, useStyleConfig } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { useStore } from 'zustand';
import { useCardStore } from '../../store';

export type DepartmentProps = BoxProps;

export const Department: FunctionComponent<DepartmentProps> = ({ children, ...props }) => {
  const cardStore = useCardStore();
  const { details, palette } = useStore(cardStore, (state) => ({
    details: state.details,
    palette: state.themePalette,
  }));
  const styles = useStyleConfig('CardDepartment', { palette });

  if (details?.department === null) {
    return null;
  }

  return (
    <Box __css={styles} className="CardDepartment" {...props}>
      {details?.department ?? <Skeleton h="5" mb="3" width="30%" />}
      {children}
    </Box>
  );
};
