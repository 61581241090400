import { TableContainer as ChakraTableContainer, TableContainerProps } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

export const TableContainer: FunctionComponent<TableContainerProps> = ({ children, ...props }) => (
  <ChakraTableContainer
    bgColor="bg-surface"
    borderColor="gray.200"
    borderRadius="md"
    borderWidth={1}
    p={3}
    w="full"
    {...props}
  >
    {children}
  </ChakraTableContainer>
);
