import { createIcon } from '@chakra-ui/react';

export const DesignIconSleek = createIcon({
  displayName: 'DesignIconSleek',
  path: (
    <>
      <g clipPath="url(#a)">
        <rect fill="#F5F5F5" height="72" rx="16" width="72" />
        <circle cx="36" cy="-6.75" fill="currentColor" r="59.625" />
        <path d="M15.75 42.75h41.625v13.5H15.75z" fill="#fff" />
      </g>
      <defs>
        <clipPath id="a">
          <rect fill="#fff" height="72" rx="16" width="72" />
        </clipPath>
      </defs>
    </>
  ),
  viewBox: '0 0 72 72',
});
