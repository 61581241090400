import { anatomy, PartsStyleFunction, MultiStyleConfig } from '@chakra-ui/theme-tools';

const parts = anatomy('fieldlist--detail').parts(
  'container',
  'list',
  'listItem',
  'actions',
  'action',
  'textPrimary',
  'textSecondary',
);

const baseStyle: PartsStyleFunction<typeof parts> = ({ colorMode, ...props }) => ({
  action: {
    alignSelf: 'flex-start',
    button: {
      alignItems: 'center',
      bg: props.palette?.[colorMode]?.brand ?? '#8f60de',
      borderRadius: '100%',
      display: 'inline-flex',
      h: '100%',
      justifyContent: 'center',
      p: 0,
      svg: {
        color: props.palette?.[colorMode]?.onBrand ?? '#ffffff',
        h: '1.5rem',
        w: '1.5rem',
      },
      transitionDuration: '300ms',
      transitionProperty: 'background-color, color',
      w: 'full',
    },
    h: '2.625rem',
    marginRight: '0.8125rem',
    w: '2.625rem',
  },
  actions: {
    alignSelf: 'flex-start',
    display: 'flex',
  },
  container: {},
  list: {
    display: 'flex',
    flexDirection: 'column',
    listStyleType: 'none',
    p: 0,
  },
  listItem: {
    '&:first-of-type': {
      paddingTop: '1rem',
    },
    '&:hover': {
      background: `${props.palette?.[colorMode]?.brand ?? '#000000'}0b`,
      button: {
        bg: `${props.palette?.[colorMode]?.accent ?? '#000000'}`,
      },
    },
    '&:last-child': {
      paddingBottom: '1rem',
    },
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
    h: 'auto',
    m: 0,
    p: '0.5rem 1.5rem',
  },
  textPrimary: {
    '&::after': {
      display: 'none',
    },
    '&::before': {
      display: 'none',
    },
    fontSize: '1.1rem',
    lineHeight: '1.5rem',
    m: 0,
    whiteSpace: 'pre-wrap',
  },
  textSecondary: {
    '&::before': {
      display: 'none',
    },
    fontWeight: 300,
    lineHeight: '1.5rem',
  },
});

export const FieldListDetail: MultiStyleConfig<typeof parts> = {
  baseStyle,
  defaultProps: {
    colorScheme: 'brand',
  },
  parts: ['container', 'list', 'listItem', 'actions', 'action', 'textPrimary', 'textSecondary'],
};
