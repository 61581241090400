import { createIcon } from '@chakra-ui/react';

export const IconCheckLine: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconCheckLine',
  path: (
    <>
      <rect fill="#00BB00" height="16" rx="8" width="16" />
      <path
        d="M6.66649 10.1148L12.7945 3.98608L13.7378 4.92875L6.66649 12.0001L2.42383 7.75742L3.36649 6.81475L6.66649 10.1148Z"
        fill="white"
      />
    </>
  ),
  viewBox: '0 0 16 16',
});
