import { interpolate } from 'flubber';
import { animate, EasingFunction, motion, useMotionValue, useTransform } from 'framer-motion';
import { useEffect, useState } from 'react';

export type Easing =
  | [number, number, number, number]
  | 'linear'
  | 'easeIn'
  | 'easeOut'
  | 'easeInOut'
  | 'circIn'
  | 'circOut'
  | 'circInOut'
  | 'backIn'
  | 'backOut'
  | 'backInOut'
  | 'anticipate'
  | EasingFunction;

export const PathMorph = ({
  animationOptions,
  color,
  paths,
  transparency,
}: {
  animationOptions?: { delay?: number; duration?: number; ease?: Easing | Easing[] };
  color?: string;
  paths: string[];
  transparency?: number;
}) => {
  const [pathIndex, setPathIndex] = useState(0);

  const progress = useMotionValue(pathIndex);

  const arrayOfIndex = paths.map((_, i) => i);

  const path = useTransform(progress, arrayOfIndex, paths, {
    mixer: (a, b) => interpolate(a, b),
  });

  useEffect(() => {
    const animation = animate(progress, pathIndex, {
      duration: 3,
      ease: 'easeInOut',
      onComplete: () => {
        if (pathIndex === paths.length - 1) {
          progress.set(0);
          setPathIndex(1);
        } else {
          setPathIndex(pathIndex + 1);
        }
      },
      ...animationOptions,
    });

    return () => {
      animation.stop();
    };
  }, [animationOptions, pathIndex, paths.length, progress]);

  return (
    <motion.path
      d={path}
      fill={color ?? 'currentColor'}
      style={{
        fillOpacity: transparency ?? 0.7,
      }}
    />
  );
};
