import { FieldType } from '@hihello/core';
import { FunctionComponent } from 'react';
import { useStore } from 'zustand';
import { CardSchemaFilter, FieldListRule, FieldListVariant } from '../../layout';
import { useCardStore, useFields } from '../../store';
import { Detail, Mini, Summary } from './variants';

export type FieldListProps = {
  fieldListFilter: CardSchemaFilter<FieldType>;
  rules?: FieldListRule[];
  variant: FieldListVariant;
};

export const FieldList: FunctionComponent<FieldListProps> = ({
  fieldListFilter,
  rules,
  variant,
  ...props
}) => {
  const fields = useFields(fieldListFilter);
  const cardStore = useCardStore();
  const cardVariant = useStore(cardStore, (state) => state.variant);

  if (cardVariant === 'mini') {
    return <Mini variant={variant} />;
  }

  if (fields && fields.length === 0) {
    return null;
  }

  switch (variant) {
    case 'detail': {
      return <Detail {...props} fields={fields} rules={rules} />;
    }

    case 'summary': {
      return <Summary {...props} fields={fields} />;
    }

    default: {
      return null;
    }
  }
};
