/* Tg => Short for Table Group Cell */
import type { TableCellProps } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { Td } from './Td';

type TgProps = TableCellProps & {
  colSpan: number;
};

export const Tg: FunctionComponent<TgProps> = ({ colSpan, ...props }) => (
  <Td
    borderBottom="1px solid"
    borderBottomColor="gray.200"
    color="gray.600"
    colSpan={colSpan}
    fontWeight="700"
    px={8}
    py={2}
    {...props}
  />
);
