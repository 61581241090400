import { createIcon } from '@chakra-ui/react';

export const IconTemplates: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconTemplates',
  path: (
    <>
      <mask
        height="18"
        id="mask0_2492_6829"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
        width="14"
        x="3"
        y="1"
      >
        <path
          clipRule="evenodd"
          d="M5.45455 2.63636C5.00268 2.63636 4.63636 3.00268 4.63636 3.45455V11.0227L4.68112 10.9556L6.41817 12.1136C7.37647 12.7525 8.60361 12.8289 9.63375 12.3138L10.1381 12.0616C11.4805 11.3904 12.9924 11.1684 14.4545 11.4112V3.45455C14.4545 3.00268 14.0882 2.63636 13.6364 2.63636H5.45455ZM14.4545 13.0769C13.2493 12.8139 11.9836 12.9684 10.8699 13.5253L10.3656 13.7774C8.81019 14.5551 6.95737 14.4398 5.51048 13.4752L4.63636 12.8924V16.5455C4.63636 16.9973 5.00268 17.3636 5.45455 17.3636H13.6364C14.0882 17.3636 14.4545 16.9973 14.4545 16.5455V13.0769ZM3 3.45455C3 2.09894 4.09894 1 5.45455 1H13.6364C14.992 1 16.0909 2.09894 16.0909 3.45455V16.5455C16.0909 17.9011 14.992 19 13.6364 19H5.45455C4.09894 19 3 17.9011 3 16.5455V3.45455Z"
          fill="black"
          fillRule="evenodd"
        />
        <path d="M4.27295 17.2726V13.1816H14.7275V17.2726H4.27295Z" fill="black" />
        <path
          clipRule="evenodd"
          d="M3.81836 12.7275H15.182V17.7275H3.81836V12.7275ZM4.72745 13.6366V16.8184H14.2729V13.6366H4.72745Z"
          fill="black"
          fillRule="evenodd"
        />
      </mask>
      <g mask="url(#mask0_2492_6829)">
        <path d="M1.09082 0H17.4545V20.9091H1.09082V0Z" fill="currentColor" />
      </g>
    </>
  ),
  viewBox: '0 0 20 20',
});
