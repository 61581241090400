import {
  anatomy,
  PartsStyleFunction,
  PartsStyleObject,
  MultiStyleConfig,
} from '@chakra-ui/theme-tools';

export type SidebarSizes = 'full' | 'sm';
export type SidebarVariants = 'default' | 'web' | 'mobile' | 'tools';

const parts = anatomy('sidebar').parts('container', 'logo', 'list', 'item', 'icon');

const baseStyle: PartsStyleFunction<typeof parts> = () => ({
  container: {
    a: {
      color: 'onCompany',
    },
    bgColor: 'company.500',
    boxShadow: 'xs.dark',
    color: 'onCompany',
    height: '100vh',
    overflowY: 'auto',
    padding: 4,
    position: 'sticky',
    top: 0,
    transition: 'all 0.2s ease-in-out',
    w: 'full',
    willChange: 'max-width',
  },
  handler: {
    cursor: 'pointer',
    h: '8',
    position: 'absolute',
    right: '-4',
    top: '1.25rem',
    transition: 'opacity 0.2s',
    w: '8',
    zIndex: 1,
  },

  icon: {
    path: {
      color: 'onCompany',
      stroke: 'transparent',
    },
  },
  item: {
    '&.active': {
      bgColor: 'blackAlpha.300',
      opacity: 1,
    },
    _active: {
      bgColor: 'blackAlpha.300',
    },
    _focus: {
      bgColor: 'blackAlpha.50',
      boxShadow: 'xs',
      outline: 'none',
    },
    _hover: {
      '&.active': {
        bgColor: 'blackAlpha.300',
      },
      _active: {
        bgColor: 'blackAlpha.300',
      },
      bgColor: 'blackAlpha.50',
    },
    borderRadius: 'md',
    color: 'on-accent',
    justifyContent: 'flex-start',
    opacity: 0.8,
  },
  list: {
    flex: 1,
    justifyContent: 'space-between',
  },
  logo: {
    margin: '2',
    marginBottom: '6',
  },
  text: {
    color: 'onCompany',
    fontSize: 'sm',
    height: '1.25rem',
    lineHeight: '1.25rem',
    overflow: 'hidden',
  },
});

const sizes: Record<
  SidebarSizes,
  PartsStyleObject<typeof parts> | PartsStyleFunction<typeof parts>
> = {
  full: ({ variant }) => {
    let maxWidth;
    if (variant === 'web') {
      maxWidth = '15rem';
    } else if (variant === 'mobile') {
      maxWidth = 'full';
    } else {
      maxWidth = '20rem';
    }
    return {
      container: {
        maxWidth,
      },
      logo: {
        paddingX: variant === 'web' ? '1.625rem' : '0',
      },
    };
  },
  sm: ({ variant }) => ({
    container: {
      maxWidth: variant === 'web' ? '5rem' : '4.5rem',
    },
    item: {
      p: {
        display: 'none',
      },
    },
    logo: {
      paddingX: variant === 'web' ? '1.25rem' : '0',
    },
  }),
};

const variants: Record<SidebarVariants, PartsStyleObject<typeof parts>> = {
  default: {},
  mobile: {
    container: {
      alignItems: 'stretch',
      padding: '0',
      w: 'full',
    },
    item: {
      padding: '0.625rem',
    },
    list: {
      alignItems: 'stretch',
    },
    logo: {
      borderBottom: '2px solid',
      borderColor: 'companyAlpha',
      flexShrink: '0',
      height: '5.625rem',
      margin: '0',
      marginBottom: '0',
    },
  },
  tools: {
    logo: {
      width: 'auto',
    },
  },
  web: {
    container: {
      alignItems: 'stretch',
      padding: '0 0 1.625rem 0',
      w: 'full',
    },
    item: {
      padding: '0.625rem',
    },
    list: {
      alignItems: 'stretch',
    },
    logo: {
      borderBottom: '2px solid',
      borderColor: 'companyAlpha',
      flexShrink: '0',
      height: '5.625rem',
      margin: '0',
      marginBottom: '0',
    },
  },
};

export const Sidebar: MultiStyleConfig<typeof parts> = {
  baseStyle,
  defaultProps: {
    colorScheme: 'company',
  },
  parts: ['container', 'logo', 'list', 'item', 'icon'],
  sizes,
  variants,
};
