import { createIcon } from '@chakra-ui/react';

export const IconLock: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconLock',
  path: (
    <path
      d="M12.6667 7.16671H13.3333C13.5101 7.16671 13.6797 7.23695 13.8047 7.36197C13.9298 7.48699 14 7.65656 14 7.83337V14.5C14 14.6769 13.9298 14.8464 13.8047 14.9714C13.6797 15.0965 13.5101 15.1667 13.3333 15.1667H2.66667C2.48986 15.1667 2.32029 15.0965 2.19526 14.9714C2.07024 14.8464 2 14.6769 2 14.5V7.83337C2 7.65656 2.07024 7.48699 2.19526 7.36197C2.32029 7.23695 2.48986 7.16671 2.66667 7.16671H3.33333V6.50004C3.33333 5.88721 3.45404 5.28037 3.68856 4.71418C3.92308 4.148 4.26683 3.63355 4.70017 3.20021C5.13351 2.76687 5.64796 2.42312 6.21414 2.1886C6.78033 1.95408 7.38716 1.83337 8 1.83337C8.61284 1.83337 9.21967 1.95408 9.78586 2.1886C10.352 2.42312 10.8665 2.76687 11.2998 3.20021C11.7332 3.63355 12.0769 4.148 12.3114 4.71418C12.546 5.28037 12.6667 5.88721 12.6667 6.50004V7.16671ZM11.3333 7.16671V6.50004C11.3333 5.61599 10.9821 4.76814 10.357 4.14302C9.7319 3.5179 8.88406 3.16671 8 3.16671C7.11595 3.16671 6.2681 3.5179 5.64298 4.14302C5.01786 4.76814 4.66667 5.61599 4.66667 6.50004V7.16671H11.3333ZM7.33333 9.83337V12.5H8.66667V9.83337H7.33333Z"
      fill="currentColor"
      id="Vector"
    />
  ),
  viewBox: '0 0 15 15',
});
