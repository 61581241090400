import { createIcon } from '@chakra-ui/react';

export const IconLogout: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconLogout',
  path: (
    <path
      d="M1.33333 12C0.966667 12 0.652889 11.8696 0.392 11.6087C0.131111 11.3478 0.000444444 11.0338 0 10.6667V1.33333C0 0.966667 0.130667 0.652889 0.392 0.392C0.653333 0.131111 0.967111 0.000444444 1.33333 0H6V1.33333H1.33333V10.6667H6V12H1.33333ZM8.66667 9.33333L7.75 8.36667L9.45 6.66667H4V5.33333H9.45L7.75 3.63333L8.66667 2.66667L12 6L8.66667 9.33333Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 12 12',
});
