import { createIcon } from '@chakra-ui/react';

export const IconAvatar: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconAvatar',
  path: (
    <>
      <rect fill="#E2E8F0" height="64" rx="15" width="64" />
      <g clipPath="url(#clip0_331_41725)">
        <path
          d="M32 36.5C39.575 36.5 45.7143 30.3447 45.7143 22.75C45.7143 15.1553 39.575 9 32 9C24.425 9 18.2857 15.1553 18.2857 22.75C18.2857 30.3447 24.425 36.5 32 36.5ZM41.6 39.9375H39.8107C37.4321 41.0332 34.7857 41.6562 32 41.6562C29.2143 41.6562 26.5786 41.0332 24.1893 39.9375H22.4C14.45 39.9375 8 46.4043 8 54.375V58.8438C8 61.6904 10.3036 64 13.1429 64H50.8571C53.6964 64 56 61.6904 56 58.8438V54.375C56 46.4043 49.55 39.9375 41.6 39.9375Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_331_41725">
          <rect fill="white" height="55" transform="translate(8 9)" width="48" />
        </clipPath>
      </defs>
    </>
  ),
  viewBox: '0 0 64 64',
});
