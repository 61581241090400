import { Box, HStack } from '@chakra-ui/react';
import { Step } from './Step';

export interface StepperProps {
  currentStep: number;
  numberOfSteps: number;
}

export const Stepper = (props: StepperProps) => {
  const { numberOfSteps, currentStep } = props;

  return (
    <Box>
      <HStack spacing="2">
        {Array.from(Array(numberOfSteps).keys()).map((id) => (
          <Step key={id} isActive={currentStep === id} />
        ))}
      </HStack>
    </Box>
  );
};
