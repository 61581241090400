import {
  As,
  Button,
  forwardRef,
  HStack,
  Icon,
  Box,
  ThemingProps,
  useStyles,
  type SystemStyleObject,
  Tooltip,
} from '@chakra-ui/react';

export type SidebarListItemProps = ThemingProps<'Sidebar'> & {
  active?: boolean;
  customStyles?: SystemStyleObject;
  hideTooltip?: boolean;
  icon: As;
  label: string;
};

export const SidebarListItem = forwardRef<SidebarListItemProps, typeof Button>(
  ({ active, customStyles, icon, hideTooltip = false, label, ...props }, ref) => {
    const styles = useStyles();

    return (
      <Tooltip isDisabled={hideTooltip} label={label} placement="right">
        <Button
          ref={ref}
          __css={styles.item}
          className={active ? 'active' : ''}
          tabIndex={0}
          {...props}
        >
          <HStack spacing="3">
            <Icon __css={customStyles || styles.icon} as={icon} boxSize="5" />
            <Box __css={styles.text} as="p">
              {label}
            </Box>
          </HStack>
        </Button>
      </Tooltip>
    );
  },
);
