import { Box, Icon, useMultiStyleConfig } from '@chakra-ui/react';
import { useStore } from 'zustand';
import { IconNote } from '../../../../../Icons';
import { FieldListVariant } from '../../../../layout';
import { useCardStore } from '../../../../store';

export type MiniProps = {
  variant: FieldListVariant;
};

export const Mini = ({ variant }: MiniProps) => {
  const cardStore = useCardStore();
  const palette = useStore(cardStore, (state) => state.themePalette);
  const styles = useMultiStyleConfig('FieldListMini', { palette, variant });

  if (variant === 'summary') {
    return null;
  }

  return (
    <Box __css={styles.container}>
      <Box __css={styles.list} as="ul">
        {Array(variant === 'detail' ? 6 : 4)
          .fill(null)
          .map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={index} __css={styles.listItem} as="li">
              <Box className="FieldListMini__icon">
                <Icon as={IconNote} />
              </Box>
              {variant === 'detail' && <Box className="FieldListMini__skeleton" />}
            </Box>
          ))}
      </Box>
    </Box>
  );
};
