import { GraphQLClient } from 'graphql-request';
import { IncomingMessage } from 'http';
import { ParsedUrlQuery } from 'querystring';
import { getCookie } from '../utils/cookies';
import { logger } from '../utils/logger';

const ENDPOINT = process.env.GRAPHQL_ENDPOINT ?? process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT ?? '';

export const X_HIHELLO_ACCOUNT_ID_COOKIE_KEY = 'profileId';

export type GraphError = Error & {
  request: {};
  response:
    | {
        errors: Array<
          Error & {
            extensions: {
              code: string;
              exception: object;
              serviceName: string;
            };
          }
        >;
      }
    | {
        error: string;
      };
};

export const getIdTokenFromQuery = (query?: ParsedUrlQuery): string | undefined =>
  (query?.token as string) || (query?.t as string) || undefined;

export const getIdToken = (req?: IncomingMessage, idTokenName = 'session'): string | undefined =>
  getCookie(idTokenName, req);

export const getRefreshToken = (
  req?: IncomingMessage,
  refreshTokenName = 'refreshToken',
): string | undefined => getCookie(refreshTokenName, req);

export const getProfileId = () => getCookie(X_HIHELLO_ACCOUNT_ID_COOKIE_KEY);

const extractOperationName = (query: string): string | undefined => {
  const match = query.match(/query\s+(\w+)\s*(\([^)]*\))?\s*\{/);
  return match ? match[1] : undefined;
};

type CustomHeaders = HeadersInit & {
  'X-Operation-Name'?: string;
};

type CustomRequestInit = Omit<RequestInit, 'headers'> & {
  headers?: CustomHeaders;
};

const customFetcher = (endpoint: string, options: CustomRequestInit) => {
  try {
    const url = new URL(endpoint);
    const operationName = options.headers?.['X-Operation-Name'];
    if (operationName) {
      url.searchParams.append('operationName', operationName);
    }
    return fetch?.(url.toString(), options);
  } catch {
    logger.error('Error in customFetcher');
  }
  return fetch?.(endpoint, options);
};

let client = new GraphQLClient(ENDPOINT);

try {
  // @ts-ignore
  const clientOptions = fetch ? { fetch: customFetcher } : {};
  client = new GraphQLClient(ENDPOINT, clientOptions);
} catch {
  logger.info(
    'This is probably a test environment so fetch is undefined. We will just use the original client without the custom fetcher.',
  );
}

export function fetcher<TData, TVariables>(
  query: string,
  variables?: TVariables & { fetchAsAccountId?: string },
  options?: Record<string, string>,
) {
  const vars = variables;
  const headers: Record<string, string> = { ...options };
  const token = getIdToken();
  const profileId = getProfileId();

  if (vars && 'fetchAsAccountId' in vars && vars.fetchAsAccountId) {
    headers['X-HiHello-Account-ID'] = vars.fetchAsAccountId;
    delete vars.fetchAsAccountId;
  }

  // Extract the operation name from the query
  const operationName = extractOperationName(query);
  if (operationName) {
    headers['X-Operation-Name'] = operationName;
  }

  if (!headers.Authorization && token) {
    headers.Authorization = `Bearer ${token}`;
  }

  if (!headers['X-HiHello-Account-ID'] && profileId) {
    headers['X-HiHello-Account-ID'] = profileId;
  }

  return async function requestWithGraphQLClient(): Promise<TData> {
    return client.request<TData, TVariables>(query, vars, headers);
  };
}
