import { Flex, FlexProps, IconButton } from '@chakra-ui/react';
import { useCallback, useEffect, useState, useRef, FunctionComponent } from 'react';
import { useStore } from 'zustand';
import { IconChevronDown } from '../../../../Icons';
import { useCardStore } from '../../../store';
import { Image } from '../../Image';

export type CollapsibleProps = FlexProps;

export const Collapsible: FunctionComponent<CollapsibleProps> = (props) => {
  const cardStore = useCardStore();
  const details = useStore(cardStore, (state) => state.details);

  const [isListCollapsed, setIsListCollapsed] = useState(true);
  const [showToggle, setShowToggle] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const toggleCollapse = () => {
    setIsListCollapsed((prev) => !prev);
  };

  const handleLayoutChange = useCallback(() => {
    if (!ref.current) {
      return;
    }

    setShowToggle(ref.current.scrollHeight > ref.current.clientHeight);
  }, []);

  useEffect(() => {
    handleLayoutChange();
    window.addEventListener('resize', handleLayoutChange);

    return () => {
      window.removeEventListener('resize', handleLayoutChange);
    };
  }, [handleLayoutChange]);

  if (!details?.badges || details.badges.length === 0) {
    return null;
  }

  return (
    <Flex
      align="flex-start"
      className="CardBadgeListCollapsible"
      justify="space-between"
      {...props}
    >
      <Flex
        ref={ref}
        columnGap="2rem"
        flex="1"
        flexFlow="row wrap"
        maxH={isListCollapsed ? '5rem' : '100vh'}
        overflow="hidden"
        rowGap="2rem"
        transitionDuration="300ms"
        transitionProperty="max-height"
      >
        {details?.badges?.map((badge) => (
          <Image
            key={badge.uri}
            alt="Badge"
            h="5rem"
            onError={handleLayoutChange}
            onLoad={handleLayoutChange}
            src={badge.variants?.imageSmall ?? badge.uri}
            w="auto"
          />
        ))}
      </Flex>
      {showToggle && (
        <IconButton
          aria-label={isListCollapsed ? 'expand' : 'collapse'}
          icon={<IconChevronDown />}
          m="0"
          onClick={toggleCollapse}
          sx={{
            svg: {
              transform: isListCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
              transition: 'transform 0.3s linear',
            },
          }}
        />
      )}
    </Flex>
  );
};
