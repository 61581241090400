import { createIcon } from '@chakra-ui/react';

export const IconCompare: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconCompare',
  path: (
    <svg fill="none" height="17" viewBox="0 0 17 17" width="17" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1634_9109)" id="Analytics Icons">
        <path
          d="M15.9375 16.4688H1.59375C1.31196 16.4688 1.04171 16.3568 0.842449 16.1576C0.643192 15.9583 0.53125 15.688 0.53125 15.4062V1.0625C0.53125 0.921604 0.587221 0.786478 0.68685 0.68685C0.786478 0.587221 0.921604 0.53125 1.0625 0.53125C1.2034 0.53125 1.33852 0.587221 1.43815 0.68685C1.53778 0.786478 1.59375 0.921604 1.59375 1.0625V15.4062H15.9375C16.0784 15.4062 16.2135 15.4622 16.3132 15.5618C16.4128 15.6615 16.4688 15.7966 16.4688 15.9375C16.4688 16.0784 16.4128 16.2135 16.3132 16.3132C16.2135 16.4128 16.0784 16.4688 15.9375 16.4688Z"
          fill="#A0AEC0"
          id="Vector"
        />
        <path
          d="M15.5002 6.5L12.5001 9.50011L7.00015 4.50006L3.50012 9.00011"
          id="Vector_2"
          stroke="#4A5568"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.8252 13.3166L6.65853 7.6499L10.2002 10.8374L13.7419 3.3999"
          id="Vector_3"
          stroke="#1A202C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1634_9109">
          <rect fill="white" height="17" width="17" />
        </clipPath>
      </defs>
    </svg>
  ),
  viewBox: '0 0 17 17',
});
