import { createIcon } from '@chakra-ui/react';

export const IconSyncDisabled: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconSyncDisabled',
  path: (
    <path
      d="M10.6663 0.333496H7.1663V3.8335L8.47297 2.52683C9.12937 3.18269 9.49863 4.07226 9.49964 5.00016C9.49964 5.5835 9.3538 6.13183 9.10297 6.616L9.95464 7.46766C10.5168 6.57597 10.7574 5.51892 10.6364 4.47176C10.5155 3.4246 10.0403 2.45023 9.28964 1.71016L10.6663 0.333496ZM0.667969 1.156L2.04464 2.53266C1.48246 3.42436 1.2419 4.48141 1.36285 5.52856C1.4838 6.57572 1.95899 7.5501 2.70964 8.29016L1.33297 9.66683H4.83297V6.16683L3.5263 7.4735C2.8699 6.81764 2.50064 5.92807 2.49964 5.00016C2.49964 4.41683 2.64547 3.8685 2.8963 3.38433L7.60964 8.09766C7.45797 8.1735 7.31797 8.2435 7.1663 8.296V9.51516C7.62714 9.39266 8.06464 9.20016 8.4613 8.95516L9.83797 10.3318L10.5788 9.591L1.41464 0.415163L0.667969 1.156ZM4.83297 1.70433V0.485163C4.3663 0.607663 3.9288 0.800163 3.53214 1.04516L4.3838 1.89683C4.5413 1.82683 4.67547 1.75683 4.83297 1.70433Z"
      fill="currentColor"
    />
  ),

  viewBox: '0 0 11 11',
});
