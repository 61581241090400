import {
  getField,
  getFieldMetadata,
  validateField,
  FieldMetadata,
} from '@hihello/api/build/utils/field';
import { Fields, TemplateFields, FieldType, TemplateFieldState } from '../api';

export type FieldMeta = FieldMetadata;

export const prepareField = <T extends Fields>(
  primaryInput: string,
  type?: FieldType,
  secondaryInput?: string,
) => getField(primaryInput, type, secondaryInput) as T;

export const getFieldMeta = (type: FieldType) => getFieldMetadata(type);

export const checkField = (primaryInput: string, type: FieldType, secondaryInput?: string) =>
  validateField(primaryInput, type, secondaryInput);

export const getCardTemplateFields = (
  cardFields: Array<Fields & { id?: string }>,
  templateFields: Array<TemplateFields>,
): Array<{
  cardField?: Fields & { id?: string };
  templateField?: TemplateFields;
  valid: boolean;
}> => {
  // Templated Fields and matching Card Fields if they exist
  const templated = templateFields.map((templateField) => {
    const cardField = cardFields.find((cField) => cField.templateFieldId === templateField.id);
    return {
      cardField,
      templateField,
      valid:
        !!cardField ||
        (!cardField &&
          templateField?.state === TemplateFieldState.Required &&
          templateField.text === ''),
    };
  });

  // Fields in the card that don't match any template fields
  const nonTemplatedCardFields = cardFields.filter((cField) => !cField.templateFieldId);

  return [
    ...templated,
    ...nonTemplatedCardFields.map((cField) => ({ cardField: cField, valid: true })),
  ];
};

export const validateEmail = (email: string) => checkField(email, FieldType.Email);
export const validatePhone = (phone: string) => checkField(phone, FieldType.Phone);
