import { createIcon } from '@chakra-ui/react';

export const IconAnalytics: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconAnalytics',
  path: (
    <path
      d="M5.8976 15.3849C5.8976 16.093 5.3236 16.667 4.61555 16.667C3.90749 16.667 3.3335 16.093 3.3335 15.3849V12.949C3.3335 12.241 3.90749 11.667 4.61555 11.667C5.3236 11.667 5.8976 12.241 5.8976 12.949V15.3849ZM13.0771 15.3849C13.0771 16.093 12.5031 16.667 11.795 16.667C11.087 16.667 10.513 16.093 10.513 15.3849V10.449C10.513 9.74099 11.087 9.16699 11.795 9.16699C12.5031 9.16699 13.0771 9.74099 13.0771 10.449V15.3849ZM16.6668 15.3849C16.6668 16.093 16.0928 16.667 15.3848 16.667C14.6767 16.667 14.1027 16.093 14.1027 15.3849V7.53238C14.1027 6.82432 14.6767 6.25033 15.3848 6.25033C16.0928 6.25033 16.6668 6.82432 16.6668 7.53238V15.3849ZM9.48734 15.3849C9.48734 16.093 8.91335 16.667 8.20529 16.667C7.49723 16.667 6.92324 16.093 6.92324 15.3849V5.44904C6.92324 4.74099 7.49723 4.16699 8.20529 4.16699C8.91335 4.16699 9.48734 4.74099 9.48734 5.44904V15.3849Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 20 20',
});
