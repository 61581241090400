import { createIcon } from '@chakra-ui/react';

export const IconGroup: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconGroup',
  path: (
    <path
      d="M16 35.496V28.8C16 26.234 17.99 24.216 20.934 23.064C19.0354 22.3579 17.0257 21.9975 15 22C11.22 22 7.728 23.234 4.906 25.32C5.78101 27.7947 7.25018 30.0167 9.1845 31.7909C11.1188 33.5652 13.4591 34.8375 16 35.496ZM33.76 28.172C32.97 27.106 30.34 26 27 26C22.988 26 20 27.594 20 28.8V36C22.7757 36.0015 25.504 35.2806 27.9166 33.9081C30.3292 32.5356 32.343 30.5587 33.76 28.172ZM15.1 19C16.2935 19 17.4381 18.5259 18.282 17.682C19.1259 16.8381 19.6 15.6935 19.6 14.5C19.6 13.3065 19.1259 12.1619 18.282 11.318C17.4381 10.4741 16.2935 10 15.1 10C13.9065 10 12.7619 10.4741 11.918 11.318C11.0741 12.1619 10.6 13.3065 10.6 14.5C10.6 15.6935 11.0741 16.8381 11.918 17.682C12.7619 18.5259 13.9065 19 15.1 19ZM27 21C28.0609 21 29.0783 20.5786 29.8284 19.8284C30.5786 19.0783 31 18.0609 31 17C31 15.9391 30.5786 14.9217 29.8284 14.1716C29.0783 13.4214 28.0609 13 27 13C25.9391 13 24.9217 13.4214 24.1716 14.1716C23.4214 14.9217 23 15.9391 23 17C23 18.0609 23.4214 19.0783 24.1716 19.8284C24.9217 20.5786 25.9391 21 27 21ZM20 40C8.954 40 0 31.046 0 20C0 8.954 8.954 0 20 0C31.046 0 40 8.954 40 20C40 31.046 31.046 40 20 40Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 40 40',
});

// 718096
