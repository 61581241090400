import { createIcon } from '@chakra-ui/react';

export const IconLoop: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconLoop',
  path: (
    <>
      <path
        clipRule="evenodd"
        d="M1.71832 4.78544C1.94995 4.55254 2.31996 4.37516 2.86013 4.37516H9.00655C9.59041 4.37516 9.99214 4.55659 10.2447 4.79415C10.4993 5.03359 10.625 5.35036 10.625 5.66683C10.625 5.9833 10.4993 6.30007 10.2447 6.53951C9.99214 6.77707 9.59041 6.9585 9.00655 6.9585H8.09243L8.0922 7.7085H9.00655C9.75166 7.7085 10.3466 7.47326 10.7586 7.08582C11.1685 6.70026 11.375 6.1837 11.375 5.66683C11.375 5.14996 11.1685 4.6334 10.7586 4.24784C10.3466 3.8604 9.75166 3.62516 9.00655 3.62516H2.86013C2.1467 3.62516 1.57651 3.86446 1.18654 4.25655C0.799659 4.64555 0.612826 5.16287 0.625614 5.67617C0.638416 6.19001 0.851191 6.7037 1.26483 7.08754C1.6807 7.47346 2.27752 7.7085 3.02625 7.7085H6C6.20711 7.7085 6.375 7.5406 6.375 7.3335C6.375 7.12639 6.20711 6.9585 6 6.9585H3.02625C2.43831 6.9585 2.03263 6.77687 1.77499 6.53778C1.51511 6.29662 1.38334 5.97699 1.37538 5.65749C1.36741 5.33745 1.48359 5.02144 1.71832 4.78544Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6 8.9585C6.20711 8.9585 6.375 8.7906 6.375 8.5835V6.0835C6.375 5.87639 6.20711 5.7085 6 5.7085C5.79289 5.7085 5.625 5.87639 5.625 6.0835V8.5835C5.625 8.7906 5.79289 8.9585 6 8.9585Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M9.26517 5.56833C9.11872 5.42188 8.88128 5.42188 8.73483 5.56833L7.23483 7.06833C7.08839 7.21478 7.08839 7.45221 7.23483 7.59866L8.73483 9.09866C8.88128 9.24511 9.11872 9.24511 9.26517 9.09866C9.41161 8.95221 9.41161 8.71478 9.26517 8.56833L8.03033 7.3335L9.26517 6.09866C9.41161 5.95221 9.41161 5.71478 9.26517 5.56833Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </>
  ),
  viewBox: '0 0 12 12',
});
