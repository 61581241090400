import { create } from 'zustand';

export enum RedirectKeys {
  Popup = 'popupReadyToRedirect',
  Token = 'tokenReadyToRedirect',
}

type ApprovedToRedirectState = {
  [RedirectKeys.Popup]: boolean;
  [RedirectKeys.Token]: boolean;
  setApprovedToRedirect: (key: RedirectKeys, approvedToRedirect: boolean) => void;
};

export const useApprovedToRedirect = create<ApprovedToRedirectState>((set) => ({
  [RedirectKeys.Popup]: true,
  [RedirectKeys.Token]: false,
  setApprovedToRedirect: (key: RedirectKeys, approvedToRedirect: boolean) =>
    set((state) => ({ ...state, [key]: approvedToRedirect })),
}));
