import Papa, { UnparseConfig } from 'papaparse';

export const saveAs = (content: any, fileName: string) => {
  const a = document.createElement('a');
  const isBlob = content.toString().indexOf('Blob') > -1;
  let url = content;
  if (isBlob) {
    url = window.URL.createObjectURL(content);
  }
  a.href = url;
  a.download = fileName;
  a.click();
  if (isBlob) {
    window.URL.revokeObjectURL(url);
  }
};

export const saveAsBlob = (csv: any, fileName: string) => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, fileName);
};

export const generateCsv = (
  data: any[],
  fileName: string,
  fields?: string[],
  config?: UnparseConfig,
) => {
  let csv;
  if (fields) {
    csv = Papa.unparse({ data, fields }, config);
  } else {
    csv = Papa.unparse(data, config);
  }
  saveAsBlob(csv, fileName);
};
