import { createIcon } from '@chakra-ui/react';

export const IconCamera: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconCamera',
  path: (
    <path
      d="M3 3C2.33696 3 1.70107 3.26339 1.23223 3.73223C0.763392 4.20107 0.5 4.83696 0.5 5.5L0.5 13C0.5 13.663 0.763392 14.2989 1.23223 14.7678C1.70107 15.2366 2.33696 15.5 3 15.5H18C18.663 15.5 19.2989 15.2366 19.7678 14.7678C20.2366 14.2989 20.5 13.663 20.5 13V5.5C20.5 4.83696 20.2366 4.20107 19.7678 3.73223C19.2989 3.26339 18.663 3 18 3H16.535C15.872 2.99986 15.2362 2.73637 14.7675 2.2675L13.7325 1.2325C13.2638 0.763627 12.628 0.500142 11.965 0.5H9.035C8.37201 0.500142 7.73623 0.763627 7.2675 1.2325L6.2325 2.2675C5.76377 2.73637 5.12799 2.99986 4.465 3H3ZM3.625 5.5C3.45924 5.5 3.30027 5.43415 3.18306 5.31694C3.06585 5.19973 3 5.04076 3 4.875C3 4.70924 3.06585 4.55027 3.18306 4.43306C3.30027 4.31585 3.45924 4.25 3.625 4.25C3.79076 4.25 3.94973 4.31585 4.06694 4.43306C4.18415 4.55027 4.25 4.70924 4.25 4.875C4.25 5.04076 4.18415 5.19973 4.06694 5.31694C3.94973 5.43415 3.79076 5.5 3.625 5.5ZM14.875 8.625C14.875 9.78532 14.4141 10.8981 13.5936 11.7186C12.7731 12.5391 11.6603 13 10.5 13C9.33968 13 8.22688 12.5391 7.40641 11.7186C6.58594 10.8981 6.125 9.78532 6.125 8.625C6.125 7.46468 6.58594 6.35188 7.40641 5.53141C8.22688 4.71094 9.33968 4.25 10.5 4.25C11.6603 4.25 12.7731 4.71094 13.5936 5.53141C14.4141 6.35188 14.875 7.46468 14.875 8.625Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 21 16',
});
