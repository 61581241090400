import { createIcon } from '@chakra-ui/react';

export const IconVirtualBackground: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconVirtualBackground',
  path: (
    <path
      clipRule="evenodd"
      d="M1.66675 3.33342C1.66675 2.41294 2.41294 1.66675 3.33341 1.66675H16.6667C17.5872 1.66675 18.3334 2.41294 18.3334 3.33341V16.6667C18.3334 17.5872 17.5872 18.3334 16.6667 18.3334H3.33342C2.41294 18.3334 1.66675 17.5872 1.66675 16.6667V3.33342ZM4.16675 13.3334V15.8334H8.75008L11.6667 12.5001L8.59473 9.08673C8.24361 8.69659 7.62359 8.7243 7.30866 9.1442L4.16675 13.3334ZM10.4167 15.8334H15.8334V13.7501L14.7801 12.4334C14.4588 12.0318 13.854 12.0142 13.5099 12.3965L10.4167 15.8334Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
  viewBox: '0 0 20 20',
});
