import { anatomy, PartsStyleFunction, MultiStyleConfig } from '@chakra-ui/theme-tools';

const parts = anatomy('fieldlist--summary').parts('container', 'list', 'listItem', 'action');

const baseStyle: PartsStyleFunction<typeof parts> = ({ colorMode, ...props }) => ({
  action: {
    button: {
      alignItems: 'center',
      bg: props.palette?.[colorMode]?.accent ?? '#6c31cd',
      borderRadius: '100%',
      display: 'inline-flex',
      h: '100%',
      justifyContent: 'center',
      p: 0,
      svg: {
        color: props.palette?.[colorMode]?.onAccent ?? '#ffffff',
        h: '1.5rem',
        w: '1.5rem',
      },
      transitionDuration: '300ms',
      transitionProperty: 'background-color, color',
      w: 'full',
    },
    h: '3.25rem',
    w: '3.25rem',
  },
  container: {
    lineHeight: '100%',
  },
  list: {
    display: 'flex',
    flexFlow: 'row wrap',
    listStyleType: 'none',
    p: 0,
  },
  listItem: {
    '&:first-child': {
      marginLeft: 0,
    },
    m: '0 0.5rem',
  },
});

export const FieldListSummary: MultiStyleConfig<typeof parts> = {
  baseStyle,
  defaultProps: {
    colorScheme: 'brand',
  },
  parts: ['container', 'list', 'listItem', 'action'],
};
