import { createIcon } from '@chakra-ui/react';

export const IconEmail: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconEmail',
  path: (
    <path
      d="M16.0708 4H3.56342C2.70354 4 2.00782 4.70354 2.00782 5.56342L2 14.944C2 15.8038 2.70354 16.5074 3.56342 16.5074H16.0708C16.9307 16.5074 17.6342 15.8038 17.6342 14.944V5.56342C17.6342 4.70354 16.9307 4 16.0708 4ZM15.7581 7.32227L10.2314 10.7774C9.98127 10.9338 9.65295 10.9338 9.4028 10.7774L3.87611 7.32227C3.68068 7.1972 3.56342 6.98614 3.56342 6.75944C3.56342 6.23569 4.13407 5.92301 4.57965 6.19661L9.81711 9.47198L15.0546 6.19661C15.5001 5.92301 16.0708 6.23569 16.0708 6.75944C16.0708 6.98614 15.9535 7.1972 15.7581 7.32227Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 20 20',
});
