import { anatomy, PartsStyleFunction, MultiStyleConfig } from '@chakra-ui/theme-tools';

const parts = anatomy('fieldlist--mini').parts('container', 'list', 'listItem');

const baseStyle: PartsStyleFunction<typeof parts> = ({ colorMode, ...props }) => ({
  container: {},
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4',
    'li:nth-of-type(2n + 1) .FieldListMini__skeleton': {
      w: '80%',
    },
    'li:nth-of-type(2n + 2) .FieldListMini__skeleton': {
      w: '71.4%',
    },
  },
  listItem: {
    '&:first-of-type': {
      pt: '1rem',
    },
    '.FieldListMini__icon': {
      alignItems: 'center',
      bg: props.palette?.[colorMode]?.brand ?? '#8f60de',
      borderRadius: '100%',
      color: props.palette?.[colorMode]?.onBrand ?? '#ffffff',
      display: 'flex',
      h: '8',
      justifyContent: 'center',
      svg: {
        h: '5',
        w: '5',
      },
      w: '8',
    },
    '.FieldListMini__skeleton': {
      bg: 'gray.200',
      borderRadius: 'md',
      h: '6',
    },
    alignItems: 'center',
    display: 'flex',
    gap: '4',
    p: '0.5rem 1.5rem',
    w: 'full',
  },
});

export const FieldListMini: MultiStyleConfig<typeof parts> = {
  baseStyle,
  defaultProps: {
    colorScheme: 'brand',
  },
  parts: ['container', 'list', 'listItem'],
  variants: {
    summary: {
      list: {
        flexDirection: 'row',
        gap: '3',
      },
      listItem: {
        '&:first-of-type': {
          pt: '0',
        },
        '.FieldListMini__icon': {
          h: '10',
          w: '10',
        },
        p: '0',
        w: 'auto',
      },
    },
  },
};
