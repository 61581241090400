import { createIcon } from '@chakra-ui/react';

export const IconAddVideo: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconAddVideo',
  path: (
    <>
      <path
        d="M4.5625 8.60938H3.20312V7.25C3.20312 7.19844 3.16094 7.15625 3.10938 7.15625H2.54688C2.49531 7.15625 2.45312 7.19844 2.45312 7.25V8.60938H1.09375C1.04219 8.60938 1 8.65156 1 8.70312V9.26562C1 9.31719 1.04219 9.35938 1.09375 9.35938H2.45312V10.7188C2.45312 10.7703 2.49531 10.8125 2.54688 10.8125H3.10938C3.16094 10.8125 3.20312 10.7703 3.20312 10.7188V9.35938H4.5625C4.61406 9.35938 4.65625 9.31719 4.65625 9.26562V8.70312C4.65625 8.65156 4.61406 8.60938 4.5625 8.60938Z"
        fill="currentColor"
      />
      <path
        d="M10.9375 3.66758L9.4375 4.53125V2.75C9.4375 2.33633 9.10117 2 8.6875 2H1.75C1.33633 2 1 2.33633 1 2.75V6.875H1.84375V2.84375H8.59375V9.40625H5.5V10.25H8.6875C9.10117 10.25 9.4375 9.91367 9.4375 9.5V7.71875L10.9375 8.58242C11.1871 8.72656 11.5 8.54609 11.5 8.25898V3.99219C11.5 3.70391 11.1871 3.52344 10.9375 3.66758ZM10.6562 7.44922L9.4375 6.74844V5.50273L10.6562 4.80078V7.44922Z"
        fill="currentColor"
      />
      <path
        d="M4 4.34375C4.05156 4.34375 4.09375 4.30156 4.09375 4.25V3.6875C4.09375 3.63594 4.05156 3.59375 4 3.59375H2.6875C2.63594 3.59375 2.59375 3.63594 2.59375 3.6875V4.25C2.59375 4.30156 2.63594 4.34375 2.6875 4.34375H4Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: '0 0 12 12',
});
