import { createIcon } from '@chakra-ui/react';

export const IconLockPerson: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconLockPerson',
  path: (
    <>
      <path
        d="M14.372 5.2168C14.3904 5.0776 14.4 4.9384 14.4 4.8C14.4 2.8968 12.6856 1.3696 10.7832 1.628C10.2288 0.6416 9.1728 0 8 0C6.8272 0 5.7712 0.6416 5.2168 1.628C3.3104 1.3696 1.6 2.8968 1.6 4.8C1.6 4.9384 1.6096 5.0776 1.628 5.2168C0.6416 5.772 0 6.828 0 8C0 9.172 0.6416 10.228 1.628 10.7832C1.60954 10.9214 1.60019 11.0606 1.6 11.2C1.6 13.1032 3.3104 14.6264 5.2168 14.372C5.7712 15.3584 6.8272 16 8 16C9.1728 16 10.2288 15.3584 10.7832 14.372C12.6856 14.6264 14.4 13.1032 14.4 11.2C14.4 11.0616 14.3904 10.9224 14.372 10.7832C15.3584 10.228 16 9.172 16 8C16 6.828 15.3584 5.772 14.372 5.2168Z"
        fill="black"
      />
      <path
        d="M8.00001 8.66659V11.3333H5.33334C5.33334 10.626 5.6143 9.94773 6.11439 9.44763C6.61449 8.94754 7.29277 8.66659 8.00001 8.66659ZM8.00001 8.33325C6.89501 8.33325 6.00001 7.43825 6.00001 6.33325C6.00001 5.22825 6.89501 4.33325 8.00001 4.33325C9.10501 4.33325 10 5.22825 10 6.33325C10 7.43825 9.10501 8.33325 8.00001 8.33325ZM11 9.66659H11.3333V11.3333H8.66668V9.66659H9.00001V9.33325C9.00001 9.06804 9.10537 8.81368 9.2929 8.62615C9.48044 8.43861 9.73479 8.33325 10 8.33325C10.2652 8.33325 10.5196 8.43861 10.7071 8.62615C10.8947 8.81368 11 9.06804 11 9.33325V9.66659ZM10.3333 9.66659V9.33325C10.3333 9.24485 10.2982 9.16006 10.2357 9.09755C10.1732 9.03504 10.0884 8.99992 10 8.99992C9.9116 8.99992 9.82682 9.03504 9.76431 9.09755C9.7018 9.16006 9.66668 9.24485 9.66668 9.33325V9.66659H10.3333Z"
        fill="white"
      />
    </>
  ),
  viewBox: '0 0 16 16',
});
