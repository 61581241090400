import { createIcon } from '@chakra-ui/react';

export const IconHiHello: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconHiHello',
  path: (
    <>
      <path
        clipRule="evenodd"
        d="M8.20928 1.0601C7.92112 1.0601 7.64481 1.0601 7.37942 1.06055C7.0898 1.06104 6.85463 0.824122 6.85415 0.531383C6.85366 0.238645 7.08805 0.000937252 7.37767 0.000449007C7.64401 0 7.92114 0 8.20903 0H8.20928C8.49889 0 8.73368 0.237312 8.73368 0.530051C8.73368 0.82279 8.49889 1.0601 8.20928 1.0601ZM6.25134 0.534276C6.25764 0.826946 6.02802 1.06936 5.73847 1.07572C5.11596 1.08941 4.58424 1.11493 4.12751 1.16159C3.83936 1.19103 3.58215 0.978787 3.55303 0.687533C3.5239 0.396278 3.73388 0.136303 4.02203 0.106863C4.51824 0.0561653 5.08104 0.0298209 5.71567 0.0158733C6.00522 0.0095097 6.24505 0.241607 6.25134 0.534276ZM2.94914 0.785772C3.0652 1.05398 2.94419 1.3665 2.67884 1.48381C2.25757 1.67006 1.99552 1.93493 1.81126 2.36074C1.6952 2.62895 1.386 2.75127 1.12066 2.63396C0.855311 2.51664 0.734293 2.20412 0.850355 1.93591C1.14038 1.2657 1.59547 0.805709 2.25854 0.512557C2.52389 0.395245 2.83308 0.517567 2.94914 0.785772ZM1.02347 3.24435C1.31162 3.27379 1.5216 3.53376 1.49247 3.82502C1.4463 4.28667 1.42106 4.82412 1.40752 5.45333C1.40122 5.746 1.16139 5.9781 0.871843 5.97174C0.582293 5.96537 0.35267 5.72296 0.358966 5.43029C0.372765 4.78882 0.398828 4.21996 0.448986 3.7184C0.478112 3.42715 0.735316 3.21491 1.02347 3.24435ZM0.868981 6.58103C1.1586 6.58152 1.39299 6.81923 1.39251 7.11197C1.39206 7.38021 1.39206 7.6595 1.39206 7.95076C1.39206 8.2435 1.15728 8.48081 0.867663 8.48081C0.578044 8.48081 0.343262 8.2435 0.343262 7.95076V7.95052C0.343262 7.65953 0.343262 7.37941 0.343706 7.1102C0.344189 6.81746 0.579363 6.58054 0.868981 6.58103Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8.20928 1.0601C7.92112 1.0601 7.64481 1.0601 7.37942 1.06055C7.0898 1.06104 6.85463 0.824122 6.85415 0.531383C6.85366 0.238645 7.08805 0.000937252 7.37767 0.000449007C7.64401 0 7.92114 0 8.20903 0H8.20928C8.49889 0 8.73368 0.237312 8.73368 0.530051C8.73368 0.82279 8.49889 1.0601 8.20928 1.0601ZM6.25134 0.534276C6.25764 0.826946 6.02802 1.06936 5.73847 1.07572C5.11596 1.08941 4.58424 1.11493 4.12751 1.16159C3.83936 1.19103 3.58215 0.978787 3.55303 0.687533C3.5239 0.396278 3.73388 0.136303 4.02203 0.106863C4.51824 0.0561653 5.08104 0.0298209 5.71567 0.0158733C6.00522 0.0095097 6.24505 0.241607 6.25134 0.534276ZM2.94914 0.785772C3.0652 1.05398 2.94419 1.3665 2.67884 1.48381C2.25757 1.67006 1.99552 1.93493 1.81126 2.36074C1.6952 2.62895 1.386 2.75127 1.12066 2.63396C0.855311 2.51664 0.734293 2.20412 0.850355 1.93591C1.14038 1.2657 1.59547 0.805709 2.25854 0.512557C2.52389 0.395245 2.83308 0.517567 2.94914 0.785772ZM1.02347 3.24435C1.31162 3.27379 1.5216 3.53376 1.49247 3.82502C1.4463 4.28667 1.42106 4.82412 1.40752 5.45333C1.40122 5.746 1.16139 5.9781 0.871843 5.97174C0.582293 5.96537 0.35267 5.72296 0.358966 5.43029C0.372765 4.78882 0.398828 4.21996 0.448986 3.7184C0.478112 3.42715 0.735316 3.21491 1.02347 3.24435ZM0.868981 6.58103C1.1586 6.58152 1.39299 6.81923 1.39251 7.11197C1.39206 7.38021 1.39206 7.6595 1.39206 7.95076C1.39206 8.2435 1.15728 8.48081 0.867663 8.48081C0.578044 8.48081 0.343262 8.2435 0.343262 7.95076V7.95052C0.343262 7.65953 0.343262 7.37941 0.343706 7.1102C0.344189 6.81746 0.579363 6.58054 0.868981 6.58103Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M23.6438 1.0601C23.9319 1.0601 24.2082 1.0601 24.4736 1.06055C24.7632 1.06104 24.9984 0.824122 24.9989 0.531383C24.9994 0.238645 24.765 0.000937252 24.4754 0.000449007C24.209 0 23.9319 0 23.644 0H23.6438C23.3541 0 23.1194 0.237312 23.1194 0.530051C23.1194 0.82279 23.3541 1.0601 23.6438 1.0601ZM25.6017 0.534276C25.5954 0.826946 25.825 1.06936 26.1146 1.07572C26.7371 1.08941 27.2688 1.11493 27.7255 1.16159C28.0137 1.19103 28.2709 0.978787 28.3 0.687533C28.3291 0.396278 28.1191 0.136303 27.831 0.106863C27.3348 0.0561653 26.772 0.0298209 26.1374 0.0158733C25.8478 0.0095097 25.608 0.241607 25.6017 0.534276ZM28.9039 0.785772C28.7878 1.05398 28.9088 1.3665 29.1742 1.48381C29.5955 1.67006 29.8575 1.93493 30.0418 2.36074C30.1578 2.62895 30.467 2.75127 30.7324 2.63396C30.9977 2.51664 31.1187 2.20412 31.0027 1.93591C30.7126 1.2657 30.2576 0.805709 29.5945 0.512557C29.3291 0.395245 29.0199 0.517567 28.9039 0.785772ZM30.8296 3.24435C30.5414 3.27379 30.3314 3.53376 30.3606 3.82502C30.4067 4.28667 30.432 4.82412 30.4455 5.45333C30.4518 5.746 30.6916 5.9781 30.9812 5.97174C31.2707 5.96537 31.5004 5.72296 31.4941 5.43029C31.4803 4.78882 31.4542 4.21996 31.404 3.7184C31.3749 3.42715 31.1177 3.21491 30.8296 3.24435ZM30.984 6.58103C30.6944 6.58152 30.46 6.81923 30.4605 7.11197C30.461 7.38021 30.461 7.6595 30.461 7.95076C30.461 8.2435 30.6957 8.48081 30.9854 8.48081C31.275 8.48081 31.5098 8.2435 31.5098 7.95076V7.95052C31.5098 7.65953 31.5098 7.37941 31.5093 7.1102C31.5088 6.81746 31.2737 6.58054 30.984 6.58103Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M23.6438 30.4394C23.9319 30.4394 24.2082 30.4394 24.4736 30.439C24.7632 30.4385 24.9984 30.6754 24.9989 30.9681C24.9994 31.2609 24.765 31.4986 24.4754 31.4991C24.209 31.4995 23.9319 31.4995 23.644 31.4995H23.6438C23.3541 31.4995 23.1194 31.2622 23.1194 30.9695C23.1194 30.6767 23.3541 30.4394 23.6438 30.4394ZM25.6017 30.9652C25.5954 30.6726 25.825 30.4302 26.1146 30.4238C26.7371 30.4101 27.2688 30.3846 27.7255 30.3379C28.0137 30.3085 28.2709 30.5207 28.3 30.812C28.3291 31.1032 28.1191 31.3632 27.831 31.3926C27.3348 31.4433 26.772 31.4697 26.1374 31.4836C25.8478 31.49 25.608 31.2579 25.6017 30.9652ZM28.9039 30.7137C28.7878 30.4455 28.9088 30.133 29.1742 30.0157C29.5955 29.8295 29.8575 29.5646 30.0418 29.1388C30.1578 28.8706 30.467 28.7482 30.7324 28.8656C30.9977 28.9829 31.1187 29.2954 31.0027 29.5636C30.7126 30.2338 30.2576 30.6938 29.5945 30.987C29.3291 31.1043 29.0199 30.9819 28.9039 30.7137ZM30.8296 28.2552C30.5414 28.2257 30.3314 27.9658 30.3606 27.6745C30.4067 27.2128 30.432 26.6754 30.4455 26.0462C30.4518 25.7535 30.6916 25.5214 30.9812 25.5278C31.2707 25.5341 31.5004 25.7766 31.4941 26.0692C31.4803 26.7107 31.4542 27.2795 31.404 27.7811C31.3749 28.0724 31.1177 28.2846 30.8296 28.2552ZM30.984 24.9185C30.6944 24.918 30.46 24.6803 30.4605 24.3875C30.461 24.1193 30.461 23.84 30.461 23.5487C30.461 23.256 30.6957 23.0187 30.9854 23.0187C31.275 23.0187 31.5098 23.256 31.5098 23.5487V23.549C31.5098 23.84 31.5098 24.1201 31.5093 24.3893C31.5088 24.6821 31.2737 24.919 30.984 24.9185Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8.20928 30.4394C7.92112 30.4394 7.64481 30.4394 7.37942 30.439C7.0898 30.4385 6.85463 30.6754 6.85415 30.9681C6.85366 31.2609 7.08805 31.4986 7.37767 31.4991C7.64401 31.4995 7.92114 31.4995 8.20903 31.4995H8.20928C8.49889 31.4995 8.73368 31.2622 8.73368 30.9695C8.73368 30.6767 8.49889 30.4394 8.20928 30.4394ZM6.25134 30.9652C6.25764 30.6726 6.02802 30.4302 5.73847 30.4238C5.11596 30.4101 4.58424 30.3846 4.12751 30.3379C3.83936 30.3085 3.58215 30.5207 3.55303 30.812C3.5239 31.1032 3.73388 31.3632 4.02203 31.3926C4.51824 31.4433 5.08104 31.4697 5.71567 31.4836C6.00522 31.49 6.24505 31.2579 6.25134 30.9652ZM2.94914 30.7137C3.0652 30.4455 2.94419 30.133 2.67884 30.0157C2.25757 29.8295 1.99552 29.5646 1.81126 29.1388C1.6952 28.8706 1.386 28.7482 1.12066 28.8656C0.855311 28.9829 0.734293 29.2954 0.850355 29.5636C1.14038 30.2338 1.59547 30.6938 2.25854 30.987C2.52389 31.1043 2.83308 30.9819 2.94914 30.7137ZM1.02347 28.2552C1.31162 28.2257 1.5216 27.9658 1.49247 27.6745C1.4463 27.2128 1.42106 26.6754 1.40752 26.0462C1.40122 25.7535 1.16139 25.5214 0.871843 25.5278C0.582293 25.5341 0.35267 25.7766 0.358966 26.0692C0.372765 26.7107 0.398828 27.2795 0.448986 27.7811C0.478112 28.0724 0.735316 28.2846 1.02347 28.2552ZM0.868981 24.9185C1.1586 24.918 1.39299 24.6803 1.39251 24.3875C1.39206 24.1193 1.39206 23.84 1.39206 23.5487C1.39206 23.256 1.15728 23.0187 0.867663 23.0187C0.578044 23.0187 0.343262 23.256 0.343262 23.5487V23.549C0.343262 23.84 0.343262 24.1201 0.343706 24.3893C0.344189 24.6821 0.579363 24.919 0.868981 24.9185Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <g clipPath="url(#clip0_805_64875)">
        <path
          d="M22.7351 12.2197C22.4166 12.2197 22.098 12.3082 21.8503 12.5383L21.8326 12.556L21.8149 12.5737C21.5494 12.8391 21.4609 13.1753 21.4609 13.4939V22.3953C21.4609 22.7138 21.5494 23.0501 21.8149 23.3155L21.8326 23.3332L21.8503 23.3509C22.098 23.581 22.4166 23.6694 22.7351 23.6694C23.0536 23.6694 23.3722 23.581 23.6376 23.3155C23.9031 23.0501 23.9916 22.7138 23.9916 22.3953V13.4762C23.9916 13.1576 23.9031 12.8214 23.6376 12.556C23.3899 12.3082 23.0536 12.2197 22.7351 12.2197Z"
          fill="currentColor"
        />
        <path
          d="M17.9741 7.84863C17.6379 7.84863 17.3016 7.97251 17.0539 8.23796C16.8238 8.48571 16.7353 8.80425 16.7353 9.12279V14.4495H10.0637V9.14049C10.0637 8.82195 9.97522 8.50341 9.74516 8.25566C9.47971 7.97251 9.14348 7.84863 8.80724 7.84863C8.47101 7.84863 8.15247 7.97251 7.90471 8.22026L7.88702 8.23796H7.86932C7.63926 8.50341 7.55078 8.82195 7.55078 9.14049V22.3776C7.55078 22.6961 7.63926 23.0146 7.86932 23.2624L7.88702 23.2801H7.90471C8.15247 23.5278 8.4887 23.6517 8.82494 23.6517C9.16117 23.6517 9.49741 23.5278 9.74516 23.2624C9.97522 23.0146 10.0637 22.6961 10.0637 22.3776V16.8208H16.7353V22.3776C16.7353 22.6961 16.8238 23.0146 17.0539 23.2624C17.3016 23.5455 17.6379 23.6517 17.9741 23.6517C18.2926 23.6517 18.6289 23.5632 18.8943 23.2978L18.912 23.2801L18.9297 23.2624C19.1598 22.9969 19.2483 22.6784 19.2483 22.3599V9.14049C19.2483 8.83964 19.1598 8.50341 18.912 8.23796C18.6466 7.95481 18.3103 7.84863 17.9741 7.84863Z"
          fill="currentColor"
        />
        <path
          d="M21.6363 10.7685C21.9548 11.0694 22.3441 11.1932 22.7512 11.1932C23.1582 11.1932 23.5475 11.0694 23.8483 10.7685C24.1669 10.4854 24.3085 10.096 24.3085 9.68902C24.3085 9.2643 24.1669 8.87498 23.8483 8.57413C23.5475 8.27329 23.1582 8.14941 22.7512 8.14941C22.3441 8.14941 21.9548 8.27329 21.654 8.55644H21.6363L21.6186 8.57413C21.3177 8.87498 21.1938 9.2643 21.1938 9.67132C21.1938 10.0783 21.3177 10.4677 21.6363 10.7685Z"
          fill="currentColor"
        />
      </g>
      <path
        clipRule="evenodd"
        d="M53.4513 23.4309C53.4513 24.2821 54.051 24.4323 54.3509 24.4323C54.6508 24.4323 55.2506 24.2821 55.2506 23.4309V7.35748C55.2506 6.50624 54.6508 6.35602 54.3509 6.35602C54.051 6.35602 53.4513 6.50624 53.4513 7.35748V14.2175H43.8047V7.35748C43.8047 6.50624 43.2049 6.35602 42.905 6.35602C42.6052 6.35602 42.0054 6.50624 42.0054 7.35748V23.4309C42.0054 24.2821 42.6052 24.4323 42.905 24.4323C43.2049 24.4323 43.8047 24.2821 43.8047 23.4309V15.8699H53.4513V23.4309ZM68.8672 18.8497H60.0703C60.1203 21.2031 61.9447 23.1059 64.3438 23.1059C65.9682 23.1059 67.0428 22.3298 67.7176 21.5787C67.9425 21.3283 68.1674 21.2532 68.3673 21.2532C68.7672 21.2532 69.1421 21.5286 69.1421 22.0043C69.1421 22.1795 69.0921 22.3798 68.8922 22.6052C67.7176 23.907 66.1431 24.558 64.3438 24.558C60.87 24.558 58.296 21.9292 58.296 18.399C58.296 14.8689 60.87 12.2401 64.1938 12.2401C67.5676 12.2401 69.7918 14.5184 69.7918 17.9734C69.7918 18.5493 69.4919 18.8497 68.8672 18.8497ZM60.0953 17.4978H68.0674C67.9674 15.2946 66.6179 13.6672 64.1938 13.6672C61.9196 13.6672 60.2452 15.4949 60.0953 17.4978ZM72.7873 23.506C72.7873 24.3072 73.3621 24.4323 73.637 24.4323C73.9119 24.4323 74.4867 24.3072 74.4867 23.506V6.08064C74.4867 5.27948 73.9119 5.1543 73.637 5.1543C73.3621 5.1543 72.7873 5.27948 72.7873 6.08064V23.506ZM78.3819 23.506C78.3819 24.3072 78.9566 24.4323 79.2316 24.4323C79.5065 24.4323 80.0812 24.3072 80.0812 23.506V6.08064C80.0812 5.27948 79.5065 5.1543 79.2316 5.1543C78.9566 5.1543 78.3819 5.27948 78.3819 6.08064V23.506ZM95.5473 18.399C95.5473 21.9292 92.9482 24.558 89.2996 24.558C85.6759 24.558 83.0768 21.9292 83.0768 18.399C83.0768 14.8689 85.6759 12.2401 89.2996 12.2401C92.9482 12.2401 95.5473 14.8689 95.5473 18.399ZM93.7469 18.3991C93.7469 15.7703 92.0225 13.6672 89.2985 13.6672C86.5995 13.6672 84.8751 15.7703 84.8751 18.3991C84.8751 21.0279 86.5995 23.1059 89.2985 23.1059C92.0225 23.1059 93.7469 21.0279 93.7469 18.3991Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <defs>
        <clipPath id="clip0_805_64875">
          <rect
            fill="currentColor"
            height="15.8031"
            transform="translate(7.55566 7.84863)"
            width="16.741"
          />
        </clipPath>
      </defs>
    </>
  ),
  viewBox: '0 0 96 32',
});
