import { ServerResponse } from 'http';
import { NextPageContext } from 'next';
import { ParsedUrlQuery, stringify } from 'querystring';

export const allowedUrls = [
  process.env.NEXT_PUBLIC_API_URL,
  process.env.NEXT_PUBLIC_APP_URL,
  process.env.NEXT_PUBLIC_AUTH_URL,
  process.env.NEXT_PUBLIC_TOOLS_URL,
  process.env.NEXT_PUBLIC_WEB_APP_URL,
  process.env.NEXT_PUBLIC_WWW_URL,
];

const pathnameToRedirectIfAuthenticated = ['/login', '/signup', '/forgot-password', '/redirecting'];

const isExternalUrl = (url: string) => url?.startsWith('//') || url?.startsWith('http');

const isAllowedUrl = (url: string) => allowedUrls.some((allowedUrl) => url?.startsWith(allowedUrl));

const isUrlToIgnore = (url: string, urlsToIgnore: string[]) =>
  urlsToIgnore.some((urlToIgnore) => url?.includes(urlToIgnore));

export const isValidUrlToRedirect = (url: string) => !isExternalUrl(url) || isAllowedUrl(url);

export const isPathnameToRedirectIfAuthenticated = (pathname: string) =>
  pathnameToRedirectIfAuthenticated.some(
    (pathnameToRedirect) => pathname?.startsWith(pathnameToRedirect),
  );

export const getNextAndParamsFromQuery = (query?: ParsedUrlQuery) => {
  const next = query?.next?.toString();
  if (!next) {
    return '';
  }

  const searchParams = new URLSearchParams(stringify(query));
  searchParams.delete('next');

  return next + (searchParams.toString() ? `&${searchParams.toString()}` : '');
};

export const getValidNextUrl = (query: ParsedUrlQuery, urlsToIgnore: string[] = []) => {
  let nextUrl = decodeURIComponent(query.next?.toString() || '').replace(
    process.env.NEXT_PUBLIC_HOST,
    '',
  );

  if (!nextUrl || !isValidUrlToRedirect(nextUrl) || isUrlToIgnore(nextUrl, urlsToIgnore)) {
    nextUrl = process.env.NEXT_PUBLIC_APP_URL;
  }

  const type = query.type?.toString();
  const token = query.integrationToken?.toString();
  const email = query.email?.toString();
  const name = query.name?.toString();
  const instanceUrl = query.instanceUrl?.toString();

  if (type && token && email && name) {
    nextUrl += `?type=${type}&integrationToken=${token}&email=${email}&name=${name}${
      instanceUrl ? `&instanceUrl=${instanceUrl}` : ''
    }`;
  }

  return nextUrl;
};

export const clientRedirectToInternalUrl = (query: ParsedUrlQuery, urlsToIgnore: string[] = []) => {
  const nextUrl = getValidNextUrl(query, urlsToIgnore);

  window.location.assign(nextUrl);
};

export const serverRedirectToInternalUrl = (res: ServerResponse, query: ParsedUrlQuery) => {
  const nextUrl = getValidNextUrl(query);

  res.writeHead(307, { Location: nextUrl });
  res.end();
};

export const redirectIfAuthenticated = (context: NextPageContext): boolean => {
  const { query, pathname, res } = context;

  if (res && isPathnameToRedirectIfAuthenticated(pathname)) {
    serverRedirectToInternalUrl(res, query);
    return true;
  }

  return false;
};

export function getNextUrl(nextUrl?: string): string {
  if (nextUrl && (nextUrl.startsWith('//') || nextUrl.startsWith('http'))) {
    return nextUrl;
  }

  return `${process.env.NEXT_PUBLIC_HOST}${nextUrl ?? '/app'}`;
}
