import { Box, BoxProps } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

export type IdentityProps = BoxProps;

export const Identity: FunctionComponent<IdentityProps> = (props) => (
  <Box className="CardIdentity" {...props} />
);

Identity.displayName = 'Identity';
