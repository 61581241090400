import { createIcon } from '@chakra-ui/react';

export const IconCamera2: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconCamera2',
  path: (
    <>
      <path
        d="M7.3125 4.31299C7.3125 4.72739 7.14788 5.12482 6.85485 5.41784C6.56183 5.71087 6.1644 5.87549 5.75 5.87549C5.3356 5.87549 4.93817 5.71087 4.64515 5.41784C4.35212 5.12482 4.1875 4.72739 4.1875 4.31299C4.1875 3.89859 4.35212 3.50116 4.64515 3.20813C4.93817 2.91511 5.3356 2.75049 5.75 2.75049C6.1644 2.75049 6.56183 2.91511 6.85485 3.20813C7.14788 3.50116 7.3125 3.89859 7.3125 4.31299Z"
        fill="currentColor"
      />
      <path
        d="M2 1.5C1.66848 1.5 1.35054 1.6317 1.11612 1.86612C0.881696 2.10054 0.75 2.41848 0.75 2.75L0.75 6.5C0.75 6.83152 0.881696 7.14946 1.11612 7.38388C1.35054 7.6183 1.66848 7.75 2 7.75H9.5C9.83152 7.75 10.1495 7.6183 10.3839 7.38388C10.6183 7.14946 10.75 6.83152 10.75 6.5V2.75C10.75 2.41848 10.6183 2.10054 10.3839 1.86612C10.1495 1.6317 9.83152 1.5 9.5 1.5H8.7675C8.43601 1.49993 8.11812 1.36819 7.88375 1.13375L7.36625 0.61625C7.13188 0.381813 6.81399 0.250071 6.4825 0.25H5.0175C4.68601 0.250071 4.36812 0.381813 4.13375 0.61625L3.61625 1.13375C3.38188 1.36819 3.06399 1.49993 2.7325 1.5H2ZM2.3125 2.75C2.22962 2.75 2.15013 2.71708 2.09153 2.65847C2.03292 2.59987 2 2.52038 2 2.4375C2 2.35462 2.03292 2.27513 2.09153 2.21653C2.15013 2.15792 2.22962 2.125 2.3125 2.125C2.39538 2.125 2.47487 2.15792 2.53347 2.21653C2.59208 2.27513 2.625 2.35462 2.625 2.4375C2.625 2.52038 2.59208 2.59987 2.53347 2.65847C2.47487 2.71708 2.39538 2.75 2.3125 2.75ZM7.9375 4.3125C7.9375 4.89266 7.70703 5.44906 7.2968 5.8593C6.88656 6.26953 6.33016 6.5 5.75 6.5C5.16984 6.5 4.61344 6.26953 4.2032 5.8593C3.79297 5.44906 3.5625 4.89266 3.5625 4.3125C3.5625 3.73234 3.79297 3.17594 4.2032 2.7657C4.61344 2.35547 5.16984 2.125 5.75 2.125C6.33016 2.125 6.88656 2.35547 7.2968 2.7657C7.70703 3.17594 7.9375 3.73234 7.9375 4.3125Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: '0 0 11 8',
});
