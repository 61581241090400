import { OAuthProviderId } from '@src/hooks';

const ROBERT_HALF_CONFIGURATION_ID = 'e72ef99a67e8';

const configurations: Record<string, LoginConfiguration> = {
  default: {
    allowEmail: true,
    providers: [
      OAuthProviderId.Google,
      OAuthProviderId.Facebook,
      OAuthProviderId.Apple,
      OAuthProviderId.Microsoft,
    ],
  },
  [ROBERT_HALF_CONFIGURATION_ID]: {
    allowEmail: false,
    providers: [OAuthProviderId.Microsoft],
  },
};

type LoginConfiguration = {
  allowEmail: boolean;
  providers: OAuthProviderId[];
};

// TODO: Replace with real backend request
export function getLoginConfiguration(configurationId: string): Promise<LoginConfiguration> {
  return new Promise((resolve) => {
    if (configurations[configurationId]) {
      resolve(configurations[configurationId]);
      return;
    }

    resolve(configurations.default);
  });
}
