import { createIcon } from '@chakra-ui/react';

export const IconCalendlyIntegration: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconCalendlyIntegration',
  path: (
    <>
      <path
        d="M16.3665 15.5629C15.6045 16.2391 14.6529 17.0806 12.9275 17.0806H11.8957C10.6471 17.0806 9.51143 16.6267 8.69858 15.8041C7.90448 15.0003 7.4674 13.9002 7.4674 12.706V11.2943C7.4674 10.1001 7.90448 8.99996 8.69858 8.19611C9.51143 7.3735 10.6471 6.92036 11.8957 6.92036H12.9275C14.655 6.92036 15.6045 7.76111 16.3665 8.43738C17.1578 9.13451 17.8403 9.74381 19.6605 9.74381C19.9381 9.74373 20.2153 9.72153 20.4894 9.67758L20.4831 9.66153C20.3741 9.39078 20.2463 9.12798 20.1004 8.87516L18.8839 6.76768C17.7687 4.83644 15.7013 3.64299 13.4712 3.64307H11.0375C8.80748 3.64299 6.74 4.83644 5.62484 6.76768L4.40832 8.87516C3.29358 10.8066 3.29358 13.1937 4.40832 15.1251L5.62484 17.2326C6.74 19.1638 8.80748 20.3573 11.0375 20.3572H13.4712C15.7013 20.3573 17.7687 19.1638 18.8839 17.2326L20.1004 15.1286C20.2463 14.8757 20.3741 14.613 20.4831 14.3422L20.4894 14.3268C20.2154 14.2813 19.9382 14.2581 19.6605 14.2572C17.8403 14.2572 17.1578 14.8623 16.3665 15.5629Z"
        fill="#006BFF"
      />
      <path
        d="M12.9269 8.19922H11.8951C9.99469 8.19922 8.74609 9.55657 8.74609 11.2939V12.7056C8.74609 14.4429 9.99469 15.8002 11.8951 15.8002H12.9269C15.696 15.8002 15.4784 12.9768 19.6599 12.9768C20.0561 12.9763 20.4515 13.0127 20.8409 13.0855C20.9678 12.3673 20.9678 11.6322 20.8409 10.9139C20.4514 10.9864 20.0561 11.0227 19.6599 11.0227C15.477 11.0227 15.696 8.19922 12.9269 8.19922Z"
        fill="#006BFF"
      />
      <path
        d="M23.2437 14.1176C22.5316 13.5961 21.7104 13.2431 20.842 13.0851V13.106C20.7672 13.5223 20.6504 13.9298 20.4934 14.3225C21.2107 14.4336 21.8914 14.714 22.4789 15.1403C22.4789 15.1459 22.4754 15.1529 22.4733 15.1592C21.0915 19.6336 16.9269 22.7061 12.2439 22.7061C6.37073 22.7061 1.53782 17.8732 1.53782 12C1.53782 6.12681 6.37073 1.29391 12.2439 1.29391C16.9269 1.29391 21.0915 4.36644 22.4733 8.84085C22.4733 8.84715 22.4768 8.85412 22.4789 8.85967C21.8915 9.28605 21.2108 9.56617 20.4934 9.67672C20.6502 10.0699 20.767 10.478 20.842 10.8947C20.8416 10.9012 20.8416 10.9077 20.842 10.9142C21.7103 10.7566 22.5316 10.4038 23.2437 9.88245C23.9283 9.3756 23.7958 8.8032 23.6919 8.46443C22.1448 3.44656 17.4755 0 12.2246 0C5.64158 0 0.224609 5.41697 0.224609 12C0.224609 18.5831 5.64158 24 12.2246 24C17.4755 24 22.1448 20.5534 23.6919 15.5356C23.7958 15.1968 23.9283 14.6244 23.2437 14.1176Z"
        fill="#006BFF"
      />
      <path
        d="M20.4888 9.67678C20.2149 9.72231 19.9377 9.74563 19.6599 9.74653C17.8396 9.74653 17.1571 9.14068 16.3665 8.44003C15.6039 7.76383 14.6544 6.92236 12.9268 6.92236H11.895C10.6458 6.92236 9.51079 7.3762 8.69794 8.19883C7.90392 9.00268 7.4668 10.1028 7.4668 11.2969V12.7087C7.4668 13.9029 7.90392 15.003 8.69794 15.8068C9.51079 16.6294 10.6458 17.0826 11.895 17.0826H12.9268C14.6544 17.0826 15.6039 16.2418 16.3665 15.5656C17.1571 14.8684 17.8396 14.2591 19.6599 14.2591C19.9375 14.2592 20.2147 14.2814 20.4888 14.3254C20.6462 13.9328 20.7629 13.5252 20.8374 13.1089C20.8378 13.1019 20.8378 13.0949 20.8374 13.0879C20.4479 13.0157 20.0526 12.9796 19.6564 12.9799C15.4735 12.9799 15.6924 15.804 12.9234 15.804H11.8916C9.99117 15.804 8.74257 14.446 8.74257 12.7087V11.2942C8.74257 9.55686 9.99117 8.19951 11.8916 8.19951H12.9234C15.6924 8.19951 15.4749 11.0223 19.6564 11.0223C20.0526 11.023 20.448 10.9868 20.8374 10.9142V10.8947C20.7624 10.478 20.6457 10.07 20.4888 9.67678Z"
        fill="#0AE8F0"
      />
      <path
        d="M20.4888 9.67678C20.2149 9.72231 19.9377 9.74563 19.6599 9.74653C17.8396 9.74653 17.1571 9.14068 16.3665 8.44003C15.6039 7.76383 14.6544 6.92236 12.9268 6.92236H11.895C10.6458 6.92236 9.51079 7.3762 8.69794 8.19883C7.90392 9.00268 7.4668 10.1028 7.4668 11.2969V12.7087C7.4668 13.9029 7.90392 15.003 8.69794 15.8068C9.51079 16.6294 10.6458 17.0826 11.895 17.0826H12.9268C14.6544 17.0826 15.6039 16.2418 16.3665 15.5656C17.1571 14.8684 17.8396 14.2591 19.6599 14.2591C19.9375 14.2592 20.2147 14.2814 20.4888 14.3254C20.6462 13.9328 20.7629 13.5252 20.8374 13.1089C20.8378 13.1019 20.8378 13.0949 20.8374 13.0879C20.4479 13.0157 20.0526 12.9796 19.6564 12.9799C15.4735 12.9799 15.6924 15.804 12.9234 15.804H11.8916C9.99117 15.804 8.74257 14.446 8.74257 12.7087V11.2942C8.74257 9.55686 9.99117 8.19951 11.8916 8.19951H12.9234C15.6924 8.19951 15.4749 11.0223 19.6564 11.0223C20.0526 11.023 20.448 10.9868 20.8374 10.9142V10.8947C20.7624 10.478 20.6457 10.07 20.4888 9.67678Z"
        fill="#0AE8F0"
      />
    </>
  ),
  viewBox: '0 0 28 29',
});
