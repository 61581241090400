import { createIcon } from '@chakra-ui/react';

export const IconNotion: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconNotion',
  path: (
    <>
      <g clipPath="url(#clip0_1639_28115)">
        <path
          clipRule="evenodd"
          d="M6.43719 0.396314C3.50574 0.6229 1.01329 0.821163 0.899995 0.835325C0.574278 0.906133 0.276883 1.11856 0.135267 1.41595L0.0078125 1.67086L0.0219741 7.81701L0.0361358 13.9632L0.220237 14.3455C0.33353 14.5579 1.04161 15.5493 1.8205 16.5547C3.36412 18.5657 3.47741 18.6648 4.05804 18.7356C4.22798 18.7498 5.51669 18.6931 6.89037 18.6082C8.2782 18.5232 10.5157 18.3816 11.8469 18.3108C17.5541 17.9567 17.2567 17.9851 17.5966 17.7018C18.0072 17.3619 17.9648 17.9284 17.9931 10.4511C18.0072 4.12082 17.9931 3.69597 17.894 3.51187C17.7948 3.28529 17.5966 3.14367 14.9908 1.31682C13.2489 0.0847583 13.1215 0.0281118 12.3426 0.0139502C12.0169 -0.000211453 9.35449 0.18389 6.43719 0.396314Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6.43719 0.396314C3.50574 0.6229 1.01329 0.821163 0.899995 0.835325C0.574278 0.906133 0.276883 1.11856 0.135267 1.41595L0.0078125 1.67086L0.0219741 7.81701L0.0361358 13.9632L0.220237 14.3455C0.33353 14.5579 1.04161 15.5493 1.8205 16.5547C3.36412 18.5657 3.47741 18.6648 4.05804 18.7356C4.22798 18.7498 5.51669 18.6931 6.89037 18.6082C8.2782 18.5232 10.5157 18.3816 11.8469 18.3108C17.5541 17.9567 17.2567 17.9851 17.5966 17.7018C18.0072 17.3619 17.9648 17.9284 17.9931 10.4511C18.0072 4.12082 17.9931 3.69597 17.894 3.51187C17.7948 3.28529 17.5966 3.14367 14.9908 1.31682C13.2489 0.0847583 13.1215 0.0281118 12.3426 0.0139502C12.0169 -0.000211453 9.35449 0.18389 6.43719 0.396314ZM13.0224 1.20353C13.2773 1.31682 15.0758 2.56304 15.3307 2.80379C15.4015 2.8746 15.4298 2.94541 15.3873 2.97373C15.3165 3.04454 4.17133 3.71014 3.84561 3.65349C3.704 3.63933 3.49157 3.55436 3.36412 3.46939C2.8543 3.11535 1.59392 2.08155 1.59392 2.01074C1.59392 1.81248 1.55143 1.81248 6.508 1.44427C7.45683 1.38763 9.02877 1.27434 9.9776 1.18937C12.031 1.03359 12.64 1.03359 13.0224 1.20353ZM16.5628 4.40406C16.6477 4.48903 16.7185 4.6448 16.7469 4.80058C16.761 4.9422 16.7752 7.57626 16.761 10.6352C16.7469 15.875 16.7327 16.2149 16.6336 16.3565C16.5769 16.4556 16.4636 16.5547 16.3645 16.5831C16.1237 16.6822 4.45456 17.3478 4.21382 17.277C4.10052 17.2487 3.94475 17.1495 3.87394 17.0646L3.73232 16.9229L3.71816 11.2583C3.704 7.27887 3.71816 5.52282 3.76064 5.36705C3.78897 5.25375 3.8881 5.11214 3.95891 5.05549C4.05804 4.98468 4.75196 4.92804 7.13111 4.78642C8.80219 4.70145 11.3938 4.54567 12.8666 4.44654C16.407 4.23412 16.3928 4.23412 16.5628 4.40406Z"
          fill="black"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M13.4329 6.34441C12.7957 6.3869 12.2292 6.44354 12.1726 6.48603C11.9743 6.58516 11.861 6.7551 11.8327 6.9392C11.8185 7.1233 11.8752 7.15163 12.5124 7.2366L12.7815 7.26492V9.77153C12.7815 11.2585 12.7673 12.2357 12.7249 12.2073C12.6965 12.179 11.8752 10.9045 10.8838 9.40333C9.89253 7.87387 9.07115 6.62765 9.05699 6.62765C9.04283 6.61348 8.40556 6.64181 7.62667 6.69845C6.67784 6.7551 6.15386 6.82591 6.05473 6.88256C5.88479 6.96752 5.68652 7.27908 5.68652 7.47734C5.68652 7.6048 5.91311 7.67561 6.38044 7.67561H6.63535V14.8697L6.22467 14.9972C5.92727 15.0821 5.81398 15.1388 5.75733 15.2662C5.67236 15.4645 5.67236 15.6344 5.77149 15.6344C5.79982 15.6344 6.46541 15.6061 7.23014 15.5495C8.73127 15.4645 8.90121 15.422 9.05699 15.1105C9.11364 15.0255 9.15612 14.9264 9.15612 14.8839C9.15612 14.8697 8.9437 14.7989 8.70295 14.7423C8.44804 14.6856 8.19313 14.6148 8.13649 14.6148C8.03735 14.5865 8.03735 14.4024 8.03735 11.8816V9.17674L9.82172 11.9666C11.6911 14.898 11.9176 15.2379 12.215 15.3795C12.5691 15.5636 13.4754 15.4362 13.9286 15.1388L14.0702 15.0538L14.0844 11.0602L14.0985 7.05249L14.4101 6.99585C14.7783 6.92504 14.9482 6.7551 14.9482 6.45771C14.9482 6.27361 14.9341 6.25944 14.7641 6.27361C14.665 6.27361 14.056 6.31609 13.4329 6.34441Z"
          fill="black"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_1639_28115">
          <rect fill="white" height="18.75" width="18.015" />
        </clipPath>
      </defs>
    </>
  ),
  viewBox: '0 0 19 19',
});
