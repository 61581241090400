import { createIcon } from '@chakra-ui/react';

export const IconBrightcove: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconBrightcove',
  path: (
    <>
      <path
        clipRule="evenodd"
        d="M116.5 116.5C200.167 116.333 283.834 116.5 367.5 117C389.485 117.738 409.152 124.738 426.5 138C449.5 161 472.5 184 495.5 207C495 207.167 494.5 207.333 494 207.5C476.885 198.679 458.719 193.513 439.5 192C356.834 191.5 274.167 191.333 191.5 191.5C191.833 301.502 191.5 411.502 190.5 521.5C165.847 497.347 141.347 473.014 117 448.5C116.5 337.834 116.333 227.167 116.5 116.5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M199.5 530.5C275.328 528.834 351.328 527.834 427.5 527.5C503.586 526.568 536.752 488.235 527 412.5C524.975 391.6 514.475 376.767 495.5 368C479.957 362.743 463.957 360.076 447.5 360C448.451 356.943 448.784 353.777 448.5 350.5C463.856 350.092 478.856 347.592 493.5 343C514.102 333.993 524.935 318.16 526 295.5C527.641 277.904 526.308 260.571 522 243.5C520.194 238.554 517.861 233.888 515 229.5C514.333 228.5 514.333 227.5 515 226.5C538.667 250.167 562.333 273.833 586 297.5C592.445 306.629 597.112 316.629 600 327.5C601.522 342.467 601.855 357.467 601 372.5C598.051 399.615 582.884 414.615 555.5 417.5C555.201 419.604 555.534 421.604 556.5 423.5C567.486 433.986 578.319 444.652 589 455.5C596.062 464.294 600.396 474.294 602 485.5C602.866 503.529 602.533 521.529 601 539.5C594.552 568.614 577.052 587.448 548.5 596C539.02 598.896 529.353 600.896 519.5 602C436.5 602.667 353.5 602.667 270.5 602C246.632 578.299 222.965 554.465 199.5 530.5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M388.5 260.5C404.636 259.959 413.47 267.625 415 283.5C416.074 293.057 415.074 302.391 412 311.5C406.961 318.31 400.128 322.144 391.5 323C362.502 323.5 333.502 323.667 304.5 323.5C304.5 302.5 304.5 281.5 304.5 260.5C332.5 260.5 360.5 260.5 388.5 260.5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M387.5 458.5C359.833 458.5 332.167 458.5 304.5 458.5C304.5 436.5 304.5 414.5 304.5 392.5C331.997 391.336 359.664 391.17 387.5 392C403.475 392.048 412.642 399.881 415 415.5C417.201 428.377 415.034 440.377 408.5 451.5C402.535 456.702 395.535 459.035 387.5 458.5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </>
  ),
  viewBox: '0 0 720 720',
});
