import { createIcon } from '@chakra-ui/react';

export const IconExport: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconExport',
  path: (
    <path
      d="M8.5 10.9785L4.51563 6.99414L5.63125 5.83867L7.70313 7.91055V1.41602H9.29688V7.91055L11.3688 5.83867L12.4844 6.99414L8.5 10.9785ZM3.71875 14.166C3.28047 14.166 2.90514 14.0098 2.59277 13.6975C2.28039 13.3851 2.12447 13.01 2.125 12.5723V10.1816H3.71875V12.5723H13.2812V10.1816H14.875V12.5723C14.875 13.0105 14.7188 13.3859 14.4064 13.6982C14.0941 14.0106 13.719 14.1665 13.2812 14.166H3.71875Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 17 17',
});
