import { createIcon } from '@chakra-ui/react';

export const IconLightningBolt: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconLightningBolt',
  path: (
    <path
      d="M8.66663 6.5H14L7.33329 16.5V10.5H2.66663L8.66663 0.5V6.5ZM7.33329 7.83333V5.31333L5.02129 9.16667H8.66663V12.096L11.5086 7.83333H7.33329Z"
      fill="currentColor"
      id="Vector"
    />
  ),
  viewBox: '0 0 15 15',
});
