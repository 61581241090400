import { UserSession } from '@hihello/core';
import { useAuth } from '@src/contexts/auth';
import { MutableRefObject, useEffect, useState } from 'react';

type UserInfo = {
  claims: Record<string, unknown>;
  isInitialized: boolean;
  user: UserSession | null;
};

const ref: MutableRefObject<UserInfo> = {
  current: {
    claims: {},
    isInitialized: false,
    user: null,
  },
};

export function useAuthenticatedUser(): UserInfo {
  const { user } = useAuth();
  const [userInfo, setUserInfo] = useState(ref.current);

  useEffect(() => {
    const controller = new AbortController();
    ref.current = {
      claims: {},
      isInitialized: true,
      user,
    };

    setUserInfo(ref.current);

    return () => {
      controller.abort();
      ref.current = {
        claims: {},
        isInitialized: false,
        user: null,
      };
    };
  }, [user]);

  return userInfo;
}
