import { Icon, IconProps } from '@chakra-ui/react';
import { v4 as uuid } from 'uuid';
import { Easing, PathMorph } from './PathMorph';

export type BlobShapeProps = IconProps & {
  shapePaths: {
    animationOptions?: {
      delay?: number;
      duration?: number;
      ease?: Easing | Easing[];
    };
    color: string;
    paths: string[];
    transparency?: number;
  }[];
};

export const BlobShape = ({ shapePaths, ...props }: BlobShapeProps) => (
  <Icon fill="none" viewBox="0 0 508 507" xmlns="http://www.w3.org/2000/svg" {...props}>
    {shapePaths.map(({ color, paths, transparency, animationOptions }) => (
      <PathMorph
        key={uuid()}
        animationOptions={animationOptions}
        color={color}
        paths={paths}
        transparency={transparency}
      />
    ))}
  </Icon>
);
