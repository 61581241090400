export enum AnalyticsEvent {
  AUTH_CLAIM_CARD_SUCCESSFUL = 'card claimed',
  AUTH_LOGIN_PAGE_VIEW = 'viewed login page',
  AUTH_RESET_PASSWORD_PAGE_VIEW = 'viewed password reset page',
  AUTH_RESET_PASSWORD_RESULT = 'result of reset password',
  AUTH_SIGN_UP_PAGE_VIEW = 'viewed sign up page',
}

export type AnalyticsLog =
  | {
      event: AnalyticsEvent.AUTH_LOGIN_PAGE_VIEW;
      payload: { 'page type': 'card claim' | 'standard' };
    }
  | {
      event: AnalyticsEvent.AUTH_SIGN_UP_PAGE_VIEW;
      payload: { 'page type': 'card claim' | 'standard' };
    }
  | {
      event: AnalyticsEvent.AUTH_RESET_PASSWORD_PAGE_VIEW;
      payload: { 'page type': 'standard' };
    }
  | {
      event: AnalyticsEvent.AUTH_RESET_PASSWORD_RESULT;
      payload: { 'event type': 'success' | 'token expired' };
    }
  | {
      event: AnalyticsEvent.AUTH_CLAIM_CARD_SUCCESSFUL;
      payload: { 'claimed by': string; 'event type': 'success' };
    };
