import { Box, BoxProps } from '@chakra-ui/react';

interface Props extends BoxProps {
  isActive: boolean;
}

export const Step = (props: Props) => {
  const { isActive, ...boxProps } = props;
  return (
    <Box
      bg={isActive ? 'gray.400' : 'gray.200'}
      borderRadius="full"
      boxSize="2.5"
      transition="background 0.2s"
      {...boxProps}
    />
  );
};
