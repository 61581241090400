import { Box, useMultiStyleConfig } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { useStore } from 'zustand';
import { useCardStore } from '../../../store';
import { Media } from '../../Media';
import { HeaderVariantProps } from './props';

export const Wave: FunctionComponent<HeaderVariantProps> = ({ children, ...props }) => {
  const cardStore = useCardStore();
  const palette = useStore(cardStore, (state) => state.themePalette);

  const styles = useMultiStyleConfig('CardHeaderWave', {
    palette,
  });

  return (
    <Box __css={styles.container} as="header" className="CardHeaderFlat" {...props}>
      <Box __css={styles.contents}>
        <Media variant="avatar" />
        <Box __css={styles.wave}>
          <Box
            __css={styles.divider}
            as="svg"
            preserveAspectRatio="xMinYMax meet"
            viewBox="0 0 246 57"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              d="M 214.7168,6.1113281 C 195.65271,5.9023124 172.37742,11.948182 137.87305,32.529297 110.16613,49.05604 86.980345,56.862784 65.015625,57 H 65 v 1 H 246 V 11.453125 C 236.0775,8.6129313 226.15525,6.2367376 214.7168,6.1113281 Z"
              fill="white"
              fillRule="evenodd"
            />
            <path
              clipRule="evenodd"
              d="M 0,35.773438 V 58 H 65 L 64.97852,57 C 43.192081,57.127508 22.605139,49.707997 0,35.773438 Z "
              fill="white"
              fillRule="evenodd"
            />
            <path
              clipRule="evenodd"
              d="m 0,16.7221 v 19.052 C 45.4067,63.7643 82.6667,65.4583 137.873,32.5286 193.08,-0.401184 219.54,3.87965 246,11.4535 V 6.51403 C 185.24,-16.8661 135.913,29.331 97.6933,40.8564 59.4733,52.3818 33.6467,44.1494 0,16.7221 Z "
              fill={palette?.light?.brand ?? '#8f60de'}
              fillRule="evenodd"
            />
          </Box>
          <Box __css={styles.logo}>
            <Media variant="logo" />
          </Box>
        </Box>
      </Box>
      {children}
    </Box>
  );
};
