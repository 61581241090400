import { Box, Icon } from '@chakra-ui/react';
import { Design } from '@hihello/core';
import { useMemo } from 'react';
import {
  DesignIconLinear,
  DesignIconModern,
  DesignIconRobertHalf,
  DesignIconRound,
  DesignIconSleek,
  DesignIconWave,
} from './designs';

export type DesignIconProps = {
  design: Design;
  height?: string;
  profileColor?: string;
  width?: string;
};

export const DesignIcon = ({
  design,
  height = '4.5rem',
  profileColor,
  width = '4.5rem',
}: DesignIconProps) => {
  const icon = useMemo(() => {
    switch (design.name) {
      case 'Wave':
        return DesignIconWave;
      case 'Flat':
        return DesignIconLinear;
      case 'Modern':
        return DesignIconModern;
      case 'Sleek':
        return DesignIconSleek;
      case 'Round':
        return DesignIconRound;
      case 'Robert Half':
        return DesignIconRobertHalf;
      default:
        return DesignIconWave;
    }
  }, [design.name]);

  return (
    <Box bg="bg-surface" borderRadius="1rem" boxShadow="md" height={height} width={width}>
      <Icon as={icon} color={profileColor} h="full" maxW="full" w="full" />
    </Box>
  );
};
