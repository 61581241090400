import { Checkbox as ChakraCheckbox, CheckboxProps as ChakraCheckboxProps } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { CheckboxIcon } from './CheckboxIcon';

export type CheckboxProps = ChakraCheckboxProps;

export const Checkbox: FunctionComponent<CheckboxProps> = (props) => {
  const { isIndeterminate } = props;

  return (
    <ChakraCheckbox
      icon={<CheckboxIcon isIndeterminate={isIndeterminate} />}
      size="lg"
      {...props}
    />
  );
};
