import { createIcon } from '@chakra-ui/react';

export const IconLinkChain: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconLinkChain',
  path: (
    <path
      d="M12.2428 10.8573L11.3002 9.91333L12.2428 8.97066C12.5546 8.66162 12.8022 8.29406 12.9716 7.88906C13.1409 7.48406 13.2285 7.0496 13.2295 6.61064C13.2304 6.17167 13.1447 5.73683 12.9772 5.3311C12.8096 4.92536 12.5636 4.55671 12.2532 4.24631C11.9428 3.93592 11.5741 3.68988 11.1684 3.52234C10.7627 3.3548 10.3278 3.26905 9.88886 3.27001C9.44989 3.27097 9.01544 3.35863 8.61044 3.52794C8.20544 3.69726 7.83787 3.94491 7.52884 4.25666L6.58617 5.19999L5.64284 4.25733L6.58684 3.31466C7.46205 2.43944 8.6491 1.94775 9.88684 1.94775C11.1246 1.94775 12.3116 2.43944 13.1868 3.31466C14.0621 4.18987 14.5537 5.37692 14.5537 6.61466C14.5537 7.8524 14.0621 9.03944 13.1868 9.91466L12.2435 10.8573H12.2428ZM10.3575 12.7427L9.41417 13.6853C8.53896 14.5605 7.35191 15.0522 6.11417 15.0522C4.87643 15.0522 3.68938 14.5605 2.81417 13.6853C1.93896 12.8101 1.44727 11.6231 1.44727 10.3853C1.44727 9.14759 1.93896 7.96054 2.81417 7.08533L3.7575 6.14266L4.70017 7.08666L3.7575 8.02933C3.44575 8.33836 3.19811 8.70593 3.02879 9.11093C2.85947 9.51593 2.77182 9.95038 2.77086 10.3893C2.76989 10.8283 2.85565 11.2632 3.02319 11.6689C3.19073 12.0746 3.43676 12.4433 3.74716 12.7537C4.05756 13.0641 4.42621 13.3101 4.83194 13.4776C5.23768 13.6452 5.67251 13.7309 6.11148 13.73C6.55045 13.729 6.9849 13.6414 7.3899 13.472C7.7949 13.3027 8.16247 13.0551 8.4715 12.7433L9.41417 11.8007L10.3575 12.7433V12.7427ZM9.8855 5.67133L10.8288 6.61466L6.11484 11.328L5.1715 10.3853L9.8855 5.67199V5.67133Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 16 16',
});
