import { createIcon } from '@chakra-ui/react';

export const IconEmailSend: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconEmailSend',
  path: (
    <path
      d="M8.66671 12.6667C8.66671 10.4601 10.46 8.66675 12.6667 8.66675C13.4 8.66675 14.08 8.86675 14.6667 9.20675V4.00008C14.6667 3.64646 14.5262 3.30732 14.2762 3.05727C14.0261 2.80722 13.687 2.66675 13.3334 2.66675H2.66671C1.92671 2.66675 1.33337 3.26008 1.33337 4.00008V12.0001C1.33337 12.3537 1.47385 12.6928 1.7239 12.9429C1.97395 13.1929 2.31309 13.3334 2.66671 13.3334H8.72671C8.69337 13.1134 8.66671 12.8934 8.66671 12.6667ZM2.66671 5.33341V4.00008L8.00004 7.33342L13.3334 4.00008V5.33341L8.00004 8.66675L2.66671 5.33341ZM13.3334 14.6667V13.3334H10.6667V12.0001H13.3334V10.6667L15.3334 12.6667L13.3334 14.6667Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 20 20',
});
