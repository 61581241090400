import { createIcon } from '@chakra-ui/react';

export const IconNonEditable: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconNonEditable',
  path: (
    <>
      <path
        clipRule="evenodd"
        d="M20.7074 5.63216C21.0974 6.02211 21.0974 6.65203 20.7074 7.04198L18.8776 8.87175L15.1281 5.12223L16.9579 3.29246C17.3478 2.90251 17.9778 2.90251 18.3677 3.29246L20.7074 5.63216ZM3 21.001V17.2515L14.0586 6.1929L17.8081 9.94242L6.74952 21.001H3Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <rect
        fill="currentColor"
        height="3"
        transform="rotate(45 4.06055 3.51758)"
        width="23"
        x="4.06055"
        y="3.51758"
      />
    </>
  ),
});
