import { IncomingMessage, ServerResponse } from 'http';
import { setCookie } from '../utils/cookies';
import { fetcher, getIdToken, getRefreshToken } from './fetcher';
import { GetUserDataDocument } from './generated';
import { refreshToken } from './refreshToken';

export interface UserSession {
  email: string;
  name: string;
  providers: string[];
  token: string;
  uid: string;
}

export async function getUserProfile(token?: string): Promise<UserSession | null> {
  if (!token) {
    return null;
  }

  try {
    const userData = await fetcher<
      {
        user: {
          email: string;
          id: string;
          name: string;
          providerData: {
            providerId: string;
          }[];
        };
      },
      null
    >(GetUserDataDocument, undefined, { Authorization: `Bearer ${token}` })();

    if (!userData) {
      return null;
    }

    const { email, id: uid, name, providerData } = userData.user;

    return { email, name, providers: providerData.map((p) => p.providerId), token, uid };
  } catch (error) {
    return null;
  }
}

export const getUserData = async (
  req?: IncomingMessage,
  res?: ServerResponse,
  idTokenName = 'session',
  refreshTokenName = 'refreshToken',
): Promise<UserSession | null> => {
  const token = getIdToken(req, idTokenName);
  const refreshTokenCookie = getRefreshToken(req, refreshTokenName);
  let user = await getUserProfile(token);

  if (user || !refreshTokenCookie) {
    return user;
  }

  const { token: newToken } = await refreshToken({
    refreshTokenName,
    returnToken: true,
    sessionName: idTokenName,
    token: refreshTokenCookie,
  });

  if (newToken) {
    setCookie({ name: idTokenName, res, value: newToken });
    user = await getUserProfile(newToken);
  }

  return user;
};
