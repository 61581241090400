import { createIcon } from '@chakra-ui/react';

export const IconAlert: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconAlert',
  path: (
    <path
      d="M7.00016 13.6663C3.31816 13.6663 0.333496 10.6817 0.333496 6.99967C0.333496 3.31767 3.31816 0.333008 7.00016 0.333008C10.6822 0.333008 13.6668 3.31767 13.6668 6.99967C13.6668 10.6817 10.6822 13.6663 7.00016 13.6663ZM6.3335 8.99967V10.333H7.66683V8.99967H6.3335ZM6.3335 3.66634V7.66634H7.66683V3.66634H6.3335Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 14 14',
});
