import { createIcon } from '@chakra-ui/react';

export const IconFax: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconFax',
  path: (
    <>
      <path
        d="M19.7071 11.162L17.3818 9.03056V5.75149L14.7137 3H7.49091V7.8H4.87273C4.64135 7.80028 4.41952 7.89519 4.25591 8.06391C4.0923 8.23263 4.00027 8.46139 4 8.7V20.1C4.00027 20.3386 4.0923 20.5674 4.25591 20.7361C4.41952 20.9048 4.64135 20.9997 4.87273 21H20V11.8347C19.9999 11.7076 19.9738 11.582 19.9233 11.4661C19.8728 11.3501 19.7991 11.2465 19.7071 11.162ZM7.49091 19.8H5.16364V9H7.49091V19.8ZM13.8909 4.2H14.2317L16.2182 6.24851V6.6H13.8909V4.2ZM8.65455 4.2H12.7273V7.8H16.2182V10.5H8.65455V4.2ZM18.8364 19.8H8.65455V11.7H17.3818V10.6361L18.8364 11.9695V19.8Z"
        fill="currentColor"
      />
      <path
        d="M11 15.4H12V16.6H11V15.4ZM13 15.4H14V16.6H13V15.4ZM11 17.8H12V19H11V17.8ZM13 17.8H14V19H13V17.8ZM15 15.4H16V16.6H15V15.4ZM15 17.8H16V19H15V17.8ZM11 13H16V14.2H11V13Z"
        fill="currentColor"
      />
    </>
  ),
});
