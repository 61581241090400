import { LoginProviderTypes, OAuthProviderId } from '@src/hooks';

export type GetLoginProvidersResponse = { allowEmail: boolean; providers: OAuthProviderId[] };

export async function getLoginProviders(email: string): Promise<GetLoginProvidersResponse> {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/identity-HttpsGetAllowedProvidersV1?email=${email}`,
  );

  const data = await response.json();

  if (!response.ok) {
    throw data.error;
  }

  return (data.result?.allowed_providers as LoginProviderTypes[]).reduce(
    (acc: GetLoginProvidersResponse, provider) => {
      if (provider === 'email') {
        acc.allowEmail = true;
      } else {
        acc.providers.push(provider);
      }

      return acc;
    },
    { allowEmail: false, providers: [] },
  );
}

export const hasWorkOSProvider = (enabledProviders?: GetLoginProvidersResponse) =>
  enabledProviders?.providers.find((value) => value === OAuthProviderId.WorkOS);
