import { Box, BoxProps, useStyleConfig } from '@chakra-ui/react';
import { FunctionComponent, HTMLAttributes } from 'react';

export type ImageTransition = 'fade' | 'scale' | 'scaleX' | 'scaleY';

export type ImageProps = BoxProps &
  HTMLAttributes<HTMLImageElement> & {
    alt: string;
    placeholder?: string;
    src?: string;
    transition?: ImageTransition;
  };

export const Image: FunctionComponent<ImageProps> = ({
  alt,
  placeholder,
  src,
  transition,
  ...props
}) => {
  const styles = useStyleConfig('CardImage', {
    isVisible: true,
    variant: transition,
  });

  return <Box {...props} __css={styles} alt={alt} as="img" className="CardImage" src={src} />;
};
