import { createIcon } from '@chakra-ui/react';

export const IconBuilding2: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconBuilding2',
  path: (
    <path
      clipRule="evenodd"
      d="M9.85009 0.25C10.279 0.249864 10.6922 0.411861 11.0067 0.703516C11.3212 0.995171 11.5139 1.39493 11.546 1.82265L11.5503 1.95016V13.8513H12.4004V5.77553C12.4003 5.67605 12.4352 5.57971 12.4989 5.50329C12.5626 5.42686 12.6511 5.3752 12.7489 5.35729L12.8254 5.35049H14.1006C14.5295 5.35036 14.9426 5.51235 15.2572 5.80401C15.5717 6.09567 15.7643 6.49542 15.7965 6.92315L15.8008 7.05066V13.8513H16.6508C16.8675 13.8516 17.0759 13.9345 17.2335 14.0833C17.391 14.232 17.4858 14.4353 17.4985 14.6516C17.5112 14.8679 17.4409 15.0809 17.3018 15.247C17.1627 15.4132 16.9655 15.5199 16.7503 15.5455L16.6508 15.5515H1.34915C1.13248 15.5512 0.924078 15.4683 0.766525 15.3195C0.608972 15.1708 0.51416 14.9675 0.501462 14.7512C0.488764 14.5349 0.559138 14.3219 0.698205 14.1558C0.837273 13.9896 1.03454 13.8829 1.24969 13.8573L1.34915 13.8513H2.19925V1.95016C2.19911 1.52123 2.36111 1.1081 2.65277 0.793587C2.94443 0.479072 3.34419 0.28642 3.77192 0.254251L3.89943 0.25H9.85009ZM8.14991 10.451H5.59962V12.1512H8.14991V10.451ZM8.14991 7.05066H5.59962V8.75082H8.14991V7.05066ZM8.14991 3.65033H5.59962V5.35049H8.14991V3.65033Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
  viewBox: '0 0 18 16',
});
