import { createIcon } from '@chakra-ui/react';

export const IconQuestionLine: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconQuestionLine',
  path: (
    <path
      d="M10.2917 15.3333C10.5833 15.3333 10.83 15.2325 11.0317 15.0308C11.2333 14.8292 11.3339 14.5828 11.3333 14.2917C11.3333 14 11.2328 13.7533 11.0317 13.5517C10.8306 13.35 10.5839 13.2494 10.2917 13.25C10 13.25 9.75361 13.3508 9.5525 13.5525C9.35139 13.7542 9.25056 14.0006 9.25 14.2917C9.25 14.5833 9.35083 14.83 9.5525 15.0317C9.75417 15.2333 10.0006 15.3339 10.2917 15.3333ZM9.54167 12.125H11.0833C11.0833 11.6667 11.1356 11.3056 11.24 11.0417C11.3444 10.7778 11.6394 10.4167 12.125 9.95833C12.4861 9.59722 12.7708 9.25333 12.9792 8.92667C13.1875 8.6 13.2917 8.20778 13.2917 7.75C13.2917 6.97222 13.0069 6.375 12.4375 5.95833C11.8681 5.54167 11.1944 5.33333 10.4167 5.33333C9.625 5.33333 8.9825 5.54167 8.48917 5.95833C7.99583 6.375 7.65222 6.875 7.45833 7.45833L8.83333 8C8.90278 7.75 9.05917 7.47917 9.3025 7.1875C9.54583 6.89583 9.91722 6.75 10.4167 6.75C10.8611 6.75 11.1944 6.87167 11.4167 7.115C11.6389 7.35833 11.75 7.62556 11.75 7.91667C11.75 8.19444 11.6667 8.455 11.5 8.69833C11.3333 8.94167 11.125 9.16722 10.875 9.375C10.2639 9.91667 9.88889 10.3264 9.75 10.6042C9.61111 10.8819 9.54167 11.3889 9.54167 12.125ZM10.3333 18.6667C9.18056 18.6667 8.09722 18.4478 7.08333 18.01C6.06944 17.5722 5.1875 16.9786 4.4375 16.2292C3.6875 15.4792 3.09389 14.5972 2.65667 13.5833C2.21944 12.5694 2.00056 11.4861 2 10.3333C2 9.18056 2.21889 8.09722 2.65667 7.08333C3.09444 6.06944 3.68806 5.1875 4.4375 4.4375C5.1875 3.6875 6.06944 3.09389 7.08333 2.65667C8.09722 2.21944 9.18056 2.00056 10.3333 2C11.4861 2 12.5694 2.21889 13.5833 2.65667C14.5972 3.09444 15.4792 3.68806 16.2292 4.4375C16.9792 5.1875 17.5731 6.06944 18.0108 7.08333C18.4486 8.09722 18.6672 9.18056 18.6667 10.3333C18.6667 11.4861 18.4478 12.5694 18.01 13.5833C17.5722 14.5972 16.9786 15.4792 16.2292 16.2292C15.4792 16.9792 14.5972 17.5731 13.5833 18.0108C12.5694 18.4486 11.4861 18.6672 10.3333 18.6667Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 20 20',
});
