import {
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalProps,
} from '@chakra-ui/react';
import { useCallback, useMemo, useRef } from 'react';
import { Stepper } from './Stepper';
import { useStep } from './useStep';

export type StepperModalProps = Omit<ModalProps, 'children'> & {
  colorScheme?: string;
  content: React.ReactNode[];
  isClosable?: boolean;
  lastStepAction: () => void;
  lastStepText: string;
  title?: React.ReactNode;
};

export const StepperModal = (props: StepperModalProps) => {
  const {
    colorScheme,
    content,
    isClosable = true,
    title,
    lastStepText,
    lastStepAction,
    ...modalProps
  } = props;

  const initialRef = useRef(null);
  const numberOfSteps = content.length;
  const [currentStep, { setStep, canGoToPrevStep }] = useStep({
    initialStep: 0,
    maxStep: numberOfSteps,
  });
  const isLastStep = useMemo(() => currentStep === numberOfSteps - 1, [currentStep, numberOfSteps]);
  const handlePrevious = useCallback(() => setStep(currentStep - 1), [currentStep, setStep]);
  const handleContinue = useCallback(() => {
    if (isLastStep) {
      lastStepAction();
      setStep(0);
    } else {
      setStep(currentStep + 1);
    }
  }, [currentStep, isLastStep, lastStepAction, setStep]);

  return (
    <Modal
      closeOnEsc={isClosable}
      closeOnOverlayClick={isClosable}
      initialFocusRef={initialRef}
      isCentered
      size="lg"
      {...modalProps}
    >
      <ModalOverlay />
      <ModalContent borderRadius="xl">
        {isClosable && <ModalCloseButton />}
        <ModalBody p={4}>{content[currentStep]}</ModalBody>
        <ModalFooter borderTop="solid 1px" borderTopColor="gray.200">
          <Center justifyContent="start" w="100%">
            {canGoToPrevStep && (
              <Button onClick={handlePrevious} variant="outline">
                Previous
              </Button>
            )}
          </Center>
          <Center justifyContent="center" w="100%">
            <Stepper currentStep={currentStep} numberOfSteps={numberOfSteps} />
          </Center>
          <Center justifyContent="end" w="100%">
            <Button ref={initialRef} colorScheme={colorScheme || 'brand'} onClick={handleContinue}>
              {isLastStep ? lastStepText : 'Continue'}
            </Button>
          </Center>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
