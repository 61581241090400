import { createIcon } from '@chakra-ui/react';

export const IconExternal: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconExternal',
  path: (
    <path
      d="M5.83333 3.5V4.66667H2.91667V11.0833H9.33333V8.16667H10.5V11.6667C10.5 11.8214 10.4385 11.9697 10.3291 12.0791C10.2197 12.1885 10.0714 12.25 9.91667 12.25H2.33333C2.17862 12.25 2.03025 12.1885 1.92085 12.0791C1.81146 11.9697 1.75 11.8214 1.75 11.6667V4.08333C1.75 3.92862 1.81146 3.78025 1.92085 3.67085C2.03025 3.56146 2.17862 3.5 2.33333 3.5H5.83333ZM12.25 1.75V6.41667H11.0833V3.74092L6.53742 8.28742L5.71258 7.46258L10.2579 2.91667H7.58333V1.75H12.25Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 14 14',
});
