import { createIcon } from '@chakra-ui/react';

export const IconRequired: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconRequired',
  path: (
    <>
      <path
        d="M20.224 10.4482H15.7462L18.9125 7.28187C19.2155 6.97891 19.2155 6.48762 18.9125 6.18466L17.8153 5.08745C17.5123 4.78448 17.021 4.78448 16.7181 5.08745L13.5517 8.25382V3.77584C13.5517 3.34731 13.2043 3 12.7758 3H11.2241C10.7956 3 10.4482 3.34737 10.4482 3.77584V8.25382L7.28187 5.08745C6.97891 4.78448 6.48762 4.78448 6.18466 5.08745L5.08745 6.18466C4.78448 6.48762 4.78448 6.97891 5.08745 7.28187L8.25382 10.4482H3.77584C3.34731 10.4482 3 10.7956 3 11.2241V12.7758C3 13.2044 3.34737 13.5517 3.77584 13.5517H8.25382L5.08745 16.7181C4.78448 17.021 4.78448 17.5123 5.08745 17.8153L6.18466 18.9125C6.48762 19.2155 6.97891 19.2155 7.28187 18.9125L10.4482 15.7461V20.2241C10.4482 20.6526 10.7956 20.9999 11.2241 20.9999H12.7758C13.2044 20.9999 13.5517 20.6526 13.5517 20.2241V15.7462L16.7181 18.9126C17.021 19.2156 17.5123 19.2156 17.8153 18.9126L18.9125 17.8154C19.2155 17.5124 19.2155 17.0211 18.9125 16.7182L15.7462 13.5518H20.224C20.6526 13.5518 20.9999 13.2044 20.9999 12.776V11.2242C20.9999 10.7956 20.6525 10.4482 20.224 10.4482Z"
        fill="currentColor"
      />
      <path
        d="M20.224 10.4483H15.7462L18.9125 7.28187C19.6031 6.59133 18.6974 5.96953 17.8153 5.08732C17.5123 4.78436 17.021 4.78436 16.7181 5.08732L13.5517 8.2537V3.77584C13.5517 3.34731 13.2043 3 12.7758 3H12.0107V21H12.7758C13.2044 21 13.5517 20.6526 13.5517 20.2242V15.7462L16.7181 18.9126C17.021 19.2155 17.5123 19.2155 17.8153 18.9126L18.9125 17.8153C19.2155 17.5124 19.2155 17.0211 18.9125 16.7181L15.7462 13.5517H20.224C21.2006 13.5517 20.9999 12.4716 20.9999 11.2242C20.9999 10.7956 20.6525 10.4483 20.224 10.4483Z"
        fill="currentColor"
      />
    </>
  ),
});
