import { createIcon } from '@chakra-ui/react';

export const IconWebex: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconWebex',
  path: (
    <>
      <path
        d="M0.999008 4.45326C1.00451 5.27426 1.21651 6.32176 1.71076 7.67176C2.53351 9.91951 3.77026 9.75851 4.09501 9.75801C4.36376 9.75726 5.27976 9.86226 6.04926 8.63301M0.999008 4.45326C0.980258 3.81776 1.40726 3.38001 1.94101 3.37201M0.999008 4.45326C0.980508 3.81776 1.40726 3.38001 1.94101 3.37201M1.94101 3.37201C2.47451 3.36401 2.78026 3.80951 2.91551 4.23876C3.28476 5.41126 3.25876 7.46176 4.10901 7.46701M4.10901 7.46701C4.59301 7.47001 4.85801 6.89926 5.12151 6.04451C4.85801 6.89926 4.59301 7.46976 4.10901 7.46701ZM7.05776 5.71501C7.26426 4.80976 7.63426 4.36576 7.95126 4.45101C8.75626 4.66701 8.43376 5.97826 9.73626 5.80301C11.3363 5.42376 9.98776 2.23476 7.85451 2.23951C7.17451 2.25201 6.55951 2.68201 6.16351 3.32576M2.17751 3.32376C3.05626 2.13276 3.95701 2.26376 4.10876 2.23951C4.23801 2.26126 5.32951 2.10201 6.16651 3.53776"
        fill="transparent"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.75"
      />
      <path
        d="M9.97199 3.40002C11.917 3.43277 10.5655 7.03077 10.2095 7.90752C9.64874 9.28777 8.81149 9.82027 7.77499 9.75777C5.14599 9.86852 5.48099 4.32377 4.10899 4.45077C3.64074 4.49427 3.43049 5.26902 3.29224 6.05877"
        fill="transparent"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.75"
      />
      <path
        d="M6.1665 3.53772C6.954 4.64022 7.2645 7.49972 7.96075 7.49347C8.37025 7.48972 8.64425 6.68472 8.87975 5.69022"
        fill="transparent"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.75"
      />
    </>
  ),
  viewBox: '0 0 12 12',
});
