import { useEffect } from 'react';
import { popConfetti } from './helpers';

export const Confetti = () => {
  useEffect(() => {
    popConfetti();
  }, []);

  return null;
};
