import { anatomy, PartsStyleFunction, MultiStyleConfig } from '@chakra-ui/theme-tools';
import { darken } from '../../../../utils';

const parts = anatomy('cardheader--flat').parts('container', 'column', 'details');

const baseStyle: PartsStyleFunction<typeof parts> = ({ colorMode, ...props }) => ({
  column: {
    '& .AvatarVideo__AudioControls': {
      right: '1.5rem',
      top: '1.5rem',
    },
    '&:not(.CardAvatar) > .CardImage': {
      height: 'full',
      maxH: '3rem',
      maxW: 'full',
      objectFit: 'contain',
      objectPosition: 'left',
      w: 'full',
    },
    '.CardAvatar': {
      aspectRatio: '1',
      borderRadius: 'full',
      display: 'inline',
      float: 'right',
      marginRight: '-3rem',
      maskImage: 'radial-gradient(white, black)',
      maxW: '55%',
      objectFit: 'cover',
      overflow: 'hidden',
      shapeMargin: '0.5rem',
      shapeOutside: 'content-box',
    },
    '.CardName--accreditations': {
      color: '#ffffff',
      display: 'inline-block',
      fontSize: '1rem',
      fontWeight: 400,
    },
    '.CardName--full, .CardName--maiden': {
      color: '#ffffff',
      display: 'inline',
      fontSize: '2.25rem',
      fontWeight: 800,
      lineHeight: '2.25rem',
      marginRight: '.5rem',
    },
    display: 'flex',
    flexFlow: 'column nowrap',
    gap: '2rem',
    position: 'relative',
  },
  container: {
    '&:before': {
      background: `linear-gradient(251.74deg,
      ${darken(props.palette?.[colorMode]?.brand ?? '#8f60de', 0.2)} -20.73%,
      ${props.palette?.[colorMode]?.brand ?? '#8f60de'} 127.58%)`,
      bottom: 0,
      clipPath: 'polygon(0 0, 100% 0, 100% calc(100% - 7rem), 0 100%)',
      content: '""',
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
    },
    marginBottom: '1.5rem',
    minH: '6rem',
    p: '2rem',
    paddingBottom: 0,
    position: 'relative',
  },
  details: {
    '&:empty': {
      paddingBottom: '25%',
    },
    '.CardTitle, .CardDepartment, .CardCompany': {
      '&:last-child:not(.CardAvatar)': {
        paddingBottom: '25%',
      },
      color: '#ffffff',
      display: 'block',
      fontSize: '1.125rem',
      fontStyle: 'italic',
      fontWeight: 400,
      lineHeight: '1.625rem',
    },
    flex: 1,
  },
});

export const CardHeaderModern: MultiStyleConfig<typeof parts> = {
  baseStyle,
  defaultProps: {
    colorScheme: 'brand',
  },
  parts: ['container', 'column', 'details'],
};
