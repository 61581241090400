import { createIcon } from '@chakra-ui/react';

export const DesignIconRound = createIcon({
  displayName: 'DesignIconModern',
  path: (
    <>
      <g clipPath="url(#a)">
        <rect fill="#fff" height="72" rx="16" width="72" />
        <circle cx="36" cy="19.125" fill="currentColor" r="30.375" />
      </g>
      <defs>
        <clipPath id="a">
          <rect fill="#fff" height="72" rx="16" width="72" />
        </clipPath>
      </defs>
    </>
  ),
  viewBox: '0 0 72 72',
});
