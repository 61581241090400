import { createIcon } from '@chakra-ui/react';

export const IconPeople: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconPeople',
  path: (
    <>
      <path
        d="M12.6949 9.93071C12.7461 9.68297 12.7724 9.42616 12.7712 9.16302C12.7707 9.06268 12.7662 8.96329 12.7579 8.86502C12.6468 7.55702 11.8525 6.44562 10.7347 5.88659C10.4199 5.72914 10.0794 5.61551 9.72125 5.55364C9.51052 5.51724 9.29368 5.49875 9.07237 5.49981C8.91518 5.50057 8.76033 5.51117 8.60843 5.53104C8.15237 5.59068 7.72274 5.73382 7.33525 5.94455C6.18313 6.57114 5.40361 7.79538 5.41022 9.19972C5.42079 11.2335 7.07634 12.8727 9.10904 12.8629C9.1651 12.8626 9.22085 12.8611 9.27629 12.8583C9.46579 12.8489 9.65149 12.8251 9.83233 12.7881C9.96767 12.7479 10.1046 12.7115 10.243 12.6788C10.6689 12.5385 11.0604 12.3226 11.4015 12.047C11.7503 11.7652 12.0465 11.421 12.2733 11.0315L12.2633 11.0328L12.2747 11.0291C12.47 10.6931 12.6137 10.3233 12.6949 9.93071Z"
        fill="currentColor"
      />
      <path
        d="M13.5467 10.6177C13.7452 10.5097 13.9327 10.384 14.1069 10.2427C14.4613 9.95539 14.7611 9.60357 14.9887 9.2052C15.2987 8.66244 15.4746 8.03327 15.4714 7.36302C15.4616 5.32967 13.8057 3.69006 11.7726 3.69981C11.5792 3.70074 11.3893 3.71658 11.2041 3.74624C10.7425 3.82015 10.3098 3.97989 9.9226 4.20853C9.76733 4.30022 9.6194 4.40299 9.47988 4.51575C9.59826 4.5255 9.7154 4.53966 9.83114 4.55807C10.1664 4.61139 10.4899 4.70033 10.7976 4.82091C12.5307 5.50011 13.7617 7.18308 13.7712 9.15823L13.7712 9.19799C13.77 9.53966 13.7322 9.87278 13.6614 10.1936C13.6297 10.3376 13.5913 10.4791 13.5467 10.6177Z"
        fill="#currentColor"
      />
      <path
        d="M12.0855 2.7089C14.5233 2.86048 16.4595 4.87922 16.4714 7.35823C16.4737 7.83983 16.4032 8.30478 16.2704 8.74266C17.3602 8.09898 18.0887 6.90972 18.0822 5.55192C18.081 5.29776 18.0541 5.04974 18.0039 4.81033C17.6526 3.13444 16.1624 1.88019 14.3834 1.88872C13.5123 1.89289 12.7133 2.1996 12.0855 2.7089Z"
        fill="currentColor"
      />
      <path
        d="M12.4024 12.4894C12.5738 12.3177 12.7318 12.1329 12.875 11.9365C13.0621 11.8921 13.2448 11.8365 13.4224 11.7704C13.7908 11.704 14.1701 11.6684 14.5574 11.6666C18.1352 11.6493 21.0518 14.5131 21.0691 17.9907C21.0696 18.0867 21.0567 18.1447 20.9125 18.2452C20.8625 18.2801 20.8013 18.3154 20.7279 18.3504C20.5078 18.4555 20.1775 18.5584 19.7062 18.6417C19.6926 18.6441 19.679 18.6465 19.6653 18.6489C19.5693 18.6653 19.4702 18.6806 19.3683 18.6948C18.829 15.3164 15.9305 12.6856 12.4024 12.4894Z"
        fill="currentColor"
      />
      <path
        d="M18.3711 18.7953C17.8606 15.7886 15.1724 13.4621 11.9466 13.4777C11.4539 13.4801 10.9743 13.537 10.5136 13.6425C10.3706 13.6881 10.2247 13.727 10.0761 13.7588C9.94021 13.8001 9.80619 13.8457 9.67425 13.8954C9.65569 13.9023 9.63718 13.9094 9.6187 13.9166C9.34161 14.0238 9.07382 14.1493 8.81687 14.2916C8.95745 14.283 9.09906 14.2784 9.24159 14.2777C9.28271 14.2775 9.32375 14.2776 9.36472 14.2781C12.4484 14.3128 15.1024 16.2078 16.2085 18.8724C16.3418 19.1935 16.4527 19.5259 16.539 19.8675C16.5929 20.0807 16.6373 20.2975 16.6717 20.5175C16.8188 20.4982 16.9601 20.4767 17.0954 20.4528C17.5667 20.3695 17.8969 20.2666 18.1171 20.1615C18.1905 20.1265 18.2516 20.0912 18.3017 20.0563C18.4459 19.9558 18.4588 19.8978 18.4583 19.8018C18.4583 19.7983 18.4582 19.7948 18.4582 19.7913C18.456 19.4528 18.4262 19.1201 18.3711 18.7953Z"
        fill="currentColor"
      />
      <path
        d="M15.6738 20.6123C15.6322 20.3632 15.5757 20.1187 15.5051 19.8798C15.4032 19.5347 15.2721 19.2012 15.1145 18.8822C14.0609 16.7498 11.8254 15.2652 9.24641 15.2777C9.07631 15.2785 8.90776 15.2858 8.74101 15.2994C8.33733 15.3323 7.94418 15.4018 7.56495 15.5045C7.41444 15.5453 7.26612 15.5913 7.12021 15.6423C7.07682 15.6575 7.03364 15.6731 6.99067 15.6891C6.34625 15.9298 5.75091 16.2688 5.2234 16.6868C3.73755 17.8641 2.78993 19.6678 2.79988 21.6646C2.80036 21.7606 2.81385 21.8185 2.95909 21.9176C3.1607 22.0553 3.53992 22.1974 4.16943 22.3024C5.42232 22.5113 7.19161 22.5061 9.28383 22.496C11.3756 22.486 13.1446 22.4739 14.3952 22.2528C15.0235 22.1417 15.4012 21.9959 15.6014 21.8563C15.7422 21.7582 15.7579 21.7006 15.7581 21.6086C15.7581 21.6064 15.7581 21.6042 15.7581 21.602C15.7564 21.2658 15.7276 20.935 15.6738 20.6123Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: '0 0 24 24',
});
