import { anatomy, PartsStyleFunction, MultiStyleConfig } from '@chakra-ui/theme-tools';

const parts = anatomy('cardheader--flat').parts('container', 'media');

const baseStyle: PartsStyleFunction<typeof parts> = ({ colorMode, ...props }) => ({
  container: {
    '.CardAvatar': {
      h: 'full',
      maxW: 'full',
      objectFit: 'cover',
      position: 'absolute',
      top: 0,
      w: 'full',
    },
    '.CardIdentity': {
      '.CardBox, .CardBox > *': {
        lineHeight: '1.75rem',
      },
      '.CardImage': {
        h: '3.75rem',
        marginBottom: '0.5rem',
        maxW: 'full',
        objectFit: 'contain',
      },
      '.chakra-skeleton': {
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      m: '1.5rem auto',
      textAlign: 'center',
      w: '80%',
    },
  },
  media: {
    '&:empty': {
      marginTop: '-65%',
    },
    bg: props.palette?.[colorMode]?.brand ?? '#8f60de',
    borderRadius: 'full',
    m: '-1rem auto 0',
    maskImage: 'radial-gradient(white, black)',
    overflow: 'hidden',
    paddingTop: '90%',
    position: 'relative',
    w: '90%',
  },
});

export const CardHeaderRound: MultiStyleConfig<typeof parts> = {
  baseStyle,
  defaultProps: {
    colorScheme: 'brand',
  },
  parts: ['container', 'media'],
};
