import { createIcon } from '@chakra-ui/react';

export const IconExclamation: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconExclamation',
  path: (
    <path
      d="M8.98818 0.668852C6.76176 0.705301 4.63913 1.61646 3.07912 3.20537C2.29857 3.98249 1.68271 4.90901 1.26836 5.92955C0.854017 6.95008 0.649686 8.04368 0.667586 9.14498C0.666305 10.2211 0.877398 11.2869 1.28877 12.2813C1.70014 13.2757 2.30369 14.1791 3.06485 14.9398C3.82601 15.7005 4.7298 16.3035 5.72444 16.7143C6.71907 17.1251 7.78499 17.3356 8.86111 17.3336H9.0097C11.2368 17.3107 13.3638 16.4053 14.9241 14.816C16.4844 13.2266 17.3505 11.0833 17.3324 8.85613V8.85613C17.3349 7.76695 17.1197 6.68826 16.6993 5.68349C16.2789 4.67872 15.6618 3.76815 14.8844 3.00533C14.107 2.24251 13.1848 1.64284 12.1723 1.24159C11.1597 0.840346 10.0771 0.645614 8.98818 0.668852V0.668852ZM7.95843 12.1551C7.95343 12.0189 7.97562 11.8831 8.0237 11.7557C8.07179 11.6282 8.14481 11.5116 8.23849 11.4127C8.33217 11.3137 8.44464 11.2345 8.56931 11.1796C8.69398 11.1246 8.82835 11.0951 8.96457 11.0927H8.98332C9.25732 11.0932 9.52048 11.1998 9.71763 11.3901C9.91478 11.5804 10.0306 11.8396 10.0408 12.1134C10.0459 12.2496 10.0238 12.3854 9.97576 12.5129C9.92771 12.6404 9.85469 12.7571 9.76099 12.856C9.66729 12.9549 9.55479 13.0342 9.43008 13.0891C9.30536 13.144 9.17095 13.1735 9.0347 13.1758H9.01595C8.74205 13.1749 8.47909 13.0682 8.28201 12.878C8.08494 12.6878 7.969 12.4288 7.95843 12.1551V12.1551ZM8.30562 9.34843V5.18223C8.30562 4.99808 8.37877 4.82146 8.50899 4.69124C8.63921 4.56102 8.81582 4.48787 8.99998 4.48787C9.18414 4.48787 9.36075 4.56102 9.49097 4.69124C9.62119 4.82146 9.69435 4.99808 9.69435 5.18223V9.34843C9.69435 9.53259 9.62119 9.7092 9.49097 9.83942C9.36075 9.96964 9.18414 10.0428 8.99998 10.0428C8.81582 10.0428 8.63921 9.96964 8.50899 9.83942C8.37877 9.7092 8.30562 9.53259 8.30562 9.34843Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 18 18',
});
