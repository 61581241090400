import { createIcon } from '@chakra-ui/react';

export const IconBackground: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconBackground',
  path: (
    <path
      clipRule="evenodd"
      d="M1.6665 3.33366C1.6665 2.41318 2.4127 1.66699 3.33317 1.66699H16.6665C17.587 1.66699 18.3332 2.41318 18.3332 3.33366V16.667C18.3332 17.5875 17.587 18.3337 16.6665 18.3337H3.33317C2.4127 18.3337 1.6665 17.5875 1.6665 16.667V3.33366ZM4.1665 13.3337V15.8337H8.74984L11.6665 12.5003L8.59449 9.08698C8.24336 8.69684 7.62334 8.72454 7.30841 9.14445L4.1665 13.3337ZM10.4165 15.8337H15.8332V13.7503L14.7798 12.4337C14.4586 12.0321 13.8538 12.0145 13.5097 12.3968L10.4165 15.8337Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
  viewBox: '0 0 20 20',
});
