import { createIcon } from '@chakra-ui/react';

export const IconSync: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconSync',
  path: (
    <path
      d="M8.62311 1.98568L9.87973 0.767717L6.5803 0.716178L6.52876 4.01561L7.78537 2.79765C9.12918 4.18411 9.09452 6.40298 7.70806 7.74679C7.285 8.15684 6.78139 8.44187 6.25103 8.59034L6.23222 9.79464C7.09443 9.61717 7.8881 9.19737 8.52003 8.58453C10.3714 6.79008 10.4176 3.83709 8.62311 1.98568ZM2.75892 7.66948C1.4151 6.28302 1.44976 4.06415 2.83623 2.72034C3.25929 2.31029 3.7629 2.02526 4.29326 1.87679L4.31207 0.672495C3.44986 0.849963 2.65618 1.26976 2.02425 1.8826C0.172843 3.67706 0.126715 6.63004 1.92117 8.48145L0.66456 9.69941L3.96399 9.75095L4.01553 6.45152L2.75892 7.66948Z"
      fill="currentColor"
    />
  ),

  viewBox: '0 0 10 10',
});
