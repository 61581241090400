import { createIcon } from '@chakra-ui/react';

export const IconPinterest: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconPinterest',
  path: (
    <path
      d="M13 0C5.85 0 0 5.85 0 13C0 18.525 3.46667 23.1833 8.23333 25.1333C8.125 24.1583 8.01667 22.5333 8.23333 21.45C8.45 20.475 9.75 14.95 9.75 14.95C9.75 14.95 9.425 14.0833 9.425 13C9.425 11.1583 10.5083 9.85833 11.8083 9.85833C12.8917 9.85833 13.4333 10.725 13.4333 11.7C13.4333 12.7833 12.675 14.5167 12.35 16.0333C12.025 17.3333 13 18.4167 14.3 18.4167C16.575 18.4167 18.4167 16.0333 18.4167 12.4583C18.4167 9.31667 16.1417 7.15 13 7.15C9.31667 7.15 7.15 9.96667 7.15 12.7833C7.15 13.8667 7.58333 15.0583 8.125 15.7083C8.23333 15.8167 8.23333 15.925 8.23333 16.0333L7.90833 17.55C7.8 17.7667 7.69167 17.875 7.475 17.7667C5.85 17.0083 4.875 14.625 4.875 12.7833C4.875 8.66667 7.90833 4.875 13.4333 4.875C17.9833 4.875 21.45 8.125 21.45 12.35C21.45 16.7917 18.6333 20.475 14.7333 20.475C13.4333 20.475 12.1333 19.825 11.7 18.9583L10.9417 22.1C10.6167 23.1833 9.85833 24.7 9.31667 25.4583C10.4 25.7833 11.7 26 13 26C20.15 26 26 20.15 26 13C26 5.85 20.15 0 13 0Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 26 26',
});
