import {
  Box,
  BoxProps,
  Icon as ChakraIcon,
  IconButton,
  SkeletonCircle,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { Fields } from '@hihello/core';
import { FunctionComponent } from 'react';
import { useStore } from 'zustand';
import { useCardStore } from '../../../../store';
import { useFieldActions, useFieldIcon, useFieldText } from '../../hooks';

export type SummaryItemProps = BoxProps & {
  field?: Fields;
  icon?: JSX.Element;
};

export const SummaryItem: FunctionComponent<SummaryItemProps> = ({ field, icon, ...props }) => {
  const cardStore = useCardStore();
  const palette = useStore(cardStore, (state) => state.themePalette);
  const styles = useMultiStyleConfig('FieldListSummary', { palette });
  const actions = useFieldActions(field);
  const Icon = useFieldIcon(field);
  const text = useFieldText(field);

  return (
    <Box __css={styles.listItem} as="li" {...props}>
      <Box __css={styles.action}>
        {field ? (
          <IconButton
            {...actions}
            aria-label={text?.name ?? ''}
            icon={<ChakraIcon as={Icon} />}
            tabIndex={0}
          />
        ) : (
          <SkeletonCircle height="100%" width="100%" />
        )}
      </Box>
    </Box>
  );
};
