import { UserSession } from '@hihello/core';
import React, { createContext, PropsWithChildren, useMemo, useState, useContext } from 'react';

export type AuthContextProps = {
  signIn: (user: UserSession) => void;
  signOut: () => void;
  user: UserSession | null;
};

export type AuthProviderState = {
  initialUser?: UserSession | null;
};

export type AuthProviderProps = PropsWithChildren<AuthProviderState>;

const AuthContext = createContext<AuthContextProps>({
  signIn: () => null,
  signOut: () => null,
  user: null,
});

export const AuthProvider = ({ children, initialUser = null }: AuthProviderProps) => {
  const [user, setUser] = useState<UserSession | null>(initialUser);

  const signIn = (newUser: UserSession) => {
    setUser(newUser);
  };

  const signOut = () => {
    setUser(null);
  };

  const valueMemo = useMemo(() => ({ signIn, signOut, user }), [user]);

  return <AuthContext.Provider value={valueMemo}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthContext.Provider');
  }
  return context;
};
