import { createIcon } from '@chakra-ui/react';

export const IconXboxLive: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconXboxLive',
  path: (
    <path
      d="M11.7017 25.9425C9.69796 25.7541 7.67326 25.0353 5.92782 23.8979C4.46864 22.9489 4.1405 22.5581 4.1405 21.7766C4.1405 20.2066 5.86499 17.4642 8.81826 14.3311C10.4939 12.5517 12.8328 10.4653 13.0841 10.5211C13.5728 10.6327 17.5035 14.4567 18.9697 16.257C21.2946 19.104 22.3628 21.4347 21.8183 22.4744C21.4063 23.2629 18.844 24.812 16.966 25.4052C15.416 25.9006 13.3773 26.103 11.7017 25.9425ZM2.17165 20.1438C0.956823 18.2876 0.349411 16.4524 0.0491965 13.8077C-0.0485479 12.9355 -0.0136392 12.4331 0.272612 10.6397C0.628681 8.39979 1.89936 5.81793 3.43534 4.21997C4.08464 3.54311 4.14748 3.52217 4.9434 3.79432C5.90688 4.12228 6.94018 4.83404 8.53899 6.27848L9.47455 7.12282L8.96488 7.75084C6.59109 10.6467 4.09162 14.7637 3.14909 17.3107C2.63942 18.6923 2.42997 20.0879 2.65339 20.6601C2.8 21.0509 2.66735 20.9113 2.17165 20.1438ZM23.459 20.4647C23.5777 19.8786 23.4241 18.804 23.075 17.7224C22.307 15.3778 19.7377 11.0165 17.3709 8.05089L16.6308 7.11584L17.4337 6.37617C18.481 5.41321 19.2141 4.83404 19.996 4.34558C20.6174 3.96179 21.5041 3.61987 21.8881 3.61987C22.1255 3.61987 22.9493 4.47816 23.6196 5.41321C24.6528 6.86463 25.4139 8.62309 25.7979 10.4513C26.0492 11.6306 26.0701 14.1636 25.8397 15.3429C25.6512 16.3128 25.2533 17.5689 24.8623 18.4202C24.5691 19.0552 23.85 20.2973 23.5288 20.702C23.3682 20.9113 23.3682 20.9113 23.459 20.4647ZM11.9182 3.17328C10.829 2.62201 9.14641 2.02888 8.21783 1.86839C7.88969 1.81257 7.33813 1.78465 6.98207 1.79861C6.21407 1.84048 6.24898 1.79861 7.47777 1.21944C8.4971 0.737956 9.34888 0.451859 10.5078 0.207629C11.8064 -0.0645129 14.257 -0.0714909 15.5347 0.200651C16.9171 0.493726 18.5438 1.10081 19.4654 1.65905L19.7377 1.82652L19.1093 1.79163C17.8666 1.72883 16.0513 2.23125 14.1104 3.18025C13.524 3.46635 13.0143 3.69662 12.9724 3.68965C12.9445 3.68267 12.4627 3.45239 11.9182 3.17328Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 26 26',
});
