import { useCallback, useState } from 'react';

type FetchFn = (...args: any[]) => any;

type FetchFnOptions<Fetcher extends FetchFn> = {
  enabled?: boolean;
  onError?: (error: Error) => void;
  onSuccess?: (result: Awaited<ReturnType<Fetcher>>) => void;
};

export const useFetch = <Fetcher extends FetchFn, Options extends FetchFnOptions<Fetcher>>(
  fn: Fetcher,
  options: Options,
): [FetchFn, { isLoading: boolean }] => {
  const [state, setState] = useState({
    enabled: options.enabled ?? true,
    isLoading: false,
  });

  const fetcher = useCallback(
    async (...args: Parameters<Fetcher>) => {
      if (!state.enabled) {
        return;
      }
      try {
        setState((currentState) => ({
          ...currentState,
          isLoading: true,
        }));

        const result = await fn(...args);
        options.onSuccess?.(result);
      } catch (error) {
        options.onError?.(error as any);
      } finally {
        setState((currentState) => ({
          ...currentState,
          isLoading: false,
        }));
      }
    },
    [options, fn, state.enabled],
  );

  return [fetcher, state];
};
