import { createIcon } from '@chakra-ui/react';

export const IconGrid: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconGrid',
  path: (
    <path
      d="M20.6555 3C20.8942 3 21.1232 3.10536 21.2919 3.29289C21.4607 3.48043 21.5555 3.73478 21.5555 4V20C21.5555 20.2652 21.4607 20.5196 21.2919 20.7071C21.1232 20.8946 20.8942 21 20.6555 21H4.45554C4.21685 21 3.98793 20.8946 3.81915 20.7071C3.65036 20.5196 3.55554 20.2652 3.55554 20V4C3.55554 3.73478 3.65036 3.48043 3.81915 3.29289C3.98793 3.10536 4.21685 3 4.45554 3H20.6555ZM11.6555 13H5.35554V19H11.6555V13ZM19.7555 13H13.4555V19H19.7555V13ZM11.6555 5H5.35554V11H11.6555V5ZM19.7555 5H13.4555V11H19.7555V5Z"
      fill="currentColor"
    />
  ),
});
