import { IconButton, IconButtonProps, Tooltip } from '@chakra-ui/react';

export type ActionIconButtonProps = IconButtonProps & {
  tooltipLabel: string;
};

export const ActionIconButton = ({ tooltipLabel, ...props }: ActionIconButtonProps) => (
  <Tooltip borderRadius="md" fontSize="sm" hasArrow label={tooltipLabel} placement="top-start">
    <IconButton {...props} bgColor="bg-surface" border="1px solid" borderColor="gray.200" />
  </Tooltip>
);
