import { anatomy, PartsStyleFunction, MultiStyleConfig } from '@chakra-ui/theme-tools';

const parts = anatomy('cardheader--flat').parts('container', 'avatar', 'logo', 'divider');

const baseStyle: PartsStyleFunction<typeof parts> = ({ colorMode, ...props }) => ({
  avatar: {
    '.CardAvatar': {
      aspectRatio: '1',
    },
    bg: props.palette?.[colorMode]?.brand ?? '#8f60de',
    color: props.palette?.[colorMode]?.onBrand ?? '#ffffff',
    minH: '5rem',
    position: 'relative',
    transitionDuration: '300ms',
    transitionProperty: 'background-color',
    willChange: 'background-color',
  },
  container: {},
  divider: {
    bg: props.palette?.[colorMode]?.brand ?? '#8f60de',
    bottom: 0,
    h: '1rem',
    position: 'absolute',
    width: 'full',
  },
  logo: {
    img: {
      h: '4rem',
      maxW: 'full',
      objectFit: 'contain',
      w: 'auto',
    },
  },
});

export const CardHeaderFlat: MultiStyleConfig<typeof parts> = {
  baseStyle,
  defaultProps: {
    colorScheme: 'brand',
  },
  parts: ['avatar', 'container', 'logo', 'divider'],
};
