import { Badge as ChakraBadge, BadgeProps as ChakraBadgeProps } from '@chakra-ui/react';
import { FunctionComponent } from 'react';

type BadgeProps = ChakraBadgeProps & {
  isAllCaps?: boolean;
};

export const Badge: FunctionComponent<BadgeProps> = ({
  isAllCaps,
  variant = 'subtle',
  ...props
}) => (
  <ChakraBadge
    borderRadius="sm"
    fontSize="xs"
    fontWeight="bold"
    px={1}
    py={0}
    textTransform={isAllCaps ? 'uppercase' : 'none'}
    variant={variant}
    {...props}
  />
);
