import { RedirectKeys, useApprovedToRedirect, useAuthenticatedUser } from '@src/hooks';
import { clientRedirectToInternalUrl } from '@src/lib/utils';
import { ParsedUrlQuery } from 'querystring';
import { useEffect } from 'react';

export function useRedirectOnAuthentication({
  to = { next: process.env.NEXT_PUBLIC_APP_URL },
}: {
  to?: ParsedUrlQuery;
}) {
  const approvedToRedirect = useApprovedToRedirect((state) => [
    state[RedirectKeys.Token],
    state[RedirectKeys.Popup],
  ]);

  const allApprovedToRedirect = approvedToRedirect.every(Boolean);

  const { isInitialized, user } = useAuthenticatedUser();

  useEffect(() => {
    if (!isInitialized || !user || !allApprovedToRedirect) {
      return;
    }

    clientRedirectToInternalUrl(to, [window.location.pathname]);
  }, [allApprovedToRedirect, isInitialized, to, user]);

  return {
    isRedirecting: !!(isInitialized && user && allApprovedToRedirect),
  };
}
