import {
  ChakraProvider,
  ChakraProviderProps,
  cookieStorageManagerSSR,
  localStorageManager,
} from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { theme as defaultTheme } from '../../theme';

export type ThemeProviderProps = ChakraProviderProps;

export const ThemeProvider: FunctionComponent<ThemeProviderProps & { cookies?: string }> = ({
  cookies = '',
  theme = defaultTheme,
  ...props
}) => {
  const manager =
    typeof cookies === 'string' ? cookieStorageManagerSSR(cookies) : localStorageManager;

  return <ChakraProvider colorModeManager={manager} theme={theme} {...props} />;
};
