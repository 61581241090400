import { IconProps } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { IconCheck, IconMinus } from '../Icons';

export type CheckboxIconProps = IconProps & {
  isChecked?: boolean;
  isIndeterminate?: boolean;
};

export const CheckboxIcon: FunctionComponent<CheckboxIconProps> = ({
  isChecked,
  isIndeterminate,
  ...rest
}) => {
  const BaseIcon = isIndeterminate ? IconMinus : IconCheck;

  return isChecked || isIndeterminate ? <BaseIcon {...rest} /> : null;
};
