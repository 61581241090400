import { createIcon } from '@chakra-ui/react';

export const DesignIconModern = createIcon({
  displayName: 'DesignIconModern',
  path: (
    <>
      <g clipPath="url(#clip0_805_62524)">
        <rect fill="white" height="72" rx="16" width="72" />
        <g clipPath="url(#clip1_805_62524)">
          <path d="M0 -16.875H72V30.3333L0 55.637V-16.875Z" fill="url(#paint0_linear_805_62524)" />
          <circle cx="53" cy="27.125" fill="#EDF2F7" r="14" />
        </g>
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_805_62524"
          x1="36"
          x2="36"
          y1="-16.875"
          y2="55.637"
        >
          <stop offset="0" stopColor="currentColor" />
          <stop offset="0.75" stopColor="currentColor" stopOpacity="0.75" />
        </linearGradient>
        <clipPath id="clip0_805_62524">
          <rect fill="white" height="72" rx="16" width="72" />
        </clipPath>
        <clipPath id="clip1_805_62524">
          <rect fill="white" height="72.576" transform="translate(0 -16.875)" width="72" />
        </clipPath>
      </defs>
    </>
  ),
  viewBox: '0 0 72 72',
});
