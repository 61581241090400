import { getAmplitudeInstance, LogCallback } from '@hihello/core';
import { useAuthenticatedUser } from '@src/hooks';
import { useCallback, useEffect, useRef } from 'react';
import { AnalyticsLog } from './events';

export function useAnalytics() {
  const { user } = useAuthenticatedUser();

  const analytics = useRef(getAmplitudeInstance());
  const isLoggedIn = useRef(false);

  const logEvent = useCallback(
    ({ event, payload }: AnalyticsLog, callback?: LogCallback, errorCallback?: LogCallback) => {
      analytics.current?.logEvent(event, payload, callback, errorCallback);
    },
    [],
  );

  useEffect(() => {
    if (!user) {
      if (isLoggedIn.current) {
        isLoggedIn.current = false;
        analytics.current?.clearUserProperties();
      }

      return;
    }

    isLoggedIn.current = true;
    analytics.current?.setUserId(user.uid);
  }, [user]);

  return {
    logEvent,
  };
}
