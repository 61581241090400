import { useRouter } from 'next/router';
import { useCallback } from 'react';

const snakeToCamel = (str) =>
  str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

export const useNextParam = () => {
  const router = useRouter();

  const getNextParam = useCallback(() => {
    const nextParams = new URLSearchParams();
    Object.entries(router.query).forEach(([key, value]) => {
      nextParams.append(snakeToCamel(key), `${value}`);
    });

    return encodeURIComponent(nextParams.toString());
  }, [router.query]);
  return getNextParam;
};
