import { FieldType, Fields } from '@hihello/core';
import { useMemo } from 'react';
import {
  IconAddress,
  IconAppleMusic,
  IconBehance,
  IconCashApp,
  IconCalendar,
  IconCalendly,
  IconDiscord,
  IconDribbble,
  IconEmail,
  IconFacebook,
  IconFile,
  IconGitHub,
  IconGoogleMeet,
  IconInstagram,
  IconLine,
  IconLink,
  IconLinkedIn,
  IconMicrosoftBookings,
  IconMicrosoftTeams,
  IconNintendoSwitch,
  IconNote,
  IconPatreon,
  IconPayPal,
  IconPhone,
  IconPinterest,
  IconPlaystationNetwork,
  IconSignal,
  IconSkype,
  IconSMS,
  IconSnapchat,
  IconSoundCloud,
  IconSpotify,
  IconTelegram,
  IconTikTok,
  IconTwitch,
  IconTwitterX,
  IconVenmo,
  IconVimeo,
  IconWebex,
  IconWebsite,
  IconWeChat,
  IconWhatsApp,
  IconXboxLive,
  IconXing,
  IconYelp,
  IconYouTube,
  IconZelle,
  IconZoom,
  IconPlayCircle,
} from '../../../../Icons';

type IconType = typeof IconAddress;

export const fieldTypeToIcon: Partial<Record<FieldType, IconType | undefined>> = {
  [FieldType.Address]: IconAddress,
  [FieldType.Applemusic]: IconAppleMusic,
  [FieldType.Behance]: IconBehance,
  [FieldType.Brightcove]: IconPlayCircle,
  [FieldType.Cashapp]: IconCashApp,
  [FieldType.Calendly]: IconCalendly,
  [FieldType.Discord]: IconDiscord,
  [FieldType.Dribbble]: IconDribbble,
  [FieldType.Email]: IconEmail,
  [FieldType.Facebook]: IconFacebook,
  [FieldType.GoogleMeet]: IconGoogleMeet,
  [FieldType.File]: IconFile,
  [FieldType.Github]: IconGitHub,
  [FieldType.ImportantDate]: IconCalendar,
  [FieldType.Instagram]: IconInstagram,
  [FieldType.Line]: IconLine,
  [FieldType.Link]: IconLink,
  [FieldType.Linkedin]: IconLinkedIn,
  [FieldType.Nintendo]: IconNintendoSwitch,
  [FieldType.Note]: IconNote,
  [FieldType.MicrosoftBookings]: IconMicrosoftBookings,
  [FieldType.MicrosoftTeams]: IconMicrosoftTeams,
  [FieldType.Patreon]: IconPatreon,
  [FieldType.Paypal]: IconPayPal,
  [FieldType.Phone]: IconPhone,
  [FieldType.Pinterest]: IconPinterest,
  [FieldType.Playstation]: IconPlaystationNetwork,
  [FieldType.Signal]: IconSignal,
  [FieldType.Skype]: IconSkype,
  [FieldType.Snapchat]: IconSnapchat,
  [FieldType.Soundcloud]: IconSoundCloud,
  [FieldType.Spotify]: IconSpotify,
  [FieldType.Telegram]: IconTelegram,
  [FieldType.Tiktok]: IconTikTok,
  [FieldType.Twitch]: IconTwitch,
  [FieldType.Twitter]: IconTwitterX,
  [FieldType.Venmo]: IconVenmo,
  [FieldType.Vimeo]: IconVimeo,
  [FieldType.Webex]: IconWebex,
  [FieldType.Website]: IconWebsite,
  [FieldType.Wechat]: IconWeChat,
  [FieldType.Whatsapp]: IconWhatsApp,
  [FieldType.Xbox]: IconXboxLive,
  [FieldType.Xing]: IconXing,
  [FieldType.Yelp]: IconYelp,
  [FieldType.Youtube]: IconYouTube,
  [FieldType.Zelle]: IconZelle,
  [FieldType.Zoom]: IconZoom,
};

const phoneFieldTypeToIcon: Record<string, IconType> = {
  cell: IconSMS,
  direct: IconPhone,
  home: IconPhone,
  main: IconPhone,
  office: IconPhone,
  work: IconPhone,
  'work,fax': IconPhone,
};

export function useFieldIcon(field?: Fields): IconType | undefined {
  return useMemo(() => {
    if (!field) {
      return undefined;
    }

    if (field?.type === FieldType.Phone) {
      return phoneFieldTypeToIcon[field.label || ''] ?? fieldTypeToIcon[field.type];
    }

    return fieldTypeToIcon[field.type];
  }, [field]);
}
