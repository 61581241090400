import { createIcon } from '@chakra-ui/react';

export const IconPerson: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconPerson',
  path: (
    <path
      d="M10 11H2V10C2 9.33696 2.26339 8.70107 2.73223 8.23223C3.20107 7.76339 3.83696 7.5 4.5 7.5H7.5C8.16304 7.5 8.79893 7.76339 9.26777 8.23223C9.73661 8.70107 10 9.33696 10 10V11ZM6 6.5C5.60603 6.5 5.21593 6.4224 4.85195 6.27164C4.48797 6.12087 4.15726 5.8999 3.87868 5.62132C3.6001 5.34274 3.37913 5.01203 3.22836 4.64805C3.0776 4.28407 3 3.89397 3 3.5C3 3.10603 3.0776 2.71593 3.22836 2.35195C3.37913 1.98797 3.6001 1.65726 3.87868 1.37868C4.15726 1.1001 4.48797 0.879125 4.85195 0.728361C5.21593 0.577597 5.60603 0.5 6 0.5C6.79565 0.5 7.55871 0.81607 8.12132 1.37868C8.68393 1.94129 9 2.70435 9 3.5C9 4.29565 8.68393 5.05871 8.12132 5.62132C7.55871 6.18393 6.79565 6.5 6 6.5Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 12 12',
});
