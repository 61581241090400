import { createIcon } from '@chakra-ui/react';

export const IconYelp: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconYelp',
  path: (
    <>
      <path
        d="M4.32006 15.7941C4.48106 16.1008 4.76623 16.29 5.08003 16.3009C5.09743 16.302 5.1133 16.3027 5.13015 16.3027C5.30182 16.3027 5.56625 16.2212 8.42076 15.3042C9.40942 14.9885 9.67643 14.9032 9.75594 14.8767C10.1685 14.7712 10.4532 14.395 10.4815 13.9174C10.5077 13.4305 10.2453 12.9985 9.83354 12.8444C9.19923 12.586 8.97578 12.4947 8.8862 12.4629L8.88751 12.4599C5.4864 11.0567 5.46994 11.0563 5.27413 11.0541C4.95679 11.0395 4.66713 11.2044 4.47789 11.5025C4.12053 12.077 3.98328 13.7615 4.09941 14.8853C4.14543 15.2974 4.21964 15.603 4.32006 15.7941Z"
        fill="currentColor"
      />
      <path
        d="M11.467 15.7477C11.0128 15.5723 10.5223 15.6879 10.2487 16.0305C10.2487 16.0305 9.80367 16.5589 9.64474 16.7467L9.58751 16.814C7.20702 19.6077 7.1986 19.632 7.13446 19.8175C7.08882 19.9413 7.07295 20.0685 7.08696 20.1919C7.10381 20.3711 7.18213 20.5494 7.31434 20.7088C7.84206 21.3356 10.1268 22.2051 11.1418 22.2051C11.2187 22.2051 11.2884 22.2003 11.3494 22.1898C11.6987 22.1262 11.9532 21.9262 12.0678 21.6202C12.131 21.4355 12.1361 21.4205 12.1454 18.1361C12.1454 18.1361 12.1527 16.7748 12.1533 16.7336C12.1804 16.3057 11.911 15.9186 11.467 15.7477Z"
        fill="currentColor"
      />
      <path
        d="M11.6847 2.44318L11.6807 2.42523C11.593 2.10547 11.3415 1.87174 10.9903 1.78419C10.0544 1.55155 6.63421 2.51346 5.9739 3.19489C5.73382 3.44471 5.63636 3.76972 5.72649 4.10032C5.8256 4.30417 9.54816 10.162 9.97097 10.8274C10.3468 11.4363 10.7517 11.7426 11.1775 11.7426C11.2723 11.7426 11.368 11.7276 11.4647 11.6969C11.9964 11.5335 12.2447 11.011 12.2026 10.1445C12.1437 8.79101 11.737 2.74537 11.6847 2.44318Z"
        fill="currentColor"
      />
      <path
        d="M14.0186 13.408C13.7579 13.3429 13.5157 13.1709 13.3493 12.9158C13.0897 12.5179 13.0979 12.0478 13.3702 11.7175C13.4071 11.67 14.2022 10.587 14.2022 10.587C16.111 7.97609 16.1505 7.92411 16.3139 7.81755C16.5817 7.6369 16.9075 7.62234 17.2284 7.77792C18.0057 8.15678 19.5271 10.4019 19.6227 11.3107L19.6244 11.3256L19.6238 11.3608C19.6448 11.703 19.5111 11.997 19.2467 12.1768C19.083 12.2831 19.0495 12.3047 15.4936 13.166C15.4936 13.166 14.4923 13.4743 14.0186 13.408Z"
        fill="currentColor"
      />
      <path
        d="M19.4138 16.3936C19.261 16.2796 19.2484 16.2702 16.1217 15.2425C15.6179 15.0764 14.8486 14.8221 14.7919 14.8004C14.3932 14.6475 13.9426 14.7847 13.6443 15.152C13.3363 15.5274 13.2956 16.0323 13.5396 16.4041L14.0714 17.2695C15.932 20.2918 16.0119 20.4066 16.1703 20.5304C16.3238 20.6508 16.5022 20.7114 16.6923 20.7114C16.8157 20.7114 16.944 20.686 17.0738 20.634C17.8906 20.3079 19.6046 18.1301 19.732 17.2553C19.7797 16.9075 19.6655 16.5948 19.4138 16.3936Z"
        fill="currentColor"
      />
    </>
  ),
});
