import { createIcon } from '@chakra-ui/react';

export const IconRepeatClockReverse: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconRepeatClockReverse',
  path: (
    <path
      d="M9.52381 4.79979H8.38095V8.79977L11.6419 10.8318L12.1905 9.86377L9.52381 8.19978V4.79979ZM9.14286 0.799805C7.32423 0.799805 5.58009 1.55837 4.29413 2.90863C3.00816 4.25888 2.28571 6.09023 2.28571 7.99978H0L3.01714 11.2238L6.09524 7.99978H3.80952C3.80952 6.51457 4.37143 5.09019 5.37162 4.03999C6.37182 2.98979 7.72837 2.3998 9.14286 2.3998C10.5573 2.3998 11.9139 2.98979 12.9141 4.03999C13.9143 5.09019 14.4762 6.51457 14.4762 7.99978C14.4762 9.48498 13.9143 10.9094 12.9141 11.9596C11.9139 13.0098 10.5573 13.5998 9.14286 13.5998C7.67238 13.5998 6.33905 12.9678 5.37905 11.9518L4.29714 13.0878C4.93065 13.7602 5.68547 14.2934 6.51748 14.656C7.3495 15.0187 8.24202 15.2035 9.14286 15.1997C10.9615 15.1997 12.7056 14.4412 13.9916 13.0909C15.2776 11.7407 16 9.90933 16 7.99978C16 6.09023 15.2776 4.25888 13.9916 2.90863C12.7056 1.55837 10.9615 0.799805 9.14286 0.799805Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 16 16',
});
