import { createIcon } from '@chakra-ui/react';

export const IconGoogleMeet: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconGoogleMeet',
  path: (
    <path
      d="M2.765 1.06502L0 3.87502H2.765V1.06502ZM2.964 1.06502V3.87502H6.768V5.70002L9.503 3.47502C9.496 2.86502 9.5185 2.35002 9.4905 1.74502C9.4165 1.20002 8.847 1.01002 8.3725 1.06502H2.964ZM11.55 2.16002C11.149 2.30752 10.871 2.65752 10.5265 2.90502C9.2735 3.93002 8.0355 4.96502 6.7925 6.00002C8.305 7.29502 9.8125 8.59002 11.325 9.88002C11.934 10.2155 12.039 9.47302 11.989 9.06002V2.56002C11.9889 2.50365 11.9773 2.4479 11.9549 2.39618C11.9325 2.34447 11.8997 2.29787 11.8587 2.25925C11.8176 2.22063 11.7691 2.1908 11.7161 2.17158C11.6631 2.15237 11.6068 2.14418 11.5505 2.14752L11.55 2.16002ZM0.019 4.07502V7.92502H2.784V4.07502H0.019ZM6.8075 8.12502H3.004V10.935C4.936 10.932 6.8725 10.9405 8.794 10.9305C9.304 10.8955 9.603 10.3705 9.528 9.89552V8.64052L6.793 6.30052V8.12552L6.8075 8.12502ZM0.019 8.12502C0.029 8.84502 -0.0015 9.56502 0.0355 10.28C0.1165 10.754 0.6145 10.995 1.059 10.935H2.791V8.12502H0.019Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 12 12',
});
