import { createIcon } from '@chakra-ui/react';

export const IconSortIndiscriminate: ReturnType<typeof createIcon> = createIcon({
  displayName: 'IconSortIndiscriminate',
  path: (
    <>
      <path d="M12 4L18 10H6L12 4Z" fill="currentColor" />
      <path d="M12 20L6 14H18L12 20Z" fill="currentColor" />
    </>
  ),
});
