import {
  Box,
  BoxProps,
  Icon as ChakraIcon,
  IconButton,
  SkeletonCircle,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { Fields } from '@hihello/core';
import { FunctionComponent } from 'react';
import { useStore } from 'zustand';
import { useCardStore } from '../../../../store';
import { useFieldActions, useFieldIcon } from '../../hooks';

export type DetailItemActionProps = BoxProps & {
  field?: Fields;
  icon?: JSX.Element;
};

export const DetailItemAction: FunctionComponent<DetailItemActionProps> = ({
  field,
  icon,
  ...props
}) => {
  const cardStore = useCardStore();
  const palette = useStore(cardStore, (state) => state.themePalette);
  const styles = useMultiStyleConfig('FieldListDetail', { palette });
  const actions = useFieldActions(field);
  const Icon = useFieldIcon(field);

  return (
    <Box __css={styles.action} {...props}>
      {field ? (
        <IconButton
          {...actions}
          aria-label="action"
          icon={icon ?? (Icon ? <ChakraIcon as={Icon} /> : undefined)}
        />
      ) : (
        <SkeletonCircle h="full" w="full" />
      )}
    </Box>
  );
};
